/// <reference path="../../../../../../web-workers/workers/typings/offscreen-canvas.d.ts" />

import {RectSize} from '../../../../../../globals/elements/rect-size';
import {BrowserUtil} from '../../../../../../utils/browser-util';
import {LayerEndpoints} from '../../api/layer-endpoints';
import {ScaledPreRenderData} from '../data/interfaces/scaled-pre-render-data';
import {PreRenderDrawPool} from '../drawers/interfaces/pre-render-draw-pool';
import {ScaledRasterElement} from '../elements/scaled-raster-element';
import {LoadingPool} from '../loader/loading-pool';
import {PreRender} from './interfaces/pre-render';

export class ScaledRasterPreRenderPart implements PreRender {

  private _canvas: HTMLCanvasElement;
  private _ctx: CanvasRenderingContext2D;
  private _drawImages: boolean[];
  private _loading: boolean;
  private _initLoad: boolean;

  public constructor(private _data: ScaledPreRenderData, private readonly _drawPool: PreRenderDrawPool,
                     private readonly _loader: LoadingPool, private readonly _offline: boolean) {
    if (!this._data.preLoading) return;
    this._initLoad = true;
    this.setCanvas();
  }

  private setCanvas(): void {
    if (!this._data.active) return;
    this._canvas = BrowserUtil.asyncRender ? new OffscreenCanvas(0, 0) as any : document.createElement('canvas');
    this._canvas.width = this._drawPool.canvas.width;
    this._canvas.height = this._drawPool.canvas.height;
    this._ctx = this._canvas.getContext('2d');
    this._ctx.imageSmoothingEnabled = true;
    this._ctx.imageSmoothingQuality = 'high';
    this.loadImages();
  }

  private loadImages(): void {
    this._loading = true;
    if (!this._drawImages) {
      this._drawImages = [];
      for (let i = 0; i < this._data.data.length; i++) this._drawImages.push(false);
    }
    this._data.data.forEach((element: ScaledRasterElement, index: number) => this.loadImage(element, index));
  }

  private loadImage(element: ScaledRasterElement, index: number): void {
    if (this._drawImages[index]) return;
    this._loader.load(LayerEndpoints.FILES + element.data,
      (imageData: ImageBitmap | HTMLImageElement) => this.drawImage(element, imageData, index), () => this.drawComplete(index),
      this._data.preLoading, this._offline);
  }

  private drawImage(element: RectSize, image: ImageBitmap | HTMLImageElement, index: number): void {
    if (!this._ctx) return;
    this._ctx.drawImage(image, element.x, element.y, element.width, element.height);
    if (BrowserUtil.asyncLoad && image instanceof ImageBitmap) image.close();
    else (image as HTMLImageElement).remove();
    this.drawComplete(index);
  }

  private drawComplete(index: number): void {
    this._drawImages[index] = true;
    if (!this.isDrawComplete || this._initLoad) return;
    this._loading = false;
    this._drawPool.reDraw();
  }

  private get isDrawComplete(): boolean {
    return this._drawImages ? this._drawImages.indexOf(false) < 0 : false;
  }

  public draw(): boolean {
    this._initLoad = false;
    if (!this._data.data) return true;
    if (!this._canvas) {
      this.setCanvas();
      return false;
    }
    if (!this.isDrawComplete) {
      if (!this._loading) this.loadImages();
      return false;
    }
    this._drawPool.addToDraw(this._canvas);
    return true;
  }

  public stopLoading(): void {
    this._loading = false;
  }

  public clear(): void {
    if (this._data.preLoading) return;
    this.destroy();
  }

  public destroy(): void {
    this._drawImages = undefined;
    if (!this._canvas) return;
    this._ctx = undefined;
    if (!BrowserUtil.asyncLoad) this._canvas.remove();
    this._canvas = undefined;
  }

  public setDisabledLayers(layers: boolean[]): boolean {
    const changed = this._data.setDisabledLayers(layers);
    if (changed) this.clear();
    return changed;
  }
}
