import {JsonProperty} from '@dhkatz/json-ts';
import {EnumConverter} from '../../../../../../../../globals/api/json/enum-converter';
import {RectSize} from '../../../../../../../../globals/elements/rect-size';
import {VectorType} from '../enums/vector-type.enum';

export abstract class DrawElement extends RectSize {

  @JsonProperty({name: 't', converter: EnumConverter.converter(VectorType)})
  private _type: VectorType;

  @JsonProperty('o')
  private _opacity: number;

  protected constructor() {
    super();
    this._type = undefined;
    this._opacity = undefined;
  }

  public get type(): VectorType {
    return this._type;
  }

  public get opacity(): number {
    return this._opacity;
  }
}
