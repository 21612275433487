import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ServiceWorkerService} from '../../../web-workers/service/service-worker.service';
import {JWT} from '../../../web-workers/workers/service-worker/interceptors/security-interceptor/jwt/jwt';
import {AuthorizationService} from '../../api/services/authorization.service';
import {ConfirmStatus} from '../enums/confirm-status.enum';

@Component({
  selector: 'app-register-confirm',
  templateUrl: './register-confirm.component.html',
  styleUrls: ['./register-confirm.component.scss']
})
export class RegisterConfirmComponent implements OnInit {

  public status: ConfirmStatus;
  public confirmStatus = ConfirmStatus;
  public showSpinner = true;
  public email: string;

  public constructor(private readonly _service: AuthorizationService, private readonly _route: ActivatedRoute,
                     private readonly _serviceWorker: ServiceWorkerService) {
  }

  public ngOnInit(): void {
    this._serviceWorker.logout();
    setTimeout(() => this._route.queryParams.subscribe((params) => {
      const token = params['tok'];
      try {
        this.email = JWT.getUnsafePayload(token).subject;
        if (!this.email) throw new Error('Token error');
      } catch {
        this.status = ConfirmStatus.invalid;
        this.showSpinner = false;
        return;
      }
      this._service.confirmRegister(token).subscribe(() => {
        this.status = ConfirmStatus.success;
      }, (error: HttpErrorResponse) => {
        this.showSpinner = false;
        if (error.error.key === 'register_confirm_token_used') {
          this.status = ConfirmStatus.used;
          return;
        }
        if (error.error.key === 'register_confirm_token_expired') {
          this.status = ConfirmStatus.expired;
          return;
        }
        this.status = ConfirmStatus.invalid;
      }, () => this.showSpinner = false);
    }), 0);
  }

  public resend(): void {
    if (!this.email) return;
    this.showSpinner = true;
    this._service.resendRegisterToken(this.email).subscribe(() => this.status = ConfirmStatus.resend,
      () => {
        this.status = ConfirmStatus.invalid;
        this.showSpinner = false;
      }, () => this.showSpinner = false);
  }
}
