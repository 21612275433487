import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {SubscriptionResponse} from '../../../../api/data/subscription-response';
import {SubscriptionCouponService} from '../../../../api/service/subscription-coupon.service';
import {SubscriptionAccessService} from '../../../../services/subscription-access.service';

@Component({
  selector: 'app-subscription-coupon',
  templateUrl: './subscription-coupon.component.html',
  styleUrls: ['./subscription-coupon.component.scss']
})
export class SubscriptionCouponComponent implements OnInit {

  @Output()
  public subscriptionAdded: EventEmitter<SubscriptionResponse> = new EventEmitter();

  @Output()
  public showSpinner: EventEmitter<boolean> = new EventEmitter();

  public couponField: FormControl;

  public constructor(private readonly _service: SubscriptionCouponService, private readonly _accessService: SubscriptionAccessService) {
  }

  public ngOnInit(): void {
    this.couponField = new FormControl();
  }

  public submit(): void {
    if (!this.couponField.value) return;
    this.showSpinner.emit(true);
    this._service.addCoupon(this.couponField.value).subscribe((response: SubscriptionResponse) => {
      this.couponField.setValue('');
      if (!response) return;
      this.subscriptionAdded.emit(response);
      this._accessService.clearAndReloadSubscriptionAccess();
    }, (error: HttpErrorResponse) => {
      if (error.error.key === 'subscription_coupon_not_found') this.couponField.setErrors({invalidCode: true});
      if (error.error.key === 'subscription_coupon_used') this.couponField.setErrors({used: true});
      if (error.error.key === 'subscription_coupon_inactive') this.couponField.setErrors({inactive: true});
      if (error.error.key === 'subscription_coupon_register_only') this.couponField.setErrors({registerOnly: true});
      if (error.error.key === 'subscription_coupon_invalid_role') this.couponField.setErrors({invalidRole: true});
      this.showSpinner.emit(false);
    }, () => this.showSpinner.emit(false));
  }
}
