import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractPartResponse} from './abstracts/abstract-part-response';

export class PartResponse extends AbstractPartResponse {

  @JsonProperty('d')
  private _data: string;

  public constructor() {
    super();
    this._data = undefined;
  }

  public get data(): string {
    return this._data;
  }
}
