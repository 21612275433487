export class GeoDataUtil {

  private static readonly URL = '/assets/geodata/';

  public static convert(config: any): any {
    const configCopy = JSON.parse(JSON.stringify(config));
    if (configCopy.type !== 'MapChart') return configCopy;
    return this.checkAndChange(configCopy);
  }

  private static checkAndChange(data: any): any {
    if (Array.isArray(data)) return data.map((value: any) => this.checkAndChange(value));
    for (const key in data) {
      if (data[key] === null || data[key] === undefined) continue;
      if (key === 'geodata') {
        if ((typeof data[key]).toLowerCase() !== 'string') continue;
        this.change(data);
      }
      if (this.isPrimitive(data[key])) continue;
      data[key] = this.checkAndChange(data[key]);
    }
    return data;
  }

  private static change(data: any): void {
    data.geodataSource = {url: this.URL + data.geodata + '.json'};
    delete data.geodata;
  }

  private static isPrimitive(data: any): boolean {
    return (typeof data).toLowerCase() === 'string' ||
      (typeof data).toLowerCase() === 'number' ||
      (typeof data).toLowerCase() === 'boolean' ||
      (typeof data).toLowerCase() === 'bigint' ||
      (typeof data).toLowerCase() === 'symbol' ||
      (typeof data).toLowerCase() === 'function';
  }
}
