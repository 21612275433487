import {Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {CategoryOfflineService} from '../../../../category/api/services/category-offline.service';
import {CategoryService} from '../../../../category/api/services/category.service';
import {RoutingConfig} from '../../../../configs/routing-config';
import {OfflineService} from '../../../../offline/services/offline-service/offline.service';
import {ProjectOfflineService} from '../../../../project/api/services/project-offline.service';
import {ProjectService} from '../../../../project/api/services/project.service';
import {TitleItem} from '../../../api/data/title-item';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnChanges {

  @Input()
  public categoryId: string;

  @Input()
  public projectId: string;

  @Input()
  public versionId: string;

  @Input()
  public title: string;

  @Output()
  public titleChange: EventEmitter<string> = new EventEmitter();

  @Output()
  public backLink: EventEmitter<string> = new EventEmitter<string>();

  public category: TitleItem[];

  public constructor(private readonly _categoryService: CategoryService, private readonly _categoryOfflineService: CategoryOfflineService,
                     private readonly _projectService: ProjectService, private readonly _projectOfflineService: ProjectOfflineService,
                     private readonly _router: Router, private readonly _template: ElementRef, private readonly _offline: OfflineService,
                     private readonly _translate: TranslateService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.categoryId) this.setCategoriesPath();
    if (changes.title) this.setBackLink();
    if (changes.projectId || changes.versionId) this.getTitle();
  }

  private setCategoriesPath(): void {
    if (!this.categoryId) {
      this.category = undefined;
      return;
    }
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      const requestMethod = result
        ? this._categoryOfflineService.getCategoryPath(this.categoryId, this.projectId)
        : this._categoryService.getCategoryPath(this.categoryId);
      requestMethod.subscribe((response: TitleItem[]) => {
        this.category = response;
        this.setBackLink();
      });
    });
  }

  private getTitle(): void {
    if (!this.projectId && !this.versionId) return;
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      const requestMethod = result
        ? this._projectOfflineService.getProjectTitle(this.projectId)
        : this.getOnlineMethod();
      requestMethod.subscribe((response: string) => {
        this.title = response;
        this.titleChange.emit(this.title);
        this.setBackLink();
      });
    });
  }

  private getOnlineMethod(): Observable<string> {
    return this.projectId === undefined
      ? this._projectService.getProjectTitlePreview(this.versionId)
      : this._projectService.getProjectTitle(this.projectId);
  }

  private setBackLink(): void {
    this.setBrowserTitle();
    if (!this.category || !this.category.length || (!this.title && this.category.length < 2)) {
      this.backLink.emit(undefined);
      return;
    }
    const prevIndex = this.category.length - (this.title ? 1 : 2);
    this.backLink.emit(RoutingConfig.CATEGORY.replace(RoutingConfig.CATEGORY_ID_PARAM, this.category[prevIndex].id));
    this.scrollToTitle();
  }

  public openCategory(id: string): void {
    this._router.navigate([RoutingConfig.CATEGORY.replace(RoutingConfig.CATEGORY_ID_PARAM, id)]);
  }

  private setBrowserTitle(): void {
    let title = this._translate.instant('system.title');
    if (this.title) {
      title = this.title + ' :: ' + title;
    } else if (this.category && this.category.length) {
      title = this.category[this.category.length - 1].title + ' :: ' + title;
    }
    document.title = title;
  }

  @HostListener('window:resize', ['$event'])
  private scrollToTitle(): void {
    setTimeout(() => {
      const title = this._template.nativeElement.getElementsByClassName('title');
      if (!title || !title.length) return;
      title.item(0).scrollIntoView({inline: 'end'});
    }, 0);
  }
}
