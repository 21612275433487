import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Json, JsonArray} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {ProjectResponse} from '../data/project-response';
import {ProjectEndpoints} from '../project-endpoints';
import {deserializeFix} from '../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getProjectTitle(projectId: string): Observable<string> {
    return this._http.get<string>(ProjectEndpoints.PROJECT_TITLE.replace(ProjectEndpoints.PROJECT_ID, projectId));
  }

  public getProjectCategories(projectId: string): Observable<string[]> {
    return this._http.get<string[]>(ProjectEndpoints.PROJECT_CATEGORIES.replace(ProjectEndpoints.PROJECT_ID, projectId));
  }

  public getProjectTitlePreview(versionId: string): Observable<string> {
    return this._http.get<string>(ProjectEndpoints.PROJECT_TITLE_PREVIEW.replace(ProjectEndpoints.VERSION_ID, versionId));
  }

  public getCategoryProjects(categoryId: string): Observable<ProjectResponse[]> {
    return this._http.get<JsonArray>(ProjectEndpoints.PROJECTS_CATEGORY.replace(ProjectEndpoints.CATEGORY_ID, categoryId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(ProjectResponse, value))));
  }

  public getDirectCategoryProjects(categoryId: string): Observable<ProjectResponse[]> {
    return this._http.get<JsonArray>(ProjectEndpoints.PROJECTS_DIRECT.replace(ProjectEndpoints.CATEGORY_ID, categoryId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(ProjectResponse, value))));
  }

  public getSearchProjects(search?: string, tags?: string[]): Observable<ProjectResponse[]> {
    const params = {};
    if (search) params['search'] = search;
    if (tags && tags.length) params['tags'] = tags;
    return this._http.get<JsonArray>(ProjectEndpoints.PROJECTS_SEARCH, {params})
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(ProjectResponse, value))));
  }
}
