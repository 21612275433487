import {Component, OnDestroy} from '@angular/core';
import {Rwd} from '../../../../globals/services/enums/rwd.enum';
import {RwdChange} from '../../../../globals/services/interfaces/rwd-change';
import {RwdService} from '../../../../globals/services/rwd.service';

@Component({template: ''})
export abstract class AbstractProjectsComponent implements OnDestroy, RwdChange {

  public carriageSize = 6;

  protected constructor(private readonly _rwd: RwdService) {
    this._rwd.addRwdChangeListener(this);
    this.onRwdChange(this._rwd.screen);
  }

  public onRwdChange(screen: Rwd): void {
    switch (screen) {
      case Rwd.screenXxxl:
        this.carriageSize = 6;
        break;
      case Rwd.screenXxl:
        this.carriageSize = 5;
        break;
      case Rwd.screenXl:
        this.carriageSize = 4;
        break;
      case Rwd.screenLg:
        this.carriageSize = 3;
        break;
      case Rwd.screenMd:
        this.carriageSize = 2;
        break;
      case Rwd.screenSm:
        this.carriageSize = 2;
        break;
      case Rwd.screenXs:
        this.carriageSize = 1;
        break;
    }
  }

  public ngOnDestroy(): void {
    this._rwd.removeRwdChangeListener(this);
  }
}
