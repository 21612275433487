import {JsonProperty} from '@dhkatz/json-ts';
import {PointType} from '../enums/point-type.enum';
import {EnumConverter} from '../../../../../../../../globals/api/json/enum-converter';

export abstract class AbstractPointResponse {

  @JsonProperty('id')
  private _id: string;

  @JsonProperty({name: 'ty', converter: EnumConverter.converter(PointType)})
  private _type: PointType;

  @JsonProperty('t')
  private _title: string;

  @JsonProperty('i')
  private _icon: number;

  @JsonProperty('s')
  private _source: string;

  @JsonProperty('su')
  private _sourceUrl: string;

  @JsonProperty('c')
  private _copyrights: string;

  @JsonProperty('cu')
  private _copyrightsUrl: string;

  @JsonProperty('d')
  private _description: string;

  @JsonProperty('x')
  private _x: number;

  @JsonProperty('y')
  private _y: number;

  protected constructor() {
    this._id = undefined;
    this._type = undefined;
    this._title = undefined;
    this._icon = undefined;
    this._source = undefined;
    this._sourceUrl = undefined;
    this._copyrights = undefined;
    this._copyrightsUrl = undefined;
    this._description = undefined;
    this._x = undefined;
    this._y = undefined;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get type(): PointType {
    return this._type;
  }

  public get title(): string {
    return this._title;
  }

  public get icon(): number {
    return this._icon;
  }

  public get source(): string {
    return this._source;
  }

  public get sourceUrl(): string {
    return this._sourceUrl;
  }

  public get copyrights(): string {
    return this._copyrights;
  }

  public get copyrightsUrl(): string {
    return this._copyrightsUrl;
  }

  public get description(): string {
    return this._description;
  }

  public get x(): number {
    return this._x;
  }

  public get y(): number {
    return this._y;
  }

  public set x(value: number) {
    this._x = value;
  }

  public set y(value: number) {
    this._y = value;
  }
}
