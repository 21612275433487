import {Injectable} from '@angular/core';
import {Subscriber} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {OfflineUtil} from '../../../utils/offline-util';
import {MiniMapEndpoints} from '../../version/modules/mini-map/api/mini-map-endpoints';
import {ProjectEndpoints} from '../project-endpoints';

@Injectable({
  providedIn: 'root'
})
export class ProjectOfflineService {

  public constructor() {
  }

  public getProjectTitle(projectId: string): Observable<string> {
    const path = ProjectEndpoints.PROJECT_TITLE.replace(MiniMapEndpoints.PROJECT_ID, projectId);
    return new Observable<string>((subscriber: Subscriber<string>) =>
      OfflineUtil.loadText(projectId, path, (file: string) => subscriber.next(file)));
  }
}
