import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractFilePointResponse} from './abstracts/abstract-file-point-response';

export class ImagePointResponse extends AbstractFilePointResponse {

  @JsonProperty('rf')
  private _retinaFile: string;

  @JsonProperty('fi')
  private _fileId: string;

  public constructor() {
    super();
    this._retinaFile = undefined;
    this._fileId = undefined;
  }

  public get retinaFile(): string {
    return this._retinaFile;
  }

  public get fileId(): string {
    return this._fileId;
  }
}
