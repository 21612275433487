import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ViewerConfig} from '../../../../../../../configs/viewer-config';
import {AbstractRoleComponent} from '../../../../../../../globals/screens/abstracts/abstract-role.component';
import {ServiceWorkerService} from '../../../../../../../web-workers/service/service-worker.service';
import {LoadingComplete} from '../../../../../screens/version/components/loading-progress/service/interfaces/loading-complete';
import {LoadingProgressService} from '../../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {SizeUnit} from '../../../api/data/enums/size-unit.enum';
import {ViewType} from '../../../api/data/enums/view-type.enum';
import {PanelsResponse} from '../../../api/data/panels-response';
import {PanelsCommunicationService} from '../../../communication/panels-communication.service';

export const panelAnimations = trigger('slideAnimation', [
  state('close', style({
    right: (window.innerWidth * -1) + 'px',
    display: 'none'
  })),
  state('preOpen', style({
    right: (window.innerWidth * -1) + 'px',
    display: 'block'
  })),
  state('open', style({
    right: ViewerConfig.RIGHT_BAR_SIZE + 'px',
  })),
  transition('open => close', animate(250)),
  transition('preOpen => open', animate(250)),
]);

@Component({
  selector: 'app-panel',
  animations: [panelAnimations],
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PanelComponent extends AbstractRoleComponent implements OnInit, OnDestroy, LoadingComplete {

  @ViewChild('panelElement', {static: true})
  public element: ElementRef;

  @Input()
  public panel: PanelsResponse;

  @Input()
  public panelElements: Map<string, PanelComponent>;

  public panelSize: number;
  public panelState = 'preInit';

  private _showPanel = true;

  public constructor(private readonly _cdr: ChangeDetectorRef, private readonly loading: LoadingProgressService,
                     private readonly _communication: PanelsCommunicationService, workerService: ServiceWorkerService) {
    super(workerService);
    this.loading.addLoadingCompleteListener(this);
    this.showPanel = false;
  }

  public onLoadingComplete(): void {
    setTimeout(() => {
      this.afterViewInit();
    }, 0);
  }

  public ngOnInit(): void {
    this.panelElements[this.panel.title] = this;
    this.panelSize = this.panel.unit === SizeUnit.pixel ? this.panel.size : window.innerWidth * this.panel.size / 100;
    this.afterViewInit();
  }

  public afterViewInit(): void {
    this.element.nativeElement.style.width = this.panelSize + 'px';
    this._cdr.detectChanges();
  }

  public get showPanel(): boolean {
    return this._showPanel;
  }

  public set showPanel(value: boolean) {
    const changed = this._showPanel !== value;
    this._showPanel = value;
    if (changed) this.panelState = value ? 'preOpen' : 'close';
    setTimeout(() => {
      if (value && changed) this.panelState = 'open';
    }, 0);
  }

  public isFlex(): string {
    return this.panel.viewType === ViewType.grid ? 'display: flex; flex-wrap: wrap' : '';
  }

  public ngOnDestroy(): void {
    this.loading.removeLoadingCompleteListener(this);
  }

  public hidePanel(): void {
    if (!this.showPanel) return;
    this.showPanel = false;
    this._communication.panelHide();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.panelSize = this.panel.unit === SizeUnit.pixel ? this.panel.size : window.innerWidth * this.panel.size / 100;
    this.afterViewInit();
  }
}
