import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Json, JsonArray, JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomImagesEndpoints} from '../custom-images-endpoints';
import {ImagePointFileResponse} from '../data/image-point-file-response';
import {deserializeFix} from '../../../../../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class ImagePointFileService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getImages(): Observable<ImagePointFileResponse[]> {
    return this._http.get<JsonArray>(CustomImagesEndpoints.IMAGE_POINT_FILES)
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(ImagePointFileResponse, value))));
  }

  public uploadImage(fileToUpload: File): Observable<ImagePointFileResponse> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this._http.post<JsonObject>(CustomImagesEndpoints.IMAGE_POINT_FILES, formData)
      .pipe(map((response: JsonObject) => deserializeFix(ImagePointFileResponse, response)));
  }

  public getImage(fileId: string): Observable<ImagePointFileResponse> {
    return this._http.get<JsonObject>(CustomImagesEndpoints.IMAGE_POINT_FILE.replace(CustomImagesEndpoints.FILE_ID, fileId))
      .pipe(map((response: JsonObject) => deserializeFix(ImagePointFileResponse, response)));
  }

  public deleteImage(fileId: string): Observable<void> {
    return this._http.delete<void>(CustomImagesEndpoints.IMAGE_POINT_FILE.replace(CustomImagesEndpoints.FILE_ID, fileId));
  }
}
