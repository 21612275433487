import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {ViewerConfig} from '../../../../../../configs/viewer-config';
import {Endpoints} from '../../../../../../globals/api/endpoints';
import {RectSize} from '../../../../../../globals/elements/rect-size';
import {OfflineService} from '../../../../../../offline/services/offline-service/offline.service';
import {PrintService} from '../../../../../../print/communication/print.service';
import {DragComponent} from '../../../../../../services/drag-service/components/drag.component';
import {DragService} from '../../../../../../services/drag-service/drag.service';
import {Pinch} from '../../../../../../services/pinch-service/interfaces/pinch';
import {PinchService} from '../../../../../../services/pinch-service/pinch.service';
import {FileSystemUtil} from '../../../../../../utils/file-system-util';
import {ServiceWorkerService} from '../../../../../../web-workers/service/service-worker.service';
import {DraggableWindow} from '../../../../screens/version/components/interfaces/draggable-window';
import {LoadingProgressService} from '../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {AbstractWindowComponent, contentAnimations, windowAnimations} from '../../../abstracts/screens/abstract-window.component';
import {CustomInteractivesCommunicationService} from '../../../layer-manager/modules/custom-interactives-manager/communiaction/custom-interactives-communication.service';
import {ScaleChange} from '../../../layer/communication/interfaces/scale-change';
import {ViewChange} from '../../../layer/communication/interfaces/view-change';
import {ViewMove} from '../../../layer/communication/interfaces/view-move';
import {LayersCommunicationService} from '../../../layer/communication/layers-communication.service';
import {MiniMapResponse} from '../../api/data/mini-map-response';
import {MiniMapOfflineService} from '../../api/services/mini-map-offline.service';
import {MiniMapService} from '../../api/services/mini-map.service';

@Component({
  selector: 'app-mini-map',
  animations: [windowAnimations, contentAnimations],
  templateUrl: './mini-map.component.html',
  styleUrls: ['./mini-map.component.scss']
})
export class MiniMapComponent extends AbstractWindowComponent implements OnInit, OnDestroy, ViewMove, ViewChange, Pinch,
  ScaleChange, DraggableWindow {

  @Input()
  public versionId: string;

  @Input()
  public projectId: string;

  @Input()
  public windows: Map<string, DraggableWindow>;

  private isOffline: boolean;

  @ViewChild('miniMap', {static: true})
  private _canvasRef: ElementRef;

  @ViewChild('resize', {static: true})
  private _resizeRef: ElementRef;

  private _img: HTMLImageElement;
  private _x: number;
  private _y: number;
  private _scale: number;
  private _canvasDrag: DragComponent;
  private _resizeDrag: DragComponent;
  private _miniMap: MiniMapResponse;
  private _windowStartParams: RectSize;

  public constructor(private readonly _miniMapService: MiniMapService, private readonly _miniMapOfflineService: MiniMapOfflineService,
                     private readonly _dragService: DragService, private readonly _pinchService: PinchService,
                     private readonly _offline: OfflineService,
                     layersCommunication: LayersCommunicationService, loading: LoadingProgressService, print: PrintService,
                     workerService: ServiceWorkerService, customInteractivesCommunicationService: CustomInteractivesCommunicationService) {
    super(layersCommunication, customInteractivesCommunicationService, loading, print, workerService);
    layersCommunication.addViewMoveListener(this);
    layersCommunication.addScaleChangeListener(this);
  }

  protected onShowWindow(value: boolean): void {
    if (value) this.draw();
  }

  public afterViewInit(): void {
    this.updateSize();
    this.windowParams.y = ViewerConfig.TOP_BAR_SIZE + 10;
    this.windowParams.x = this.viewWidth - this.windowParams.width + ViewerConfig.LEFT_BAR_SIZE - 10;
    super.afterViewInit();
    this.draw();
  }

  private canvasOnDrag(event: any): void {
    const offset = this._dragService.getElementOffset(this._canvasRef, event);
    this.moveMapToPosition(offset.x, offset.y);
  }

  private canvasOnStartDrag(event: any): void {
    this.setOnTop(this);
    const offset = this._dragService.getElementOffset(this._canvasRef, event);
    this.moveMapToPosition(offset.x, offset.y);
  }

  private resizeOnDrag(event: any, startInfo: any): void {
    const posX = startInfo.offsetX + event.clientX - startInfo.x;
    this.resizeWindow(posX);
    this.resizeCanvas();
    this.draw();
  }

  private resizeOnStartDrag(event: any): void {
    this.setOnTop(this);
    this._windowStartParams = new RectSize(this.windowParams);
  }

  public onPinch(distanceDiff: number): void {
    this._windowStartParams = new RectSize(this.windowParams);
    this.resizeWindow(-distanceDiff);
    this.resizeCanvas();
    this.draw();
  }

  public ngOnInit(): void {
    this.afterViewInit();
    this.windows['MiniMapWindow'] = this;
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      this.isOffline = result;
      setTimeout(() => {
        const requestMethod = this.isOffline
          ? this._miniMapOfflineService.getMiniMap(this.projectId)
          : this.getOnlineMethod();
        requestMethod.subscribe((response: MiniMapResponse) => {
          this._miniMap = response;
          this.handleResponse();
        });
      }, 0);
    });
    this._canvasDrag = new DragComponent(this._canvasRef,
      (event: any) => this.canvasOnDrag(event),
      (event: any) => this.canvasOnStartDrag(event));
    this._dragService.addDrag(this._canvasDrag);
    this._resizeDrag = new DragComponent(this._resizeRef,
      (event: any, x?: number, y?: number, startInfo?: any) => this.resizeOnDrag(event, startInfo),
      (event: any) => this.resizeOnStartDrag(event));
    this._dragService.addDrag(this._resizeDrag);
    this._pinchService.addPinch(this);
  }

  private getOnlineMethod(): Observable<MiniMapResponse> {
    return this.projectId === undefined
      ? this._miniMapService.getMiniMapPreview(this.versionId)
      : this._miniMapService.getMiniMap(this.projectId);
  }

  private handleResponse(): void {
    if (!this._miniMap) return;
    this.createImg(this._miniMap.file);
    this.draw();
    this.loading.configLoaded();
  }

  public onViewMove(x: number, y: number): void {
    this._x = x;
    this._y = y;
    this.draw();
  }

  public onViewChange(width: number, height: number): void {
    if (this.printPreview) return;
    super.onViewChange(width, height);
    this.draw();
  }

  public onScaleChange(scale: number): void {
    this._scale = scale;
    this.draw();
  }

  private createImg(url: string): void {
    this._img = new Image();
    this._img.src = this.isOffline ? FileSystemUtil.getOfflineUrl(this.projectId, Endpoints.FILES + url) : Endpoints.FILES + url;
    this._img.onload = () => this.draw();
  }

  private resizeWindow(posX: number): void {
    const widthLimits = this.getWindowWidthLimits();
    let width = this._windowStartParams.width - posX;
    let x = this._windowStartParams.x + posX;
    if (width > widthLimits.max) {
      x += width - widthLimits.max;
      width = widthLimits.max;
    } else if (width < widthLimits.min) {
      x -= widthLimits.min - width;
      width = widthLimits.min;
    }
    this.windowParams.width = width;
    this.windowParams.x = x;
    this.element.nativeElement.style.width = this.windowParams.width + 'px';
    this.element.nativeElement.style.left = this.windowParams.x + 'px';
  }

  private getWindowWidthLimits(): { max: number, min: number } {
    const proportion = this._img.width / this._img.height;
    const widthSpace = this.element.nativeElement.clientWidth - this._canvasRef.nativeElement.width;
    const heightSpace = this.element.nativeElement.clientHeight - this._canvasRef.nativeElement.height;
    let maxHeight = window.innerHeight * 0.6;
    const maxYPosition = window.innerHeight - ViewerConfig.BOTTOM_BAR_SIZE;
    if (this.windowParams.y + maxHeight > maxYPosition) maxHeight = maxYPosition - this.windowParams.y;
    let maxWidth = (maxHeight - heightSpace) * proportion + widthSpace;
    const maxWidthX = this.windowParams.width + this.windowParams.x - maxWidth;
    if (maxWidthX < ViewerConfig.LEFT_BAR_SIZE) maxWidth -= ViewerConfig.LEFT_BAR_SIZE - maxWidthX - 1;
    return {max: Math.floor(maxWidth), min: Math.floor(window.innerHeight * 0.25 / proportion)};
  }

  private resizeCanvas(): void {
    const proportion = this._img.width / this._img.height;
    this._canvasRef.nativeElement.width = this.element.nativeElement.clientWidth - 20;
    this._canvasRef.nativeElement.height = this._canvasRef.nativeElement.width / proportion;
  }

  private draw(): void {
    if (this._img === undefined || this._img.width === 0) return;
    this.resizeCanvas();
    const canvas = this._canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(this._img, 0, 0, canvas.width, canvas.height);
    this.drawRect(canvas, ctx);
  }

  private drawRect(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    let rectWidth = canvas.width * (this.viewWidth / (this._miniMap.width * this._scale));
    rectWidth = rectWidth > canvas.width ? canvas.width : rectWidth;

    let rectHeight = canvas.height * (this.viewHeight / (this._miniMap.height * this._scale));
    rectHeight = rectHeight > canvas.height ? canvas.height : rectHeight;

    let rectX = this._x < 0 ? this._x * canvas.width / (this._miniMap.width * this._scale * -1) : 0;
    rectX = rectX > canvas.width - rectWidth ? canvas.width - rectWidth : rectX;

    let rectY = this._y < 0 ? this._y * canvas.height / (this._miniMap.height * this._scale * -1) : 0;
    rectY = rectY > canvas.height - rectHeight ? canvas.height - rectHeight : rectY;

    ctx.save();
    ctx.strokeStyle = '#ed523d';
    ctx.lineWidth = 2;
    ctx.strokeRect(rectX + 1, rectY + 1, rectWidth - 1.5, rectHeight - 1.5);
    ctx.restore();
  }

  private moveMapToPosition(x: number, y: number): void {
    const canvas = this._canvasRef.nativeElement;
    x = x / (canvas.width / (this._miniMap.width * this._scale * -1)) + this.viewWidth / 2;
    y = y / (canvas.height / (this._miniMap.height * this._scale * -1)) + this.viewHeight / 2;
    this.layersCommunication.viewMove(x, y);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._dragService.removeDrag(this._canvasDrag);
    this._dragService.removeDrag(this._resizeDrag);
    this._pinchService.removePinch(this);
    this.layersCommunication.removeViewMoveListener(this);
    this.layersCommunication.removeScaleChangeListener(this);
  }

  public customInteractiveEditModeEnabled(layerId: string, projectId: string): void {
  }

  public customInteractiveEditModeDisabled(): void {
  }
}
