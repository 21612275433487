import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {deserializeFix} from '../../../../../utils/deserialize-util';
import {ServiceWorkerService} from '../../../../../web-workers/service/service-worker.service';
import {AddressData} from '../../../../api/data/address-data';
import {AddressDataRequest} from '../../../../api/data/address-data-request';
import {AccountService} from '../../../../api/service/account.service';
import {InstitutionsResponse} from '../../../../institutions/api/data/institutions-response';
import {InstitutionsService} from '../../../../institutions/api/service/institutions.service';
import {AbstractAccountPart} from '../abstracts/abstract-account-part';

@Component({
  selector: 'app-address-data',
  templateUrl: './address-data.component.html',
  styleUrls: ['./address-data.component.scss']
})
export class AddressDataComponent extends AbstractAccountPart implements OnInit {

  @Input()
  public addressData: AddressData;

  @Input()
  public institutions: string[] = [];

  public nameField: FormControl;
  public surnameField: FormControl;
  public institutionNameField: FormControl;
  public streetNameField: FormControl;
  public streetNumberField: FormControl;
  public apartmentNumberField: FormControl;
  public postCodeField: FormControl;
  public cityField: FormControl;
  public stateField: FormControl;
  public taxIdField: FormControl;
  public phoneNoField: FormControl;

  public institutionsFound: InstitutionsResponse[] = [];

  private _institutionsAssigned: string[] = [];

  public constructor(service: AccountService, snackBar: MatSnackBar, serviceWorker: ServiceWorkerService,
                     private readonly _translate: TranslateService, private readonly _institutionsService: InstitutionsService) {
    super(service, snackBar, serviceWorker);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.isInstitution) return;
    this.findInstitution(this.addressData && this.addressData.institutionName ? this.addressData.institutionName : '');
    this._institutionsAssigned = this.institutions;
  }

  public findInstitution(search: string): void {
    this._institutionsService.search(search).subscribe((response: InstitutionsResponse[]) => this.institutionsFound = response);
  }

  protected createForm(): void {
    this.form = new FormGroup({
      firstName: this.nameField,
      lastName: this.surnameField,
      institutionName: this.institutionNameField,
      streetName: this.streetNameField,
      streetNumber: this.streetNumberField,
      apartmentNumber: this.apartmentNumberField,
      postCode: this.postCodeField,
      city: this.cityField,
      state: this.stateField,
      taxId: this.taxIdField,
      phoneNo: this.phoneNoField
    });
  }

  protected createFormControls(): void {
    if (!this.addressData) this.addressData = new AddressData();
    this.nameField = new FormControl(this.addressData.firstName, [Validators.required]);
    this.surnameField = new FormControl(this.addressData.lastName, [Validators.required]);
    this.institutionNameField = new FormControl(this.addressData.institutionName,
      this.isAgent || this.isInstitution ? [Validators.required] : []);
    this.streetNameField = new FormControl(this.addressData.streetName, this.isInstitution ? [Validators.required] : []);
    this.streetNumberField = new FormControl(this.addressData.streetNumber, this.isInstitution ? [Validators.required] : []);
    this.apartmentNumberField = new FormControl(this.addressData.apartmentNumber);
    this.postCodeField = new FormControl(this.addressData.postCode, this.isInstitution ? [Validators.required] : []);
    this.cityField = new FormControl(this.addressData.city, this.isInstitution ? [Validators.required] : []);
    this.stateField = new FormControl(this.addressData.state, this.isInstitution ? [Validators.required] : []);
    this.taxIdField = new FormControl(this.addressData.taxId, this.isInstitution ? [Validators.required] : []);
    this.phoneNoField = new FormControl(this.addressData.phoneNo, this.isInstitution ? [Validators.required] : []);
  }

  public submit(): void {
    if (this.form.invalid) return;
    const request = deserializeFix(AddressDataRequest, this.form.value);
    if (this.isInstitution) request.institutions = this._institutionsAssigned;
    this.showSpinner = true;
    this.service.changeAddressData(request).subscribe(() => {
      this.addressData = request;
      ViewerConfig.openSnackBar(this.snackBar, this._translate.instant('system.toast.savedSuccessfully'));
      this.institutions = this._institutionsAssigned;
      this.cancel();
    }, () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public cancel(): void {
    this.form.reset();
    this.nameField.setValue(this.addressData.firstName);
    this.surnameField.setValue(this.addressData.lastName);
    this.institutionNameField.setValue(this.addressData.institutionName);
    this.streetNameField.setValue(this.addressData.streetName);
    this.streetNumberField.setValue(this.addressData.streetNumber);
    this.apartmentNumberField.setValue(this.addressData.apartmentNumber);
    this.postCodeField.setValue(this.addressData.postCode);
    this.cityField.setValue(this.addressData.city);
    this.stateField.setValue(this.addressData.state);
    this.taxIdField.setValue(this.addressData.taxId);
    this.phoneNoField.setValue(this.addressData.phoneNo);
    this.enableEdit = false;
    this._institutionsAssigned = this.institutions;
  }

  public assignToInstitution(institution: InstitutionsResponse): void {
    this.streetNameField.setValue(institution.streetName ? institution.streetName.trim() : '');
    this.streetNumberField.setValue(institution.streetNo ? institution.streetNo.trim() : '');
    this.apartmentNumberField.setValue(institution.apartmentNo ? institution.apartmentNo.trim() : '');
    this.postCodeField.setValue(institution.postCode ? institution.postCode.trim() : '');
    this.cityField.setValue(institution.city ? institution.city.trim() : '');
    this.stateField.setValue(institution.province ? institution.province.trim() : '');
    this.taxIdField.setValue(institution.taxId ? institution.taxId.trim() : '');
    this._institutionsAssigned = [institution.id];
  }
}
