import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EmbedPointResponse} from '../../../../../../../../interactive/api/data/points/embed-point-response';
import {LinkType} from '../../../../../../../../interactive/api/data/points/enums/link-type.enum';
import {PointType} from '../../../../../../../../interactive/api/data/points/enums/point-type.enum';
import {EmbedPointRequest} from '../../../../../../../../interactive/modules/points/api/data/embed-point-request';
import {PointService} from '../../../../../../../../interactive/modules/points/api/services/point.service';
import {CustomInteractivesCommunicationService} from '../../../../../communiaction/custom-interactives-communication.service';
import {AbstractCustomPointComponent} from '../abstracts/abstract-custom-point.component';

@Component({
  selector: 'app-embed-point-dialog',
  templateUrl: './embed-point-dialog.component.html',
  styleUrls: ['./embed-point-dialog.component.scss']
})

export class EmbedPointDialogComponent extends AbstractCustomPointComponent<EmbedPointRequest, EmbedPointResponse> implements OnInit {

  public embedLinkField: FormControl;
  public embedTypeField: FormControl;

  public linkTypes: LinkType[];
  public removePoint = false;

  public constructor(service: PointService, communication: CustomInteractivesCommunicationService,
                     @Inject(MAT_DIALOG_DATA) data: any, dialogRef: MatDialogRef<EmbedPointDialogComponent>) {
    super(service, communication, dialogRef, data);
    this.pointType = PointType.embed;
    this.linkTypes = this.getValue('type');
  }

  protected createFormControls(): void {
    super.createFormControls();
    this.embedLinkField = new FormControl(this.point ? this.point.link : '', [Validators.required]);
    if (this.linkTypes.length === 1) this.embedTypeField = new FormControl(this.linkTypes[0], [Validators.required]);
    else this.embedTypeField = new FormControl(this.point ? this.point.linkType : '', [Validators.required]);
  }

  protected createForm(): void {
    super.createForm();
    this.form.addControl('embedLinkField', this.embedLinkField);
    this.form.addControl('embedTypeField', this.embedTypeField);
  }

  private parseLink(link: string): string {
    if (this.embedTypeField.value === LinkType.youtube) return this.parseYoutube(link);
    if (this.embedTypeField.value === LinkType.vimeo) return this.parseVimeo(link);
    return link;
  }

  private parseYoutube(link: string): string {
    const oldLink = link;
    if (!link) return;
    if (link.includes('v=')) link = link.split('v=')[1].split('&')[0];
    else if (link.includes('/embed/')) link = link.split('/embed/')[1];
    else if (link.includes('youtu.be/')) link = link.split('youtu.be/')[1];
    link = link.split(' ').join('');
    if (oldLink === link) return oldLink;
    return link;
  }

  protected getRequest(): EmbedPointRequest {
    const request = new EmbedPointRequest();
    request.link = this.parseLink(this.embedLinkField.value);
    request.linkType = this.embedTypeField.value;
    return request;
  }

  private parseVimeo(link: string): string {
    if (link.includes('vimeo.com/')) return link.split('vimeo.com/')[1];
    return link;
  }
}
