import {JsonProperty} from '@dhkatz/json-ts';
import {Role} from './enums/role.enum';
import {EnumConverter} from '../../../globals/api/json/enum-converter';

export class CouponValidRequest {

  @JsonProperty('coupon')
  private _coupon: string;

  @JsonProperty({name: 'role', converter: EnumConverter.converter(Role)})
  private _role: Role;

  public constructor() {
    this._coupon = undefined;
    this._role = undefined;
  }

  public get coupon(): string {
    return this._coupon;
  }

  public set coupon(value: string) {
    this._coupon = value;
  }

  public get role(): Role {
    return this._role;
  }

  public set role(value: Role) {
    this._role = value;
  }
}
