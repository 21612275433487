import {JsonProperty} from '@dhkatz/json-ts';

export class UpdateMyPasswordRequest {

  @JsonProperty('password')
  private _password: string;

  @JsonProperty('oldPassword')
  private _oldPassword: string;

  @JsonProperty('isAutoLogin')
  private _isAutoLogin: boolean;

  public constructor() {
    this._password = undefined;
    this._oldPassword = undefined;
    this._isAutoLogin = undefined;
  }

  public get password(): string {
    return this._password;
  }

  public set password(value: string) {
    this._password = value;
  }

  public get oldPassword(): string {
    return this._oldPassword;
  }

  public set oldPassword(value: string) {
    this._oldPassword = value;
  }

  public get isAutoLogin(): boolean {
    return this._isAutoLogin;
  }

  public set isAutoLogin(value: boolean) {
    this._isAutoLogin = value;
  }
}
