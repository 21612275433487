import {ElementRef} from '@angular/core';
import {Pinch} from '../interfaces/pinch';

export class PinchComponent implements Pinch {

  private readonly _componentTarget: ElementRef;
  private readonly _onPinch: (distanceDiff: number, center?: { x: number; y: number }, distance?: number) => void;

  public constructor(componentTarget: ElementRef,
                     onPinch: (distanceDiff: number, center?: { x: number; y: number }, distance?: number) => void) {
    this._componentTarget = componentTarget;
    this._onPinch = onPinch;
  }

  public get element(): ElementRef {
    return this._componentTarget;
  }

  public onPinch(distanceDiff: number, center?: { x: number; y: number }, distance?: number): void {
    if (this._onPinch) this._onPinch(distanceDiff, center, distance);
  }
}
