import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CacheEndpoints} from '../cache-endpoints';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getCacheDates(): Observable<void> {
    return this._http.get<void>(CacheEndpoints.CACHE_DATES);
  }
}
