import {Endpoints} from '../../globals/api/endpoints';

export class MessagesEndpoints extends Endpoints {

  public static readonly MESSAGES = MessagesEndpoints.VIEWER + '/messages';
  public static readonly MESSAGES_COUNT = MessagesEndpoints.MESSAGES + '/count';
  public static readonly MESSAGES_MARK_ALL_AS_READ = MessagesEndpoints.MESSAGES + '/mark';

  public static readonly MESSAGE = MessagesEndpoints.MESSAGES + '/' + MessagesEndpoints.MESSAGE_ID;
  public static readonly MESSAGE_CONTENT = MessagesEndpoints.MESSAGE + '/content';
}
