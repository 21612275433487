import {JsonProperty} from '@dhkatz/json-ts';
import {TitleItem} from '../../../../../../../../globals/api/data/title-item';

export class CustomInteractiveRequest extends TitleItem {

  @JsonProperty('enabled')
  private _enabled: boolean;

  public constructor() {
    super();
    this._enabled = undefined;
  }

  public get enabled(): boolean {
    return this._enabled;
  }

  public set enabled(value: boolean) {
    this._enabled = value;
  }
}
