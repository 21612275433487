import {Endpoints} from '../globals/api/endpoints';
import {IdbUtil} from './idb-util';

type StringCallback = (file: string) => void;
type UrlCallback = (file: FileEntry) => string;

export class FileSystemUtil {

  public static FILE_SYSTEM_STORAGE_SIZE = 1024 * 1024 * 1024 * 1024 * 10;

  public static isFileSystemAvailAble(): boolean {
    const fileSystem = self.requestFileSystem || self.webkitRequestFileSystem;
    return !!fileSystem;
  }

  public static hasAccess(): Promise<boolean> {
    return IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).get('FILE_SYSTEM_ACCESS')
      .then((result: boolean) => result)
      .catch(() => false);
  }

  public static checkAndRequestFileSystem(successCallback): void {
    self.requestFileSystem = (<any>self).requestFileSystem || (<any>self).webkitRequestFileSystem;
    this.hasAccess().then((result: boolean) => {
      if (result) {
        this.success(successCallback);
        return;
      }
      (<any>navigator).webkitPersistentStorage.requestQuota(FileSystemUtil.FILE_SYSTEM_STORAGE_SIZE, ((grantedSize: number) => {
        if (grantedSize) IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).set('FILE_SYSTEM_ACCESS', true).then(() => {
          this.success(successCallback);
        });
      }));
    });
  }

  private static success(successCallback): void {
    self.requestFileSystem((<any>self).PERSISTENT, this.FILE_SYSTEM_STORAGE_SIZE, (fs) => {
      if (fs) successCallback();
    });
  }

  public static saveToFileSystem(projectId: string, filePath: string, blob: Blob, success, error): void {
    self.requestFileSystem = (<any>self).requestFileSystem || (<any>self).webkitRequestFileSystem;

    self.requestFileSystem((<any>self).PERSISTENT, this.FILE_SYSTEM_STORAGE_SIZE, (fs) => {
      this.saveFile(this.getPath(projectId, filePath), fs.root, blob, success, error);
    }, (err) => console.log(err));
  }

  public static loadFileFromFileSystem(projectId: string, path: string, dir: DirectoryEntry, successCallback: FileCallback,
                                       errorCallback?: any): void {
    this.loadFile(this.getPath(projectId, path), dir, successCallback, () => {
      console.error(projectId, path);
      errorCallback();
    });
  }

  public static loadFileFromFileSystemSync(projectId: string, path: string, dir: DirectoryEntrySync): Blob {
    return this.loadFileSync(this.getPath(projectId, path), dir) as Blob;
  }

  public static loadTextFileFromFileSystem(projectId: string, path: string, successCallback: StringCallback, errorCallback?: any): void {
    window.requestFileSystem = (<any>window).requestFileSystem || (<any>window).webkitRequestFileSystem;
    window.requestFileSystem((<any>window).PERSISTENT, 1024, (fs) => {
      const fileSystemPath = this.getPath(projectId, path);
      this.loadText(fileSystemPath, fs.root, successCallback, () => {
        console.log(fileSystemPath);
        if (errorCallback) errorCallback();
      });
    });
  }

  public static removeFromOffline(projectId: string, success: any, error?: any): void {
    window.requestFileSystem = (<any>window).requestFileSystem || (<any>window).webkitRequestFileSystem;
    window.requestFileSystem((<any>window).PERSISTENT, 1024, (fs) => {
      fs.root.getDirectory(projectId, {create: false}, (dir: DirectoryEntry) => {
        dir.removeRecursively(success, (err: DOMException) => {
          if (error) error(err);
          console.log(err.message);
        });
      }, (err: DOMException) => {
        if (error) error(err);
        console.log(err.message);
      });
    }, (err: DOMException) => {
      if (error) error(err);
      console.log(err.message);
    });
  }

  private static getPath(projectId: string, filePath: string): string[] {
    return [projectId].concat(...filePath.split(/[\\\/]/).slice(4));
  }

  private static saveFile(folders: string[], dir: DirectoryEntry, blob: Blob, success, error): void {
    if (!folders.length) return;

    if (folders.length === 1) {
      dir.getFile(folders[0], {create: true}, (file: FileEntry) => {
        file.remove(() => {
          dir.getFile(folders[0], {create: true}, (file: FileEntry) => {
            file.createWriter((writer: FileWriter) => {
              writer.onwriteend = () => success();
              writer.onerror = () => error();
              writer.write(blob);
            }, (err: DOMException) => {
              console.log(err.message);
              error();
            });
          }, (err: DOMException) => {
            console.log(err.message);
            error();
          });
        }, () => {
        });
      }, () => {
      });
      return;
    } else {
      dir.getDirectory(folders[0], {create: true}, (newDir: DirectoryEntry) => {
        this.saveFile(folders.slice(1), newDir, blob, success, error);
      }, (err: DOMException) => {
        console.log(err.message);
        error();
      });
    }
  }

  private static loadFile(folders: string[], dir: DirectoryEntry, successCallback: FileCallback, errorCallback?: any): void {
    if (!folders.length) return undefined;

    if (folders.length === 1) {
      dir.getFile(folders[0], {create: false}, (file: FileEntry) => {
        file.file(successCallback);
      }, (err: DOMException) => {
        if (errorCallback) errorCallback();
        console.log(folders, err.message);
      });
      return;
    }
    dir.getDirectory(folders[0], {create: true}, (newDir: DirectoryEntry) => {
      return this.loadFile(folders.slice(1), newDir, successCallback, errorCallback);
    }, (err: DOMException) => {
      if (errorCallback) errorCallback();
      console.log(folders[0], err.message);
    });
  }

  private static loadFileSync(folders: string[], dir: DirectoryEntrySync): Blob | DirectoryEntrySync {
    if (!folders.length) return undefined;
    const folder = folders[0];
    if (folders.length > 1) {
      try {
        const directory = dir.getDirectory(folder, {create: false});
        return this.loadFileSync(folders.slice(1), directory);
      } catch (e) {
        console.log(folders, e);
      }
    } else {
      try {
        const file = dir.getFile(folder, {create: false});
        return file.file();
      } catch (e) {
        console.log(folders, e);
      }
    }
  }

  private static loadText(folders: string[], dir: DirectoryEntry, successCallback: StringCallback, errorCallback?: any): void {
    if (!folders.length) return undefined;
    if (folders.length === 1) {
      dir.getFile(folders[0], {create: false}, (fileEntry: FileEntry) => {
        fileEntry.file((file: File) => {
          const reader = new FileReader();

          reader.onerror = (ev) => console.log(ev);
          reader.onload = () => successCallback(reader.result as string);

          reader.readAsText(file);
        }, (err: DOMException) => {
          if (errorCallback) errorCallback();
          console.log(err.message);
        });
      }, (err: DOMException) => {
        if (errorCallback) errorCallback();
        console.log(err.message);
      });
      return;
    }
    dir.getDirectory(folders[0], {create: true}, (newDir: DirectoryEntry) => {
      return this.loadText(folders.slice(1), newDir, successCallback, errorCallback);
    }, (err: DOMException) => {
      if (errorCallback) errorCallback();
      console.log(folders[0], err.message);
    });
  }

  public static getOfflineUrl(projectId: string, url: string): string {
    const fileSystemPath = 'filesystem:' + self.location.protocol + '//' + self.location.host + '/persistent/' + projectId;
    return url.replace(Endpoints.VIEWER, fileSystemPath);
  }
}
