import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  public constructor(private readonly _sanitizer: DomSanitizer) {
  }

  public transform(url): any {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
