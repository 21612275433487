<div class="messages-part">
  <h4 class="section-name">{{'messages.info.message' | translate}}</h4>
  <ng-container *ngIf="selected != null; else noSelected">
    <span class="date">{{selected?.published | date:'dd.MM.yy'}}</span>
    <div class="message-title" [innerHTML]="selected?.title"></div>
    <div [innerHTML]="message"></div>
  </ng-container>
  <ng-template #noSelected>
    <p class="no-message">{{'messages.error.noMessageToView' | translate}}</p>
  </ng-template>
  <app-spinner *ngIf="showSpinner"></app-spinner>
</div>
