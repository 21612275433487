import {Injectable, NgZone} from '@angular/core';
import {Role} from '../../authorization/api/data/enums/role.enum';
import {SecurityWorkerLogin} from './interfaces/security-worker-login';
import {SecurityWorkerLogout} from './interfaces/security-worker-logout';
import {SecurityWorkerSubscription} from './interfaces/security-worker-subscription';
import {ServiceWorkerActivated} from './interfaces/service-worker-activated';
import {ServiceWorkerError} from './interfaces/service-worker-error';
import {ServiceWorkerUpdated} from './interfaces/service-worker-updated';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerCommunicationService {

  private readonly _serviceWorkerActivatedListeners: ServiceWorkerActivated[] = [];
  private readonly _serviceWorkerUpdatedListeners: ServiceWorkerUpdated[] = [];
  private readonly _serviceWorkerErrorListeners: ServiceWorkerError[] = [];

  private readonly _securityWorkerLoginListeners: SecurityWorkerLogin[] = [];
  private readonly _securityWorkerLogoutListeners: SecurityWorkerLogout[] = [];

  private readonly _securityWorkerSubscriptionListeners: SecurityWorkerSubscription[] = [];

  public constructor(private readonly _ngZone: NgZone) {
  }

  public addServiceWorkerActivatedListener(listener: ServiceWorkerActivated): void {
    this._serviceWorkerActivatedListeners.push(listener);
  }

  public removeServiceWorkerActivatedListener(listener: ServiceWorkerActivated): void {
    const index: number = this._serviceWorkerActivatedListeners.indexOf(listener);
    if (index === -1) return;
    this._serviceWorkerActivatedListeners.splice(index, 1);
  }

  public callServiceWorkerActivated(): void {
    if (this._serviceWorkerActivatedListeners.length === 0) return;
    this._ngZone.run(() => {
      this._serviceWorkerActivatedListeners.filter((listener: ServiceWorkerActivated) => !!listener)
        .forEach((listener: ServiceWorkerActivated) => listener.onServiceWorkerActivated());
    });
  }

  public addServiceWorkerUpdatedListener(listener: ServiceWorkerUpdated): void {
    this._serviceWorkerUpdatedListeners.push(listener);
  }

  public removeServiceWorkerUpdatedListener(listener: ServiceWorkerUpdated): void {
    const index: number = this._serviceWorkerUpdatedListeners.indexOf(listener);
    if (index === -1) return;
    this._serviceWorkerUpdatedListeners.splice(index, 1);
  }

  public callServiceWorkerUpdated(): void {
    if (this._serviceWorkerUpdatedListeners.length === 0) return;
    this._ngZone.run(() => {
      this._serviceWorkerUpdatedListeners.filter((listener: ServiceWorkerUpdated) => !!listener)
        .forEach((listener: ServiceWorkerUpdated) => listener.onServiceWorkerUpdated());
    });
  }

  public addServiceWorkerErrorListener(listener: ServiceWorkerError): void {
    this._serviceWorkerErrorListeners.push(listener);
  }

  public removeServiceWorkerErrorListener(listener: ServiceWorkerError): void {
    const index: number = this._serviceWorkerErrorListeners.indexOf(listener);
    if (index === -1) return;
    this._serviceWorkerErrorListeners.splice(index, 1);
  }

  public callServiceWorkerError(error?: any): void {
    if (this._serviceWorkerErrorListeners.length === 0) return;
    this._ngZone.run(() => {
      this._serviceWorkerErrorListeners.filter((listener: ServiceWorkerError) => !!listener)
        .forEach((listener: ServiceWorkerError) => listener.onServiceWorkerError(error));
    });
  }

  public addSecurityWorkerLoginListener(listener: SecurityWorkerLogin): void {
    this._securityWorkerLoginListeners.push(listener);
  }

  public removeSecurityWorkerLoginListener(listener: SecurityWorkerLogin): void {
    const index: number = this._securityWorkerLoginListeners.indexOf(listener);
    if (index === -1) return;
    this._securityWorkerLoginListeners.splice(index, 1);
  }

  public callSecurityWorkerLogin(email: string, role: Role): void {
    if (this._securityWorkerLoginListeners.length === 0) return;
    this._ngZone.run(() => {
      this._securityWorkerLoginListeners.filter((listener: SecurityWorkerLogin) => !!listener)
        .forEach((listener: SecurityWorkerLogin) => listener.onSecurityWorkerLogin(email, role));
    });
  }

  public addSecurityWorkerLogoutListener(listener: SecurityWorkerLogout): void {
    this._securityWorkerLogoutListeners.push(listener);
  }

  public removeSecurityWorkerLogoutListener(listener: SecurityWorkerLogout): void {
    const index: number = this._securityWorkerLogoutListeners.indexOf(listener);
    if (index === -1) return;
    this._securityWorkerLogoutListeners.splice(index, 1);
  }

  public callSecurityWorkerLogout(): void {
    if (this._securityWorkerLogoutListeners.length === 0) return;
    this._ngZone.run(() => {
      this._securityWorkerLogoutListeners.filter((listener: SecurityWorkerLogout) => !!listener)
        .forEach((listener: SecurityWorkerLogout) => listener.onSecurityWorkerLogout());
    });
  }

  public addSecurityWorkerSubscriptionListener(listener: SecurityWorkerSubscription): void {
    this._securityWorkerSubscriptionListeners.push(listener);
  }

  public removeSecurityWorkerSubscriptionListener(listener: SecurityWorkerSubscription): void {
    const index: number = this._securityWorkerSubscriptionListeners.indexOf(listener);
    if (index === -1) return;
    this._securityWorkerSubscriptionListeners.splice(index, 1);
  }

  public callSecurityWorkerSubscription(): void {
    if (this._securityWorkerSubscriptionListeners.length === 0) return;
    this._ngZone.run(() => {
      this._securityWorkerSubscriptionListeners.filter((listener: SecurityWorkerSubscription) => !!listener)
        .forEach((listener: SecurityWorkerSubscription) => listener.onSubscriptionNoAccess());
    });
  }
}
