import {JsonProperty} from '@dhkatz/json-ts';
import {ScaledRasterElement} from '../elements/scaled-raster-element';
import {AbstractIndexPreRenderData} from './abstracts/abstract-index-pre-render-data';
import {ScaledPreRenderData} from './interfaces/scaled-pre-render-data';

export class ScaledRasterPreRenderData extends AbstractIndexPreRenderData implements ScaledPreRenderData {

  @JsonProperty({name: 'd', type: ScaledRasterElement})
  private _data: ScaledRasterElement[];

  @JsonProperty('p')
  private _preLoading: boolean;

  public constructor() {
    super();
    this._data = undefined;
    this._preLoading = undefined;
  }

  public get data(): ScaledRasterElement[] {
    return this.active ? this._data : undefined;
  }

  public set data(value: ScaledRasterElement[]) {
    this._data = value;
  }

  public get preLoading(): boolean {
    return this._preLoading;
  }

  public set preLoading(value: boolean) {
    this._preLoading = value;
  }
}
