import {serialize} from '@dhkatz/json-ts';
import * as CryptoJS from 'crypto-js';
import {deserializeFix} from '../../../../../../utils/deserialize-util';
import {Header} from './header';
import {Payload} from './payload';

export class JWT {

  private static header: Header = Object.assign(new Header(), {
    type: 'JWT',
    algorithm: 'HS512'
  });

  public static getToken(payload: Payload | string, secret: string): string {
    const stringPayload = payload instanceof Payload ? JSON.stringify(serialize(payload)) : payload;
    const encodedHeader = this.base64UrlEncode(CryptoJS.enc.Utf8.parse(JSON.stringify(serialize(this.header))));
    const encodedPayload = this.base64UrlEncode(CryptoJS.enc.Utf8.parse(stringPayload));
    const sing = this.base64UrlEncode(CryptoJS.HmacSHA512(encodedHeader + '.' + encodedPayload, secret));
    return encodedHeader + '.' + encodedPayload + '.' + sing;
  }

  public static getPayload(token: string, secret: string): Payload {
    if (!token || !secret) return undefined;
    const tokenElements = token.split('.');
    if (tokenElements.length !== 3) {
      console.log('Invalid token.');
      return undefined;
    }

    let tokenHeader: Header;
    try {
      tokenHeader = deserializeFix(Header, JSON.parse(this.base64UrlDecode(tokenElements[0])));
    } catch (e) {
      console.log(e);
      return undefined;
    }
    if (tokenHeader === undefined) {
      console.log('Can not deserialize the header');
      return undefined;
    }
    if (tokenHeader.type !== this.header.type) {
      console.log('Wrong token type. Required ' + this.header.type + ' type, type ' + tokenHeader.type + ' obtained.');
      return undefined;
    }
    if (tokenHeader.algorithm !== this.header.algorithm) {
      console.log('Wrong token algorithm. Required ' + this.header.algorithm + ' algorithm, algorithm ' + tokenHeader.algorithm +
        ' obtained.');
      return undefined;
    }

    let payload: Payload;
    try {
      payload = deserializeFix(Payload, JSON.parse(this.base64UrlDecode(tokenElements[1])));
    } catch (e) {
      console.log(e);
      return undefined;
    }
    if (payload === undefined) {
      console.log('Can not deserialize the payload');
      return undefined;
    }

    const sing = this.base64UrlEncode(CryptoJS.HmacSHA512(tokenElements[0] + '.' + tokenElements[1], secret));
    if (sing !== tokenElements[2]) {
      console.log('Token signature does not match.');
      return undefined;
    }

    return payload;
  }

  public static getUnsafePayload(token: string): Payload {
    if (!token) return undefined;
    const tokenElements = token.split('.');
    if (tokenElements.length !== 3) {
      console.log('Invalid token.');
      return undefined;
    }

    let payload: Payload;
    try {
      payload = deserializeFix(Payload, JSON.parse(this.base64UrlDecode(tokenElements[1])));
    } catch (e) {
      console.log(e);
      return undefined;
    }
    if (payload === undefined) {
      console.log('Can not deserialize the payload');
      return undefined;
    }

    return payload;
  }

  public static isTokenExpired(token: string, miliseconds?: number): boolean {
    const payload = this.getUnsafePayload(token);
    return payload ? payload.isExpired(miliseconds) : false;
  }

  public static base64UrlEncode(source: any): string {
    let encodedSource = CryptoJS.enc.Base64.stringify(source);
    encodedSource = encodedSource.replace(/=+$/, '');
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }

  public static base64UrlDecode(source: string): string {
    source = source.replace(/\-/g, '+');
    source = source.replace(/\_/g, '/');
    const words = CryptoJS.enc.Base64.parse(source);
    return CryptoJS.enc.Utf8.stringify(words);
  }
}
