import {JsonProperty} from '@dhkatz/json-ts';
import {CustomTaskRequest} from './custom-task-request';
import {TaskType} from './enums/task-type.enum';
import {EnumConverter} from '../../../../../../globals/api/json/enum-converter';

export class CustomTaskResponse extends CustomTaskRequest {

  @JsonProperty('id')
  private _id: string;

  @JsonProperty({name: 'type', converter: EnumConverter.converter(TaskType)})
  private _type: TaskType;

  public constructor() {
    super();
    this._id = undefined;
    this._type = undefined;
  }

  public get id(): string {
    return this._id;
  }

  public get type(): TaskType {
    return this._type;
  }

  public set type(value: TaskType) {
    this._type = value;
  }
}
