<div [ngClass]="{'media': true, 'active': active}">
  <app-video *ngIf="actualProject?.module === projectModule.video" [projectId]="actualProject.id"></app-video>
  <app-carousel *ngIf="actualProject?.module === projectModule.carousel"
                [projectId]="actualProject.id"></app-carousel>
</div>
<div class="close">
  <mat-icon (click)="closePreview()">close</mat-icon>
</div>
<div [ngClass]="{'description': true, 'active': active}">
  <h1>{{actualProject?.title}}</h1>
  <div *ngIf="actualProject?.description" [innerHTML]="actualProject?.description | html"></div>
  <div *ngIf="!isAvailable" class="not-available">
    <p class="text" [innerHTML]="'project.subscription.info.noAccess' | translate"></p>
    <a class="btn" [href]="subscriptionsUrl" target="_blank">{{'project.subscription.label' | translate}}</a>
  </div>
  <div class="button-area" *ngIf="isAvailable">
    <app-download-to-offline [inDialog]="inDialog" [project]="project"
                             [categoryId]="categoryId" (closeDialog)="closeDialog.emit()"
                             class="download-to-offline"></app-download-to-offline>
  </div>
  <app-tags *ngIf="actualProject?.tagsIds?.length" [tagsIds]="actualProject?.tagsIds"></app-tags>
</div>

<!--<div class="bottom">-->
<!--<button>-->
<!--<mat-icon>information</mat-icon>informations-->
<!--</button>-->
<!--<button>-->
<!--<mat-icon>book</mat-icon>dictionary-->
<!--</button>-->
<!--<button>-->
<!--<mat-icon>school</mat-icon>exercises-->
<!--</button>-->
<!--</div>-->
