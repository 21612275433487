<div class="account-container content-box">
  <div class="content-box-container">
    <mat-card class="boxes mail-boxes">
      <div class="account-info">
        <img class="user-icon" src="/assets/images/profile_menu_on.png"
             srcset="/assets/images/profile_menu_on.png 1x, /assets/images/profile_menu_on_2x.png 2x" *ngIf="email">
        <p class="data" *ngIf="email"><span
          class="data-label">{{'system.form.placeholder.email' | translate}}</span><br>
          {{email}}
        </p>
        <img class="user-icon" src="/assets/images/profile_menu.png"
             srcset="/assets/images/profile_menu.png 1x, /assets/images/profile_menu_2x.png 2x" *ngIf="!email">
        <p class="data" *ngIf="!email">
          {{'offlineManagement.unknown' | translate}}
        </p>
      </div>
      <div class="account-actions" *ngIf="!this.workerService.isLoggedInOnline">
        <a (click)="logoutOffline()" *ngIf="email">
          <img src="/assets/images/logout.png"
               srcset="/assets/images/logout.png 1x, /assets/images/logout_2x.png 2x">
          {{'offlineManagement.logout' | translate}}
        </a>
        <a (click)="loginOffline()" *ngIf="!email">
          <img src="/assets/images/logout.png"
               srcset="/assets/images/logout.png 1x, /assets/images/logout_2x.png 2x">
          {{'offlineManagement.login' | translate}}
        </a>
      </div>
    </mat-card>
    <mat-card class="boxes offline-project" *ngFor="let project of projects">
      <div class="image-data single-data-image">
        <img
          [src]="resourcesUrl + project?.project + '/thumb.jpg' | offline : {offline: !isDownloading(project), project: project?.project} | safe">
      </div>
      <div class="info-data">
        <div class="single-data single-data-title">
          <p class="data">{{project?.projectTitle}}</p>
        </div>
        <div class="single-data single-data-update">
          <p class="data"><span class="data-label">{{'offlineManagement.updated' | translate}}:</span>
            {{project?.lastUpdateDate?.toLocaleDateString()}}
          </p>
        </div>
        <div class="single-data single-data-date">
          <p class="data"><span class="data-label">{{'offlineManagement.expires' | translate}}:</span>
            <ng-container *ngIf="project?.validUntilDate">{{project?.validUntilDate?.toLocaleDateString()}}</ng-container>
            <ng-container *ngIf="!project?.validUntilDate">{{'offlineManagement.indefinitely' | translate}}</ng-container>
          </p>
        </div>
      </div>
      <div class="single-data single-data-options">
        <app-manage-offline (removed)="removeProject(project.project)" [categoryId]="project.categoryId" [email]="email"
                            [lastUpdated]="project.lastUpdateDate" [projectId]="project.project"
                            [projectTitle]="project.projectTitle" [validUntil]="project.validUntilDate">
        </app-manage-offline>
      </div>
    </mat-card>
    <mat-card class="boxes offline-message" *ngIf="!showSpinner && !projects?.length && email">
      <p class="data" [innerHTML]="'offlineManagement.info.login' | translate"></p>
    </mat-card>
    <mat-card class="boxes offline-message" *ngIf="!showSpinner && !email">
      <p class="data">{{'offlineManagement.info.logout' | translate}}</p>
    </mat-card>
    <app-spinner class="full" *ngIf="showSpinner"></app-spinner>
  </div>
</div>
