import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {OfflineService} from '../../../../../../offline/services/offline-service/offline.service';
import {BrowserUtil} from '../../../../../../utils/browser-util';
import {FontsUtil} from '../../../../../../utils/fonts-util';
import {LoadingProgressService} from '../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {FontResponse} from '../../api/data/font-response';
import {FontsOfflineService} from '../../api/services/fonts-offline.service';
import {FontsService} from '../../api/services/fonts.service';
import {FontsCommunicationService} from '../../communication/fonts-communication.service';

@Component({
  selector: 'app-fonts',
  templateUrl: './fonts.component.html',
  styleUrls: ['./fonts.component.scss']
})
export class FontsComponent implements OnInit, OnDestroy {

  @Input()
  public versionId: string;

  @Input()
  public projectId: string;

  public isOffline: boolean;

  public fonts: FontResponse[];
  public supportFontApi: boolean;

  private _style: HTMLStyleElement;

  public constructor(private readonly _fontsService: FontsService, private readonly _fontsOfflineService: FontsOfflineService,
                     private readonly _communication: FontsCommunicationService, private readonly _loading: LoadingProgressService,
                     private readonly _offline: OfflineService) {
    this.supportFontApi = 'fonts' in document;
  }

  public ngOnInit(): void {
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      this.isOffline = result;
      setTimeout(() => {
        const requestMethod = this.isOffline
          ? this._fontsOfflineService.getFonts(this.projectId)
          : this.getOnlineMethod();
        requestMethod.subscribe((response: FontResponse[]) => {
          if (!response) return;
          this.getFonts(response);
        });
      }, 0);
    });
  }

  private getOnlineMethod(): Observable<FontResponse[]> {
    return this.projectId === undefined
      ? this._fontsService.getFontsPreview(this.versionId)
      : this._fontsService.getFonts(this.projectId);
  }

  private getFonts(response: FontResponse[]): void {
    this._communication.fontsInfoLoaded(response);
    if (BrowserUtil.asyncRender) {
      this._communication.fontsLoaded();
      this._loading.configLoaded();
      return;
    }
    this._loading.addFilesToLoad(response.length);
    this._loading.configLoaded();
    if (this.supportFontApi) FontsUtil.setFonts(response, document, () => this._loading.fileLoaded(),
      () => this._communication.fontsLoaded());
    else this.setCssFonts(response);
    this.fonts = response;
  }

  private setCssFonts(fonts: FontResponse[]): void {
    if (!fonts || fonts.length === 0) {
      this._communication.fontsLoaded();
      return;
    }
    this._style = document.createElement('style');
    document.head.appendChild(this._style);
    const sheet = <CSSStyleSheet>this._style.sheet;
    fonts.forEach((font) => {
      sheet.insertRule(this.getFont(font));
      sheet.insertRule(this.useFont(font));
      this._loading.fileLoaded();
    });
    setTimeout(() => this._communication.fontsLoaded(), 500);
  }

  private getFont(font: FontResponse): string {
    return '@font-face { font-family: "' + font.name + '"; src: ' + FontsUtil.getFontSource(font.files) + '; }';
  }

  private useFont(font: FontResponse): string {
    return '#' + font.name + ' { font-family: "' + font.name + '"; position: fixed; z-index:-1; top: -100px; }';
  }

  public ngOnDestroy(): void {
    if (this._style) this._style.remove();
  }
}
