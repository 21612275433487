<ng-container *ngIf="!showConfirm">
  <div class="system-dialog-body">
    <div class="change-password">
      <form>
        <mat-form-field>
          <input [type]="hide ? 'password' : 'text'"
                 [placeholder]="'profile.password.placeholder.newPassword' | translate" matInput
                 [formControl]="passwordField">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-icon matSuffix class="generate" (click)="generatePassword()">vpn_key</mat-icon>
          <mat-error *ngIf="passwordField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
          <mat-error *ngIf="passwordField.hasError('weakPassword') && !passwordField.hasError('required')">
            {{'profile.password.error.viewerWeakPassword' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input [type]="hide ? 'password' : 'text'"
                 [placeholder]="'register.placeholder.repeatPassword' | translate" matInput
                 [formControl]="confirmPasswordField">
          <mat-error *ngIf="confirmPasswordField.hasError('mustMatch')"
                     [innerHTML]="'profile.password.error.match' | translate">
          </mat-error>
        </mat-form-field>
        <button class="submit-button" (click)="onSubmit()">{{'system.label.send' | translate}}</button>
      </form>
    </div>
  </div>
  <hr>
  <footer>
    <span class="link"
          (click)="changeScreen(screensEnum.login)">{{'authorization.label.backToLogin' | translate}}</span>
  </footer>
</ng-container>
<ng-container *ngIf="showConfirm">
  <div class="system-dialog-body">
    <div class="confirm">
      <p>{{'resetPassword.info.passwordChanged' | translate}}</p>
    </div>
  </div>
  <hr>
  <footer>
    <span class="link"
          (click)="changeScreen(screensEnum.login)">{{'authorization.label.backToLogin' | translate}}</span>
  </footer>
</ng-container>
