import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ViewerConfig} from '../../../../configs/viewer-config';
import {ProjectService} from '../../../../project/api/services/project.service';
import {SearchProjectsComponent} from '../../../../project/screens/search-projects/search-projects.component';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss']
})
export class SearchButtonComponent {

  public constructor(private _dialog: MatDialog, private _service: ProjectService) { }

  public openSearchDialog(): void {
    this._dialog.open(SearchProjectsComponent, ViewerConfig.getSearchDialogOptions());
  }
}
