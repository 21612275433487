import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FileEndpoints} from '../../file-endpoints';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public listFiles(projectId: string): Observable<string[]> {
    return this._http.get<string[]>(FileEndpoints.FILES_LIST.replace(FileEndpoints.PROJECT_ID, projectId));
  }

  public listViewerFiles(): Observable<string[]> {
    return this._http.get<string[]>(self.location.origin + '/offline-files');
  }
}
