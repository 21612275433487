import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {OfflineService} from '../../../../../../offline/services/offline-service/offline.service';
import {LoadingProgressService} from '../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {PanelsResponse} from '../../api/data/panels-response';
import {PanelsOfflineService} from '../../api/service/panels-offline.service';
import {PanelsService} from '../../api/service/panels.service';
import {ChangePanelStatus} from '../../communication/interfaces/change-panel-status';
import {PanelsCommunicationService} from '../../communication/panels-communication.service';
import {PanelComponent} from '../parts/panel/panel.component';

@Component({
  selector: 'app-panels',
  templateUrl: './panels.component.html',
  styleUrls: ['./panels.component.css']
})
export class PanelsComponent implements OnInit, OnDestroy, ChangePanelStatus {

  @Input()
  public versionId: string;

  @Input()
  public projectId: string;

  public panelElements: Map<string, PanelComponent>;

  public panels: PanelsResponse[];

  public constructor(private readonly _panelsService: PanelsService, private readonly _communication: PanelsCommunicationService,
                     private readonly _panelsOfflineService: PanelsOfflineService, private readonly _cdr: ChangeDetectorRef,
                     private readonly _offline: OfflineService, private readonly _loading: LoadingProgressService) {
    this.panelElements = new Map<string, PanelComponent>();
  }

  public ngOnInit(): void {
    this._communication.addChangePanelStatusListener(this);
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      const requestMethod = result
        ? this._panelsOfflineService.getPanels(this.projectId)
        : this.getOnlineMethod();
      requestMethod.subscribe((response: PanelsResponse[]) => {
        this.panels = response;
        this._communication.panelsLoaded(this.panels.map((panel: PanelsResponse) => panel.title));
        this._loading.configLoaded();
      });
    });
  }

  public ngOnDestroy(): void {
    this._communication.removeChangePanelStatusListener(this);
  }

  private getOnlineMethod(): Observable<PanelsResponse[]> {
    return this.projectId === undefined ? this._panelsService.getPanelsPreview(this.versionId) :
      this._panelsService.getPanels(this.projectId);
  }

  public onChangePanelStatus(panel: string): void {
    Object.keys(this.panelElements).forEach((key) => {
      this.panelElements[key].showPanel = key === panel ? !this.panelElements[key].showPanel : false;
    });
  }
}
