import {JsonProperty} from '@dhkatz/json-ts';
import {ScaledRasterElement} from '../elements/scaled-raster-element';
import {AbstractCombinedPreRenderData} from './abstracts/abstract-combined-pre-render-data';
import {ScaledPreRenderData} from './interfaces/scaled-pre-render-data';
import {ScaledCombinedPreRenderDataPart} from './parts/scaled-combined-pre-render-data-part';

export class ScaledCombinedPreRenderData extends AbstractCombinedPreRenderData implements ScaledPreRenderData {

  @JsonProperty({name: 'd', type: ScaledCombinedPreRenderDataPart})
  private _data: ScaledCombinedPreRenderDataPart[];

  public constructor() {
    super();
    this._data = undefined;
  }

  public get data(): ScaledRasterElement[] {
    return this.active ? this._data[this.activeIndex].data : undefined;
  }

  public getData(): ScaledCombinedPreRenderDataPart[] {
    return this._data;
  }

  public setData(value: ScaledCombinedPreRenderDataPart[]): void {
    this._data = value;
  }

  public get preLoading(): boolean {
    return false;
  }

  public setDisabledLayers(layers: boolean[]): boolean {
    const prevActive = this.activeIndex;
    this.activeIndex = this._data.findIndex((part: ScaledCombinedPreRenderDataPart) => part.setDisabledLayers(layers));
    return prevActive !== this.activeIndex;
  }
}
