<mat-dialog-content>
  <form [ngClass]="{'loading': showSpinner}">
    <mat-checkbox [color]="'primary'" [formControl]="enabledField">
      <ng-container *ngIf="enabledField?.value">{{'project.tasks.input.defaultEnabled' | translate}}</ng-container>
      <ng-container *ngIf="!enabledField?.value">{{'project.tasks.input.defaultDisabled' | translate}}</ng-container>
    </mat-checkbox>
    <div class="field-wysiwyg">
      <label class="field-label">{{'project.tasks.input.title' | translate}}</label>
      <ck-editor [config]="titleConfig" [formControl]="titleField" [fullPage]="false"
                 [language]="'system.langCode' | translate" skin="moono-lisa"></ck-editor>
      <mat-error *ngIf="titleField.hasError('required')"
                 [innerHTML]="'system.form.error.required' | translate">
      </mat-error>
    </div>
    <div class="field-wysiwyg">
      <label class="field-label">{{'project.tasks.input.description' | translate}}</label>
      <ck-editor [config]="descriptionConfig" [formControl]="descriptionField" [fullPage]="false"
                 [language]="'system.langCode' | translate" skin="moono-lisa"></ck-editor>
      <mat-error *ngIf="descriptionField.hasError('required')"
                 [innerHTML]="'system.form.error.required' | translate">
      </mat-error>
    </div>
    <footer class="buttons">
      <button mat-button class="cancel-button" (click)="close.emit()">{{'system.label.cancel' | translate}}</button>
      <button mat-button class="submit-button" (click)="onSubmit()"
              *ngIf="task">{{'system.label.add' | translate}}</button>
      <button mat-button class="submit-button" (click)="onSubmit()"
              *ngIf="!task">{{'system.label.save' | translate}}</button>
    </footer>
  </form>
</mat-dialog-content>
<app-spinner *ngIf="showSpinner" class="full primary"></app-spinner>
