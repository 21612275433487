<div class="window legend noselect " #window [@fadeAnimation]="windowState">
    <div class="top-resizer drag" #topResizer></div>
    <app-window-header [headerTitle]="'project.legend.title' | translate" [(close)]="showWindow" [(show)]="showContent"
                       [drag]="this"></app-window-header>
    <div class="content" #content [@slideAnimation]="contentState"
         (@slideAnimation.done)="updateDivParams()">
        <table mat-table [dataSource]="legend">
            <ng-container matColumnDef="icon">
                <ng-container *matCellDef="let item;">
                    <td *ngIf="item?.icon" mat-cell [attr.colspan]="item?.title ? null : '2'">
                        <img #element (load)="setStartPosition()" [src]="{normal: item?.icon, retina: item?.retinaIcon}
                        | retina | fileUrl : 'FILES' | offline : {offline: isOffline, project: projectId}
                        | safe" [style.height]="getIconHeight(item, element)">
                    </td>
                </ng-container>
            </ng-container>
            <ng-container matColumnDef="title">
                <ng-container *matCellDef="let item;">
                    <td *ngIf="item?.title" mat-cell [innerHTML]="item?.title | html"
                        [attr.colspan]="item?.icon ? null : '2'"></td>
                </ng-container>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [hidden]="!showIfActiveLayer(row) && !showIfActiveInteractive(row)"
                [ngClass]="{'separator': !row.icon, 'leg-interactive': !row?.layers?.length && row?.interactives?.length}"></tr>
        </table>
        <div class="bottom-resizer drag" #bottomResizer></div>
    </div>
</div>
