import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {RoutingConfig} from '../../../configs/routing-config';
import {MainCategoryResponse} from '../../api/data/main-category-response';
import {CategoryService} from '../../api/services/category.service';

@Component({
  selector: 'app-main-categories',
  templateUrl: './main-categories.component.html',
  styleUrls: ['./main-categories.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainCategoriesComponent implements OnInit {

  public data: MainCategoryResponse[];
  public showSpinner = true;

  public constructor(private _service: CategoryService, private _router: Router) {
  }

  public ngOnInit(): void {

    this._service.getMainCategories().subscribe((response: MainCategoryResponse[]) => {
        if (!response) return;
        this.data = response;
      }, () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public openCategory(id: string): void {
    this._router.navigate([RoutingConfig.CATEGORY.replace(RoutingConfig.CATEGORY_ID_PARAM, id)]);
  }
}
