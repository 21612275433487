<div class="account-part subscription-add">
  <div class="box-title">
    {{'subscriptions.coupon.add' | translate}}
  </div>
  <mat-form-field>
    <input matInput [formControl]="couponField" [placeholder]="'subscriptions.coupon.input' | translate">
    <mat-error *ngIf="couponField.hasError('invalidCode')"
               [innerHTML]="'subscriptions.coupon.error.invalid' | translate">
    </mat-error>
    <mat-error *ngIf="couponField.hasError('used')"
               [innerHTML]="'subscriptions.coupon.error.used' | translate">
    </mat-error>
    <mat-error *ngIf="couponField.hasError('inactive')"
               [innerHTML]="'subscriptions.coupon.error.inactive' | translate">
    </mat-error>
    <mat-error *ngIf="couponField.hasError('registerOnly')"
               [innerHTML]="'subscriptions.coupon.error.register' | translate">
    </mat-error>
    <mat-error *ngIf="couponField.hasError('invalidRole')"
               [innerHTML]="'subscriptions.coupon.error.role' | translate">
    </mat-error>
  </mat-form-field>
  <button class="submit-button" (click)="submit()">{{'system.label.add' | translate}}</button>
</div>
