import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent {

  @Input()
  public backLink;

  public constructor(private _router: Router) {
  }

  public back(): void {
    const back = this.backLink ? this.backLink : '/';
    this._router.navigate([back]);
  }
}
