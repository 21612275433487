<mat-dialog-content class="content point-details">
  <img *ngIf="data.type === pointType.image && asImagePoint" (load)="onImageLoaded($event)"
       [src]="asImagePoint | fileUrl : 'FILES'| offline : {offline: isOffline, project: projectId} | safe"
       [ngClass]="{loaded: imageLoaded, show: imageShow, portrait: portraitImage}">
  <video controls *ngIf="data.type === pointType.movie && asMoviePoint?.file" height="350"
         [src]="asMoviePoint?.file | fileUrl : 'FILES' | offline : {offline: isOffline, project: projectId} | safe">
    {{'system.error.video' | translate}}
  </video>
  <audio controls *ngIf="data.type === pointType.sound && asSoundPoint?.file"
         [src]="asSoundPoint?.file | fileUrl : 'FILES' | offline : {offline: isOffline, project: projectId} | safe">
    {{'system.error.audio' | translate}}
  </audio>
  <ng-container *ngIf="data.type === pointType.embed">
    <youtube-player *ngIf="asEmbedPoint?.link && asEmbedPoint?.linkType === linkType.youtube"
                    [playerVars]="config.youtubePlayer" height="350" (change)="config.onYouTubeStateChange($event)"
                    (ready)="config.onYoutubePlayerReady($event, asEmbedPoint?.link)">
    </youtube-player>
    <iframe *ngIf="asEmbedPoint.link && asEmbedPoint?.linkType === linkType.vimeo"
            [src]="'https://player.vimeo.com/video/' + asEmbedPoint?.link | safe" height="350"
            frameborder="0" frameborder="0" webkitallowfullscreen mozallowfullscreen
            allowfullscreen></iframe>
    <iframe *ngIf="asEmbedPoint?.link && asEmbedPoint?.linkType === linkType.soundcloud"
            height="125"
            [src]="'https://w.soundcloud.com/player/?url=' + asEmbedPoint?.link + '&auto_play=false' +
            '&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false' | safe"
            scrolling="no" frameborder="no" allow="autoplay"></iframe>
  </ng-container>
  <div id="chart" *ngIf="data.type === pointType.chart && asChartPoint.chart"><app-spinner class="primary full"></app-spinner></div>
  <div class="details">
    <div class="title" [innerHTML]="data.title | html"></div>
    <div class="copyrights" *ngIf="data?.copyrights">
      <a *ngIf="data?.copyrightsUrl; else copyrights" href="{{data.copyrightsUrl}}" target="_blank">&#169;
        {{(data.copyrights)
          ? data.copyrights : data.copyrightsUrl}}</a>
      <ng-template #copyrights>&#169; {{data.copyrights}}</ng-template>
    </div>
    <div class="source" *ngIf="data?.source && data?.sourceUrl">
      <a *ngIf="data?.sourceUrl; else source" [href]="data.sourceUrl" target="_blank">{{(data.source) ?
        data.source : data.sourceUrl}}</a>
      <ng-template #source>{{data.copyrights}}</ng-template>
    </div>
    <div *ngIf="data?.description" class="description" [innerHTML]="data?.description | html">
    </div>
  </div>
</mat-dialog-content>
