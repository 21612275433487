import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InteractiveLayerResponse} from '../../../../../interactive/api/data/interactive-layer-response';
import {InteractiveResponse} from '../../../../../interactive/api/data/interactive-response';
import {InteractiveCommunicationService} from '../../../../../interactive/communication/interactive-communication.service';
import {CustomInteractivesLoaded} from '../../../../../interactive/communication/interfaces/custom-interactives-loaded';
import {InteractiveSettingsScreen} from '../../api/enum/interactive-settings-screen.enum';
import {CustomInteractiveService} from '../../api/services/custom-interactive-service';
import {CustomInteractivesCommunicationService} from '../../communiaction/custom-interactives-communication.service';

@Component({
  selector: 'app-interactive-settings',
  templateUrl: './interactive-settings-dialog.component.html',
  styleUrls: ['./interactive-settings-dialog.component.scss']
})
export class InteractiveSettingsDialogComponent implements OnDestroy, CustomInteractivesLoaded {

  public cInteractiveLayers: InteractiveLayerResponse[];
  public init: boolean;
  public screen = InteractiveSettingsScreen.List;
  public screens = InteractiveSettingsScreen;
  public selected: InteractiveLayerResponse;

  public projectId: string;

  public constructor(private readonly _service: CustomInteractiveService,
                     private readonly _dialogRef: MatDialogRef<InteractiveSettingsDialogComponent>,
                     private readonly _communication: CustomInteractivesCommunicationService,
                     private readonly _interactivesCommunication: InteractiveCommunicationService,
                     @Inject(MAT_DIALOG_DATA) private data: any) {
    this._dialogRef.afterOpened().subscribe(() => this.init = true);
    this._dialogRef.backdropClick().subscribe(() => {
      if (this.init) this._dialogRef.close(this.cInteractiveLayers);
    });
    this.projectId = this.getValue('projectId');
    this.cInteractiveLayers = this.getValue('customInteractives');
    this._interactivesCommunication.addCustomInteractiveLoadedListener(this);
  }

  public add(): void {
    this.selected = undefined;
    this.screen = InteractiveSettingsScreen.Edit;
  }

  public update(cInteractiveLayer: InteractiveLayerResponse): void {
    this.selected = cInteractiveLayer;
    this.screen = InteractiveSettingsScreen.Edit;
  }

  public remove(cInteractiveLayer: InteractiveLayerResponse): void {
    this.selected = cInteractiveLayer;
    this.screen = InteractiveSettingsScreen.Remove;
  }

  private getValue(...path: string[]): any {
    if (this.data === undefined || this.data === null) return '';
    let data = this.data;
    for (const element of path) {
      if (data[element] === undefined || this.data === null) return '';
      data = data[element];
    }
    return data;
  }

  public ngOnDestroy(): void {
    this._interactivesCommunication.removeCustomInteractiveLoadedListener(this);
  }

  public onCustomInteractivesLoaded(interactive: InteractiveResponse): void {
    this.cInteractiveLayers = interactive.interactives;
  }

  public close(): void {
    this._dialogRef.close();
  }
}
