import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {deserializeFix} from '../../../utils/deserialize-util';
import {SubscriptionResponse} from '../data/subscription-response';
import {SubscriptionCouponEndpoints} from '../subscription-coupon-endpoints';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionCouponService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public addCoupon(code: string): Observable<SubscriptionResponse> {
    return this._http.post<JsonObject>(SubscriptionCouponEndpoints.ADD_COUPON, code)
      .pipe(map((response: JsonObject) => deserializeFix(SubscriptionResponse, response)));
  }
}
