import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import {Role} from '../../../../authorization/api/data/enums/role.enum';
import {
  AuthorizationDialogComponent
} from '../../../../authorization/screens/authorization/authorization-dialog/authorization-dialog.component';
import {RoutingConfig} from '../../../../configs/routing-config';
import {ViewerConfig} from '../../../../configs/viewer-config';
import {DialogsService} from '../../../../globals/dialogs/dialogs.service';
import {ProjectResponse} from '../../../../project/api/data/project-response';
import {
  SubscriptionNoAccessComponent
} from '../../../../subscriptions/screens/dialogs/subscription-no-access/subscription-no-access.component';
import {SubscriptionAccessService} from '../../../../subscriptions/services/subscription-access.service';
import {IdbUtil} from '../../../../utils/idb-util';
import {OfflineUtil} from '../../../../utils/offline-util';
import {SecurityWorkerLogin} from '../../../../web-workers/service/interfaces/security-worker-login';
import {SecurityWorkerLogout} from '../../../../web-workers/service/interfaces/security-worker-logout';
import {ServiceWorkerCommunicationService} from '../../../../web-workers/service/service-worker-communication.service';
import {ServiceWorkerService} from '../../../../web-workers/service/service-worker.service';
import {JWT} from '../../../../web-workers/workers/service-worker/interceptors/security-interceptor/jwt/jwt';
import {FileService} from '../../../api/services/file-service/file.service';
import {OfflineStatus} from '../../../communication/enums/offline-status.enum';
import {OfflineDBEntry} from '../../../services/offline-service/data/OfflineDBEntry';
import {UserOfflineEntry} from '../../../services/offline-service/data/UserOfflineEntry';
import {OfflineService} from '../../../services/offline-service/offline.service';
import {AbstractOfflineComponent} from '../abstracts/abstract-offline.component';
import {deserializeFix} from '../../../../utils/deserialize-util';

@Component({
  selector: 'app-download-to-offline',
  templateUrl: './download-to-offline.component.html',
  styleUrls: ['./download-to-offline.component.scss']
})
export class DownloadToOfflineComponent extends AbstractOfflineComponent implements OnInit, OnChanges, SecurityWorkerLogin, SecurityWorkerLogout {

  @Input()
  public project: ProjectResponse;

  @Input()
  public categoryId: string;

  @Input()
  public inDialog = false;

  @Output()
  public closeDialog: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  public hasAccess = false;

  public constructor(private readonly _workerCommunication: ServiceWorkerCommunicationService, private readonly _dialog: MatDialog,
                     private readonly _worker: ServiceWorkerService, dialogs: DialogsService, router: Router, worker: ServiceWorkerService,
                     offlineService: OfflineService, fileService: FileService, subscriptionAccess: SubscriptionAccessService,
                     translate: TranslateService) {
    super(translate, dialogs, router, worker, offlineService, subscriptionAccess);
    this._workerCommunication.addSecurityWorkerLoginListener(this);
    this._workerCommunication.addSecurityWorkerLogoutListener(this);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  private init(): void {
    if (!this.project) return;
    this.projectId = this.project.id;
    this.showSpinner = true;
    this.progress = 0;
    this.status = undefined;
    this.checkProject();
  }

  private checkProject(): void {
    this.offlineService.checkProject(this.project.id).then((fileStatus: OfflineStatus) => {
      this.status = fileStatus;
      if (this.status === OfflineStatus.DOWNLOADING) this.progress = this.offlineService.getProgress(this.project.id);
      if (this.status === OfflineStatus.DOWNLOADED || this.status === OfflineStatus.UPDATE_AVAILABLE)
        this.hasOfflineAccess(this.project.id).then((result: boolean) => this.hasAccess = result);
      this.showSpinner = false;
    });
  }

  public ngOnInit(): void {
    if (!this.project) return;
    this.projectId = this.project.id;
    this.showSpinner = true;
    this.checkProject();
  }

  public remove(projectId: string, projectName: string): void {
    super.remove(projectId, projectName, () => {
      this.removed.emit();
      this.ngOnInit();
    });
  }

  public downloadProjectFiles(force: boolean): void {
    if (!OfflineUtil.isOfflineAvailable()) return;
    const categoryId = this.inDialog ? this.project.defaultCategory : this.categoryId;
    if (!this.workerService.isLoggedInOnline) {
      this.showLogin(categoryId);
      return;
    }
    if (!this.subscriptionAccess.haveAccess(this.project.id)) {
      this._dialog.open(SubscriptionNoAccessComponent, ViewerConfig.dialogSubscriptionOptions);
      return;
    }
    super.downloadProject(this.project.id, this._worker.email, this.project.title, categoryId, force);
  }

  public onFileDownload(projectId: string, progress: number): void {
    super.onFileDownload(projectId, progress);
    this.hasOfflineAccess(projectId).then((result: boolean) => this.hasAccess = result);
  }

  private showLogin(categoryId: string): void {
    const path = RoutingConfig.PROJECT.replace(RoutingConfig.CATEGORY_ID_PARAM, categoryId)
      .replace(RoutingConfig.PROJECT_ID_PARAM, this.project.id);
    this._dialog.open(AuthorizationDialogComponent, ViewerConfig.getDialogOptions({path})).afterClosed()
      .subscribe((opened: boolean) => {
        if (opened) this.closeDialog.emit();
      });
  }

  public openProject(): void {
    const categoryId = this.inDialog ? this.project.defaultCategory : this.categoryId;
    const path = RoutingConfig.PROJECT.replace(RoutingConfig.CATEGORY_ID_PARAM, categoryId)
      .replace(RoutingConfig.PROJECT_ID_PARAM, this.project.id);
    this.hasOfflineAccess(this.project.id).then((result: boolean) => {
      if (result) {
        this.router.navigate([path]);
        this.closeDialog.emit();
        return;
      }
      if (!this._worker.isLoggedIn) {
        this._dialog.open(AuthorizationDialogComponent, ViewerConfig.getAuthDialogOptions({path})).afterClosed()
          .subscribe((opened: boolean) => {
            if (opened) this.closeDialog.emit();
          });
        return;
      }
      if (!this.subscriptionAccess.haveAccess(this.project.id)) {
        this._dialog.open(SubscriptionNoAccessComponent, ViewerConfig.dialogSubscriptionOptions);
        return;
      }
      this.router.navigate([path]);
      this.closeDialog.emit();
    });
  }

  private hasOfflineAccess(projectId: string): Promise<boolean> {
    return IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).get(projectId).then((result: any) => {
      if (!result) return false;
      const offlineEntry = deserializeFix(OfflineDBEntry, result);
      const email = this.workerService.email ? this.workerService.email : localStorage.getItem('offlineEmail');
      const hashedEmail = CryptoJS.MD5(email).toString(CryptoJS.enc.Hex);
      const entryForUser = offlineEntry.userEntry.filter((entry: UserOfflineEntry) => entry.email === hashedEmail)[0];
      if (!entryForUser) return false;
      const payload = JWT.getPayload(entryForUser.token, hashedEmail + '.' + OfflineService.SALT);
      return payload && !payload.isExpired() && payload.id === projectId;
    });
  }

  public onSecurityWorkerLogin(email?: string, role?: Role): void {
    this.init();
  }

  public onSecurityWorkerLogout(): void {
    this.init();
  }
}
