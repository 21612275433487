import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {MiniMapResponse} from '../data/mini-map-response';
import {MiniMapEndpoints} from '../mini-map-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class MiniMapService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getMiniMap(projectId: string): Observable<MiniMapResponse> {
    return this._http.get<JsonObject>(MiniMapEndpoints.MINI_MAP.replace(MiniMapEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonObject) => deserializeFix(MiniMapResponse, response)));
  }

  public getMiniMapPreview(versionId: string): Observable<MiniMapResponse> {
    return this._http.get<JsonObject>(MiniMapEndpoints.MINI_MAP_PREVIEW.replace(MiniMapEndpoints.VERSION_ID, versionId))
      .pipe(map((response: JsonObject) => deserializeFix(MiniMapResponse, response)));
  }
}
