import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {RetinaResponse} from '../../../api/data/retina-response';
import {CarouselService} from '../../../api/service/carousel.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnChanges, OnDestroy {

  @Input()
  public projectId: string;

  public carousel = [];

  public active = 0;
  private _index = 0;
  private _images: RetinaResponse[];
  private _showNext: boolean;
  private _timeouts = [];

  private readonly DELAY = 8000;
  private readonly ANIMATION = 500;

  public constructor(private _service: CarouselService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.ngOnDestroy();
    if (!this.projectId) return;
    this._service.getCarousel(this.projectId).subscribe((response: RetinaResponse[]) => {
      this._images = response;
      this.initCarousel();
    });
  }

  private initCarousel(): void {
    if (!this._images || !this._images.length) return;
    this.carousel.push({image: this._images[0], loaded: false});
    if (this._images.length < 2) return;
    this.carousel.push({image: this._images[1], loaded: false});
    this._timeouts.push(setTimeout(() => {
      this._showNext = true;
      this.changeElement();
    }, this.DELAY));
  }

  private changeElement(): void {
    if (this.cantChange()) return;
    const nextActive = !this.active ? 1 : 0;
    if (!this.carousel[nextActive].loaded || !this._showNext) return;
    this._showNext = false;
    this.active = nextActive;
    this._timeouts.push(setTimeout(() => {
      if (this.cantChange()) return;
      this._index = this.getNextIndex(this._index);
      const prevActive = !this.active ? 1 : 0;
      this.carousel[prevActive].loaded = false;
      this.carousel[prevActive].image = this._images[this.getNextIndex(this._index)];
    }, this.ANIMATION));
    this._timeouts.push(setTimeout(() => {
      if (this.cantChange()) return;
      this._showNext = true;
      this.changeElement();
    }, this.DELAY + this.ANIMATION));
  }

  private getNextIndex(index: number): number {
    return ++index < this._images.length ? index : 0;
  }

  public imageLoaded(element: number): void {
    this.carousel[element].loaded = true;
    this.changeElement();
  }

  private cantChange(): boolean {
    return !this.carousel || this.carousel.length < 2 || !this._images || !this._images.length;
  }

  public ngOnDestroy(): void {
    this._images = undefined;
    this.carousel = [];
    this.active = 0;
    this._index = 0;
    this._images = undefined;
    this._showNext = false;
    this._timeouts.forEach((timeout) => clearTimeout(timeout));
    this._timeouts = [];
  }
}
