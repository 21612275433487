import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {OfflineService} from '../../../../../../offline/services/offline-service/offline.service';
import {PrintFormatChange} from '../../../../../../print/communication/interfaces/print-format-change';
import {PrintLegendChange} from '../../../../../../print/communication/interfaces/print-legend-change';
import {PrintPreviewChange} from '../../../../../../print/communication/interfaces/print-preview-change';
import {PrintTasksChange} from '../../../../../../print/communication/interfaces/print-tasks-change';
import {PrintService} from '../../../../../../print/communication/print.service';
import {DragService} from '../../../../../../services/drag-service/drag.service';
import {Drag} from '../../../../../../services/drag-service/interafces/drag';
import {Pinch} from '../../../../../../services/pinch-service/interfaces/pinch';
import {BrowserUtil} from '../../../../../../utils/browser-util';
import {LoadingComplete} from '../../../../screens/version/components/loading-progress/service/interfaces/loading-complete';
import {LoadingProgressService} from '../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {FontResponse} from '../../../fonts/api/data/font-response';
import {FontsCommunicationService} from '../../../fonts/communication/fonts-communication.service';
import {FontsInfoLoaded} from '../../../fonts/communication/interfaces/fonts-info-loaded';
import {FontsLoaded} from '../../../fonts/communication/interfaces/fonts-loaded';
import {DisableLayers} from '../../../layer-manager/communication/interfaces/disable-layers';
import {LayerManagerCommunicationService} from '../../../layer-manager/communication/layer-manager-communication.service';
import {CustomInteractivesCommunicationService} from '../../../layer-manager/modules/custom-interactives-manager/communiaction/custom-interactives-communication.service';
import {CustomInteractivesEditMode} from '../../../layer-manager/modules/custom-interactives-manager/communiaction/interface/custom-interactives-edit-mode';
import {LayerResponse} from '../../api/data/layer-response';
import {LayerOfflineService} from '../../api/services/layer-offline.service';
import {LayerService} from '../../api/services/layer.service';
import {ScaleCenterChange} from '../../communication/interfaces/scale-center-change';
import {ScaleChange} from '../../communication/interfaces/scale-change';
import {ViewChange} from '../../communication/interfaces/view-change';
import {ViewMove} from '../../communication/interfaces/view-move';
import {LayersCommunicationService} from '../../communication/layers-communication.service';
import {AbstractLayersConstructor} from '../../constructor/abstracts/abstract-layers-constructor';
import {AsyncLayersConstructor} from '../../constructor/async-layers-constructor';
import {LayersConstructor} from '../../constructor/layers-constructor';

@Component({
  selector: 'app-layers',
  templateUrl: './layers.component.html',
  styleUrls: ['./layers.component.scss']
})
export class LayersComponent implements OnInit, OnDestroy, ViewChange, ViewMove, ScaleChange, ScaleCenterChange, DisableLayers, Drag, Pinch,
  FontsInfoLoaded, FontsLoaded, LoadingComplete, PrintPreviewChange, PrintFormatChange, PrintLegendChange, PrintTasksChange,
  CustomInteractivesEditMode {

  @Input()
  public versionId: string;

  @Input()
  public projectId: string;

  @Input()
  public version: number;

  @Input()
  public showInteractive: boolean;

  @Input()
  private fontsReady: boolean;

  @Input()
  public isPreview: boolean;

  private isOffline: boolean;

  @ViewChild('container', {static: true})
  public containerRef: ElementRef;

  @ViewChild('canvas', {static: true})
  public canvasRef: ElementRef;

  @ViewChild('dragTarget', {static: true})
  public element: ElementRef;

  public isDrag = false;

  public isEditMode: boolean;

  public isCustomDrag = false;

  private _constructor: AbstractLayersConstructor<any, any>;
  private _initialized: boolean;
  private _fonts: FontResponse[];
  private _startX: number;
  private _startY: number;
  private _disabledLayers: boolean[] = [];

  public constructor(private readonly _layerService: LayerService, private readonly _layerOfflineService: LayerOfflineService,
                     private readonly _dragService: DragService,
                     private readonly _layerManagerCommunication: LayerManagerCommunicationService,
                     private readonly _communication: LayersCommunicationService, private readonly _offline: OfflineService,
                     private readonly _fontsCommunication: FontsCommunicationService,
                     private readonly _loading: LoadingProgressService, private readonly _print: PrintService,
                     private readonly _customInteractivesCommunication: CustomInteractivesCommunicationService) {
    this._loading.addLoadingCompleteListener(this);
    this._fontsCommunication.addFontsInfoLoadedListener(this);
    this._fontsCommunication.addFontsLoadedListener(this);
    this._communication.addViewChangeListener(this);
    this._communication.addViewMoveListener(this);
    this._communication.addScaleChangeListener(this);
    this._communication.addScaleCenterChangeListener(this);
    this._layerManagerCommunication.addDisableLayersListener(this);
    this._print.addPrintPreviewChangeListener(this);
    this._print.addPrintFormatChangeListener(this);
    this._print.addPrintLegendChangeListener(this);
    this._print.addPrintTasksChangeListener(this);
    this._customInteractivesCommunication.addCustomInteractiveEditModeListener(this);
  }

  public onLoadingComplete(): void {
    if (!this._constructor) return;
    this._communication.viewMove(this._constructor.x, this._constructor.y);
    this._communication.scaleChange(this._constructor.scale);
    this._communication.viewChange(this.containerRef.nativeElement.offsetWidth, this.containerRef.nativeElement.offsetHeight);
    this._communication.minimalScaleChange(this._constructor.minimalScale);
    this._communication.actualLevelChange(this.actualLevel);
  }

  private get actualLevel(): number {
    if (!this._constructor) return -1;
    const levelSizes = this._constructor.levelSizes;
    const width = this._constructor.width;
    for (let i = 0; i < levelSizes.length - 1; i++) {
      if (width <= levelSizes[i].width && width > levelSizes[i + 1].width) return i;
    }
    return levelSizes.length - 1;
  }

  public onDrag(event: any, x: number, y: number): void {
    if (!this._constructor || this.isCustomDrag) return;
    this._constructor.x += x;
    this._constructor.y += y;
    this._communication.viewMove(this._constructor.x, this._constructor.y);
  }

  public onStartDrag(event: MouseEvent): void {
    if (this.isCustomDrag) return;
    this.isDrag = true;
    this._startX = event.offsetX;
    this._startY = event.offsetY;
  }

  public onStopDrag(event: MouseEvent): void {
    if (this.isCustomDrag) return;
    if (this._startX === event.offsetX && this._startY === event.offsetY)
      this._communication.layerClick(event.offsetX, event.offsetY);
    this.isDrag = false;
  }

  public ngOnInit(): void {
    if (!this.element) return;
    this._dragService.addDrag(this, false, true);
    this._initialized = true;
    this.getData();
    this.onResize();
  }

  public onFontsInfoLoaded(fonts: FontResponse[]): void {
    if (!BrowserUtil.asyncRender) return;
    this._fonts = fonts;
    this.fontsReady = true;
    this.getData();
  }

  public onFontsLoaded(): void {
    if (BrowserUtil.asyncRender) return;
    this.fontsReady = true;
    this.getData();
  }

  private getData(): void {
    if (!this._initialized || !this.fontsReady) return;
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      this.isOffline = result;
      setTimeout(() => {
        const requestMethod = this.isOffline
          ? this._layerOfflineService.getLayer(this.projectId)
          : this.getOnlineMethod();
        requestMethod.subscribe((response: LayerResponse) => {
          this.setData(response);
          this._loading.configLoaded();
        });
      }, 0);
    });
  }

  private getOnlineMethod(): Observable<LayerResponse> {
    return this.projectId === undefined
      ? this._layerService.getLayerPreview(this.versionId)
      : this._layerService.getLayer(this.projectId);
  }

  public setData(data: LayerResponse): void {
    const id = this.projectId ? this.projectId : this.versionId;
    const canvas = this.canvasRef.nativeElement;
    const container = this.containerRef.nativeElement;
    canvas.width = container.offsetWidth;
    canvas.height = container.offsetHeight;
    this._constructor = BrowserUtil.asyncRender ?
      new AsyncLayersConstructor(id, this.version, data, container, canvas, this._fonts, this._loading, this.isOffline) :
      new LayersConstructor(id, this.version, data, container, canvas, this._loading, this.isOffline);
    this._communication.constructor = this._constructor;
    this._communication.viewChange(container.offsetWidth, container.offsetHeight);
    this._constructor.setDisabledLayers(this._disabledLayers);
  }

  @HostListener('window:resize', ['$event'])
  private onResize(): void {
    const container = this.containerRef.nativeElement;
    if (this._constructor) {
      this._constructor.resize(container.offsetWidth, container.offsetHeight);
      this._communication.minimalScaleChange(this._constructor.minimalScale);
    }
    this._communication.viewChange(container.offsetWidth, container.offsetHeight);
  }

  @HostListener('mousewheel', ['$event'])
  private onMouseWheelChrome(event: any): void {
    this.onMouseWheel(event);
  }

  @HostListener('DOMMouseScroll', ['$event'])
  private onMouseWheelFirefox(event: any): void {
    this.onMouseWheel(event);
  }

  @HostListener('onmousewheel', ['$event'])
  private onMouseWheelIE(event: any): void {
    this.onMouseWheel(event);
  }

  private onMouseWheel(event: any): void {
    if (this._constructor === undefined) return;
    event = window.event || event;
    event.returnValue = false;
    if (event.preventDefault) event.preventDefault();
    const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
    this.zoomWithCenter(delta, {x: event.clientX, y: event.clientY});
  }

  private zoomWithCenter(zoom: number, center: { x: number, y: number }): void {
    const container = this.containerRef.nativeElement;
    const canvasX = container.getBoundingClientRect().left;
    const canvasY = container.getBoundingClientRect().top;
    const positionXInCanvas = center.x - canvasX;
    const positionYInCanvas = center.y - canvasY;

    // for IE
    event.returnValue = false;
    // for Chrome and Firefox
    if (event.preventDefault) event.preventDefault();

    if (center.x < canvasX || positionXInCanvas > container.offsetWidth ||
      center.y < canvasY || positionYInCanvas > container.offsetHeight) return;

    const positionXInView = -this._constructor.x + positionXInCanvas;
    const positionYInView = -this._constructor.y + positionYInCanvas;

    let viewWidthDiff = this._constructor.width;
    let viewHeightDiff = this._constructor.height;
    let viewXDiff = this._constructor.x;
    let viewYDiff = this._constructor.y;

    this._constructor.width += this._constructor.width * 0.02 * zoom;

    viewWidthDiff -= this._constructor.width;
    viewHeightDiff -= this._constructor.height;
    viewXDiff -= this._constructor.x;
    viewYDiff -= this._constructor.y;

    if (viewWidthDiff === 0) return;
    this._constructor.x += this.getPositionDistance(positionXInView, this._constructor.width + viewWidthDiff, viewWidthDiff)
      + viewXDiff;
    this._constructor.y += this.getPositionDistance(positionYInView, this._constructor.height + viewHeightDiff, viewHeightDiff)
      + viewYDiff;

    this._communication.scaleChange(this._constructor.scale);
    this._communication.viewMove(this._constructor.x, this._constructor.y);
    this._communication.actualLevelChange(this.actualLevel);
  }

  public onPinch(distanceDiff: number, center?: { x: number; y: number }): void {
    this.zoomWithCenter(distanceDiff / 5, center);
  }

  public onViewChange(width: number, height: number): void {
    if (this._constructor === undefined) return;
    const viewX = this._constructor.x;
    const viewY = this._constructor.y;
    const viewScale = this._constructor.scale;
    this._constructor.recalculate();
    this._constructor.draw();

    if (viewX !== this._constructor.x || viewY !== this._constructor.y)
      this._communication.viewMove(this._constructor.x, this._constructor.y);
    if (viewScale !== this._constructor.scale)
      this._communication.scaleChange(this._constructor.scale);
  }

  public onViewMove(x: number, y: number): void {
    if (this._constructor === undefined) return;
    this._constructor.x = x;
    this._constructor.y = y;
    this._constructor.draw();
    if (this._constructor.x !== x || this._constructor.y !== y) this._communication.viewMove(this._constructor.x, this._constructor.y);
  }

  public onScaleChange(scale: number): void {
    if (this._constructor === undefined) return;
    const viewX = this._constructor.x;
    const viewY = this._constructor.y;
    this._constructor.scale = scale;
    this._constructor.draw();

    if (this._constructor.scale !== scale) {
      this._communication.scaleChange(scale);
      this._communication.actualLevelChange(this.actualLevel);
    }
    if (viewX !== this._constructor.x || viewY !== this._constructor.y)
      this._communication.viewMove(this._constructor.x, this._constructor.y);
  }

  public onScaleCenterChange(scale: number): void {
    if (this._constructor === undefined || this._constructor.scale === scale) return;
    const container = this.containerRef.nativeElement;
    const posPropX = this.getPositionProportion(this._constructor.x, container.offsetWidth / 2, this._constructor.width);
    const posPropY = this.getPositionProportion(this._constructor.y, container.offsetHeight / 2, this._constructor.height);

    let viewWidthDiff = this._constructor.width;
    let viewHeightDiff = this._constructor.height;
    let viewXDiff = this._constructor.x;
    let viewYDiff = this._constructor.y;

    this._constructor.scale = scale;

    viewWidthDiff -= this._constructor.width;
    viewHeightDiff -= this._constructor.height;
    viewXDiff -= this._constructor.x;
    viewYDiff -= this._constructor.y;

    if (viewWidthDiff !== 0) {
      const changeX = posPropX * viewWidthDiff + viewXDiff;
      const changeY = posPropY * viewHeightDiff + viewYDiff;
      this._constructor.x += changeX;
      this._constructor.y += changeY;
      this._constructor.draw();
      this._communication.viewMove(this._constructor.x, this._constructor.y);
    } else this._constructor.draw();

    this._communication.scaleChange(scale);
    this._communication.actualLevelChange(this.actualLevel);
  }

  public onDisableLayers(layers: boolean[]): void {
    this._disabledLayers = layers;
    if (this._constructor === undefined) return;
    this._constructor.setDisabledLayers(layers);
  }

  private getPositionProportion(viewPosition: number, onCanvasPosition: number, viewSize: number): number {
    return (-viewPosition + onCanvasPosition) / viewSize;
  }

  private getPositionDistance(positionInView: number, viewSize: number, viewSizeDiff: number): number {
    const viewProportion = positionInView / viewSize;
    return viewSizeDiff * viewProportion;
  }

  public onPrintPreviewChange(): void {
    this.onResize();
  }

  public onPrintFormatChange(): void {
    this.onResize();
  }

  public onPrintLegendChange(): void {
    this.onResize();
  }

  public onPrintTasksChange(): void {
    this.onResize();
  }

  public exitEditMode(): void {
    this.isEditMode = false;
    this._customInteractivesCommunication.callCustomInteractiveEditModeDisabled();
    this._customInteractivesCommunication.callInteractiveCustomLayersChanged();
  }

  public customInteractiveEditModeEnabled(layerId: string, projectId: string): void {
    this.isEditMode = true;
  }

  public customInteractiveEditModeDisabled(): void {
    this.isEditMode = false;
  }

  public ngOnDestroy(): void {
    this._loading.removeLoadingCompleteListener(this);
    this._fontsCommunication.removeFontsInfoLoadedListener(this);
    this._fontsCommunication.removeFontsLoadedListener(this);
    this._communication.removeViewChangeListener(this);
    this._communication.removeViewMoveListener(this);
    this._communication.removeScaleChangeListener(this);
    this._communication.removeScaleCenterChangeListener(this);
    this._layerManagerCommunication.removeDisableLayersListener(this);
    this._dragService.removeDrag(this);
    this._print.removePrintPreviewChangeListener(this);
    this._print.removePrintFormatChangeListener(this);
    this._print.removePrintLegendChangeListener(this);
    this._print.removePrintTasksChangeListener(this);
    this._customInteractivesCommunication.removeCustomInteractiveEditModeListener(this);
    if (this._constructor) {
      this._constructor.destroy();
      this._communication.constructor = undefined;
    }
  }

  public changeCustomDrag(customDrag: boolean): void {
    this.isCustomDrag = customDrag;
  }
}
