import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InteractiveLayerResponse} from '../../../../../../interactive/api/data/interactive-layer-response';
import {CustomInteractiveService} from '../../../api/services/custom-interactive-service';
import {CustomInteractivesCommunicationService} from '../../../communiaction/custom-interactives-communication.service';

@Component({
  selector: 'app-interactive-remove',
  templateUrl: './interactive-remove.component.html',
  styleUrls: ['./interactive-remove.component.scss']
})
export class InteractiveRemoveComponent {

  @Input()
  public projectId: string;

  @Input()
  public cInteractiveLayer: InteractiveLayerResponse;

  @Input()
  public cInteractiveLayers: InteractiveLayerResponse[];

  @Output()
  public InteractiveLayerChange = new EventEmitter<InteractiveLayerResponse[]>();

  @Output()
  public close = new EventEmitter<void>();

  public constructor(private readonly _service: CustomInteractiveService,
                     private readonly _communication: CustomInteractivesCommunicationService) {
  }

  public onSubmit(): void {
    this._service.removeLayer(this.projectId, this.cInteractiveLayer.id).subscribe(() => {
      this._communication.callInteractiveCustomLayersChanged();
      this.close.emit();
    });
  }
}
