import {JsonProperty} from '@dhkatz/json-ts';
import {PartResponse} from '../parts/part-response';
import {AbstractRasterLevelResponse} from './abstracts/abstract-raster-level-response';

export class RasterLevelResponse extends AbstractRasterLevelResponse {

  @JsonProperty({name: 'd', type: PartResponse})
  private _data: PartResponse[];

  public constructor() {
    super();
    this._data = undefined;
  }

  public get data(): PartResponse[] {
    return this._data;
  }
}
