import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'addressNo'
})
export class AddressNoPipe implements PipeTransform {

  public transform(streetNo: string, apartmentNo: string): string {
    streetNo = streetNo ? streetNo.trim() : '';
    apartmentNo = apartmentNo ? apartmentNo.trim() : '';
    return streetNo + (streetNo && apartmentNo ? '/' : '') + apartmentNo;
  }
}
