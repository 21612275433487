import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {VersionModule} from '../../../project/version/modules/version-modules.enum';
import {PrintFormat} from '../../communication/enums/print-format.enum';
import {PrintFormatChange} from '../../communication/interfaces/print-format-change';
import {PrintLegendChange} from '../../communication/interfaces/print-legend-change';
import {PrintPreviewChange} from '../../communication/interfaces/print-preview-change';
import {PrintScaleChange} from '../../communication/interfaces/print-scale-change';
import {PrintTasksChange} from '../../communication/interfaces/print-tasks-change';
import {PrintTitleChange} from '../../communication/interfaces/print-title-change';
import {PrintService} from '../../communication/print.service';

@Component({
  selector: 'app-print-tool',
  templateUrl: './print-tool.component.html',
  styleUrls: ['./print-tool.component.scss']
})
export class PrintToolComponent implements OnDestroy, PrintPreviewChange, PrintFormatChange, PrintTitleChange, PrintScaleChange,
  PrintLegendChange, PrintTasksChange, OnChanges {

  @Input()
  public versionModules: VersionModule[];

  public readonly formats = Object.keys(PrintFormat);
  public readonly formatField: FormControl = new FormControl(PrintFormat.A4);
  public readonly titleField: FormControl = new FormControl(true);
  public readonly scaleField: FormControl = new FormControl(true);
  public readonly legendField: FormControl = new FormControl(true);
  public readonly tasksField: FormControl = new FormControl(true);

  public showLegend: boolean;
  public showScale: boolean;
  public showTasks: boolean;

  private readonly _classes = document.body.classList;
  private readonly _style: HTMLStyleElement = document.createElement('style');

  public constructor(public readonly print: PrintService) {
    this.print.addPrintPreviewChangeListener(this);
    this.print.addPrintFormatChangeListener(this);
    this.print.addPrintTitleChangeListener(this);
    this.print.addPrintScaleChangeListener(this);
    this.print.addPrintLegendChangeListener(this);
    this.print.addPrintTasksChangeListener(this);
    document.head.appendChild(this._style);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.versionModules) return;
    this.scaleVisibility();
    this.legendVisibility();
    this.tasksVisibility();
  }

  private scaleVisibility(): void {
    this.showScale = this.versionModules.indexOf(VersionModule.scale) >= 0;
    this.scaleField.setValue(this.showScale);
  }

  private legendVisibility(): void {
    this.showLegend = this.versionModules.indexOf(VersionModule.legend) >= 0;
    this.legendField.setValue(this.showLegend);
  }

  private tasksVisibility(): void {
    this.showTasks = this.versionModules.indexOf(VersionModule.tasks) >= 0;
    this.tasksField.setValue(this.showTasks);
  }

  public onPrintPreviewChange(enabled: boolean): void {
    if (!enabled) {
      // tslint:disable-next-line:no-duplicate-string
      this._classes.remove('a4', 'a3', 'print', 'print-legend', 'print-title', 'print-scale', 'print-tasks');
      return;
    }
    this._classes.add('print');
    this.print.callPrintFormatChange(this.formatField.value);
    this.print.callPrintTitleChange(this.titleField.value);
    this.print.callPrintScaleChange(this.scaleField.value);
    this.print.callPrintLegendChange(this.legendField.value);
    this.print.callPrintTasksChange(this.tasksField.value);
  }

  public onPrintFormatChange(format: PrintFormat): void {
    this._classes.remove('a4', 'a3');
    this._classes.add(format.toString().toLowerCase());
    const sheet = <CSSStyleSheet>this._style.sheet;
    if (sheet.rules.length) sheet.removeRule(0);
    sheet.insertRule('@page{size: ' + format.toString() + ' landscape;}');
  }

  public onPrintTitleChange(enabled: boolean): void {
    if (enabled) this._classes.add('print-title');
    else this._classes.remove('print-title');
  }

  public onPrintScaleChange(enabled: boolean): void {
    if (enabled) this._classes.add('print-scale');
    else this._classes.remove('print-scale');
  }

  public onPrintLegendChange(enabled: boolean): void {
    if (enabled) this._classes.add('print-legend');
    else this._classes.remove('print-legend');
  }

  public onPrintTasksChange(enabled?: boolean): void {
    if (enabled) this._classes.add('print-tasks');
    else this._classes.remove('print-tasks');
  }

  public printView(): void {
    window.print();
  }

  public ngOnDestroy(): void {
    this.print.removePrintPreviewChangeListener(this);
    this.print.removePrintFormatChangeListener(this);
    this.print.removePrintTitleChangeListener(this);
    this.print.removePrintScaleChangeListener(this);
    this.print.removePrintLegendChangeListener(this);
    this.print.removePrintTasksChangeListener(this);
    this._style.remove();
  }
}
