<header mat-dialog-title>{{'project.tasks.title' | translate}}
  <button *ngIf="screen === screens.List" (click)="add()">
    <mat-icon>add</mat-icon>
  </button>
</header>
<app-tasks-list [hidden]="screen!==screens.List" [init]="init" [projectId]="projectId" [isOffline]="isOffline"
                [(tasks)]="tasks" (update)="update($event)" (remove)="remove($event)"></app-tasks-list>
<app-task-edit [hidden]="screen!==screens.Edit" [task]="selected" [(tasks)]="tasks" [isOffline]="isOffline"
               [projectId]="projectId" [screen]="screen" (close)="screen = screens.List"></app-task-edit>
<app-task-remove [hidden]="screen!==screens.Remove" [task]="selected" [(tasks)]="tasks" [isOffline]="isOffline"
                 [projectId]="projectId" (close)="screen = screens.List"></app-task-remove>
