import {devEnv} from './runtime-environments/environment.dev';
import {Environment} from './runtime-environments/environment.interface';
import {defaultEnv} from './runtime-environments/environment.default';
import {prodEnv} from './runtime-environments/environment.prod';
import {prodEnEnv} from './runtime-environments/environment.prod-en';

const envConfigs: Map<string, Environment> = new Map([
  ['dev', devEnv],
  ['prod', prodEnv],
  ['prod-en', prodEnEnv]
]);

export function envFactory(appMetaTagEnvProfile: string): Environment {
  return envConfigs.get(appMetaTagEnvProfile) || defaultEnv;
}
