import {CurveColumnSeries} from '@amcharts/amcharts4/.internal/plugins/timeline/CurveColumnSeries';
import {SerpentineChart} from '@amcharts/amcharts4/.internal/plugins/timeline/SerpentineChart';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import {ITheme} from '@amcharts/amcharts4/core';
import {MapChart} from '@amcharts/amcharts4/maps';
import * as am4bullets from '@amcharts/amcharts4/plugins/bullets';
import * as am4timeline from '@amcharts/amcharts4/plugins/timeline';
import am4themes_amcharts from '@amcharts/amcharts4/themes/amcharts';
import am4themes_amchartsdark from '@amcharts/amcharts4/themes/amchartsdark';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import am4themes_microchart from '@amcharts/amcharts4/themes/microchart';
import am4themes_moonrisekingdom from '@amcharts/amcharts4/themes/moonrisekingdom';
import am4themes_patterns from '@amcharts/amcharts4/themes/patterns';
import am4themes_spiritedaway from '@amcharts/amcharts4/themes/spiritedaway';
import {isPlatformBrowser} from '@angular/common';
import {NgZone} from '@angular/core';
import {ChartData} from './interfaces/chart-data';
import {GeoDataUtil} from './utils/GeoDataUtil';

export class Chart {

  private static readonly THEMES: Map<string, ITheme> = new Map<string, ITheme>([
    ['am4themes_animated', am4themes_animated],
    ['am4themes_dark', am4themes_dark],
    ['am4themes_amcharts', am4themes_amcharts],
    ['am4themes_amchartsdark', am4themes_amchartsdark],
    ['am4themes_dataviz', am4themes_dataviz],
    ['am4themes_frozen', am4themes_frozen],
    ['am4themes_kelly', am4themes_kelly],
    ['am4themes_material', am4themes_material],
    ['am4themes_microchart', am4themes_microchart],
    ['am4themes_moonrisekingdom', am4themes_moonrisekingdom],
    ['am4themes_patterns', am4themes_patterns],
    ['am4themes_spiritedaway', am4themes_spiritedaway]
  ]);

  private static readonly IMPORTS = [MapChart, SerpentineChart, CurveColumnSeries, am4timeline, am4bullets];

  private _data?: ChartData;
  private _chart?: am4core.Sprite;
  private _id?: string;

  private constructor(private readonly _platformId, private readonly _zone: NgZone) {
  }

  public static builder(platformId, zone: NgZone): Chart {
    return new Chart(platformId, zone);
  }

  private browserOnly(f: () => void): void {
    if (isPlatformBrowser(this._platformId)) this._zone.runOutsideAngular(() => f());
  }

  public setId(id: string): Chart {
    this._id = id;
    return this;
  }

  public setData(data: ChartData): Chart {
    this._data = data;
    return this;
  }

  public render(): Chart {
    if (!this._data) return this;
    this.browserOnly(() => {

      am4core.unuseAllThemes();
      if (this._data.themes) this._data.themes.forEach((value: string) => am4core.useTheme(Chart.THEMES.get(value)));
      const chart = GeoDataUtil.convert(this._data.chart);
      setTimeout(() => this.renderChart(chart));
    });
    return this;
  }

  private renderChart(chartData: any): void {
    if (!chartData) return;
    if (this._chart) this._chart.dispose();
    this._chart = am4core.createFromConfig(chartData, this._id, am4charts[chartData.type]);
  }

  public clear(): Chart {
    this.browserOnly(() => {
      if (this._chart) setTimeout(() => {
        this._chart.dispose();
        this._chart = undefined;
      });
    });
    this._data = undefined;
    this._id = undefined;
    return this;
  }
}
