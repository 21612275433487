import {JsonProperty} from '@dhkatz/json-ts';
import {FontFileResponse} from './font-file-response';

export class FontResponse {

  @JsonProperty('n')
  private _name: string;

  @JsonProperty({name: 'f', type: FontFileResponse})
  private _files: FontFileResponse[];

  public constructor() {
    this._name = undefined;
    this._files = undefined;
  }

  public get name(): string {
    return this._name;
  }

  public get files(): FontFileResponse[] {
    return this._files;
  }
}
