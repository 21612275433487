export class Endpoints {

  public static readonly VIEWER = location.origin + '/api';

  public static readonly FILES = Endpoints.VIEWER + '/files/';
  public static readonly RESOURCES = Endpoints.VIEWER + '/resources/';

  public static readonly PROJECT_ID = '{projectId}';
  public static readonly VERSION_ID = '{versionId}';
  public static readonly CATEGORY_ID = '{categoryId}';
  public static readonly ACCOUNT_ID = '{accountId}';
  public static readonly TAG_ID = '{catId}';
  public static readonly MESSAGE_ID = '{messageId}';
  public static readonly LAYER_ID = '{layerId}';
  public static readonly POINT_ID = '{point}';
  public static readonly FILE_ID = '{fileId}';
  public static readonly ICON_ID = '{iconId}';

  public static readonly POINT_TYPE = '{pointType}';
}
