export class RoutingConfig {

  public static readonly PROJECT_ID = 'projectId';
  public static readonly PROJECT_ID_PARAM = ':' + RoutingConfig.PROJECT_ID;
  public static readonly VERSION_ID = 'versionId';
  public static readonly VERSION_ID_PARAM = ':' + RoutingConfig.VERSION_ID;
  public static readonly CATEGORY_ID = 'categoryId';
  public static readonly CATEGORY_ID_PARAM = ':' + RoutingConfig.CATEGORY_ID;
  public static readonly MESSAGE_ID = 'messageId';
  public static readonly MESSAGE_ID_PARAM = ':' + RoutingConfig.MESSAGE_ID;

  public static readonly EMAIL_CONFIRM = 'email-confirm';
  public static readonly REGISTER_CONFIRM = 'register-confirm';
  public static readonly TEACHER_CONFIRM = 'teacher-confirm';
  public static readonly CHANGE_PASSWORD = 'change-password';
  public static readonly REGISTER = 'register';
  public static readonly LOGIN = 'login';
  public static readonly RESET_PASSWORD = 'reset-password';
  public static readonly CATEGORY = 'cat/' + RoutingConfig.CATEGORY_ID_PARAM;
  public static readonly PROJECT = RoutingConfig.CATEGORY + '/pro/' + RoutingConfig.PROJECT_ID_PARAM;
  public static readonly PROJECT_OFFLINE = RoutingConfig.PROJECT + '/offline';
  public static readonly PROJECT_PREVIEW = 'preview/' + RoutingConfig.VERSION_ID_PARAM;

  private static readonly ACCOUNT = 'account';
  public static readonly ACCOUNT_PROFILE = RoutingConfig.ACCOUNT + '/profile';
  public static readonly ACCOUNT_MANAGE = RoutingConfig.ACCOUNT + '/manage';
  public static readonly ACCOUNT_SUBSCRIPTIONS = RoutingConfig.ACCOUNT + '/subscriptions';
  public static readonly ACCOUNT_OFFLINE_PROJECTS = 'offline';

  public static readonly MESSAGES = 'messages';
  public static readonly MESSAGE = RoutingConfig.MESSAGES + '/' + RoutingConfig.MESSAGE_ID_PARAM;
}
