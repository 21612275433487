import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractPointResponse} from './abstracts/abstract-point-response';
import {LinkType} from './enums/link-type.enum';
import {EnumConverter} from '../../../../../../../globals/api/json/enum-converter';

export class EmbedPointResponse extends AbstractPointResponse {

  @JsonProperty({name: 'lt', converter: EnumConverter.converter(LinkType)})
  private _linkType: LinkType;

  @JsonProperty('l')
  private _link: string;

  public constructor() {
    super();
    this._linkType = undefined;
    this._link = undefined;
  }

  public get linkType(): LinkType {
    return this._linkType;
  }

  public get link(): string {
    return this._link;
  }
}
