import {Size} from '../../../../../globals/api/data/size';
import {CacheRequest} from '../../../../../utils/models/cache-request';
import {LayerResponse} from '../api/data/layer-response';
import {AbstractLayerResponse} from '../api/data/layer-response/layers/abstracts/abstract-layer-response';
import {AbstractPreRenderConstructor} from './abstracts/abstract-pre-render-constructor';
import {PrepareComplete} from './interfaces/prepare-complete';
import {PreRenderLevel} from './levels/pre-render-level';

export class PreRenderConstructor extends AbstractPreRenderConstructor<PreRenderLevel> {

  public constructor(data: LayerResponse, cacheRequest: CacheRequest, canvas: HTMLCanvasElement,
                     protected readonly _complete: PrepareComplete, offline) {
    super(data, cacheRequest, canvas);
    this.setPreRenderLevels(data.layers, cacheRequest, offline);
  }

  protected setPreRenderLevels(layers: AbstractLayerResponse[], cacheRequest: CacheRequest, offline: boolean): void {
    if (!this.levelSizes) return;
    this.levelSizes.forEach((levelSize: Size, index: number) => {
      const partsData = this.getPreRenderLevelData(index, cacheRequest, layers);
      if (!Array.from(partsData.keys()).length) return;
      this.levels.push(new PreRenderLevel(partsData, [levelSize], this.getNextLevelSizes(index), this, offline));
    });
  }

  public elementLoadComplete(): void {
    this._complete.loadedComplete();
  }

  public loadCalcComplete(elements: number): void {
    this._complete.loadCalcComplete(elements);
  }
}
