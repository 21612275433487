import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {AbstractDialogComponent} from '../../../../../../../../globals/dialogs/abstracts/abstract-dialog.component';
import {MeasurementSystem} from '../../../../communication/enums/measurement-system.enum';
import {ProjectorDimensions} from '../../../../communication/enums/projector-dimensions.enum';
import {ScreenType} from '../../../../communication/enums/screen-type.enum';
import {ScaleCommunicationService} from '../../../../communication/scale-communication.service';

@Component({
  selector: 'app-settings',
  templateUrl: './scale-settings.component.html',
  styleUrls: ['./scale-settings.component.scss']
})
export class ScaleSettingsComponent extends AbstractDialogComponent {

  public screenType: ScreenType;
  public screenTypes = ScreenType;

  public projectorDimensions = [];
  public projectorMeasures = [];

  public screenDiagonalField: FormControl;
  public projectorSizeField: FormControl;
  public projectorDimensionField: FormControl;
  public projectorMeasureField: FormControl;

  public constructor(private _dialogRef: MatDialogRef<ScaleSettingsComponent>, @Inject(MAT_DIALOG_DATA) data: any,
                     private _communication: ScaleCommunicationService, private readonly _translate: TranslateService) {
    super(data);
    this.projectorMeasures = [
      {key: MeasurementSystem.cm, value: this._translate.instant('project.scale.settings.measures.cm')},
      {key: MeasurementSystem.inch, value: this._translate.instant('project.scale.settings.measures.inch')}
    ];
    this.projectorDimensions = [
      {key: ProjectorDimensions.width, value: this._translate.instant('project.scale.settings.dimensions.width')},
      {key: ProjectorDimensions.height, value: this._translate.instant('project.scale.settings.dimensions.height')},
      {key: ProjectorDimensions.diagonal, value: this._translate.instant('project.scale.settings.dimensions.diagonal')},
    ];
  }

  protected createForm(): void {
    this.form = new FormGroup({
      screenDiagonalField: this.screenDiagonalField,
      projectorSizeField: this.projectorSizeField,
      projectorDimensionField: this.projectorDimensionField,
      projectorMeasureField: this.projectorMeasureField
    });
  }

  protected createFormControls(): void {
    this.screenType = this._communication.screenType;
    this.screenDiagonalField = new FormControl(this._communication.screenDiagonal);
    this.projectorSizeField = new FormControl(this._communication.projectorSize);
    this.projectorDimensionField = new FormControl(this._communication.projectorDimension);
    this.projectorMeasureField = new FormControl(this._communication.projectorMeasure);
  }

  public onSubmit(): void {
    this._communication.setScaleSettings(this.screenType, this.screenDiagonalField.value, this.projectorSizeField.value,
      this.projectorDimensionField.value, this.projectorMeasureField.value);
  }
}
