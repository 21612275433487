import {Injectable} from '@angular/core';
import {DisableInteractives} from './interfaces/disable-interactives';
import {DisableLayers} from './interfaces/disable-layers';

@Injectable({
  providedIn: 'root'
})
export class LayerManagerCommunicationService {

  private _onDisableLayersListeners: DisableLayers[] = [];
  private _onDisableInteractivesListeners: DisableInteractives[] = [];

  public constructor() {
  }

  public addDisableLayersListener(listener: DisableLayers): void {
    this._onDisableLayersListeners.push(listener);
  }

  public removeDisableLayersListener(listener: DisableLayers): void {
    const index: number = this._onDisableLayersListeners.indexOf(listener);
    if (index === -1) return;
    this._onDisableLayersListeners.splice(index, 1);
  }

  public disableLayers(layers: boolean[]): void {
    if (this._onDisableLayersListeners.length === 0) return;
    this._onDisableLayersListeners.forEach((listener: DisableLayers) => {
      try {
        listener.onDisableLayers(layers);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public addOnDisableInteractiveListener(listener: DisableInteractives): void {
    this._onDisableInteractivesListeners.push(listener);
  }

  public removeDisableInteractivesListener(listener: DisableInteractives): void {
    const index: number = this._onDisableInteractivesListeners.indexOf(listener);
    if (index === -1) return;
    this._onDisableInteractivesListeners.splice(index, 1);
  }

  public disableInteractives(interactives: boolean[], customInteractives: boolean[]): void {
    if (this._onDisableInteractivesListeners.length === 0) return;
    this._onDisableInteractivesListeners.forEach((listener: DisableInteractives) => {
      try {
        listener.onDisableInteractives(interactives, customInteractives);
      } catch (exception) {
        console.log(exception);
      }
    });
  }
}
