import {JsonProperty} from '@dhkatz/json-ts';

export class Size {

  @JsonProperty('w')
  private _width: number;

  @JsonProperty('h')
  private _height: number;

  public constructor() {
    this._width = undefined;
    this._height = undefined;
  }

  public get width(): number {
    return this._width;
  }

  public set width(value: number) {
    this._width = value;
  }

  public get height(): number {
    return this._height;
  }

  public set height(value: number) {
    this._height = value;
  }

  public setSize(width: number, height: number): Size {
    this._width = width;
    this._height = height;
    return this;
  }
}
