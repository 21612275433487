<div class="icon-select interactive-point-select">
  <mat-form-field class="mat-select-value">
    <mat-select placeholder="Ikona" [formControl]="iconField" (selectionChange)="changeIcon()">
      <mat-option *ngFor="let icon of icons;" [value]="icon.id" class="interactive-point-option icon-select-option">
        <img *ngIf="icon?.icon" [src]="icon.icon | fileUrl : 'FILES' | safe">
        {{icon?.title}}
      </mat-option>
    </mat-select>
    <div *ngIf="iconField.value && getIconImage(iconField.value)" matPrefix class="icon-image">
      <img [src]="getIconImage(iconField.value) | fileUrl : 'FILES' | safe">
    </div>
  </mat-form-field>
  <div *ngIf="selected?.custom" class="mat-select-arrow-wrapper" (click)="delete()">
    <mat-icon>delete</mat-icon>
  </div>
  <div class="mat-select-arrow-wrapper" (click)="file.click()">
    <mat-icon>add</mat-icon>
  </div>
  <input type="file" [accept]="'.jpg, .png'" multiple
         (change)="createIcon($event.target.files)"
         #file style="display: none">
  <mat-error *ngIf="iconField.hasError('file_used')">
    {{'project.interactive.icons.errorIconUsed' | translate}}
  </mat-error>
  <mat-error *ngIf="error">
    <p [innerHTML]="'system.form.error.required' | translate"></p>
  </mat-error>
</div>
