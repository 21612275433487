import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractDialogComponent} from '../../../../globals/dialogs/abstracts/abstract-dialog.component';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent extends AbstractDialogComponent {

  public emailField: FormControl;

  private _form: FormGroup;

  public constructor(private readonly _dialogRef: MatDialogRef<EmailDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    super(data);
    this._dialogRef.disableClose = this.getValue('disableClose');
  }

  public onSubmit(): void {
    if (this._form.invalid) return;
    this._dialogRef.close(this.emailField.value);

  }

  protected createFormControls(): void {
    this.emailField = new FormControl('', [Validators.required, Validators.email]);
  }

  protected createForm(): void {
    this._form = new FormGroup({
      email: this.emailField
    });
  }

  public closeDialog(): void {
    if (this._dialogRef.disableClose !== true) this._dialogRef.close();
  }
}
