<div class="print-tool">
  <div class="print-format">
    {{'project.print.format' | translate}}:
    <select class="screen-unit" [formControl]="formatField"
            (ngModelChange)="print.callPrintFormatChange(formatField.value)">
      <option *ngFor="let format of formats" [value]="format.toString()">
        {{ format }}
      </option>
    </select>
  </div>
  <mat-checkbox type="checkbox" [formControl]="titleField"
                (ngModelChange)="print.callPrintTitleChange(titleField.value)">{{'project.print.title' | translate}}
  </mat-checkbox>
  <mat-checkbox type="checkbox" *ngIf="showScale" [formControl]="scaleField"
                (ngModelChange)="print.callPrintScaleChange(scaleField.value)">{{'project.scale.title' | translate}}
  </mat-checkbox>
  <mat-checkbox type="checkbox" *ngIf="showLegend" [formControl]="legendField"
                (ngModelChange)="print.callPrintLegendChange(legendField.value)">{{'project.legend.title' | translate}}
  </mat-checkbox>
  <mat-checkbox type="checkbox" *ngIf="showTasks" [formControl]="tasksField"
                (ngModelChange)="print.callPrintTasksChange(tasksField.value)">{{'project.tasks.title' | translate}}
  </mat-checkbox>
  <mat-icon (click)="print.callPrintPreviewChange(false)">close</mat-icon>
  <button class="submit-button" (click)="printView()">{{'project.print.print' | translate}}</button>
</div>
