<mat-dialog-content class="interactive-list">
  <div [(dragulaModel)]='cInteractiveLayers' [dragula]='"interactive-bag"'>
    <div class="customInteractiveLayer" *ngFor="let cInteractiveLayer of cInteractiveLayers">
      <div class="title">
        <mat-checkbox [checked]="cInteractiveLayer.enabled"
                      (change)="changeEnabled($event, cInteractiveLayer)"></mat-checkbox>
        <div [innerHTML]="cInteractiveLayer.title"></div>
      </div>
      <div class="options">
        <mat-icon (click)="addPoints(cInteractiveLayer.id)"
                  [matTooltip]="'project.interactive.custom.buttons.addInteractivePoints' | translate"
                  matTooltipPosition="above">add_location
        </mat-icon>
        <mat-icon (click)="update.emit(cInteractiveLayer)"
                  [matTooltip]="'project.interactive.custom.buttons.edit' | translate"
                  matTooltipPosition="above">edit
        </mat-icon>
        <mat-icon (click)="remove.emit(cInteractiveLayer)"
                  [matTooltip]="'project.interactive.custom.buttons.remove' | translate"
                  matTooltipPosition="above">delete_outline
        </mat-icon>
      </div>
    </div>
  </div>
  <div *ngIf="!cInteractiveLayers?.length" class="empty-info">
    <p><span>{{'project.interactive.custom.info.empty' | translate}}</span></p>
    <p [innerHTML]="'project.interactive.custom.info.emptyAdd' | translate"></p>
  </div>
  <app-spinner class="primary full" *ngIf="showSpinner"></app-spinner>
</mat-dialog-content>
