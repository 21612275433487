import {RectSize} from '../../../../../../globals/elements/rect-size';
import {DrawElement} from '../../api/data/vector-file-response/abstracts/draw-element';
import {Text} from '../../api/data/vector-file-response/models/text';
import {VectorDrawPool} from '../drawers/interfaces/vector-draw-pool';
import {CanDraw} from '../interfaces/can-draw';
import {AbstractPart} from './abstracts/abstract-part';

export class CacheDrawPart extends AbstractPart<DrawElement> {

  public constructor(data: DrawElement, private _drawPool: VectorDrawPool, canDraw: CanDraw) {
    super(data, _drawPool.canvas, canDraw);
  }

  public draw(scaleRect: RectSize): void {
    if (this.canDraw(scaleRect)) this._drawPool.addToDraw(this.data, scaleRect);
  }

  public clear(): void {
  }

  public calculateDimensions(): void {
    const data = this.data as Text;
    const textWidth = this.getTextWidth(this._drawPool.ctx);
    const textHeight = data.size * 1.33;
    if (textWidth === undefined) return;
    const points = [[0, -data.size], [textWidth, -data.size], [textWidth, textHeight - data.size], [0, textHeight - data.size]];
    const matrix = data.transform;
    let minX = 0;
    let maxX = 0;
    let minY = 0;
    let maxY = 0;
    points.forEach((point, index) => {
      const pointX = matrix[0] * point[0] + matrix[2] * point[1] + matrix[4];
      const pointY = matrix[1] * point[0] + matrix[3] * point[1] + matrix[5];
      if (index === 0) {
        minX = pointX;
        maxX = pointX;
        minY = pointY;
        maxY = pointY;
      } else {
        minX = Math.min(minX, pointX);
        maxX = Math.max(maxX, pointX);
        minY = Math.min(minY, pointY);
        maxY = Math.max(maxY, pointY);
      }
    });

    data.x = minX;
    data.y = minY;
    data.width = maxX - minX;
    data.height = maxY - minY;
    if (data.lineWidth === undefined) return;
    const twiceLineWeight = data.lineWidth * 2;
    data.x -= data.lineWidth;
    data.y -= data.lineWidth;
    data.width += twiceLineWeight;
    data.height += twiceLineWeight;
  }

  private getTextWidth(ctx: CanvasRenderingContext2D): number {
    if (ctx === undefined) return undefined;
    const data = this.data as Text;
    ctx.save();
    ctx.font = data.size + 'px ' + data.font;

    const letters = data.text.split('');
    const spacing = data.letterSpacing === undefined ? 0 : data.letterSpacing;
    let posX = 0;
    for (const letter of letters) {
      posX += spacing + ctx.measureText(letter).width;
    }
    ctx.restore();
    return posX - spacing;
  }

  public destroy(): void {
    this.data = undefined;
  }
}
