import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {PanelHide} from '../../communication/interfaces/panel-hide';
import {PanelsLoaded} from '../../communication/interfaces/panels-loaded';
import {PanelsCommunicationService} from '../../communication/panels-communication.service';

@Component({
  selector: 'app-panel-sidebar',
  templateUrl: './panel-sidebar.component.html',
  styleUrls: ['./panel-sidebar.component.scss']
})
export class PanelSidebarComponent implements OnInit, OnDestroy, PanelsLoaded, PanelHide {

  @Output()
  public panelActiveChange = new EventEmitter<boolean>();

  public panels: string[];

  public active = '';

  public constructor(private readonly _communication: PanelsCommunicationService) {
  }

  public ngOnInit(): void {
    this._communication.addPanelLoadedListener(this);
    this._communication.addPanelHideListener(this);
  }

  public ngOnDestroy(): void {
    this._communication.removePanelLoadedListener(this);
    this._communication.removePanelHideListener(this);
  }

  public onPanelsLoaded(panels: string[]): void {
    this.panels = panels;
  }

  public changeStatus(panel: string): void {
    if (this.active === '' || this.active === panel) this.panelActiveChange.emit(this.active === '');
    this.active = this.isActive(panel) ? '' : panel;
    this._communication.changePanelStatus(panel);
  }

  public isActive(panel: string): boolean {
    return this.active === panel;
  }

  public onPanelHide(): void {
    this.active = '';
    this.panelActiveChange.emit(false);
  }

}
