import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AccountService} from '../../../../api/service/account.service';

@Component({
  selector: 'app-institution-code',
  templateUrl: './institution-code.component.html',
  styleUrls: ['./institution-code.component.scss']
})
export class InstitutionCodeComponent implements OnInit, OnDestroy {

  private static readonly INTERVAL = 300000;

  @Input()
  public institutionId: string;

  public code: string;
  public showSpinner = true;

  private _interval: any = -1;

  public constructor(private readonly _service: AccountService) {
  }

  public ngOnInit(): void {
    this.getCode();
  }

  private getCode(): void {
    this._service.getInstitutionCode(this.institutionId).subscribe((code: string) => this.code = code,
      () => {
        this.showSpinner = false;
        this.setInterval();
      }, () => {
        this.showSpinner = false;
        this.setInterval();
      });
  }

  private setInterval(): void {
    if (this._interval) this._interval = setTimeout(() => this.getCode(), InstitutionCodeComponent.INTERVAL);
  }

  public ngOnDestroy(): void {
    const interval = this._interval;
    this._interval = 0;
    if (interval > 0) clearTimeout(interval);
  }
}
