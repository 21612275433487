import {JsonProperty} from '@dhkatz/json-ts';
import {ArrayNumberConverter} from '../../../../../../../../globals/api/json/array-number-converter';
import {EnumConverter} from '../../../../../../../../globals/api/json/enum-converter';
import {VectorCommandType} from '../enums/vector-command-type.enum';

export class DrawPath {

  @JsonProperty({name: 'c', converter: EnumConverter.converter(VectorCommandType)})
  private _command: VectorCommandType;

  @JsonProperty({name: 'd', converter: ArrayNumberConverter.converter, type: Object})
  private _data: number[];

  public constructor() {
    this._command = undefined;
    this._data = undefined;
  }

  public get command(): VectorCommandType {
    return this._command;
  }

  public get data(): number[] {
    return this._data;
  }
}
