import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Subscriber} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {FontResponse} from '../data/font-response';
import {FontsEndpoints} from '../fonts-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class FontsOfflineService {

  public constructor() {
  }

  public getFonts(projectId: string): Observable<FontResponse[]> {
    const path = FontsEndpoints.FONT.replace(FontsEndpoints.PROJECT_ID, projectId);
    return new Observable<FontResponse[]>((subscriber: Subscriber<FontResponse[]>) =>
      OfflineUtil.loadText(projectId, path, (file: string) =>
        subscriber.next(JSON.parse(file).map((font) => deserializeFix(FontResponse, font)))
      ));
  }
}
