import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'target'
})
export class TargetPipe implements PipeTransform {

  public transform(value: string, target: string = '_blank'): string {
    if (!value || !value.includes('<a ')) return value;
    const regExp = /<a(.*?)>/g;
    let link;
    const replace = new Map<string, string>();
    do {
      link = regExp.exec(value);
      if (link) {
        const replaceLink = this.getLinkReplace(link[0], target);
        if (link[0] !== replaceLink) replace.set(link[0], replaceLink);
      }
    } while (link);
    if (!replace.size) return value;
    replace.forEach((value1, key) => value = value.replace(key, value1));
    return value;
  }

  private getLinkReplace(link: string, target: string): string {
    const regExp = /target="(.*?)"/g;
    const actualTarget = regExp.exec(link);
    if (actualTarget && actualTarget[1] === target) return link;
    return actualTarget ? link.replace(actualTarget[0], 'target="' + target + '"') :
      link.replace('<a', '<a target="' + target + '"');
  }
}
