import {JsonProperty} from '@dhkatz/json-ts';

export class RetinaResponse {

  @JsonProperty('image')
  private _image: string;

  @JsonProperty('retinaImage')
  private _retinaImage: string;

  public constructor() {
    this._image = undefined;
    this._retinaImage = undefined;
  }

  public get image(): string {
    return this._image;
  }

  public get retinaImage(): string {
    return this._retinaImage;
  }
}
