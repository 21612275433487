import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Json, JsonArray} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {LegendResponse} from '../data/legend-response';
import {LegendEndpoints} from '../legend-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class LegendService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getLegend(projectId: string): Observable<LegendResponse[]> {
    return this._http.get<JsonArray>(LegendEndpoints.LEGEND.replace(LegendEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(LegendResponse, value))));
  }

  public getLegendPreview(versionId: string): Observable<LegendResponse[]> {
    return this._http.get<JsonArray>(LegendEndpoints.LEGEND_PREVIEW.replace(LegendEndpoints.VERSION_ID, versionId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(LegendResponse, value))));
  }
}
