import {OfflineStatus} from '../../../communication/enums/offline-status.enum';

export class OfflineProjectEntry {

  private _project: string;
  private _status: OfflineStatus;
  private _versionId: string;
  private _projectTitle: string;
  private _categoryId: string;
  private _email: string;
  private _lastUpdateDate: Date;
  private _validUntilDate: Date;
  private _token: string;

  public get project(): string {
    return this._project;
  }

  public set project(value: string) {
    this._project = value;
  }

  public get status(): OfflineStatus {
    return this._status;
  }

  public set status(value: OfflineStatus) {
    this._status = value;
  }

  public get versionId(): string {
    return this._versionId;
  }

  public set versionId(value: string) {
    this._versionId = value;
  }

  public get projectTitle(): string {
    return this._projectTitle;
  }

  public set projectTitle(value: string) {
    this._projectTitle = value;
  }

  public get categoryId(): string {
    return this._categoryId;
  }

  public set categoryId(value: string) {
    this._categoryId = value;
  }

  public get email(): string {
    return this._email;
  }

  public set email(value: string) {
    this._email = value;
  }

  public get lastUpdateDate(): Date {
    return this._lastUpdateDate;
  }

  public set lastUpdateDate(value: Date) {
    this._lastUpdateDate = value;
  }

  public get validUntilDate(): Date {
    return this._validUntilDate;
  }

  public set validUntilDate(value: Date) {
    this._validUntilDate = value;
  }

  public get token(): string {
    return this._token;
  }

  public set token(value: string) {
    this._token = value;
  }
}
