import {JsonProperty} from '@dhkatz/json-ts';
import {TitleItem} from '../../../globals/api/data/title-item';
import {ArrayStringConverter} from '../../../globals/api/json/array-string-converter';
import {ProjectModule} from './enums/project-module.enum';
import {EnumConverter} from '../../../globals/api/json/enum-converter';

export class ProjectResponse extends TitleItem {

  @JsonProperty('teaser')
  private _teaser: string;

  @JsonProperty('description')
  private _description: string;

  @JsonProperty('image')
  private _image: string;

  @JsonProperty('retinaImage')
  private _retinaImage: string;

  @JsonProperty('defaultCategory')
  private _defaultCategory: string;

  @JsonProperty({name: 'module', converter: EnumConverter.converter(ProjectModule)})
  private _module: ProjectModule;

  @JsonProperty({name: 'tagsIds', converter: ArrayStringConverter.converter, type: Object})
  private _tagsIds: string[];

  public constructor() {
    super();
    this._teaser = undefined;
    this._description = undefined;
    this._image = undefined;
    this._retinaImage = undefined;
    this._defaultCategory = undefined;
    this._module = undefined;
    this._tagsIds = undefined;
  }

  public get teaser(): string {
    return this._teaser;
  }

  public get description(): string {
    return this._description;
  }

  public get image(): string {
    return this._image;
  }

  public get retinaImage(): string {
    return this._retinaImage;
  }

  public get defaultCategory(): string {
    return this._defaultCategory;
  }

  public get module(): ProjectModule {
    return this._module;
  }

  public get tagsIds(): string[] {
    return this._tagsIds;
  }
}
