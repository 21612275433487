import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Subscriber} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {LayerResponse} from '../data/layer-response';
import {LayerEndpoints} from '../layer-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class LayerOfflineService {

  public constructor() {
  }

  public getLayer(projectId: string): Observable<LayerResponse> {
    const path = LayerEndpoints.LAYER.replace(LayerEndpoints.PROJECT_ID, projectId);
    return new Observable<LayerResponse>((subscriber: Subscriber<LayerResponse>) =>
      OfflineUtil.loadText(projectId, path, (file: string) => {
        subscriber.next(deserializeFix(LayerResponse, JSON.parse(file)));
      }));
  }
}
