import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Json, JsonArray} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {TitleItem} from '../../../globals/api/data/title-item';
import {CategoryEndpoints} from '../category-endpoints';
import {CategoryResponse} from '../data/category-response';
import {MainCategoryResponse} from '../data/main-category-response';
import {deserializeFix} from '../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getCategoryPath(categoryId: string): Observable<TitleItem[]> {
    return this._http.get<JsonArray>(CategoryEndpoints.CATEGORY_PATH.replace(CategoryEndpoints.CATEGORY_ID, categoryId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(TitleItem, value))));
  }

  public getMainCategories(): Observable<MainCategoryResponse[]> {
    return this._http.get<JsonArray>(CategoryEndpoints.CATEGORIES)
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(MainCategoryResponse, value))));
  }

  public getCategories(categoryId: string): Observable<CategoryResponse[]> {
    return this._http.get<JsonArray>(CategoryEndpoints.CATEGORY.replace(CategoryEndpoints.CATEGORY_ID, categoryId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(CategoryResponse, value))));
  }
}
