import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {DialogsService} from '../../../../../globals/dialogs/dialogs.service';
import {TeacherResponse} from '../../../../api/data/teacher-response';
import {AccountService} from '../../../../api/service/account.service';
import {AbstractManagementPartComponent} from '../abstracts/abstract-management-part-component';
import {InviteTeacherDialogComponent} from './dialogs/invite-teacher-dialog/invite-teacher-dialog.component';

@Component({
  selector: 'app-institution-teachers',
  templateUrl: './institution-teachers.component.html',
  styleUrls: ['./institution-teachers.component.scss']
})
export class InstitutionTeachersComponent extends AbstractManagementPartComponent<TeacherResponse> {

  public readonly displayedColumns: string[] = ['title', 'actions'];

  public constructor(service: AccountService, private readonly _dialog: MatDialog, private readonly _dialogs: DialogsService,
                     private readonly _snackBar: MatSnackBar, private readonly _translate: TranslateService) {
    super(service);
  }

  public getNextPage(page: number): void {
    this.showSpinner = true;
    this.service.getTeachersForInstitution(page, this.searchField.value).subscribe((response: TeacherResponse[]) =>
        this.list = this.listController.getUpdatedList(page, response, this.list, TeacherResponse),
      () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public removeTeacher(index: number): void {
    const teacher = this.list[index];
    this._dialogs.showAlert(this._translate.instant('assignmentManagement.teacher.assignedTeachers.removeAlert.title'),
      this._translate.instant('assignmentManagement.teacher.assignedTeachers.removeAlert.description',
        {name: teacher.title})).subscribe((decision: boolean) => {
      if (!decision) return;
      this.showSpinner = true;
      this.service.removeTeacherFromInstitution(teacher.id).subscribe(() => {
        this.list.splice(index, 1);
        this.list = [...this.list];
        ViewerConfig.openSnackBar(this._snackBar, this._translate.instant('assignmentManagement.teacher.assignedTeachers.removeToast',
          {name: teacher.title}));
      }, () => this.showSpinner = false, () => this.showSpinner = false);
    });
  }

  public openInviteDialog(): void {
    this._dialog.open(InviteTeacherDialogComponent, ViewerConfig.getDialogOptions()).afterClosed().subscribe((result: boolean) => {
      if (!result) return;
      ViewerConfig.openSnackBar(this._snackBar, this._translate.instant('assignmentManagement.teacher.assignedTeachers.inviteToast'));
    });
  }
}
