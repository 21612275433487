<header mat-dialog-title class="add-point-header">
  {{'project.interactive.points.title.imagePointFile' | translate}}
  <span class="close" (click)="closeDialog()"><mat-icon>close</mat-icon></span>
</header>

<mat-dialog-content class="add-point-container" *ngIf="!removeImageConfirm; else confirmRemove">
  <div class="img-point-container" *ngIf="!showSpinner">
    <div class="segment-button add-graphic-btn" (click)="file.click()">
      <div class="add-g-cont">
        <mat-icon class="add-image-icon">add</mat-icon>
        <p class="add-image-text">{{'project.interactive.points.info.addImage' | translate}}</p>
      </div>
    </div>
    <div *ngFor="let image of imagesList" (click)="select($event, image)" [ngClass]="{'selected': isSelected(image.id)}"
         class="img-point-image">
      <div *ngIf="isSelected(image.id)" class="checked">
        <mat-icon>check</mat-icon>
      </div>
      <img [src]="image.preview | fileUrl : 'FILES'">
      <button (click)="confirmDeleteFile(image)" class="img-point-remove">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <mat-error class="used-file"
               *ngIf="fileUsedError">{{'project.interactive.points.error.fileIsUsed' | translate}}</mat-error>
  </div>
  <app-spinner class="primary" *ngIf="showSpinner"></app-spinner>
  <input type="file" [accept]="'.jpg, .png'"
         (change)="uploadFile($event.target.files)"
         #file style="display: none">
  <footer class="buttons interactive-points-footer">
    <button mat-button class="cancel-button" (click)="closeDialog()">
      <ng-container>
        {{'project.interactive.points.button.cancel' | translate}}
      </ng-container>
    </button>
    <button mat-button class="submit-button" (click)="onSubmit()">
      <ng-container>
        {{'project.interactive.points.button.save' | translate}}
      </ng-container>
    </button>
  </footer>
</mat-dialog-content>

<ng-template #confirmRemove>
  <mat-dialog-content class="remove-point-container">
    <div class="confirm-container">
      <div class="imgForRemove">
        <img [src]="imageForDelete.preview | fileUrl : 'FILES'">
      </div>
      <h3>{{'project.interactive.points.info.confirmRemoveTitle' | translate}}</h3>
      <p>{{'project.interactive.points.info.confirmRemoveInfo' | translate}}</p>
    </div>
    <footer class="buttons interactive-points-footer">
      <button mat-button class="submit-button" (click)="cancel()">
        {{'project.interactive.points.button.cancel' | translate}}
      </button>
      <button (click)="deleteFile(imageForDelete)" mat-button class="cancel-button">
        {{'project.interactive.points.button.delete' | translate}}
      </button>
    </footer>
  </mat-dialog-content>
</ng-template>
