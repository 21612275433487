import {Injectable} from '@angular/core';
import {MeasurementSystem} from './enums/measurement-system.enum';
import {ProjectorDimensions} from './enums/projector-dimensions.enum';
import {ScreenType} from './enums/screen-type.enum';
import {ScaleSettingsChange} from './interfaces/scale-settings-change';

@Injectable({
  providedIn: 'root'
})
export class ScaleCommunicationService {

  private static readonly SCREEN_TYPE = 'screenType';
  private static readonly SCREEN_DIAGONAL = 'screenDiagonal';
  private static readonly PROJECTOR_SIZE = 'projectorSize';
  private static readonly PROJECTOR_DIMENSION = 'projectorDimension';
  private static readonly PROJECTOR_MEASURE = 'projectorMeasure';

  private _scaleConfigChangeListeners: ScaleSettingsChange[] = [];

  private _screenType: ScreenType;
  private _screenDiagonal: number;
  private _projectorSize: number;
  private _projectorDimension: ProjectorDimensions;
  private _projectorMeasure: MeasurementSystem;

  public constructor() {
    this._screenType = (<any>ScreenType)[localStorage.getItem(ScaleCommunicationService.SCREEN_TYPE)] || ScreenType.screen;
    this._screenDiagonal = +localStorage.getItem(ScaleCommunicationService.SCREEN_DIAGONAL) || 15;
    this._projectorSize = +localStorage.getItem(ScaleCommunicationService.PROJECTOR_SIZE) || 150;
    this._projectorDimension = (<any>ProjectorDimensions)[localStorage.getItem(ScaleCommunicationService.PROJECTOR_DIMENSION)] ||
      ProjectorDimensions.width;
    this._projectorMeasure = (<any>MeasurementSystem)[localStorage.getItem(ScaleCommunicationService.PROJECTOR_MEASURE)] ||
      MeasurementSystem.cm;
  }

  public get screenType(): ScreenType {
    return this._screenType;
  }

  public get screenDiagonal(): number {
    return this._screenDiagonal;
  }

  public get projectorSize(): number {
    return this._projectorSize;
  }

  public get projectorDimension(): ProjectorDimensions {
    return this._projectorDimension;
  }

  public get projectorMeasure(): MeasurementSystem {
    return this._projectorMeasure;
  }

  public addScaleConfigChangeListener(listener: ScaleSettingsChange): void {
    this._scaleConfigChangeListeners.push(listener);
  }

  public removeScaleConfigChangeListener(listener: ScaleSettingsChange): void {
    const index: number = this._scaleConfigChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._scaleConfigChangeListeners.splice(index, 1);
  }

  public setScaleSettings(screenType: ScreenType, screenDiagonal: number, projectorSize: number, projectorDimension: ProjectorDimensions,
                          projectorMeasure: MeasurementSystem): void {
    this._screenType = screenType;
    this._screenDiagonal = screenDiagonal;
    this._projectorSize = projectorSize;
    this._projectorDimension = projectorDimension;
    this._projectorMeasure = projectorMeasure;
    localStorage.setItem(ScaleCommunicationService.SCREEN_TYPE, screenType.toString());
    localStorage.setItem(ScaleCommunicationService.SCREEN_DIAGONAL, screenDiagonal.toString());
    localStorage.setItem(ScaleCommunicationService.PROJECTOR_SIZE, projectorSize.toString());
    localStorage.setItem(ScaleCommunicationService.PROJECTOR_DIMENSION, projectorDimension.toString());
    localStorage.setItem(ScaleCommunicationService.PROJECTOR_MEASURE, projectorMeasure.toString());
    this.screenTypeChange();
  }

  private screenTypeChange(): void {
    if (this._scaleConfigChangeListeners.length === 0) return;
    this._scaleConfigChangeListeners.forEach((listener: ScaleSettingsChange) => {
      try {
        listener.onScreenTypeChange();
      } catch (exception) {
        console.log(exception);
      }
    });
  }
}
