import * as CryptoJS from 'crypto-js';
import {Size} from '../../../../../../globals/api/data/size';
import {OfflineDBEntry} from '../../../../../../offline/services/offline-service/data/OfflineDBEntry';
import {deserializeFix} from '../../../../../../utils/deserialize-util';
import {IdbUtil} from '../../../../../../utils/idb-util';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {VectorFileResponse} from '../../api/data/vector-file-response';
import {DrawElement} from '../../api/data/vector-file-response/abstracts/draw-element';
import {VectorType} from '../../api/data/vector-file-response/enums/vector-type.enum';
import {LayerEndpoints} from '../../api/layer-endpoints';
import {VectorDrawPool} from '../drawers/interfaces/vector-draw-pool';
import {CacheDrawPart} from '../parts/cache-draw-part';
import {AbstractLevel} from './abstracts/abstract-level';

export class CacheLevel extends AbstractLevel<CacheDrawPart> {

  private readonly _proportion: number;

  public constructor(sizes: Size[], nextSizes: Size[], data: string, drawPool: VectorDrawPool,
                     private readonly _offline: boolean) {
    super(sizes, nextSizes);
    this._proportion = sizes[0].height / sizes[0].width;
    this.loadData(data, drawPool);
  }

  private loadData(url: string, drawPool: VectorDrawPool): void {
    this.getVectorData(url, drawPool).then((layerData: VectorFileResponse) => {
      if (!layerData) {
        drawPool.elementLoadComplete();
        return;
      }
      this.defaultSize = layerData.size;
      if (layerData.data === undefined) {
        drawPool.elementLoadComplete();
        return;
      }
      layerData.data.forEach((element: DrawElement) => {
        const part = new CacheDrawPart(element, drawPool, this);
        if (element.type === VectorType.text) part.calculateDimensions();
        this.parts.push(part);
      });
      drawPool.elementLoadComplete();
    });
  }

  private getVectorData(url: string, drawPool: VectorDrawPool): Promise<VectorFileResponse | void> {
    const method = this._offline ? this.getFromOffline(LayerEndpoints.FILES + url) : this.getFromNetwork(LayerEndpoints.FILES + url);
    return method.then((response: Response) => {
      if (!this._offline && (response.status < 200 || response.status > 206)) {
        drawPool.elementLoadComplete();
        return;
      }
      return response.arrayBuffer().then((bytes: ArrayBuffer) => this.decrypt(bytes))
        .catch((err) => console.error(err));
    }).catch(() => drawPool.elementLoadComplete());
  }

  private getFromNetwork(url: string): Promise<Response> {
    return fetch(new Request(url, {
      credentials: 'omit',
      mode: 'cors'
    }));
  }

  private getFromOffline(url: string): Promise<Response> {
    const versionID = url.split('/')[5];
    return IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION)
      .getKeyForCondition((value: any) => deserializeFix(OfflineDBEntry, value).versionId === versionID)
      .then((projectId: string) => {
        return OfflineUtil.loadFileSync(projectId, url).then((file: Blob) => {
          return new Response(file);
        });
      });
  }

  private decrypt(bytes: ArrayBuffer): VectorFileResponse {
    const key = CryptoJS.enc.Utf8.parse('k57^muMuHO1Q1$IF');
    const wordArray = CryptoJS.lib.WordArray.create(bytes);
    const decrypted = CryptoJS.RC4.decrypt({
      ciphertext: wordArray
    }, key).toString(CryptoJS.enc.Utf8);
    return deserializeFix(VectorFileResponse, JSON.parse(decrypted));
  }

  protected stopLoading(): void {
  }
}
