<div class="content-box messages-container">
  <div class="content-box-container">
    <div class="boxes">
      <div class="left-boxes">
          <div class="lb-top-section">
            <input placeholder="{{'messages.info.search' | translate}}" [formControl]="searchField"
                   (ngModelChange)="searchController.search()">
            <div class="all-read" (click)="markAllAsRead()" [matTooltip]="'messages.info.markAllReadTooltip' | translate"
                 matTooltipPosition="above">
              <img src="/assets/images/messages_read.png"
                   srcset="/assets/images/messages_read.png 1x, /assets/images/messages_read_2x.png 2x">
            </div>
          </div>
          <app-spinner *ngIf="showSpinner"></app-spinner>
        <div class="messages-scroll-box" #scrollElement>
            <ng-container *ngFor="let element of messages; let i = index">
              <div
                [ngClass]="{'messages-list-position': true,  active: element?.id === selected?.id, read: element?.read}"
                (click)="onMessageClick(element?.id)">
                <div class="list-title" [innerHTML]="element?.title"></div>
                <span class="date">{{element?.published | date:'dd.MM.yy'}}</span>
              </div>
            </ng-container>
          </div>
        <p class="no-messages" *ngIf="!showSpinner && !messages.length">
            {{'messages.error.noMessages' | translate}}
          </p>
      </div>
      <div class="right-boxes message-box">
        <app-message [selected]="selected" (readChanged)="readChanged()"></app-message>
      </div>
    </div>
  </div>
</div>
