import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ViewerConfig} from '../../../configs/viewer-config';
import {TitleItem} from '../../../globals/api/data/title-item';
import {SearchProjectsComponent} from '../../../project/screens/search-projects/search-projects.component';
import {TagService} from '../../api/services/tag.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnChanges {

  @Input()
  public tagsIds: string[];

  public tags: TitleItem[];
  public showSpinner: boolean;

  public constructor(private _service: TagService, private _dialog: MatDialog) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.tags = undefined;
    if (!this.tagsIds || !this.tagsIds.length) return;
    this.showSpinner = true;
    this._service.getTags(this.tagsIds).subscribe((response: TitleItem[]) =>
      this.tags = response, () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public searchTag(tag: TitleItem): void {
    this._dialog.open(SearchProjectsComponent, ViewerConfig.getSearchDialogOptions(tag));
  }
}
