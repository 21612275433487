import {JsonProperty} from '@dhkatz/json-ts';
import {TitleItem} from '../../../globals/api/data/title-item';

export class TeacherResponse extends TitleItem {

  @JsonProperty('registred')
  private _registred: boolean;

  public constructor() {
    super();
    this._registred = undefined;
  }

  public get registred(): boolean {
    return this._registred;
  }
}
