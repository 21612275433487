<ng-container *ngIf="!showConfirm && !showLoginConfirm">
  <div class="registration-container">
    <mat-horizontal-stepper linear #stepper>
      <ng-template matStepperIcon="account-type">
        <mat-icon>how_to_reg</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="details">
        <mat-icon>https</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="more-details">
        <mat-icon>perm_contact_calendar</mat-icon>
      </ng-template>
      <mat-step [stepControl]="accountTypeGroup" state="account-type" *ngIf="!role">
        <h2>{{'register.sectionTitle.register' | translate}}</h2>
        <h3 class="section-title">
          {{'register.sectionTitle.accountType' | translate}}
        </h3>
        <form [formGroup]="accountTypeGroup" class="step-type w-100">
          <mat-form-field class="mat-field-validation-container center">
            <input type="text" [formControl]="accountTypeField" matInput>
            <mat-radio-group (change)="accountTypeChange($event.value)">
              <mat-radio-button [value]="accountTypes.MULTIPLE">
                <img class="icon" src="/assets/images/multiple.png"
                     srcset="/assets/images/multiple.png 1x, /assets/images/multiple_2x.png 2x">
                <span>{{'register.accountType.multiple.title' | translate}}</span>
                <span class="subtitle">{{'register.accountType.multiple.subtitle' | translate}}</span>
              </mat-radio-button>
              <mat-radio-button [value]="accountTypes.SINGLE">
                <img class="icon" src="/assets/images/single.png"
                     srcset="/assets/images/single.png 1x, /assets/images/single_2x.png 2x">
                <span>{{'register.accountType.single.title' | translate}}</span>
                <span class="subtitle">{{'register.accountType.single.subtitle' | translate}}</span>
              </mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="accountTypeGroup.hasError('required')"
                       [innerHTML]="'register.error.accountTypeRequired' | translate">
            </mat-error>
          </mat-form-field>
          <button class="submit-button next-but" matStepperNext>{{'register.label.next' | translate}}</button>
        </form>
      </mat-step>
      <mat-step [stepControl]="accountDetailsGroup" state="details">
        <h3 class="section-title">
          {{'register.sectionTitle.accountData' | translate}}
        </h3>
        <form [formGroup]="accountDetailsGroup" class="step-details w-100p">
          <mat-form-field *ngIf="accountTypeField.value === accountTypes.SINGLE">
            <mat-label>{{'register.accountType.single.type.placeholder' | translate}} *</mat-label>
            <mat-select [formControl]="roleField" [disabled]="!!role" [value]="role"
                        (selectionChange)="subscriptionField.updateValueAndValidity()">
              <mat-option [value]="roles.TEACHER">
                {{'register.accountType.single.type.teacher' | translate}}
              </mat-option>
              <mat-option [value]="roles.USER">
                {{'register.accountType.single.type.student' | translate}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="roleField.hasError('required')"
                       [innerHTML]="'register.error.roleRequired' | translate">
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input type="email" [placeholder]="('system.form.placeholder.email' | translate) + ' *'" matInput
                   [formControl]="emailField">
            <mat-error *ngIf="emailField.hasError('required')"
                       [innerHTML]="'profile.email.error.required' | translate">
            </mat-error>
            <mat-error *ngIf="emailField.hasError('email') && !emailField.hasError('required')"
                       [innerHTML]="'profile.email.error.incorrect' | translate">
            </mat-error>
            <mat-error *ngIf="emailField.hasError('exists') && !emailField.hasError('required')"
                       [innerHTML]="'register.error.userExists' | translate">
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input [type]="hide ? 'password' : 'text'"
                   [placeholder]="('system.form.placeholder.password' | translate) + ' *'"
                   matInput [formControl]="passwordField">
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
            <mat-icon matSuffix class="generate" (click)="generatePassword()">vpn_key</mat-icon>
            <mat-error *ngIf="passwordField.hasError('required')"
                       [innerHTML]="'system.form.error.required' | translate">
            </mat-error>
            <mat-error
                    *ngIf="passwordField.hasError('weakPassword') && !passwordField.hasError('required')"
                    [innerHTML]="'profile.password.error.viewerWeakPassword' | translate">
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input [type]="hide ? 'password' : 'text'"
                   [placeholder]="('register.placeholder.repeatPassword' | translate) + ' *'"
                   matInput [formControl]="confirmPasswordField">
            <mat-error *ngIf="confirmPasswordField.hasError('mustMatch')"
                       [innerHTML]="'system.form.error.required' | translate">
            </mat-error>
          </mat-form-field>
          <mat-form-field class="subscription-code" *ngIf="!email">
            <input [formControl]="subscriptionField" matInput
                   [placeholder]="'register.placeholder.subscription' | translate"
                   type="text">
            <mat-hint>{{'register.info.subscriptionDescribe' | translate}}</mat-hint>
            <mat-error *ngIf="subscriptionField.hasError('subscription_not_found')">
              {{'register.error.invalidSubscription' | translate}}
            </mat-error>
            <mat-error *ngIf="subscriptionField.hasError('subscription_used')">
              {{'register.error.usedSubscription' | translate}}
            </mat-error>
            <mat-error *ngIf="subscriptionField.hasError('subscription_inactive')">
              {{'register.error.inactiveSubscription' | translate}}
            </mat-error>
            <mat-error *ngIf="subscriptionField.hasError('subscription_invalid_role')">
              {{'register.error.subscriptionRoleInvalid' | translate}}
            </mat-error>
            <mat-error *ngIf="subscriptionField.hasError('subscription_role_required')">
              {{'register.error.subscriptionRoleRequired' | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="school-code" *ngIf="roleField.value === roles.USER">
            <input matInput type="text" [placeholder]="'register.placeholder.schoolCode' | translate"
                   [formControl]="institutionCodeField">
            <mat-hint>{{'register.info.codeDescribe' | translate}}</mat-hint>
          </mat-form-field>
          <mat-form-field class="mat-field-validation-container register-consents">
            <input type="text" matInput [formControl]="approvalsField">
            <mat-checkbox (change)="setApproval('privacy', $event.checked)">*
              {{'register.info.privacyPolicy' | translate | translateSplit:0}}
              <span class="link"
                    (click)="openPrivatePolicy($event)">{{'register.info.privacyPolicy' | translate | translateSplit:1}}</span>{{'register.info.privacyPolicy' | translate | translateSplit:2}}
            </mat-checkbox>
            <mat-checkbox (change)="setApproval('terms', $event.checked)">*
              {{'register.info.termsAndConditions' | translate | translateSplit:0}}
              <span class="link"
                    (click)="openTermsAndConditions($event)">{{'register.info.termsAndConditions' | translate | translateSplit:1}}</span>{{'register.info.termsAndConditions' | translate | translateSplit:2}}
            </mat-checkbox>
            <mat-checkbox (change)="setApproval('eula', $event.checked)">*
              {{'register.info.eula' | translate | translateSplit:0}}
              <span class="link"
                    (click)="openEULA($event)">{{'register.info.eula' | translate | translateSplit:1}}</span>{{'register.info.eula' | translate | translateSplit:2}}
            </mat-checkbox>
            <mat-error *ngIf="approvalsField.hasError('required')"
                       [innerHTML]="'register.error.termsRequired' | translate">
            </mat-error>
          </mat-form-field>

          <button *ngIf="stepper.selectedIndex > 0" class="neutral-button prev-but"
                  matStepperPrevious>{{'register.label.previous' | translate}}</button>
          <button class="submit-button next-but" matStepperNext>{{'register.label.next' | translate}}</button>
        </form>
      </mat-step>
      <mat-step state="more-details" [stepControl]="moreDetailsGroup">
        <form [formGroup]="moreDetailsGroup" class="step-more-details">
          <div class="personal-data-con columns">
            <h3 class="section-title">
              {{'register.sectionTitle.personalData' | translate}}
            </h3>
            <mat-form-field>
              <input type="text" [placeholder]="('profile.address.placeholder.name' | translate) + ' *'" matInput
                     [formControl]="firstNameField">
              <mat-error *ngIf="firstNameField.hasError('required')"
                         [innerHTML]="'system.form.error.required' | translate">
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input type="text" [placeholder]="('profile.address.placeholder.surname' | translate) + ' *'" matInput
                     [formControl]="lastNameField">
              <mat-error *ngIf="lastNameField.hasError('required')"
                         [innerHTML]="'system.form.error.required' | translate">
              </mat-error>
            </mat-form-field>
            <ng-container *ngIf="accountTypeField.value === accountTypes.SINGLE">
              <mat-form-field class="street-field">
                <input matInput type="text" [placeholder]="'profile.address.placeholder.street' | translate"
                       [formControl]="streetNameField">
                <mat-error *ngIf="streetNameField.hasError('required')"
                           [innerHTML]="'system.form.error.required' | translate">
                </mat-error>
              </mat-form-field>
              <mat-form-field class="no-field">
                <input matInput type="text" [placeholder]="'register.placeholder.streetNumber' | translate"
                       [formControl]="streetNumberField">
                <mat-error *ngIf="streetNumberField.hasError('required')"
                           [innerHTML]="'system.form.error.requiredShort' | translate">
                </mat-error>
              </mat-form-field>
              <span class="separator">/</span>
              <mat-form-field class="no-field">
                <input matInput type="text" [placeholder]="'register.placeholder.apartmentNumber' | translate"
                       [formControl]="apartmentNumberField">
              </mat-form-field>
              <mat-form-field class="zip-code-field">
                <input matInput type="text" [placeholder]="'profile.address.placeholder.zipCode' | translate"
                       [formControl]="postCodeField">
                <mat-error *ngIf="postCodeField.hasError('required')"
                           [innerHTML]="'system.form.error.requiredShort' | translate">
                </mat-error>
              </mat-form-field>
              <mat-form-field class="city-field">
                <input matInput type="text" [placeholder]="'profile.address.placeholder.city' | translate"
                       [formControl]="cityField">
                <mat-error *ngIf="cityField.hasError('required')"
                           [innerHTML]="'system.form.error.required' | translate">
                </mat-error>
              </mat-form-field>
              <mat-form-field class="state-field">
                <input matInput type="text" [placeholder]="'profile.address.placeholder.state' | translate"
                       [formControl]="stateField">
                <mat-error *ngIf="stateField.hasError('required')"
                           [innerHTML]="'system.form.error.required' | translate">
                </mat-error>
              </mat-form-field>
            </ng-container>
            <mat-form-field>
              <input matInput type="number"
                     [placeholder]="('profile.address.placeholder.phone' | translate) + (accountTypeField.value === accountTypes.MULTIPLE ? ' *' : '')"
                     [formControl]="phoneNoField">
              <mat-error *ngIf="phoneNoField.hasError('required')"
                         [innerHTML]="'system.form.error.required' | translate">
              </mat-error>
            </mat-form-field>
          </div>
          <div class="school-data-con columns">
            <h3 class="section-title" *ngIf="accountTypeField.value === accountTypes.MULTIPLE">
              {{'register.sectionTitle.schoolData' | translate}}
            </h3>
            <h3 class="section-title" *ngIf="accountTypeField.value === accountTypes.SINGLE">
              {{'register.sectionTitle.schoolAssign' | translate}}
            </h3>
            <mat-form-field
                    [ngClass]="{'school-name-field': true, 'w-100': accountTypeField.value === accountTypes.SINGLE}">
              <input matInput type="text"
                     [placeholder]="('profile.address.placeholder.schoolName' | translate) + (accountTypeField.value === accountTypes.MULTIPLE ? ' *' : '')"
                     [formControl]="institutionNameField" [matAutocomplete]="auto"
                     (ngModelChange)="findInstitution($event)">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option class="institution-select" *ngFor="let institution of institutions"
                            [value]="institution?.title" (onSelectionChange)="assignToInstitution(institution)">
                  {{institution?.title?.trim()}}
                  <small>
                    {{institution?.streetName?.trim()}} {{institution?.streetNo | addressNo:institution?.apartmentNo}},
                    {{institution?.city?.trim()}}, {{institution?.province?.trim()}}</small>
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="institutionNameField.hasError('required')"
                         [innerHTML]="'system.form.error.required' | translate">
              </mat-error>
            </mat-form-field>
            <ng-container *ngIf="accountTypeField.value === accountTypes.SINGLE">
              <div *ngFor="let institution of institutionsAssigned; let i = index;" class="institution-assigned">
                <mat-icon (click)="removeAssigned(i)">close</mat-icon>
                {{institution?.title?.trim()}}
                <small>
                  {{institution?.streetName?.trim()}} {{institution?.streetNo | addressNo:institution?.apartmentNo}},
                  {{institution?.city?.trim()}}, {{institution?.province?.trim()}}</small>
              </div>
            </ng-container>
            <ng-container *ngIf="accountTypeField.value === accountTypes.MULTIPLE">
              <mat-form-field class="tax-id-field">
                <input matInput type="number" [placeholder]="('profile.address.placeholder.taxID' | translate) + ' *'"
                       [formControl]="taxIdField">
                <mat-error *ngIf="taxIdField.hasError('required')"
                           [innerHTML]="'system.form.error.required' | translate">
                </mat-error>
              </mat-form-field>
              <mat-form-field class="street-field">
                <input matInput type="text" [placeholder]="('profile.address.placeholder.street' | translate) + ' *'"
                       [formControl]="streetNameField">
                <mat-error *ngIf="streetNameField.hasError('required')"
                           [innerHTML]="'system.form.error.required' | translate">
                </mat-error>
              </mat-form-field>
              <mat-form-field class="no-field">
                <input matInput type="text" [placeholder]="('register.placeholder.streetNumber' | translate) + ' *'"
                       [formControl]="streetNumberField">
                <mat-error *ngIf="streetNumberField.hasError('required')"
                           [innerHTML]="'system.form.error.requiredShort' | translate">
                </mat-error>
              </mat-form-field>
              <span class="separator">/</span>
              <mat-form-field class="no-field">
                <input matInput type="text" [placeholder]="'register.placeholder.apartmentNumber' | translate"
                       [formControl]="apartmentNumberField">
              </mat-form-field>
              <mat-form-field class="zip-code-field">
                <input matInput type="text" [placeholder]="('profile.address.placeholder.zipCode' | translate) + ' *'"
                       [formControl]="postCodeField">
                <mat-error *ngIf="postCodeField.hasError('required')"
                           [innerHTML]="'system.form.error.requiredShort' | translate">
                </mat-error>
              </mat-form-field>
              <mat-form-field class="city-field">
                <input matInput type="text" [placeholder]="('profile.address.placeholder.city' | translate) + ' *'"
                       [formControl]="cityField">
                <mat-error *ngIf="cityField.hasError('required')"
                           [innerHTML]="'system.form.error.required' | translate">
                </mat-error>
              </mat-form-field>
              <mat-form-field class="state-field">
                <input matInput type="text" [placeholder]="('profile.address.placeholder.state' | translate) + ' *'"
                       [formControl]="stateField">
                <mat-error *ngIf="stateField.hasError('required')"
                           [innerHTML]="'system.form.error.required' | translate">
                </mat-error>
              </mat-form-field>
            </ng-container>
          </div>
          <button class="neutral-button prev-but" matStepperPrevious>{{'register.label.previous' | translate}}</button>
          <button class="submit-button register-but"
                  (click)="onSubmit()">{{'register.label.register' | translate}}</button>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
    <div class="steper-nav">
      <button class="steper-arr steper-arr-prev" *ngIf="stepper.selectedIndex > 0" (click)="stepper.previous()"
              type="button">Back
      </button>
      <button class="steper-arr steper-arr-next" *ngIf="stepper.selectedIndex < (role ? 1 : 2)" (click)="stepper.next()"
              type="button">Next
      </button>
    </div>
  </div>
  <hr *ngIf="!registrationOnly">
  <footer *ngIf="!registrationOnly">
    <div>{{'authorization.info.haveAccount' | translate | translateSplit:0}}
      <span class="link" (click)="changeScreen(screensEnum.login)">
      {{'authorization.info.haveAccount' | translate | translateSplit:1}}
    </span>
    </div>
  </footer>
</ng-container>
<ng-container *ngIf="showConfirm">
  <div class="system-dialog-body">
    <div class="confirm">
      <p>
        <strong>{{'register.info.thanks' | translate}}</strong>&nbsp;<span
        [outerHTML]="'register.info.registerConfirm' | translate:{email: emailField.value}"></span>
      </p>
      <p [innerHTML]="'system.spamCheck' | translate"></p>
    </div>
  </div>
  <hr *ngIf="!registrationOnly">
  <footer *ngIf="!registrationOnly">
    <span class="link"
          (click)="changeScreen(screensEnum.login)">{{'authorization.label.backToLogin' | translate}}</span>
  </footer>
</ng-container>
<ng-container *ngIf="showLoginConfirm">
  <div class="system-dialog-body">
    <div class="confirm">
      <p>
        <strong>{{'register.info.thanks' | translate}}</strong>
        {{'register.info.registerActiveConfirm' | translate}}
      </p>
    </div>
  </div>
  <hr>
  <footer>
    <span class="link" (click)="changeScreen(screensEnum.login)">{{'authorization.label.goToLogin' | translate}}</span>
  </footer>
</ng-container>
