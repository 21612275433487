import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ViewerConfig} from '../../../../../../configs/viewer-config';
import {OfflineService} from '../../../../../../offline/services/offline-service/offline.service';
import {PrintService} from '../../../../../../print/communication/print.service';
import {ServiceWorkerService} from '../../../../../../web-workers/service/service-worker.service';
import {DraggableWindow} from '../../../../screens/version/components/interfaces/draggable-window';
import {LoadingProgressService} from '../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {AbstractWindowComponent, contentAnimations, windowAnimations} from '../../../abstracts/screens/abstract-window.component';
import {InteractiveLayerResponse} from '../../../interactive/api/data/interactive-layer-response';
import {InteractiveResponse} from '../../../interactive/api/data/interactive-response';
import {InteractiveCommunicationService} from '../../../interactive/communication/interactive-communication.service';
import {CustomInteractivesLoaded} from '../../../interactive/communication/interfaces/custom-interactives-loaded';
import {ViewChange} from '../../../layer/communication/interfaces/view-change';
import {LayersCommunicationService} from '../../../layer/communication/layers-communication.service';
import {LayerManagerResponse} from '../../api/data/layer-manager-response';
import {LayerManagerOfflineService} from '../../api/services/layer-manager-offline.service';
import {LayerManagerService} from '../../api/services/layer-manager.service';
import {LayerManagerCommunicationService} from '../../communication/layer-manager-communication.service';
import {CustomInteractivesCommunicationService} from '../../modules/custom-interactives-manager/communiaction/custom-interactives-communication.service';
import {InteractiveSettingsDialogComponent} from '../../modules/custom-interactives-manager/screens/interactive-settings/interactive-settings-dialog.component';

@Component({
  selector: 'app-layer-manager',
  animations: [windowAnimations, contentAnimations],
  templateUrl: './layer-manager.component.html',
  styleUrls: ['./layer-manager.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayerManagerComponent extends AbstractWindowComponent implements OnInit, ViewChange, DraggableWindow, OnDestroy,
  CustomInteractivesLoaded {

  @Input()
  public versionId: string;

  @Input()
  public projectId: string;

  @Input()
  public windows: Map<string, DraggableWindow>;

  private isOffline: boolean;
  private _settingsEnabled = true;

  public layerManager: LayerManagerResponse[] = [];
  public managerLayers: boolean[] = [];
  public containsInteractives: boolean;
  public containsCustomInteractives: boolean;

  public customInteractives: InteractiveResponse;

  public constructor(private readonly _layerManagerService: LayerManagerService,
                     private readonly _layerManagerOfflineService: LayerManagerOfflineService,
                     private readonly _communication: LayerManagerCommunicationService,
                     private readonly _interactiveCommunication: InteractiveCommunicationService,
                     private readonly _cdr: ChangeDetectorRef, private readonly _offline: OfflineService,
                     private readonly _dialog: MatDialog, layersCommunication: LayersCommunicationService,
                     loading: LoadingProgressService, print: PrintService, workerService: ServiceWorkerService,
                     customInteractivesCommunicationService: CustomInteractivesCommunicationService) {
    super(layersCommunication, customInteractivesCommunicationService, loading, print, workerService);
    this._interactiveCommunication.addCustomInteractiveLoadedListener(this);
  }

  public ngOnInit(): void {
    this.windows['LayerManagerWindow'] = this;
    this.afterViewInit();
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      this.isOffline = result;
      setTimeout(() => {
        const requestMethod = this.isOffline
          ? this._layerManagerOfflineService.getLayerManager(this.projectId)
          : this.getOnlineMethod();
        requestMethod.subscribe((response: LayerManagerResponse[]) => {
          if (!response) return;
          this.layerManager.push(...response);
          this.setData();
          this.loading.configLoaded();
        });
      }, 0);
    });
  }

  private getOnlineMethod(): Observable<LayerManagerResponse[]> {
    return this.projectId === undefined
      ? this._layerManagerService.getLayerManagerPreview(this.versionId)
      : this._layerManagerService.getLayerManager(this.projectId);
  }

  public afterViewInit(): void {
    this.windowParams.x = ViewerConfig.LEFT_BAR_SIZE + 10;
    this.windowParams.y = ViewerConfig.TOP_BAR_SIZE + 10;
    super.afterViewInit();
    this._cdr.detectChanges();
  }

  private setData(): void {
    this.managerLayers = [];
    this.layerManager.forEach((layer) => {
      this.containsInteractives = layer.interactives && !layer.layers ? true : this.containsInteractives;
      this.containsCustomInteractives = layer.customInteractive && !layer.layers ? true : this.containsCustomInteractives;
      this.managerLayers.push(!layer.defaultInactive);
    });
    this.sendLayersState();
  }

  private sendLayersState(): void {
    const disabledLayers: boolean[] = [];
    const disabledInteractives: boolean[] = [];
    const disabledCustomInteractives: boolean[] = [];
    this.layerManager.forEach((layer: LayerManagerResponse, index: number) => {
      if (layer.layers) layer.layers.forEach((value) => {
        disabledLayers[value] = !this.managerLayers[index];
      });
      if (layer.interactives) layer.interactives.forEach((value) => {
        disabledInteractives[value] = !this.managerLayers[index];
      });
      if (layer.customInteractive) layer.customInteractive.forEach((value) => {
        disabledCustomInteractives[value] = !this.managerLayers[index];
      });
    });
    this._communication.disableLayers(disabledLayers);
    this._communication.disableInteractives(disabledInteractives, disabledCustomInteractives);
  }

  public changeAllLayersVisibility(state: boolean): void {
    for (let i = 0; i < this.managerLayers.length; i++) {
      this.managerLayers[i] = this.layerManager[i].layers ? state : this.managerLayers[i];
    }
    this.sendLayersState();
  }

  public isAllDisabled(): boolean {
    for (let i = 0; i < this.managerLayers.length; i++) {
      if (this.managerLayers[i] && !this.layerManager[i].interactives && !this.layerManager[i].customInteractive) return false;
    }
    return true;
  }

  public changeAllInteractivesVisibility(state: boolean): void {
    for (let i = 0; i < this.managerLayers.length; i++) {
      this.managerLayers[i] = this.layerManager[i].interactives && !this.layerManager[i].layers ? state : this.managerLayers[i];
    }
    this.sendLayersState();
  }

  public changeAllCustomInteractivesVisibility(state: boolean): void {
    for (let i = 0; i < this.managerLayers.length; i++) {
      this.managerLayers[i] = this.layerManager[i].customInteractive && !this.layerManager[i].layers ? state : this.managerLayers[i];
    }
    this.sendLayersState();
  }

  public isAllDisabledInteractive(): boolean {
    for (let i = 0; i < this.managerLayers.length; i++) if (this.managerLayers[i] && this.layerManager[i].interactives) return false;
    return true;
  }

  public isAllDisabledCustomInteractive(): boolean {
    for (let i = 0; i < this.managerLayers.length; i++) if (this.managerLayers[i] && this.layerManager[i].customInteractive) return false;
    return true;
  }

  public changeLayerVisibility(index: number): void {
    this.managerLayers[index] = !this.managerLayers[index];
    this.sendLayersState();
  }

  public get settingsEnabled(): boolean {
    return !!this.projectId && this._settingsEnabled && self.navigator.onLine && this.isUserOrHigher;
  }

  public optionsClick(): void {
    this._dialog.open(InteractiveSettingsDialogComponent, ViewerConfig.getInteractiveDialogSettings({
      customInteractives: this.customInteractives ? this.customInteractives.interactives : [],
      projectId: this.projectId
    }));
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._interactiveCommunication.removeCustomInteractiveLoadedListener(this);
  }

  public onCustomInteractivesLoaded(interactive: InteractiveResponse): void {
    this.customInteractives = interactive;
    this.layerManager = this.layerManager.filter((lm: LayerManagerResponse) => !lm.customInteractive);
    this.customInteractives.interactives.forEach((interactiveLayer: InteractiveLayerResponse) => {
      const layerManager = new LayerManagerResponse();
      layerManager.title = interactiveLayer.title;
      layerManager.customInteractive = [interactiveLayer.index];
      layerManager.defaultInactive = !interactiveLayer.enabled;
      this.layerManager.push(layerManager);
    });
    this.containsCustomInteractives = false;
    this.setData();
  }
}
