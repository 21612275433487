import {ICustomConverter} from '@dhkatz/json-ts';

export class DateToSecondsConverter implements ICustomConverter<Date> {

  public static converter = new DateToSecondsConverter();

  public fromJson(data: number): Date {
    return data === undefined ? undefined : new Date(data * 1000);
  }

  public toJson(data: Date): number {
    return data === undefined ? undefined : Math.round(data.getTime() / 1000);
  }
}
