import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {DragulaService} from 'ng2-dragula';
import {Subscription} from 'rxjs/internal/Subscription';
import {InteractiveLayerResponse} from '../../../../../../interactive/api/data/interactive-layer-response';
import {CustomInteractiveService} from '../../../api/services/custom-interactive-service';
import {CustomInteractivesCommunicationService} from '../../../communiaction/custom-interactives-communication.service';

@Component({
  selector: 'app-interactive-list',
  templateUrl: './interactive-list.component.html',
  styleUrls: ['./interactive-list.component.scss']
})
export class InteractiveListComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public init: boolean;

  @Input()
  public projectId: string;

  @Input()
  public cInteractiveLayers: InteractiveLayerResponse[] = [];

  @Output()
  public InteractiveLayerChange = new EventEmitter<InteractiveLayerResponse[]>();

  @Output()
  public update = new EventEmitter<InteractiveLayerResponse>();

  @Output()
  public remove = new EventEmitter<InteractiveLayerResponse>();

  @Output()
  public close = new EventEmitter<void>();

  public showSpinner = false;

  private _draggedSubscriber = new Subscription();

  public constructor(private readonly _drag: DragulaService, private readonly _service: CustomInteractiveService,
                     private _customInteractiveCommunication: CustomInteractivesCommunicationService,
                     private readonly _dialog: MatDialog) {
    this._drag.createGroup('interactive-bag', {});
    this._draggedSubscriber.add(this._drag.dropModel('interactive-bag')
      .subscribe(({targetModel, item}) => this.onWeightChange(targetModel, item)));
  }

  public ngOnInit(): void {
  }

  public addPoints(id: string): void {
    this._customInteractiveCommunication.callCustomInteractiveEditModeEnabled(id, this.projectId);
    this.close.emit();
  }

  private onWeightChange(targetModel: InteractiveLayerResponse[], layer: InteractiveLayerResponse): void {
    if (!this.projectId) return;
    this.showSpinner = true;
    this._service.updateWeight(this.projectId, layer.id, targetModel.indexOf(layer)).subscribe(() => {
      this._customInteractiveCommunication.callInteractiveCustomLayersChanged();
    });
  }

  public ngOnDestroy(): void {
    this._drag.destroy('interactive-bag');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.cInteractiveLayers) this.showSpinner = false;
  }

  public changeEnabled(event: MatCheckboxChange, cInteractiveLayer: InteractiveLayerResponse): void {
    this._service.updateEnabled(this.projectId, cInteractiveLayer.id, event.checked).subscribe(() => {
      this._customInteractiveCommunication.callInteractiveCustomLayersChanged();
    });
  }
}
