import {Injectable} from '@angular/core';
import {InteractiveResponse} from '../api/data/interactive-response';
import {CustomInteractivesLoaded} from './interfaces/custom-interactives-loaded';

@Injectable({
  providedIn: 'root'
})
export class InteractiveCommunicationService {

  private _onCustomInteractivesLoadedListeners: CustomInteractivesLoaded[] = [];

  public constructor() {
  }

  public addCustomInteractiveLoadedListener(listener: CustomInteractivesLoaded): void {
    this._onCustomInteractivesLoadedListeners.push(listener);
  }

  public removeCustomInteractiveLoadedListener(listener: CustomInteractivesLoaded): void {
    const index: number = this._onCustomInteractivesLoadedListeners.indexOf(listener);
    if (index === -1) return;
    this._onCustomInteractivesLoadedListeners.splice(index, 1);
  }

  public callCustomInteractiveLoaded(interactive: InteractiveResponse): void {
    if (this._onCustomInteractivesLoadedListeners.length === 0) return;
    this._onCustomInteractivesLoadedListeners.forEach((listener: CustomInteractivesLoaded) => {
      try {
        listener.onCustomInteractivesLoaded(interactive);
      } catch (exception) {
        console.log(exception);
      }
    });
  }
}
