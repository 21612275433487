import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractLayerResponse} from './abstract-layer-response';

export abstract class AbstractSingleLayerResponse extends AbstractLayerResponse {

  @JsonProperty('i')
  private _index: number;

  protected constructor() {
    super();
    this._index = undefined;
  }

  public get index(): number {
    return this._index;
  }
}
