import {Injectable} from '@angular/core';
import {Pinch} from './interfaces/pinch';

@Injectable({
  providedIn: 'root'
})
export class PinchService {

  private readonly _pinchList: Map<Pinch, any> = new Map<Pinch, any>();

  public addPinch(pinch: Pinch, fromDrag?: boolean): void {
    const info: any = {};
    if (!fromDrag) {
      info.events = new Map<string, any>();
      info.events.set('touchstart', (event: TouchEvent) => this.onStartPinch(event, pinch));
      info.events.set('touchmove', (event: TouchEvent) => this.onPinch(event, pinch));
      info.events.set('touchend', (event: TouchEvent) => this.onStopPinch(event, pinch));
      info.events.set('touchcancel', (event: TouchEvent) => this.onStopPinch(event, pinch));
      info.events.forEach((value: any, key: string) => pinch.element.nativeElement.addEventListener(key, value));
    }
    this._pinchList.set(pinch, info);
  }

  public removePinch(pinch: Pinch): void {
    const info = this._pinchList.get(pinch);
    if (info && info.events)
      info.events.forEach((value: any, key: string) => pinch.element.nativeElement.removeEventListener(key, value));
    this._pinchList.delete(pinch);
  }

  public onStartPinch(event: TouchEvent, pinch: Pinch): void {
    const info = this._pinchList.get(pinch);
    const targetTouches = this.getTargetTouches(event, pinch);
    if (targetTouches.length < 2 || !info || !this.isPrimaryTouch(event, targetTouches)) return;
    this.setAddTouchesInfo(event, targetTouches, info);
    if (info.lastDistance !== undefined) return;
    info.lastDistance = this.getDistance(event, targetTouches);
    info.distance = 0;
    info.sensitive = 0;
  }

  public onPinch(event: TouchEvent, pinch: Pinch, drag?: { x: number, y: number }): void {
    const info = this._pinchList.get(pinch);
    const targetTouches = this.getTargetTouches(event, pinch);
    if (targetTouches.length < 2 || !info || info.lastDistance === undefined ||
      !this.isPrimaryTouch(event, targetTouches)) return;
    const distance = this.getDistance(event, targetTouches);
    const distanceDiff = distance - info.lastDistance - info.diff;
    info.lastDistance = distance;
    info.distance += distanceDiff;
    info.diff = 0;
    if (drag && Math.abs(info.distance) < 50) return;
    const center = this.getCenter(event, targetTouches);
    if (drag) {
      center.x += drag.x;
      center.y += drag.y;
    }
    pinch.onPinch(distanceDiff, center, info.distance);
  }

  public onStopPinch(event: TouchEvent, pinch: Pinch): void {
    const info = this._pinchList.get(pinch);
    const targetTouches = this.getTargetTouches(event, pinch);
    if (!info || info.lastDistance === undefined || !this.isPrimaryTouch(event, targetTouches)) return;
    this.setRemoveTouchesDiff(event, targetTouches, info);
    if (targetTouches.length >= 2) return;
    info.lastDistance = undefined;
    info.distance = undefined;
    info.diff = undefined;
    info.primary = undefined;
  }

  private isPrimaryTouch(event: TouchEvent, targetTouches: Touch[]): boolean {
    const primaryIdentifiers = this.getPrimaryTouchIdentifiers(targetTouches, event.changedTouches.item(0).identifier);
    return primaryIdentifiers.includes(event.changedTouches.item(0).identifier);
  }

  private getDistance(event: TouchEvent, targetTouches: Touch[]): number {
    const primary = this.getPrimaryTouches(event, targetTouches);
    return this.getTouchesDistance(primary[0], primary[1]);
  }

  private getCenter(event: TouchEvent, targetTouches: Touch[]): { x: number, y: number } {
    const primary = this.getPrimaryTouches(event, targetTouches);
    return {
      x: (primary[0].clientX - primary[1].clientX) / 2 + primary[0].clientX,
      y: (primary[0].clientY - primary[1].clientY) / 2 + primary[0].clientY
    };
  }

  private getTouchesDistance(first: Touch, second: Touch): number {
    if (!first || !second) return 0;
    const a = first.clientX - second.clientX;
    const b = second.clientY - first.clientY;
    return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
  }

  private getPrimaryTouches(event: TouchEvent, targetTouches: Touch[]): Touch[] {
    const identifiers = this.getPrimaryTouchIdentifiers(targetTouches);
    const primary = [];
    targetTouches.forEach((touch: Touch) => {
      if (identifiers.includes(touch.identifier)) primary.push(touch);
    });
    return primary;
  }

  private setAddTouchesInfo(event: TouchEvent, targetTouches: Touch[], info: any): void {
    const primaryIdentifiers = this.getPrimaryTouchIdentifiers(targetTouches);
    const previousDiff = info.diff;
    info.diff = 0;
    if (!info.primary || targetTouches.length <= 2) {
      info.primary = primaryIdentifiers;
      return;
    }
    let oldIdentifier: number;
    let secondIdentifier: number;
    info.primary.forEach((identifier: number) => {
      if (primaryIdentifiers.includes(identifier)) secondIdentifier = identifier;
      else oldIdentifier = identifier;
    });
    info.primary = primaryIdentifiers;
    if (oldIdentifier === undefined || secondIdentifier === undefined) return;
    info.diff = this.getTouchesDiff(event.changedTouches.item(0), this.getTouch(targetTouches, oldIdentifier),
      this.getTouch(targetTouches, secondIdentifier), previousDiff);
  }

  private setRemoveTouchesDiff(event: TouchEvent, targetTouches: Touch[], info: any): void {
    if (targetTouches.length < 2) return;
    const primaryIdentifiers = this.getPrimaryTouchIdentifiers(targetTouches);
    const previousDiff = info.diff;
    info.diff = 0;
    if (!info.primary) {
      info.primary = primaryIdentifiers;
      return;
    }
    let newIdentifier: number;
    let secondIdentifier: number;
    primaryIdentifiers.forEach((identifier: number) => {
      if (info.primary.includes(identifier)) secondIdentifier = identifier;
      else newIdentifier = identifier;
    });
    info.primary = primaryIdentifiers;
    if (newIdentifier === undefined || secondIdentifier === undefined) return;
    info.diff = this.getTouchesDiff(this.getTouch(targetTouches, newIdentifier), event.changedTouches.item(0),
      this.getTouch(targetTouches, secondIdentifier), previousDiff);
  }

  private getTouch(targetTouches: Touch[], identifier: number): Touch {
    for (const touch of targetTouches) {
      if (identifier === touch.identifier) return touch;
    }
    return undefined;
  }

  private getTouchesDiff(newPrimary: Touch, oldPrimary: Touch, secondPrimary: Touch, previousDiff: number): number {
    if (!newPrimary || !oldPrimary || !secondPrimary) return 0;
    const oldDistance = this.getTouchesDistance(oldPrimary, secondPrimary);
    const newDistance = this.getTouchesDistance(newPrimary, secondPrimary);
    return previousDiff + (newDistance - oldDistance);
  }

  private getPrimaryTouchIdentifiers(targetTouches: Touch[], identifier?: number): number[] {
    let firstIdentifier = identifier === undefined ? 9999999 : identifier;
    let secondIdentifier = firstIdentifier;
    targetTouches.forEach((touch: Touch) => {
      const previousFirstPrimaryIdentifier = firstIdentifier;
      firstIdentifier = Math.min(firstIdentifier, touch.identifier);
      if (firstIdentifier !== touch.identifier)
        secondIdentifier = Math.min(secondIdentifier, touch.identifier);
      if (previousFirstPrimaryIdentifier !== firstIdentifier)
        secondIdentifier = Math.min(secondIdentifier, previousFirstPrimaryIdentifier);
    });
    return [firstIdentifier, secondIdentifier];
  }

  private getTargetTouches(event: TouchEvent, pinch: Pinch): Touch[] {
    const targetTouches = [];
    const touchElement = pinch.element.nativeElement;
    for (let i = 0; i < event.touches.length; i++) {
      if (this.isInElement(event.touches.item(i).target, touchElement)) targetTouches.push(event.touches.item(i));
    }
    return targetTouches;
  }

  private isInElement(eventTarget: any, touchTarget: HTMLElement): boolean {
    if (eventTarget === touchTarget) return true;
    if (!eventTarget.parentElement) return false;
    return this.isInElement(eventTarget.parentElement, touchTarget);
  }
}
