<div class="interactive-points" [ngClass]="{'edit-mode': !!editedLayer}">
  <div *ngFor="let layer of interactive?.interactives" [hidden]="isDisabled(layer.index)">
    <app-point *ngFor="let point of layer?.details" [point]="point" [icon]="getIconUrl(point?.icon)" [x]="x"
               [isDrag]="isDrag" [projectId]="projectId" [scale]="scale" [y]="y" [editMode]="false">
    </app-point>
  </div>
  <div *ngFor="let layer of customInteractive?.interactives" [hidden]="isCustomDisabled(layer)">
    <app-point *ngFor="let point of layer?.details" [point]="point" [icon]="getCustomIconUrl(point?.icon)" [x]="x"
               [isDrag]="isDrag" [projectId]="projectId" [scale]="scale" [y]="y" [layerId]="layer.id"
               [editMode]="!!editedLayer" (isCustomDragChange)="changeCustomDrag($event)">
    </app-point>
  </div>
</div>
