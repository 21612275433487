import {JsonProperty} from '@dhkatz/json-ts';
import {CacheRequest} from '../../../../../../utils/models/cache-request';
import {AbstractIndexPreRenderData} from './abstracts/abstract-index-pre-render-data';

export class VectorPreRenderData extends AbstractIndexPreRenderData {

  @JsonProperty({name: 'd', type: CacheRequest})
  private _data: CacheRequest;

  public constructor() {
    super();
    this._data = undefined;
  }

  public get data(): CacheRequest {
    return this.active ? this._data : undefined;
  }

  public set data(value: CacheRequest) {
    this._data = value;
  }
}
