import {JsonProperty} from '@dhkatz/json-ts';

export class IconResponse {

  @JsonProperty('id')
  private _id: string;

  @JsonProperty('t')
  private _title: string;

  @JsonProperty('i')
  private _icon: string;

  @JsonProperty('ri')
  private _retinaIcon: string;

  @JsonProperty('c')
  private _custom: boolean;

  public constructor() {
    this._id = undefined;
    this._title = undefined;
    this._icon = undefined;
    this._retinaIcon = undefined;
    this._custom = undefined;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get icon(): string {
    return this._icon;
  }

  public get retinaIcon(): string {
    return this._retinaIcon;
  }

  public get custom(): boolean {
    return this._custom;
  }

  public set custom(value: boolean) {
    this._custom = value;
  }
}
