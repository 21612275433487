import {JsonProperty} from '@dhkatz/json-ts';

export class CustomTaskRequest {

  @JsonProperty('title')
  private _title: string;

  @JsonProperty('description')
  private _description: string;

  @JsonProperty('enabled')
  private _enabled: boolean;

  public constructor() {
    this._title = undefined;
    this._description = undefined;
    this._enabled = undefined;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get enabled(): boolean {
    return this._enabled;
  }

  public set enabled(value: boolean) {
    this._enabled = value;
  }
}
