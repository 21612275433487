import {deserialize, ICustomConverter, serialize} from '@dhkatz/json-ts';
import {LayerType} from '../../../../../../globals/api/data/enums/layer-type.enum';
import {AbstractLayerResponse} from '../data/layer-response/layers/abstracts/abstract-layer-response';
import {CombinedLayerResponse} from '../data/layer-response/layers/combined-layer-response';
import {RasterLayerResponse} from '../data/layer-response/layers/raster-layer-response';
import {VectorLayerResponse} from '../data/layer-response/layers/vector-layer-response';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

export class LayersListConverter implements ICustomConverter<AbstractLayerResponse[]> {

  public static converter = new LayersListConverter();

  public fromJson(data: any[]): AbstractLayerResponse[] {
    if (!data || !data.length) return undefined;
    const layers: AbstractLayerResponse[] = [];
    for (const layer of data) {
      const type: LayerType = (<any>LayerType)[layer['t']];
      switch (type) {
        case LayerType.raster:
          layers.push(deserializeFix(RasterLayerResponse, layer));
          break;
        case LayerType.combined:
          layers.push(deserializeFix(CombinedLayerResponse, layer));
          break;
        case LayerType.vector:
          layers.push(deserializeFix(VectorLayerResponse, layer));
          break;
      }
    }
    return layers;
  }

  public toJson(data: AbstractLayerResponse[]): object[] {
    return data.map((value) => serialize(value));
  }
}
