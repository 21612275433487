import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorResponse} from '../../../../globals/api/data/error-response';
import {Role} from '../../../api/data/enums/role.enum';
import {AuthorizationScreens} from '../../enums/authorization-screens.enum';
import {RoutingConfig} from '../../../../configs/routing-config';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnChanges {

  @Input()
  public screen = AuthorizationScreens.login;

  @Input()
  public redirect;

  @Input()
  public email = '';

  @Input()
  public role: Role;

  @Input()
  public registrationOnly: boolean;

  @Input()
  public token: string;

  @Input()
  public changeUrl: boolean;

  @Output()
  public error: EventEmitter<ErrorResponse> = new EventEmitter<ErrorResponse>();

  @Output()
  public login: EventEmitter<boolean> = new EventEmitter<boolean>();

  public screensEnum = AuthorizationScreens;
  public showSpinner: boolean;
  public registered: boolean;
  public title: string;

  public constructor(private readonly router: Router) {
    this.changeScreen(this.screen);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.screen) this.changeScreen(this.screen);
    else this.setScreenFromUrl();
  }

  public loginSuccess(): void {
    if (this.redirect) this.router.navigate([this.redirect]);
    this.login.emit(this.registered);
  }

  public get isDialog(): boolean {
    return false;
  }

  public closeDialog(): void {
  }

  public changeScreen(screen: AuthorizationScreens): void {
    this.changeScreenUrl(screen);
    this.setScreen(screen);
  }

  private changeScreenUrl(screen: AuthorizationScreens): void {
    if (!this.changeUrl) return;
    switch (screen) {
      case AuthorizationScreens.login:
        this.router.navigate([RoutingConfig.LOGIN]);
        break;
      case AuthorizationScreens.registration:
        this.router.navigate([RoutingConfig.REGISTER]);
        break;
      case AuthorizationScreens.resetPassword:
        this.router.navigate([RoutingConfig.RESET_PASSWORD]);
        break;
    }
  }

  private setScreenFromUrl(): void {
    switch (this.router.url) {
      case '/' + RoutingConfig.LOGIN:
        this.setScreen(AuthorizationScreens.login);
        break;
      case '/' + RoutingConfig.REGISTER:
        this.setScreen(AuthorizationScreens.registration);
        break;
      case '/' + RoutingConfig.RESET_PASSWORD:
        this.setScreen(AuthorizationScreens.resetPassword);
        break;
    }
  }

  private setScreen(screen: AuthorizationScreens): void {
    this.title = 'authorization.title.' + screen;
    this.screen = screen;
  }
}
