import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RoutingConfig} from '../../../../../configs/routing-config';
import {MessageResponse} from '../../../../api/data/message-response';
import {MessagesService} from '../../../../api/service/messages.service';
import {OnMessageChanged} from '../../../../communication/intrfaces/on-message-changed';
import {MessagesCommunicationService} from '../../../../communication/messages-communication.service';

@Component({
  selector: 'app-messages-button',
  templateUrl: './messages-button.component.html',
  styleUrls: ['./messages-button.component.scss']
})
export class MessagesButtonComponent implements OnMessageChanged, OnInit, OnDestroy {

  public unreadMessages = 0;
  public messages: MessageResponse[];
  public showSpinner: boolean;

  private _isLoaded: boolean;

  public constructor(private readonly _router: Router, private readonly _service: MessagesService,
                     private readonly _communication: MessagesCommunicationService) {
    this._communication.addOnMessageChangedListener(this);
  }

  public ngOnInit(): void {
    this.getUnreadCount();
  }

  private getUnreadCount(): void {
    this._service.getUnreadCount().subscribe((response: number) => this.unreadMessages = response);
  }

  public getMessages(): void {
    if (this._isLoaded) return;
    this.showSpinner = true;
    this._service.getShortList().subscribe((response: MessageResponse[]) => {
      this.messages = response;
      this._isLoaded = true;
    }, () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public goToMessages(id?: string): void {
    this._router.navigate([id ? RoutingConfig.MESSAGE.replace(RoutingConfig.MESSAGE_ID_PARAM, id) : RoutingConfig.MESSAGES]);
  }

  public onMessagesChanged(): void {
    this.getUnreadCount();
    this.messages = undefined;
    this._isLoaded = false;
  }

  public ngOnDestroy(): void {
    this._communication.removeOnMessagesChangedListener(this);
  }
}
