import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ViewerConfig} from '../../../../../../configs/viewer-config';
import {Endpoints} from '../../../../../../globals/api/endpoints';
import {AbstractRoleComponent} from '../../../../../../globals/screens/abstracts/abstract-role.component';
import {OfflineService} from '../../../../../../offline/services/offline-service/offline.service';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {ServiceWorkerService} from '../../../../../../web-workers/service/service-worker.service';
import {LoadingProgressService} from '../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {DisableInteractives} from '../../../layer-manager/communication/interfaces/disable-interactives';
import {LayerManagerCommunicationService} from '../../../layer-manager/communication/layer-manager-communication.service';
import {CustomInteractiveEndpoints} from '../../../layer-manager/modules/custom-interactives-manager/api/custom-interactive-endpoints';
import {CustomInteractiveOfflineService} from '../../../layer-manager/modules/custom-interactives-manager/api/services/custom-interactive-offline.service';
import {CustomInteractiveService} from '../../../layer-manager/modules/custom-interactives-manager/api/services/custom-interactive-service';
import {CustomInteractivesCommunicationService} from '../../../layer-manager/modules/custom-interactives-manager/communiaction/custom-interactives-communication.service';
import {CustomInteractivesEditMode} from '../../../layer-manager/modules/custom-interactives-manager/communiaction/interface/custom-interactives-edit-mode';
import {InteractiveCustomLayersChanged} from '../../../layer-manager/modules/custom-interactives-manager/communiaction/interface/interactive-custom-layers-changed';
import {DialogPointComponent} from '../../../layer-manager/modules/custom-interactives-manager/screens/dialogs/dialog-point/dialog-point.component';
import {LayerClick} from '../../../layer/communication/interfaces/layer-click';
import {ScaleChange} from '../../../layer/communication/interfaces/scale-change';
import {ViewMove} from '../../../layer/communication/interfaces/view-move';
import {LayersCommunicationService} from '../../../layer/communication/layers-communication.service';
import {IconResponse} from '../../api/data/icon-response';
import {InteractiveLayerResponse} from '../../api/data/interactive-layer-response';
import {InteractiveResponse} from '../../api/data/interactive-response';
import {AbstractPointResponse} from '../../api/data/points/abstracts/abstract-point-response';
import {PointType} from '../../api/data/points/enums/point-type.enum';
import {ImagePointResponse} from '../../api/data/points/image-point-response';
import {InteractiveOfflineService} from '../../api/services/interactive-offline.service';
import {InteractiveService} from '../../api/services/interactive.service';
import {InteractiveCommunicationService} from '../../communication/interactive-communication.service';

@Component({
  selector: 'app-interactive',
  templateUrl: './interactive.component.html',
  styleUrls: ['./interactive.component.scss']
})
export class InteractiveComponent extends AbstractRoleComponent implements OnInit, DisableInteractives, ScaleChange, ViewMove, OnDestroy,
  InteractiveCustomLayersChanged, CustomInteractivesEditMode, LayerClick {

  @Input()
  public versionId: string;

  @Input()
  public projectId: string;

  @Input()
  public isDrag: boolean;

  @Input()
  public isPreview: boolean;

  public isOffline: boolean;

  public editedLayer: InteractiveLayerResponse;

  public interactive: InteractiveResponse;

  public customInteractive: InteractiveResponse;

  @Output()
  public isCustomDragChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public x: number;
  public y: number;
  public scale: number;

  private _disabledLayers: boolean[] = [];
  private _disabledCustomLayers: boolean[] = [];

  public constructor(private readonly _interactiveService: InteractiveService,
                     private readonly _interactiveOfflineService: InteractiveOfflineService,
                     private readonly _customService: CustomInteractiveService,
                     private readonly _customOfflineService: CustomInteractiveOfflineService,
                     private readonly _communication: InteractiveCommunicationService,
                     private readonly _layersCommunication: LayersCommunicationService,
                     private readonly _layerManagerCommunication: LayerManagerCommunicationService,
                     private readonly _customInteractiveCommunicationService: CustomInteractivesCommunicationService,
                     private readonly _loading: LoadingProgressService, private readonly _offline: OfflineService,
                     private readonly _dialog: MatDialog, serviceWorkerService: ServiceWorkerService) {
    super(serviceWorkerService);
    this._layerManagerCommunication.addOnDisableInteractiveListener(this);
    this._layersCommunication.addViewMoveListener(this);
    this._layersCommunication.addScaleChangeListener(this);
    this._layersCommunication.addLayerClickListener(this);
    this._customInteractiveCommunicationService.addInteractiveCustomLayersChangedListener(this);
    this._customInteractiveCommunicationService.addCustomInteractiveEditModeListener(this);
  }

  public ngOnInit(): void {
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      this.isOffline = result;
      const requestMethod = this.isOffline
        ? this._interactiveOfflineService.getInteractive(this.projectId)
        : this.getOnlineMethod();
      requestMethod.subscribe((response: InteractiveResponse) => {
        this.interactive = response;
        if (this.isPreview) {
          this._loading.configLoaded();
          return;
        }
        this.loadCustomLayers();
      });
    });
  }

  private loadCustomLayers(): void {
    const customRequestMethod = this.isOffline
      ? this._customOfflineService.getLayersList(this.projectId)
      : this._customService.getLayersList(this.projectId);
    customRequestMethod.subscribe((customResponse: InteractiveResponse) => {
      if (customResponse) {
        this.customInteractive = customResponse;
        this._communication.callCustomInteractiveLoaded(this.customInteractive);
      }
      this._loading.configLoaded();
    });
  }

  private getOnlineMethod(): Observable<InteractiveResponse> {
    return this.projectId === undefined
      ? this._interactiveService.getInteractivePreview(this.versionId)
      : this._interactiveService.getInteractive(this.projectId);
  }

  public onDisableInteractives(interactives: boolean[], customInteractives: boolean[]): void {
    this._disabledLayers = interactives;
    this._disabledCustomLayers = customInteractives;
  }

  public isDisabled(index: number): boolean {
    if (index === undefined || this.editedLayer) return true;
    return this._disabledLayers[index];
  }

  public isCustomDisabled(layer: InteractiveLayerResponse): boolean {
    if (layer === undefined || (this.editedLayer && this.editedLayer.id !== layer.id)) return true;
    return this._disabledCustomLayers[layer.index];
  }

  public getIconUrl(index: number): IconResponse {
    return this.interactive.icons[index];
  }

  public getCustomIconUrl(index: number): IconResponse {
    return this.customInteractive.icons[index];
  }

  public onViewMove(x: number, y: number): void {
    this.x = x;
    this.y = y;
  }

  public onScaleChange(scale: number): void {
    this.scale = scale;
  }

  public ngOnDestroy(): void {
    this._layerManagerCommunication.removeDisableInteractivesListener(this);
    this._layersCommunication.removeViewMoveListener(this);
    this._layersCommunication.removeScaleChangeListener(this);
    this._layersCommunication.removeLayerClickListener(this);
    this._customInteractiveCommunicationService.removeOnInteractiveLayersChangedListener(this);
    this._customInteractiveCommunicationService.removeCustomInteractiveEditModeListener(this);
  }

  public interactiveCustomLayersChanged(): void {
    this._customService.getLayersList(this.projectId).subscribe((customResponse: InteractiveResponse) => {
      if (!customResponse) return;
      if (!this.isOffline) {
        this.setCustomInteractives(customResponse);
        return;
      }
      OfflineUtil.downloadFile(this.projectId,
        CustomInteractiveEndpoints.CUSTOM_INTERACTIVES.replace(CustomInteractiveEndpoints.PROJECT_ID, this.projectId),
        () => {
        }, () => {
        });
      this.downloadCustomInteractiveFiles(customResponse);
    });
  }

  private downloadCustomInteractiveFiles(customResponse: InteractiveResponse): void {
    const filesToDownload: string[] = [];
    let downloaded = 0;
    customResponse.icons.forEach((icon) => {
      if (icon.icon) filesToDownload.push(Endpoints.FILES + icon.icon);
      if (icon.retinaIcon) filesToDownload.push(Endpoints.FILES + icon.retinaIcon);
    });
    this.collectInteractiveImageFiles(customResponse, filesToDownload);
    if (filesToDownload.length === 0) {
      this.setCustomInteractives(customResponse);
      return;
    }
    filesToDownload.forEach((url: string) => OfflineUtil.downloadFile(this.projectId, url, () => {
      downloaded++;
      if (downloaded === filesToDownload.length) this.setCustomInteractives(customResponse);
    }, () => {
    }));
  }

  private setCustomInteractives(customResponse: InteractiveResponse): void {
    this.customInteractive = customResponse;
    this._communication.callCustomInteractiveLoaded(this.customInteractive);
    this._loading.configLoaded();
  }

  private collectInteractiveImageFiles(customResponse: InteractiveResponse, filesToDownload: string[]): void {
    customResponse.interactives
      .map((interactive: InteractiveLayerResponse) => interactive.details)
      .forEach((details: AbstractPointResponse[]) => {
        if (!details) return;
        details.filter((point: AbstractPointResponse) => point.type === PointType.image)
          .map((point: AbstractPointResponse) => point as ImagePointResponse)
          .forEach((point: ImagePointResponse) => {
            if (point.file) filesToDownload.push(Endpoints.FILES + point.file);
            if (point.retinaFile) filesToDownload.push(Endpoints.FILES + point.retinaFile);
          });
      });
  }

  public customInteractiveEditModeEnabled(layerId: string, projectId: string): void {
    const customs = this.customInteractive.interactives.filter((interactive: InteractiveLayerResponse) => interactive.id === layerId);
    if (customs.length === 0) return;
    this.editedLayer = customs[0];
  }

  public customInteractiveEditModeDisabled(): void {
    this.editedLayer = undefined;
  }

  public onLayerClick(x: number, y: number): void {
    if (!this.editedLayer) return;
    this._dialog.open(DialogPointComponent, ViewerConfig.getPointDialogSettings({
      layerId: this.editedLayer.id,
      projectId: this.projectId,
      x, y
    }));
  }

  public changeCustomDrag(customDrag: boolean): void {
    this.isCustomDragChange.emit(customDrag);
  }
}
