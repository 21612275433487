<div class="center-box">
  <div class="center-box-container">
    <app-authorization *ngIf="status === confirmStatus.login" [email]="email" [role]="role"
                       (login)="loginSuccess($event)" (error)="handleError($event)" [token]="token"></app-authorization>
    <div id="confirm" *ngIf="status !== confirmStatus.login && status !== undefined">
      <ng-container *ngIf="status === confirmStatus.success">
        <mat-icon class="success">done_outline</mat-icon>
        <span *ngIf="institution"
              [outerHTML]="'invitationConfirmation.info.successName' | translate:{name: institution}"></span>
        <ng-container *ngIf="!institution">{{'invitationConfirmation.info.success' | translate}}</ng-container>
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.invalid">
        <mat-icon class="error">error_outline</mat-icon>
        {{'invitationConfirmation.info.invalid' | translate}}
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.wrongUser">
        <mat-icon class="error">error_outline</mat-icon>
        {{'invitationConfirmation.info.wrongUser' | translate}}
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.expired">
        <mat-icon class="error">error_outline</mat-icon>
        {{'invitationConfirmation.info.expired' | translate}}
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.used">
        <mat-icon class="warn">warning</mat-icon>
        {{'invitationConfirmation.info.used' | translate}}
      </ng-container>
      <footer>
        <button class="submit-button" [routerLink]="'/'">{{'system.label.goToMain' | translate}}</button>
      </footer>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner" class="full"></app-spinner>
