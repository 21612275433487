import {JsonProperty} from '@dhkatz/json-ts';
import {EnumConverter} from '../../../../../../globals/api/json/enum-converter';
import {SizeUnit} from './enums/size-unit.enum';
import {ViewType} from './enums/view-type.enum';
import {PanelChartResponse} from './panel-chart-response';

export class PanelsResponse {
  @JsonProperty('t')
  private _title: string;

  @JsonProperty({name: 'ch', type: PanelChartResponse})
  private _chart: PanelChartResponse[];

  @JsonProperty('s')
  private _size: number;

  @JsonProperty({name: 'u', converter: EnumConverter.converter(SizeUnit)})
  private _unit: SizeUnit;

  @JsonProperty({name: 'v', converter: EnumConverter.converter(ViewType)})
  private _viewType: ViewType;

  @JsonProperty('c')
  private _columns: number;

  public constructor() {
    this._title = undefined;
    this._chart = undefined;
    this._size = undefined;
    this._unit = undefined;
    this._viewType = undefined;
    this._columns = undefined;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get chart(): PanelChartResponse[] {
    return this._chart;
  }

  public set chart(value: PanelChartResponse[]) {
    this._chart = value;
  }

  public get size(): number {
    return this._size;
  }

  public set size(value: number) {
    this._size = value;
  }

  public get unit(): SizeUnit {
    return this._unit;
  }

  public set unit(value: SizeUnit) {
    this._unit = value;
  }

  public get viewType(): ViewType {
    return this._viewType;
  }

  public set viewType(value: ViewType) {
    this._viewType = value;
  }

  public get columns(): number {
    return this._columns;
  }

  public set columns(value: number) {
    this._columns = value;
  }
}
