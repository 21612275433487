import {JsonProperty} from '@dhkatz/json-ts';
import {SubscriptionProjectResponse} from './subscription-project-response';

export class SubscriptionProjectsResponse {

  @JsonProperty({name: 'user', type: SubscriptionProjectResponse})
  private _user: SubscriptionProjectResponse[];

  @JsonProperty({name: 'student', type: SubscriptionProjectResponse})
  private _student: SubscriptionProjectResponse[];

  @JsonProperty({name: 'teacher', type: SubscriptionProjectResponse})
  private _teacher: SubscriptionProjectResponse[];

  public constructor() {
    this._user = undefined;
    this._student = undefined;
    this._teacher = undefined;
  }

  public get user(): SubscriptionProjectResponse[] {
    return this._user;
  }

  public get student(): SubscriptionProjectResponse[] {
    return this._student;
  }

  public get teacher(): SubscriptionProjectResponse[] {
    return this._teacher;
  }
}
