import {DB, openDb, UpgradeDB} from 'idb';

type Condition = (value: any) => boolean;

export class IdbUtil {

  private static readonly IDB = 'yUyJpfAOAt';
  private static readonly IDB_VERSION = 6; // TODO increase version after DB changes
  public static readonly IDB_AUTH = 'yHzcGHKsMt';
  public static readonly IDB_CACHE_DATES = 'ywO7dTpLxd';
  public static readonly IDB_ONLINE_VERSION = 'c24raJmk1x';
  public static readonly IDB_OFFLINE_VERSION = 'xm0ZzoSq0X';

  private static readonly TABLES = [IdbUtil.IDB_AUTH, IdbUtil.IDB_CACHE_DATES, IdbUtil.IDB_ONLINE_VERSION,
    IdbUtil.IDB_OFFLINE_VERSION];

  private static get dbKeyVal(): Promise<DB> {
    return openDb(IdbUtil.IDB, IdbUtil.IDB_VERSION, (upgradeDB: UpgradeDB) => {
      const oldCollections = upgradeDB.objectStoreNames;
      if (oldCollections) for (let i = 0; i < oldCollections.length; i++) upgradeDB.deleteObjectStore(oldCollections.item(i));
      IdbUtil.TABLES.forEach((tableName: string) => {
        if (!upgradeDB.objectStoreNames.contains(tableName)) upgradeDB.createObjectStore(tableName);
      });
    }).catch((reason: any) => {
      console.log(reason);
      return reason;
    });
  }

  public static idbKeyVal(table: string): any {
    const tableName = table.toString();
    return {
      async get(key: string): Promise<any> {
        const db = await IdbUtil.dbKeyVal;
        return db.transaction(tableName).objectStore(tableName).get(key).then((result: any) => {
          db.close();
          return result;
        }).catch((reason: any) => {
          return this.onError(db, reason);
        });
      },
      async contain(key: string): Promise<boolean> {
        const db = await IdbUtil.dbKeyVal;
        return db.transaction(tableName).objectStore(tableName).get(key).then((result: any) => {
          db.close();
          return !!result;
        }).catch((reason: any) => {
          db.close();
          console.log(reason);
          return false;
        });
      },
      async getMany(...keys: string[]): Promise<any> {
        const db = await IdbUtil.dbKeyVal;
        const result = {};
        for (const key of keys) {
          result[key] = await db.transaction(tableName).objectStore(tableName).get(key).catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        }
        db.close();
        return result;
      },
      async set(key: string, val: any): Promise<void> {
        if (!key) return;
        const db = await IdbUtil.dbKeyVal;
        const tx = db.transaction(tableName, 'readwrite');
        tx.objectStore(tableName).put(val, key).catch((reason: any) => {
          console.log(reason);
          return reason;
        });
        return tx.complete.then((result: any) => {
          db.close();
          return result;
        }).catch((reason: any) => {
          return this.onError(db, reason);
        });
      },
      async setMany(data: Map<string, any>): Promise<void> {
        const db = await IdbUtil.dbKeyVal;
        const tx = db.transaction(tableName, 'readwrite');
        for (const key of Array.from(data.keys())) {
          tx.objectStore(tableName).put(data.get(key), key).catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        }
        return tx.complete.then((result: any) => {
          db.close();
          return result;
        }).catch((reason: any) => {
          return this.onError(db, reason);
        });
      },
      async delete(key: string): Promise<void> {
        const db = await IdbUtil.dbKeyVal;
        const tx = db.transaction(tableName, 'readwrite');
        tx.objectStore(tableName).delete(key).catch((reason: any) => {
          console.log(reason);
          return reason;
        });
        return tx.complete.then((result: any) => {
          db.close();
          return result;
        }).catch((reason: any) => {
          return this.onError(db, reason);
        });
      },
      async deleteMany(...keys: string[]): Promise<void> {
        const db = await IdbUtil.dbKeyVal;
        const tx = db.transaction(tableName, 'readwrite');
        for (const key of keys) {
          tx.objectStore(tableName).delete(key).catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        }
        return tx.complete.then((result: any) => {
          db.close();
          return result;
        }).catch((reason: any) => {
          return this.onError(db, reason);
        });
      },
      async deleteIncludes(keyPart: string): Promise<any> {
        const db = await IdbUtil.dbKeyVal;
        let keys = await db.transaction(tableName).objectStore(tableName).getAllKeys();
        keys = keys.filter((key: string) => key.includes(keyPart));
        const tx = db.transaction(tableName, 'readwrite');
        for (const key of keys) {
          tx.objectStore(tableName).delete(key).catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        }
        return tx.complete.then((result: any) => {
          db.close();
          return result;
        }).catch((reason: any) => {
          return this.onError(db, reason);
        });
      },
      async clear(): Promise<void> {
        const db = await IdbUtil.dbKeyVal;
        const tx = db.transaction(tableName, 'readwrite');
        tx.objectStore(tableName).clear().catch((reason: any) => {
          console.log(reason);
          return reason;
        });
        return tx.complete.then((result: any) => {
          db.close();
          return result;
        }).catch((reason: any) => {
          return this.onError(db, reason);
        });
      },
      async getIncludes(keyPart: string): Promise<any> {
        const db = await IdbUtil.dbKeyVal;
        let keys = await db.transaction(tableName).objectStore(tableName).getAllKeys();
        keys = keys.filter((key: string) => key.includes(keyPart));
        const result = {};
        for (const key of keys) {
          result[key] = await db.transaction(tableName).objectStore(tableName).get(key).catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        }
        db.close();
        return result;
      },
      async getAll(): Promise<any> {
        const db = await IdbUtil.dbKeyVal;
        const keys = await db.transaction(tableName).objectStore(tableName).getAllKeys();
        const result = new Map<string, any>();
        for (const key of keys) {
          result[key] = await db.transaction(tableName).objectStore(tableName).get(key).catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        }
        db.close();
        return result;
      },
      async getAllKeys(): Promise<any[]> {
        const db = await IdbUtil.dbKeyVal;
        return db.transaction(tableName).objectStore(tableName).getAllKeys().then((result: any[]) => {
          db.close();
          return result;
        }).catch((reason: any) => {
          return this.onError(db, reason);
        });
      },
      async getKeyForCondition(condition: Condition): Promise<any> {
        const db = await IdbUtil.dbKeyVal;
        return db.transaction(tableName).objectStore(tableName).openCursor().then(function cursorIterate(cursor): any {
          if (!cursor) return undefined;
          if (condition(cursor.value)) return cursor.key;
          return cursor.continue().then(cursorIterate);
        });
      }
    };
  }

  private onError(db, reason: any): void {
    db.close();
    console.log(reason);
    return reason;
  }
}
