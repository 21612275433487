<div class="window tasks" #window [@fadeAnimation]="windowState">
  <app-window-header [headerTitle]="'project.tasks.title' | translate" [(close)]="showWindow" [(show)]="showContent"
                     [drag]="this" [settings]="settingsEnabled"
                     (settingsClick)="optionsClick()"></app-window-header>
  <div class="content" [@slideAnimation]="contentState" (@slideAnimation.done)="updateDivParams()">
    <ng-container *ngFor="let task of customTasks">
      <div *ngIf="task?.enabled" class="task">
        <div class="title" [innerHTML]="task.title"></div>
        <div [innerHTML]="task.description"></div>
      </div>
    </ng-container>
    <div *ngIf="showEmptyInfo" class="empty-info">
      <p><span>{{'project.tasks.empty' | translate}}</span></p>
      <p *ngIf="settingsEnabled" [innerHTML]="'project.tasks.emptyInfo' | translate"></p>
    </div>
  </div>
</div>
