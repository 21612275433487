import {JsonProperty} from '@dhkatz/json-ts';

export class PositionRequest {

  @JsonProperty('x')
  private _x: number;

  @JsonProperty('y')
  private _y: number;

  public constructor() {
    this._x = undefined;
    this._y = undefined;
  }

  public get x(): number {
    return this._x;
  }

  public set x(value: number) {
    this._x = value;
  }

  public get y(): number {
    return this._y;
  }

  public set y(value: number) {
    this._y = value;
  }
}
