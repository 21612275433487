import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Observable, Subscriber} from 'rxjs';
import {OfflineUtil} from '../../../../../../../../utils/offline-util';
import {InteractiveResponse} from '../../../../../interactive/api/data/interactive-response';
import {CustomInteractiveEndpoints} from '../custom-interactive-endpoints';
import {deserializeFix} from '../../../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class CustomInteractiveOfflineService {

  public constructor() {
  }

  public getLayersList(projectId: string): Observable<InteractiveResponse> {
    const path = CustomInteractiveEndpoints.CUSTOM_INTERACTIVES.replace(CustomInteractiveEndpoints.PROJECT_ID, projectId);
    return new Observable<InteractiveResponse>((subscriber: Subscriber<InteractiveResponse>) =>
      OfflineUtil.loadText(projectId, path, (file: string) => {
        if (file) {
          subscriber.next(deserializeFix(InteractiveResponse, JSON.parse(file)));
          return;
        }
        subscriber.next();
      }));
  }
}
