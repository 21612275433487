import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Json, JsonArray, JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {deserializeFix} from '../../../utils/deserialize-util';
import {SubscriptionProjectsResponse} from '../data/subscription-projects-response';
import {SubscriptionResponse} from '../data/subscription-response';
import {SubscriptionEndpoints} from '../subscription-endpoints';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getSubscriptions(): Observable<SubscriptionResponse[]> {
    return this._http.get<JsonArray>(SubscriptionEndpoints.SUBSCRIPTIONS)
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(SubscriptionResponse, value))));
  }

  public getSubscriptionProjects(): Observable<SubscriptionProjectsResponse> {
    return this._http.get<JsonObject>(SubscriptionEndpoints.SUBSCRIPTION_PROJECTS)
      .pipe(map((response: JsonObject) => deserializeFix(SubscriptionProjectsResponse, response)));
  }

  public hasSchoolPlusSubscription(): Observable<boolean> {
    return this._http.get<boolean>(SubscriptionEndpoints.SUBSCRIPTION_SCHOOL_PLUS);
  }
}
