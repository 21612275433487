import {Endpoints} from '../../globals/api/endpoints';

export class SubscriptionEndpoints extends Endpoints {

  public static readonly SUBSCRIPTIONS = SubscriptionEndpoints.VIEWER + '/subscriptions';
  public static readonly SUBSCRIPTION = SubscriptionEndpoints.VIEWER + '/subscription';
  public static readonly SUBSCRIPTION_SCOPE = SubscriptionEndpoints.SUBSCRIPTION + '/scope';
  public static readonly SUBSCRIPTION_PROJECTS = SubscriptionEndpoints.SUBSCRIPTION + '/projects';
  public static readonly SUBSCRIPTION_SCHOOL_PLUS = SubscriptionEndpoints.SUBSCRIPTION + '/school-plus';
}
