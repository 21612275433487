import {JsonProperty} from '@dhkatz/json-ts';
import {TitleItem} from '../../../globals/api/data/title-item';
import {DateToMilliSecondsConverter} from '../../../globals/api/json/date-to-milli-seconds-converter';

export class MessageResponse extends TitleItem {

  @JsonProperty({name: 'publishDate', converter: DateToMilliSecondsConverter.converter})
  private _published: Date;

  @JsonProperty('read')
  private _read: boolean;

  public constructor() {
    super();
    this._published = undefined;
    this._read = undefined;
  }

  public get published(): Date {
    return this._published;
  }

  public set published(value: Date) {
    this._published = value;
  }

  public get read(): boolean {
    return this._read;
  }

  public set read(value: boolean) {
    this._read = value;
  }
}
