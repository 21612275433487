import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Observable, Subscriber} from 'rxjs';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {PanelsResponse} from '../data/panels-response';
import {PanelsEndpoints} from '../panels-endpoints';

@Injectable({
  providedIn: 'root'
})
export class PanelsOfflineService {

  public constructor() {
  }

  public getPanels(projectId: string): Observable<PanelsResponse[]> {
    const path = PanelsEndpoints.PANELS_ENTRY.replace(PanelsEndpoints.PROJECT_ID, projectId);
    return new Observable<PanelsResponse[]>((subscriber: Subscriber<PanelsResponse[]>) =>
      OfflineUtil.loadText(projectId, path, (file: string) =>
        subscriber.next(JSON.parse(file).map((task) => deserialize(PanelsResponse, task)))
      ));
  }
}
