import {JsonProperty} from '@dhkatz/json-ts';
import {ChartData} from '../../../../../../charts/interfaces/chart-data';
import {AnyConverter} from '../../../../../../globals/api/json/any-converter';
import {ArrayStringConverter} from '../../../../../../globals/api/json/array-string-converter';

export class PanelChartResponse implements ChartData {
  @JsonProperty('t')
  private _title: string;

  @JsonProperty({name: 'c', converter: AnyConverter.converter})
  private _chart: any;

  @JsonProperty({name: 'th', converter: ArrayStringConverter.converter, type: Object})
  private _themes: string[];

  public constructor() {
    this._title = undefined;
    this._chart = undefined;
    this._themes = undefined;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get chart(): any {
    return this._chart;
  }

  public set chart(value: any) {
    this._chart = value;
  }

  public get themes(): string[] {
    return this._themes;
  }

  public set themes(value: string[]) {
    this._themes = value;
  }
}
