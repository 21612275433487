import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AccountService} from '../../../account/api/service/account.service';
import {ErrorResponse} from '../../../globals/api/data/error-response';
import {TitleItem} from '../../../globals/api/data/title-item';
import {ServiceWorkerService} from '../../../web-workers/service/service-worker.service';
import {JWT} from '../../../web-workers/workers/service-worker/interceptors/security-interceptor/jwt/jwt';
import {AuthorizationService} from '../../api/services/authorization.service';
import {ConfirmStatus} from '../enums/confirm-status.enum';
import {Role} from '../../api/data/enums/role.enum';
import {SubscriptionAccessService} from '../../../subscriptions/services/subscription-access.service';

@Component({
  selector: 'app-teacher-confirm',
  templateUrl: './teacher-confirm.component.html',
  styleUrls: ['./teacher-confirm.component.scss']
})
export class TeacherConfirmComponent implements OnInit {

  public readonly role: Role = Role.TEACHER;
  public status: ConfirmStatus;
  public confirmStatus = ConfirmStatus;
  public email: string;
  public institution: string;
  public showSpinner = true;

  public token: string;

  public constructor(private readonly _service: AuthorizationService, private readonly _route: ActivatedRoute,
                     private readonly _serviceWorker: ServiceWorkerService, private readonly _accountService: AccountService,
                     private readonly _accessService: SubscriptionAccessService) {
  }

  public ngOnInit(): void {
    setTimeout(() => this._route.queryParams.subscribe((params) => {
      this.token = params['tok'];
      try {
        this.email = JWT.getUnsafePayload(this.token).subject;
        if (!this.email) throw new Error('Token error');
      } catch {
        this.status = ConfirmStatus.invalid;
        this.showSpinner = false;
        return;
      }
      if (this.email === this._serviceWorker.email) {
        this.loginSuccess();
        return;
      }
      this._serviceWorker.logout();
      this._service.checkTeacherInvitationToken(this.token).subscribe(() => this.status = ConfirmStatus.login,
        (error: HttpErrorResponse) => {
          this.showSpinner = false;
          this.handleError(error.error);
        }, () => this.showSpinner = false);
    }), 0);
  }

  public loginSuccess(registered?: boolean): void {
    if (registered) {
      this.status = ConfirmStatus.success;
      return;
    }
    if (this.email !== this._serviceWorker.email) {
      this.status = ConfirmStatus.wrongUser;
      return;
    }
    this.status = undefined;
    this.showSpinner = true;
    this._accountService.addTeacherToInstitution(this.token).subscribe((institution: TitleItem) => {
      this.status = ConfirmStatus.success;
      this.institution = institution.title;
      this._accessService.clearAndReloadSubscriptionAccess();
    }, (error: HttpErrorResponse) => {
      this.showSpinner = false;
      this.handleError(error.error);
    }, () => this.showSpinner = false);
  }

  public handleError(error: ErrorResponse): void {
    if (error.key === 'user_already_in_institution') {
      this.status = ConfirmStatus.used;
      return;
    }
    if (error.key === 'invitation_token_expired') {
      this.status = ConfirmStatus.expired;
      return;
    }
    this.status = ConfirmStatus.invalid;
  }
}
