import {CdkTableModule} from '@angular/cdk/table';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserModule, Meta} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MdePopoverModule} from '@material-extended/mde';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
import {Angulartics2Module} from 'angulartics2';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';
import {DecodeHtmlEntitiesModule} from 'decode-html-entities';
import {ClickOutsideModule} from 'ng-click-outside';
import {DragulaModule} from 'ng2-dragula';
import {CKEditorModule} from 'ngx-ckeditor';
import {MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {NgxYoutubePlayerModule} from 'ngx-youtube-player';
import {environment} from '../environments/environment';
import {
  InstitutionAssignComponent
} from './account/institutions/screens/parts/institution-assign/institution-assign.component';
import {ApprovalsComponent} from './account/screens/approvals/approvals.component';
import {ManagementComponent} from './account/screens/management/management.component';
import {InstitutionCodeComponent} from './account/screens/management/parts/institution-code/institution-code.component';
import {
  InstitutionStudentsComponent
} from './account/screens/management/parts/institution-students/institution-students.component';
import {
  InviteTeacherDialogComponent
} from './account/screens/management/parts/institution-teachers/dialogs/invite-teacher-dialog/invite-teacher-dialog.component';
import {
  InstitutionTeachersComponent
} from './account/screens/management/parts/institution-teachers/institution-teachers.component';
import {UserStudentComponent} from './account/screens/management/parts/user-student/user-student.component';
import {UserTeacherComponent} from './account/screens/management/parts/user-teacher/user-teacher.component';
import {AddressDataComponent} from './account/screens/profile/parts/address-data/address-data.component';
import {EmailDataComponent} from './account/screens/profile/parts/email-data/email-data.component';
import {PasswordDataComponent} from './account/screens/profile/parts/password-data/password-data.component';
import {RoleDataComponent} from './account/screens/profile/parts/role-data/role-data.component';
import {ProfileComponent} from './account/screens/profile/profile.component';
import {AppComponent} from './app.component';
import {
  AuthorizationScreenComponent
} from './authorization/screens/authorization-screen/authorization-screen.component';
import {
  AuthorizationDialogComponent
} from './authorization/screens/authorization/authorization-dialog/authorization-dialog.component';
import {AuthorizationComponent} from './authorization/screens/authorization/authorization/authorization.component';
import {
  ChangePasswordComponent
} from './authorization/screens/authorization/components/change-password/change-password.component';
import {LoginComponent} from './authorization/screens/authorization/components/login/login.component';
import {
  RegistrationComponent
} from './authorization/screens/authorization/components/registration/registration.component';
import {
  ResetPasswordComponent
} from './authorization/screens/authorization/components/reset-password/reset-password.component';
import {
  ChangePasswordScreenComponent
} from './authorization/screens/change-password-screen/change-password-screen.component';
import {EmailConfirmComponent} from './authorization/screens/email-confirm/email-confirm.component';
import {RegisterConfirmComponent} from './authorization/screens/register-confirm/register-confirm.component';
import {TeacherConfirmComponent} from './authorization/screens/teacher-invitation-confirm/teacher-confirm.component';
import {MainCategoriesComponent} from './category/screens/main-categories/main-categories.component';
import {RoutingConfig} from './configs/routing-config';
import {AlertComponent} from './globals/dialogs/alert/alert.component';
import {ResponseErrorComponent} from './globals/dialogs/response-error/response-error.component';
import {AddressNoPipe} from './globals/pipes/address-no.pipe';
import {FileUrlPipe} from './globals/pipes/file-url.pipe';
import {HtmlPipe} from './globals/pipes/html.pipe';
import {OfflinePipe} from './globals/pipes/offline.pipe';
import {PlainPipe} from './globals/pipes/plain.pipe';
import {RetinaPipe} from './globals/pipes/retina.pipe';
import {SafePipe} from './globals/pipes/safe.pipe';
import {TargetPipe} from './globals/pipes/target.pipe';
import {TranslateSplitPipe} from './globals/pipes/translate-split.pipe';
import {BackComponent} from './globals/screens/parts/back/back.component';
import {BreadcrumbComponent} from './globals/screens/parts/breadcrumb/breadcrumb.component';
import {ImagePreloadComponent} from './globals/screens/parts/image-preloader/image-preload.component';
import {LogoComponent} from './globals/screens/parts/logo/logo.component';
import {SearchButtonComponent} from './globals/screens/parts/search-button/search-button.component';
import {SpinnerComponent} from './globals/screens/parts/spinner/spinner.component';
import {TopMenuComponent} from './globals/screens/parts/top-menu/top-menu.component';
import {UserMenuComponent} from './globals/screens/parts/user-menu/user-menu.component';
import {MessagesComponent} from './meassages/screens/messages/messages.component';
import {MessageComponent} from './meassages/screens/messages/parts/message/message.component';
import {MessagesButtonComponent} from './meassages/screens/messages/parts/messages-button/messages-button.component';
import {EmailDialogComponent} from './offline/screens/dialogs/email-dialog/email-dialog.component';
import {OfflineProjectsComponent} from './offline/screens/offline-projects/offline-projects.component';
import {DownloadToOfflineComponent} from './offline/screens/parts/download-to-offline/download-to-offline.component';
import {ManageOfflineComponent} from './offline/screens/parts/manage-offline/manage-offline.component';
import {OfflineService} from './offline/services/offline-service/offline.service';
import {PrintToolComponent} from './print/screens/print-tool/print-tool.component';
import {CarouselComponent} from './project/modules/carousel/screens/parts/carousel/carousel.component';
import {CopyrightsComponent} from './project/modules/copyrights/screens/copyrights/copyrights.component';
import {VideoComponent} from './project/modules/video/screens/parts/video/video.component';
import {CarriageComponent} from './project/screens/projects/components/carriage/carriage.component';
import {ElementDetailsComponent} from './project/screens/projects/components/element-details/element-details.component';
import {ListElementComponent} from './project/screens/projects/components/list-element/list-element.component';
import {ProjectsComponent} from './project/screens/projects/projects.component';
import {SearchProjectsComponent} from './project/screens/search-projects/search-projects.component';
import {
  BibliographyComponent
} from './project/version/modules/bibliography/screens/bibliography/bibliography.component';
import {FontsComponent} from './project/version/modules/fonts/screens/fonts/fonts.component';
import {
  PointDetailsComponent
} from './project/version/modules/interactive/modules/points/screens/point-details/point-details.component';
import {PointComponent} from './project/version/modules/interactive/modules/points/screens/point/point.component';
import {
  InteractiveComponent
} from './project/version/modules/interactive/screens/interactive-layers/interactive.component';
import {
  DialogPointComponent
} from './project/version/modules/layer-manager/modules/custom-interactives-manager/screens/dialogs/dialog-point/dialog-point.component';
import {
  EmbedPointDialogComponent
} from './project/version/modules/layer-manager/modules/custom-interactives-manager/screens/dialogs/dialog-point/parts/embed-point-dialog/embed-point-dialog.component';
import {
  IconComponent
} from './project/version/modules/layer-manager/modules/custom-interactives-manager/screens/dialogs/dialog-point/parts/icon/icon.component';
import {
  ChooseImageDialogComponent
} from './project/version/modules/layer-manager/modules/custom-interactives-manager/screens/dialogs/dialog-point/parts/image-point-dialog/dialog/choose-image-dialog/choose-image-dialog.component';
import {
  ImagePointDialogComponent
} from './project/version/modules/layer-manager/modules/custom-interactives-manager/screens/dialogs/dialog-point/parts/image-point-dialog/image-point-dialog.component';
import {
  PointRemoveComponent
} from './project/version/modules/layer-manager/modules/custom-interactives-manager/screens/dialogs/dialog-point/parts/point-remove/point-remove.component';
import {
  TextPointDialogComponent
} from './project/version/modules/layer-manager/modules/custom-interactives-manager/screens/dialogs/dialog-point/parts/text-point-dialog/text-point-dialog.component';
import {
  InteractiveEditComponent
} from './project/version/modules/layer-manager/modules/custom-interactives-manager/screens/interactive-settings/interactive-edit/interactive-edit.component';
import {
  InteractiveListComponent
} from './project/version/modules/layer-manager/modules/custom-interactives-manager/screens/interactive-settings/interactive-list/interactive-list.component';
import {
  InteractiveRemoveComponent
} from './project/version/modules/layer-manager/modules/custom-interactives-manager/screens/interactive-settings/interactive-remove/interactive-remove.component';
import {
  InteractiveSettingsDialogComponent
} from './project/version/modules/layer-manager/modules/custom-interactives-manager/screens/interactive-settings/interactive-settings-dialog.component';
import {
  LayerManagerComponent
} from './project/version/modules/layer-manager/screens/layer-manager/layer-manager.component';
import {LayersComponent} from './project/version/modules/layer/screens/layers/layers.component';
import {LegendComponent} from './project/version/modules/legend/screens/legend/legend.component';
import {MiniMapComponent} from './project/version/modules/mini-map/screens/mini-map/mini-map.component';
import {PanelSidebarComponent} from './project/version/modules/panels/screens/panel-sidebar/panel-sidebar.component';
import {PanelsComponent} from './project/version/modules/panels/screens/panels/panels.component';
import {PanelChartsComponent} from './project/version/modules/panels/screens/parts/panel-charts/panel-charts.component';
import {PanelComponent} from './project/version/modules/panels/screens/parts/panel/panel.component';
import {
  ScaleSettingsComponent
} from './project/version/modules/scale/screens/scale/dialogs/scale-settings/scale-settings.component';
import {ScaleComponent} from './project/version/modules/scale/screens/scale/scale.component';
import {
  TaskEditComponent
} from './project/version/modules/tasks/screens/tasks/dialogs/tasks-settings/task-edit/task-edit.component';
import {
  TaskRemoveComponent
} from './project/version/modules/tasks/screens/tasks/dialogs/tasks-settings/task-remove/task-remove.component';
import {
  TasksListComponent
} from './project/version/modules/tasks/screens/tasks/dialogs/tasks-settings/tasks-list/tasks-list.component';
import {
  TasksSettingsComponent
} from './project/version/modules/tasks/screens/tasks/dialogs/tasks-settings/tasks-settings.component';
import {TasksComponent} from './project/version/modules/tasks/screens/tasks/tasks.component';
import {
  LoadingProgressComponent
} from './project/version/screens/version/components/loading-progress/loading-progress.component';
import {
  WindowHeaderComponent
} from './project/version/screens/version/components/parts/window-header/window-header.component';
import {ZoomComponent} from './project/version/screens/version/components/zoom/zoom.component';
import {VersionComponent} from './project/version/screens/version/version.component';
import {SubscriptionDemoComponent} from './subscriptions/screens/dialogs/subscription-demo/subscription-demo.component';
import {
  SubscriptionNoAccessComponent
} from './subscriptions/screens/dialogs/subscription-no-access/subscription-no-access.component';
import {
  SubscriptionCouponComponent
} from './subscriptions/screens/subscriptions/parts/subscription-coupon/subscription-coupon.component';
import {SubscriptionComponent} from './subscriptions/screens/subscriptions/parts/subscription/subscription.component';
import {SubscriptionsComponent} from './subscriptions/screens/subscriptions/subscriptions.component';
import {TagsComponent} from './tag/screens/tags/tags.component';
import {APP_ENV, APP_META_TAG_ENV_PROFILE} from './variables';
import {envFactory} from './env-factory';

const appRoutes: Routes = [
  {path: RoutingConfig.PROJECT, component: VersionComponent},
  {path: RoutingConfig.PROJECT_OFFLINE, component: VersionComponent},
  {path: RoutingConfig.PROJECT_PREVIEW, component: VersionComponent},
  {path: RoutingConfig.CATEGORY, component: ProjectsComponent},
  {path: RoutingConfig.ACCOUNT_PROFILE, component: ProfileComponent},
  {path: RoutingConfig.ACCOUNT_MANAGE, component: ManagementComponent},
  {path: RoutingConfig.ACCOUNT_SUBSCRIPTIONS, component: SubscriptionsComponent},
  {path: RoutingConfig.EMAIL_CONFIRM, component: EmailConfirmComponent},
  {path: RoutingConfig.REGISTER_CONFIRM, component: RegisterConfirmComponent},
  {path: RoutingConfig.CHANGE_PASSWORD, component: ChangePasswordScreenComponent},
  {path: RoutingConfig.TEACHER_CONFIRM, component: TeacherConfirmComponent},
  {path: RoutingConfig.REGISTER, component: AuthorizationScreenComponent},
  {path: RoutingConfig.LOGIN, component: AuthorizationScreenComponent},
  {path: RoutingConfig.RESET_PASSWORD, component: AuthorizationScreenComponent},
  {path: RoutingConfig.ACCOUNT_OFFLINE_PROJECTS, component: OfflineProjectsComponent},
  {path: RoutingConfig.MESSAGES, component: MessagesComponent},
  {path: RoutingConfig.MESSAGE, component: MessagesComponent},
  {path: '', component: MainCategoriesComponent},
  {path: '**', redirectTo: '/', pathMatch: 'full'}
];

// Sentry.init({
//   dsn: 'https://f02c5eb8f75e42a7abc8adda63f4348f@sentry.io/1426747',
//   environment: 'development',
//   release: 'dev-@@VERSION@@'
// });

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  public constructor(private readonly _offlineService: OfflineService) {
  }

  public handleError(error): void {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    VersionComponent,
    LegendComponent,
    ScaleComponent,
    MiniMapComponent,
    LayersComponent,
    LayerManagerComponent,
    ScaleSettingsComponent,
    ZoomComponent,
    ImagePreloadComponent,
    ResponseErrorComponent,
    AlertComponent,
    FileUrlPipe,
    WindowHeaderComponent,
    LoadingProgressComponent,
    InteractiveComponent,
    PointComponent,
    SafePipe,
    PointDetailsComponent,
    ProjectsComponent,
    FontsComponent,
    CopyrightsComponent,
    BreadcrumbComponent,
    MainCategoriesComponent,
    ListElementComponent,
    CarriageComponent,
    ElementDetailsComponent,
    FontsComponent,
    CopyrightsComponent,
    LogoComponent,
    UserMenuComponent,
    VideoComponent,
    CarouselComponent,
    TopMenuComponent,
    BackComponent,
    TagsComponent,
    AuthorizationComponent,
    AuthorizationDialogComponent,
    RegistrationComponent,
    ResetPasswordComponent,
    SpinnerComponent,
    SearchButtonComponent,
    SearchProjectsComponent,
    ProfileComponent,
    EmailDataComponent,
    PasswordDataComponent,
    AddressDataComponent,
    ManagementComponent,
    UserTeacherComponent,
    UserStudentComponent,
    InstitutionStudentsComponent,
    InstitutionTeachersComponent,
    InstitutionCodeComponent,
    EmailConfirmComponent,
    HtmlPipe,
    PlainPipe,
    SubscriptionsComponent,
    SubscriptionComponent,
    InviteTeacherDialogComponent,
    SubscriptionNoAccessComponent,
    SubscriptionDemoComponent,
    RegisterConfirmComponent,
    ChangePasswordScreenComponent,
    ChangePasswordComponent,
    TeacherConfirmComponent,
    AuthorizationScreenComponent,
    PrintToolComponent,
    DownloadToOfflineComponent,
    OfflinePipe,
    RetinaPipe,
    OfflineProjectsComponent,
    EmailDialogComponent,
    ManageOfflineComponent,
    TasksComponent,
    BibliographyComponent,
    TasksSettingsComponent,
    TasksListComponent,
    TaskEditComponent,
    TaskRemoveComponent,
    TargetPipe,
    TranslateSplitPipe,
    RoleDataComponent,
    InstitutionAssignComponent,
    AddressNoPipe,
    MessagesButtonComponent,
    MessagesComponent,
    MessageComponent,
    ApprovalsComponent,
    InteractiveSettingsDialogComponent,
    InteractiveEditComponent,
    InteractiveRemoveComponent,
    InteractiveListComponent,
    DialogPointComponent,
    TextPointDialogComponent,
    IconComponent,
    EmbedPointDialogComponent,
    ImagePointDialogComponent,
    ChooseImageDialogComponent,
    PointRemoveComponent,
    SubscriptionCouponComponent,
    PanelsComponent,
    PanelChartsComponent,
    PanelComponent,
    PanelSidebarComponent,
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: environment.enableTracking // <-- debugging purposes only
      }
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    Angulartics2Module.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    MatIconModule,
    MatDialogModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatMenuModule,
    CdkTableModule,
    MatCardModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTableModule,
    MatProgressBarModule,
    MdePopoverModule,
    MatTooltipModule,
    MatBadgeModule,
    MatChipsModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    NgxYoutubePlayerModule.forRoot(),
    DecodeHtmlEntitiesModule,
    DragulaModule.forRoot(),
    CKEditorModule,
    MatStepperModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    ClickOutsideModule
  ],
  entryComponents: [
    ScaleSettingsComponent,
    ResponseErrorComponent,
    PointDetailsComponent,
    AuthorizationDialogComponent,
    InviteTeacherDialogComponent,
    SearchProjectsComponent,
    AlertComponent,
    SubscriptionNoAccessComponent,
    SubscriptionDemoComponent,
    EmailDialogComponent,
    TasksSettingsComponent,
    ApprovalsComponent,
    InteractiveSettingsDialogComponent,
    DialogPointComponent,
    TextPointDialogComponent,
    EmbedPointDialogComponent,
    ImagePointDialogComponent,
    ChooseImageDialogComponent
  ],
  providers: [
    HttpClientModule,
    HttpClient,
    Angulartics2GoogleTagManager,
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: MESSAGE_FORMAT_CONFIG, useValue: {locales: ['en', 'pl']}},
    {
      provide: APP_ENV,
      useFactory: envFactory,
      deps: [APP_META_TAG_ENV_PROFILE]
    },
    {
      provide: APP_META_TAG_ENV_PROFILE,
      useFactory: (meta: Meta) => meta.getTag('name=env')?.content ?? '',
      deps: [Meta]
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
