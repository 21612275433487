import {BrowserUtil} from '../../../../../../utils/browser-util';
import {VectorPreRenderData} from '../data/vector-pre-render-data';
import {PreRenderDrawPool} from '../drawers/interfaces/pre-render-draw-pool';
import {LoadingPool} from '../loader/loading-pool';
import {PreRender} from './interfaces/pre-render';

export class CachedPreRenderPart implements PreRender {

  private _imageData: ImageBitmap | HTMLImageElement;
  private _loading: boolean;
  private _clear: boolean;
  private _empty: boolean;

  public constructor(private readonly _data: VectorPreRenderData, private readonly _drawPool: PreRenderDrawPool,
                     private readonly _loader: LoadingPool, private readonly _offline: boolean) {
  }

  private loadImage(): void {
    if (this._loading) return;
    this._loading = true;
    this._loader.load(this._data.data, (imageData: ImageBitmap | HTMLImageElement) => {
      this._imageData = imageData;
      this._loading = false;
      this._drawPool.reDraw();
    }, () => {
      this._empty = true;
      this._loading = false;
    }, this._offline);
  }

  public draw(): boolean {
    if (this._empty || !this._data.data) return true;
    if (this._imageData === undefined) {
      this._clear = false;
      this.loadImage();
      return false;
    }
    this._drawPool.addToDraw(this._imageData);
    return true;
  }

  public stopLoading(): void {
    this._loading = false;
  }

  public clear(): void {
    this.destroy();
  }

  public destroy(): void {
    this._loading = false;
    if (!this._imageData) return;
    if (BrowserUtil.asyncLoad && this._imageData instanceof ImageBitmap) this._imageData.close();
    else (this._imageData as HTMLImageElement).remove();
    this._imageData = undefined;
  }

  public setDisabledLayers(layers: boolean[]): boolean {
    const changed = this._data.setDisabledLayers(layers);
    if (changed) this.clear();
    return changed;
  }
}
