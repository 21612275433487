import {JsonProperty} from '@dhkatz/json-ts';
import {ArrayNumberConverter} from '../../../../../../globals/api/json/array-number-converter';

export class LayerManagerResponse {

  @JsonProperty('t')
  private _title: string;

  @JsonProperty({name: 'l', converter: ArrayNumberConverter.converter, type: Object})
  private _layers: number[];

  @JsonProperty({name: 'i', converter: ArrayNumberConverter.converter, type: Object})
  private _interactives: number[];

  @JsonProperty({name: 'ci', converter: ArrayNumberConverter.converter, type: Object})
  private _customInteractive: number[];

  @JsonProperty('d')
  private _defaultInactive: boolean;

  public constructor() {
    this._title = undefined;
    this._layers = undefined;
    this._interactives = undefined;
    this._customInteractive = undefined;
    this._defaultInactive = undefined;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get layers(): number[] {
    return this._layers;
  }

  public get interactives(): number[] {
    return this._interactives;
  }

  public get customInteractive(): number[] {
    return this._customInteractive;
  }

  public set customInteractive(value: number[]) {
    this._customInteractive = value;
  }

  public get defaultInactive(): boolean {
    return this._defaultInactive;
  }

  public set defaultInactive(value: boolean) {
    this._defaultInactive = value;
  }
}
