<header mat-dialog-title>
  {{'project.interactive.addpoint.title' | translate}}
  <span class="close" (click)="closeDialog()"><mat-icon>close</mat-icon></span>
</header>

<form class="add-interactive-point">
  <h3>{{'project.interactive.addpoint.info.choseType' | translate}}</h3>
  <mat-dialog-content>
    <mat-form-field class="mat-field-validation-container choicePointType">
      <input type="text" *ngIf="typeField" [formControl]="typeField" matInput class="hidden-input">
      <mat-radio-group (change)="typeChange($event.value)">
        <ng-container *ngFor="let type of keys(customPointType)">
          <mat-radio-button [value]="customPointType.image" *ngIf="type === customPointType.image">
            <i class="material-icons">add_photo_alternate</i>
            <span>{{'project.interactive.addpoint.type.image' | translate}}</span>
          </mat-radio-button>
          <mat-radio-button [value]="customPointType.em_movie" *ngIf="type === customPointType.em_movie">
            <i class="material-icons">ondemand_video</i>
            <span>{{'project.interactive.addpoint.type.embedVideo' | translate}}</span>
          </mat-radio-button>
          <mat-radio-button [value]="customPointType.em_sound" *ngIf="type === customPointType.em_sound">
            <i class="material-icons">music_note</i>
            <span>{{'project.interactive.addpoint.type.embedSound' | translate}}</span>
          </mat-radio-button>
          <mat-radio-button [value]="customPointType.text" *ngIf="type === customPointType.text">
            <i class="material-icons">textsms</i>
            <span>{{'project.interactive.addpoint.type.text' | translate}}</span>
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
      <mat-error *ngIf="typeField.hasError('required')" [innerHTML]="'system.form.error.required' | translate">
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <footer class="buttons interactive-points-footer">
    <button mat-button (click)="onSubmit()"
            class="submit-button">{{'project.interactive.points.button.add' | translate}}</button>
  </footer>
</form>
