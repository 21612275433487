import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize, serialize} from '@dhkatz/json-ts';
import {Json, JsonArray, JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomTaskRequest} from '../data/custom-task-request';
import {CustomTaskResponse} from '../data/custom-task-response';
import {TaskResponse} from '../data/task-response';
import {TasksEndpoints} from '../tasks-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getTasks(projectId: string): Observable<TaskResponse[]> {
    return this._http.get<JsonArray>(TasksEndpoints.TASK.replace(TasksEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(TaskResponse, value))));
  }

  public getTasksPreview(versionId: string): Observable<TaskResponse[]> {
    return this._http.get<JsonArray>(TasksEndpoints.TASK_PREVIEW.replace(TasksEndpoints.VERSION_ID, versionId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(TaskResponse, value))));
  }

  public getCustomTasks(projectId: string): Observable<CustomTaskResponse[]> {
    return this._http.get<JsonArray>(TasksEndpoints.PROJECT_CUSTOM_TASKS.replace(TasksEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(CustomTaskResponse, value))));
  }

  public addCustomTask(projectId: string, task: CustomTaskRequest): Observable<CustomTaskResponse> {
    return this._http.post<JsonObject>(TasksEndpoints.PROJECT_CUSTOM_TASKS.replace(TasksEndpoints.PROJECT_ID, projectId), serialize(task))
      .pipe(map((response: JsonObject) => deserializeFix(CustomTaskResponse, response)));
    ;
  }

  public updateCustomTask(projectId: string, taskId: string, task: CustomTaskRequest): Observable<CustomTaskResponse> {
    return this._http.put<JsonObject>(TasksEndpoints.CUSTOM_TASK.replace(TasksEndpoints.PROJECT_ID, projectId)
      .replace(TasksEndpoints.CUSTOM_TASK_ID, taskId), serialize(task))
      .pipe(map((response: JsonObject) => deserializeFix(CustomTaskResponse, response)));
  }

  public removeCustomTask(projectId: string, taskId: string): Observable<void> {
    return this._http.delete<void>(TasksEndpoints.CUSTOM_TASK.replace(TasksEndpoints.PROJECT_ID, projectId)
      .replace(TasksEndpoints.CUSTOM_TASK_ID, taskId));
  }

  public updateCustomTaskWeight(projectId: string, taskId: string, weight: number): Observable<void> {
    return this._http.put<void>(TasksEndpoints.CUSTOM_TASK_WEIGHT.replace(TasksEndpoints.PROJECT_ID, projectId)
      .replace(TasksEndpoints.CUSTOM_TASK_ID, taskId), weight);
  }

  public updateCustomTaskEnabled(projectId: string, taskId: string, enabled: boolean): Observable<void> {
    return this._http.put<void>(TasksEndpoints.CUSTOM_TASK_ENABLED.replace(TasksEndpoints.PROJECT_ID, projectId)
      .replace(TasksEndpoints.CUSTOM_TASK_ID, taskId), enabled);
  }
}
