<div class="content-box">
    <div class="content-box-container">
        <div *ngFor="let category of categories; let index = index" class="content-row">
            <app-carriage [carriageIndex]="index" [category]="category" [mainCategoryId]="categoryId"
                          [carriageSize]="carriageSize"></app-carriage>
        </div>
        <div *ngFor="let projects of projectsGroups; let index = index" class="content-row">
            <app-carriage [carriageIndex]="index" [projectsList]="projects"
                          [mainCategoryId]="categoryId" [carriageSize]="carriageSize"></app-carriage>
        </div>
    </div>
    <app-spinner *ngIf="showSpinner" class="full"></app-spinner>
</div>
