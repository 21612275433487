import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {CouponValidRequest} from '../authorization/api/data/coupon-valid-request';
import {Role} from '../authorization/api/data/enums/role.enum';
import {AuthorizationService} from '../authorization/api/services/authorization.service';
import {AccountType} from '../authorization/screens/authorization/components/registration/accountType.enum';

export class ViewerValidators {

  public static readonly emails = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const emailsList = (control.value as string).toLowerCase().split(/[\s,;]+/).map((email: string) => email.trim())
        .filter((email: string) => email !== '');
      if (!emailsList.length) return {emailsRequired: true};
      const field = new FormControl('', Validators.email);
      const invalidEmails = emailsList.filter((email: string) => {
        field.setValue(email);
        return field.invalid;
      });
      return invalidEmails.length ? {emails: invalidEmails.join(', ')} : null;
    };
  }

  public static readonly requiredInSet = (...required: string[]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      for (const element of required) {
        if (!control.value?.has(element)) return {required: true};
      }
      return null;
    };
  }

  public static readonly userExists = (service: AuthorizationService): AsyncValidatorFn => {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return service.userExists(control.value).then((response: string) => {
        if (!response) return null;
        switch (response) {
          case 'invalid_email':
            return {email: true};
          case 'user_exists':
            return {exists: true};
        }
        return null;
      }).catch(() => null);
    };
  }

  public static readonly subscriptionCouponValid = (service: AuthorizationService, roleField: FormControl, accountTypeField: FormControl): AsyncValidatorFn => {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      if (!control.value || control.value.length === 0) return new Promise((resolve) => resolve());
      const role = accountTypeField.value === AccountType.SINGLE ? roleField.value : Role.INSTITUTION;
      if (!role) return new Promise((resolve) => resolve({subscription_role_required: true}));
      const request: CouponValidRequest = new CouponValidRequest();
      request.coupon = control.value;
      request.role = role;
      return service.couponValid(request).then((response: string) => {
        if (!response) return null;
        switch (response) {
          case 'subscription_coupon_not_found':
            return {subscription_not_found: true};
          case 'subscription_coupon_used':
            return {subscription_used: true};
          case 'subscription_coupon_inactive':
            return {subscription_inactive: true};
          case 'subscription_coupon_invalid_role':
            return {subscription_invalid_role: true};
        }
        return null;
      }).catch(() => null);
    };
  }
}
