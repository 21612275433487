import {JsonProperty} from '@dhkatz/json-ts';
import {IconResponse} from './icon-response';
import {InteractiveLayerResponse} from './interactive-layer-response';

export class InteractiveResponse {

  @JsonProperty({name: 'i', type: IconResponse})
  private _icons: IconResponse[];

  @JsonProperty({name: 'l', type: InteractiveLayerResponse})
  private _interactives: InteractiveLayerResponse[];

  public constructor() {
    this._icons = undefined;
    this._interactives = undefined;
  }

  public get icons(): IconResponse[] {
    return this._icons;
  }

  public get interactives(): InteractiveLayerResponse[] {
    return this._interactives;
  }
}
