import {Endpoints} from '../../../../../../../globals/api/endpoints';
import {CustomInteractiveEndpoints} from '../../../../layer-manager/modules/custom-interactives-manager/api/custom-interactive-endpoints';

export class PointEndpoints extends Endpoints {
  public static readonly POINTS = CustomInteractiveEndpoints.CUSTOM_INTERACTIVE + '/points';
  public static readonly POINTS_LIST = PointEndpoints.POINTS + '/list';
  public static readonly POINTS_TYPE = CustomInteractiveEndpoints.CUSTOM_INTERACTIVE + '/' + PointEndpoints.POINT_TYPE;
  public static readonly POINT = PointEndpoints.POINTS_TYPE + '/' + PointEndpoints.POINT_ID;
  public static readonly POINT_POSITION = PointEndpoints.POINT + '/position';
}
