import {Pipe, PipeTransform} from "@angular/core";



export class DecodeHtmlEntities implements PipeTransform {
    transform(value: any, args: any[]): any {
        if (!value) return;
        let txt = document.createElement("textarea");
        txt.innerHTML = value;
        return txt.value;
    }
static decorators: DecoratorInvocation[] = [
{ type: Pipe, args: [{
    name: 'decodeHtmlEntities'
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}




interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
