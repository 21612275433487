<div class="center-box">
  <div class="center-box-container">
    <div id="confirm">
      <ng-container *ngIf="status === confirmStatus.success">
        <mat-icon class="success">done_outline</mat-icon>
        <span [outerHTML]="'emailConfirmation.info.success' | translate:{email: email}"></span>
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.invalid">
        <mat-icon class="error">error_outline</mat-icon>
        {{'emailConfirmation.info.invalid' | translate}}
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.expired">
        <mat-icon class="error">error_outline</mat-icon>
        {{'emailConfirmation.info.expired' | translate}}
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.used">
        <mat-icon class="warn">warning</mat-icon>
        {{'emailConfirmation.info.used' | translate}}
      </ng-container>
      <footer>
        <button class="submit-button" [routerLink]="'/'">{{'system.label.goToMain' | translate}}</button>
      </footer>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner" class="full"></app-spinner>
