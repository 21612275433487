import {Endpoints} from '../../../../../globals/api/endpoints';

export class TasksEndpoints extends Endpoints {

  public static readonly CUSTOM_TASK_ID = '{customTaskId}';

  private static readonly TASKS = TasksEndpoints.VIEWER + '/tasks';
  public static readonly TASK = TasksEndpoints.TASKS + '/' + TasksEndpoints.PROJECT_ID;
  public static readonly TASK_PREVIEW = TasksEndpoints.TASKS + '/' + TasksEndpoints.VERSION_ID + '/preview';

  private static readonly CUSTOM_TASKS = TasksEndpoints.VIEWER + '/custom-tasks';
  public static readonly PROJECT_CUSTOM_TASKS = TasksEndpoints.CUSTOM_TASKS + '/' + TasksEndpoints.PROJECT_ID;
  public static readonly CUSTOM_TASK = TasksEndpoints.PROJECT_CUSTOM_TASKS + '/' + TasksEndpoints.CUSTOM_TASK_ID;
  public static readonly CUSTOM_TASK_ENABLED = TasksEndpoints.CUSTOM_TASK + '/enabled';
  public static readonly CUSTOM_TASK_WEIGHT = TasksEndpoints.CUSTOM_TASK + '/weight';
}
