import {JsonProperty} from '@dhkatz/json-ts';
import {Role} from './enums/role.enum';
import {EnumConverter} from '../../../globals/api/json/enum-converter';

export class AuthResponse {

  @JsonProperty('refreshToken')
  private _refreshToken: string;

  @JsonProperty('accessToken')
  private _accessToken: string;

  @JsonProperty('passwordToken')
  private _passwordToken: string;

  @JsonProperty({name: 'role', converter: EnumConverter.converter(Role)})
  private _role: Role;

  public constructor() {
    this._refreshToken = undefined;
    this._accessToken = undefined;
    this._passwordToken = undefined;
    this._role = undefined;
  }

  public get refreshToken(): string {
    return this._refreshToken;
  }

  public get accessToken(): string {
    return this._accessToken;
  }

  public get passwordToken(): string {
    return this._passwordToken;
  }

  public get role(): Role {
    return this._role;
  }
}
