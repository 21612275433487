import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Observable, Subscriber} from 'rxjs';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {LegendResponse} from '../data/legend-response';
import {LegendEndpoints} from '../legend-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class LegendOfflineService {

  public constructor() {
  }

  public getLegend(projectId: string): Observable<LegendResponse[]> {
    const path = LegendEndpoints.LEGEND.replace(LegendEndpoints.PROJECT_ID, projectId);
    return new Observable<LegendResponse[]>((subscriber: Subscriber<LegendResponse[]>) =>
      OfflineUtil.loadText(projectId, path, (file: string) => {
        subscriber.next(JSON.parse(file).map((legend) => deserializeFix(LegendResponse, legend)));
      }));
  }
}
