<ng-container *ngIf="!showConfirm">
  <div class="system-dialog-body">
    <div class="reset-password">
      <form>
        <mat-form-field>
          <input type="email" [placeholder]="'system.form.placeholder.email' | translate" matInput
                 [formControl]="emailField">
          <mat-error *ngIf="emailField.hasError('required')"
                     [innerHTML]="'profile.email.error.required' | translate">
          </mat-error>
          <mat-error *ngIf="emailField.hasError('email') && !emailField.hasError('required')"
                     [innerHTML]="'profile.email.error.incorrect' | translate">
          </mat-error>
        </mat-form-field>
        <button class="submit-button" (click)="onSubmit()">{{'system.label.send' | translate}}</button>
      </form>
    </div>
  </div>
  <hr>
  <footer>
    <span class="link"
          (click)="changeScreen(screensEnum.login)">{{'authorization.label.backToLogin' | translate}}</span>
  </footer>
</ng-container>
<ng-container *ngIf="showConfirm">
  <div class="system-dialog-body">
    <div class="confirm">
      <p [innerHTML]="'resetPassword.info.passwordChangeRequest' | translate:{email: emailField.value}"></p>
      <p [innerHTML]="'system.spamCheck' | translate"></p>
    </div>
  </div>
  <hr>
  <footer>
    <span class="link"
          (click)="changeScreen(screensEnum.login)">{{'authorization.label.backToLogin' | translate}}</span>
  </footer>
</ng-container>
