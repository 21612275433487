import {Injectable} from '@angular/core';
import {CarriageInfo} from './data/carriage-info';
import {OnCarriageEvent} from './interfaces/on-carriage-event';

@Injectable({
  providedIn: 'root'
})
export class CarriageCommunicationService {

  private _onOnCarriageEventListeners: OnCarriageEvent[] = [];
  private _onOnCarriageDialogEventListeners: OnCarriageEvent[] = [];

  public constructor() {
  }

  public addOnCarriageEventListener(listener: OnCarriageEvent): void {
    this._onOnCarriageEventListeners.push(listener);
  }

  public removeOnCarriageEventListener(listener: OnCarriageEvent): void {
    const index: number = this._onOnCarriageEventListeners.indexOf(listener);
    if (index === -1) return;
    this._onOnCarriageEventListeners.splice(index, 1);
  }

  public onCarriageEventChange(info: CarriageInfo): void {
    if (this._onOnCarriageEventListeners.length === 0) return;
    for (const listener of this._onOnCarriageEventListeners) {
      try {
        listener.onCarriageEventChange(info);
      } catch (exception) {
        console.log(exception);
      }
    }
  }

  public addOnCarriageDialogEventListener(listener: OnCarriageEvent): void {
    this._onOnCarriageDialogEventListeners.push(listener);
  }

  public removeOnCarriageDialogEventListener(listener: OnCarriageEvent): void {
    const index: number = this._onOnCarriageDialogEventListeners.indexOf(listener);
    if (index === -1) return;
    this._onOnCarriageDialogEventListeners.splice(index, 1);
  }

  public onCarriageDialogEventChange(info: CarriageInfo): void {
    if (this._onOnCarriageDialogEventListeners.length === 0) return;
    for (const listener of this._onOnCarriageDialogEventListeners) {
      try {
        listener.onCarriageEventChange(info);
      } catch (exception) {
        console.log(exception);
      }
    }
  }
}
