import {JsonProperty} from '@dhkatz/json-ts';
import {ArrayNumberConverter} from '../../../../../../../../globals/api/json/array-number-converter';
import {Rect} from './rect';

export class Text extends Rect {

  @JsonProperty('f')
  private _font: string;

  @JsonProperty('tx')
  private _text: string;

  @JsonProperty('s')
  private _size: number;

  @JsonProperty('lS')
  private _letterSpacing: number;

  @JsonProperty({name: 'tr', converter: ArrayNumberConverter.converter, type: Object})
  private _transform: number[];

  public constructor() {
    super();
    this._font = undefined;
    this._text = undefined;
    this._size = undefined;
    this._letterSpacing = undefined;
    this._transform = undefined;
  }

  public get font(): string {
    return this._font;
  }

  public get text(): string {
    return this._text;
  }

  public get size(): number {
    return this._size;
  }

  public get letterSpacing(): number {
    return this._letterSpacing;
  }

  public get transform(): number[] {
    return this._transform;
  }
}
