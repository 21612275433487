import {FontFaceSet} from 'css-font-loading-module';
import {Endpoints} from '../globals/api/endpoints';
import {FontFileResponse} from '../project/version/modules/fonts/api/data/font-file-response';
import {FontResponse} from '../project/version/modules/fonts/api/data/font-response';

export class FontsUtil {

  public static setFonts(fonts: FontResponse[], container: any, completeOne?: any, completeAll?: any): void {
    if (!fonts || fonts.length === 0) {
      if (completeAll) completeAll();
      return;
    }
    FontsUtil.setFont(fonts, 0, container, completeOne, completeAll);
  }

  private static setFont(fonts: FontResponse[], index: number, container: any, completeOne?: any, completeAll?: any): void {
    if (index >= fonts.length) {
      if (completeAll) completeAll();
      return;
    }
    const font = new FontFace(fonts[index].name, FontsUtil.getFontSource(fonts[index].files));
    container.fonts.add(font);
    font.load().then(() => {
      if (completeOne) completeOne();
      FontsUtil.setFont(fonts, ++index, container, completeOne, completeAll);
    });
  }

  public static unsetFonts(container: any): void {
    container.fonts.clear();
  }

  public static getFontSource(source: FontFileResponse[]): string {
    const files = source.map((file: FontFileResponse) =>
      'url("' + Endpoints.FILES + file.file + '") format("' + file.type + '")');
    return files.join(', ');
  }
}
