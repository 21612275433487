import {PoolDraw} from './interfaces/pool-draw';

export class PoolDrawer {

  public clearCanvas: boolean;

  private _pool: any[] = [];
  private _drawing: boolean;

  public constructor(private readonly _poolDraw: PoolDraw) {
  }

  public drawPool(): void {
    if (this._drawing || this.clearCanvas) return;
    this._drawing = !!this._pool.length;
    if (!this._drawing) {
      this._poolDraw.poolDrawComplete();
      return;
    }
    const element = this._pool[0];
    this._poolDraw.render(element);
  }

  public get drawing(): boolean {
    return this._drawing;
  }

  public addToPool(...element: any): void {
    this._pool.push(...element);
    this.drawPool();
  }

  public get pool(): any[] {
    return this._pool;
  }

  public clearPool(): void {
    this._pool = [];
    this.clearCanvas = true;
    this._poolDraw.asyncClearCanvas();
  }

  public drawComplete(): void {
    this._drawing = false;
    if (this.clearCanvas) {
      this._poolDraw.syncClearCanvas();
      this.drawPool();
      return;
    }
    if (!this._pool.length) return;
    this._pool.splice(0, 1);
    this.drawPool();
  }

  public clear(): void {
    this._drawing = false;
    this.clearCanvas = false;
    this._pool = [];
  }
}
