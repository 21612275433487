import {JsonProperty} from '@dhkatz/json-ts';
import {ArrayEnumConverter} from '../../../../globals/api/json/array-enum-converter';
import {VersionModule} from '../../modules/version-modules.enum';

export class VersionResponse {

  @JsonProperty('version')
  private _version: number;

  @JsonProperty({name: 'modules', converter: ArrayEnumConverter.converter(VersionModule), type: Object})
  private _modules: VersionModule[];

  @JsonProperty('versionId')
  private _versionId: string;

  public constructor() {
    this._version = undefined;
    this._modules = undefined;
    this._versionId = undefined;
  }

  public get version(): number {
    return this._version;
  }

  public get modules(): VersionModule[] {
    return this._modules;
  }

  public get versionId(): string {
    return this._versionId;
  }
}
