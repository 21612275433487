import {deserialize, ICustomConverter, serialize} from '@dhkatz/json-ts';
import {DrawElement} from '../data/vector-file-response/abstracts/draw-element';
import {VectorType} from '../data/vector-file-response/enums/vector-type.enum';
import {Circle} from '../data/vector-file-response/models/circle';
import {Ellipse} from '../data/vector-file-response/models/ellipse';
import {Line} from '../data/vector-file-response/models/line';
import {Path} from '../data/vector-file-response/models/path';
import {Rect} from '../data/vector-file-response/models/rect';
import {Text} from '../data/vector-file-response/models/text';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

export class VectorsListConverter implements ICustomConverter<DrawElement[]> {

  public static converter = new VectorsListConverter();

  public fromJson(data: object[]): DrawElement[] {
    if (!data || !data.length) return undefined;
    return data.map((vector: any) => this.getVector(vector)).filter((vector: DrawElement) => !!vector);
  }

  public getVector(vector: any): DrawElement {
    const type: VectorType = (<any>VectorType)[vector['t']];
    switch (type) {
      case VectorType.circle:
        return deserializeFix(Circle, vector);
      case VectorType.ellipse:
        return deserializeFix(Ellipse, vector);
      case VectorType.line:
        return deserializeFix(Line, vector);
      case VectorType.path:
        return deserializeFix(Path, vector);
      case VectorType.rect:
        return deserializeFix(Rect, vector);
      case VectorType.text:
        return deserializeFix(Text, vector);
    }
    return undefined;
  }

  public toJson(data: DrawElement[]): any[] {
    return data.map((value) => serialize(value));
  }
}
