import {ICustomConverter} from '@dhkatz/json-ts';

export class ArrayNumberConverter implements ICustomConverter<number[]> {

  public static readonly converter = new ArrayNumberConverter();

  public fromJson(data: number[]): number[] {
    return data;
  }

  public toJson(data: number[]): number[] {
    return data;
  }
}
