import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {deserialize} from '@dhkatz/json-ts';
import {ViewerConfig} from '../../../../../../../../../configs/viewer-config';
import {OfflineUtil} from '../../../../../../../../../utils/offline-util';
import {CustomTaskRequest} from '../../../../../api/data/custom-task-request';
import {CustomTaskResponse} from '../../../../../api/data/custom-task-response';
import {TasksService} from '../../../../../api/service/tasks.service';
import {TasksEndpoints} from '../../../../../api/tasks-endpoints';
import {TasksSettingsScreen} from '../enums/tasks-settings-screen.enum';
import {deserializeFix} from '../../../../../../../../../utils/deserialize-util';

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent implements OnChanges {

  @Input()
  public projectId: string;

  @Input()
  public task: CustomTaskResponse;

  @Input()
  public tasks: CustomTaskResponse[];

  @Input()
  public isOffline: boolean;

  @Output()
  public tasksChange = new EventEmitter<CustomTaskResponse[]>();

  @Input()
  public screen: TasksSettingsScreen;

  @Output()
  public close = new EventEmitter<void>();

  public showSpinner = false;
  public titleConfig: any;
  public descriptionConfig: any;
  public titleField: FormControl;
  public descriptionField: FormControl;
  public enabledField: FormControl;

  private _form: FormGroup;

  public constructor(private readonly _service: TasksService) {
    this.titleConfig = ViewerConfig.wysiwygBasicConfig;
    this.descriptionConfig = ViewerConfig.wysiwygStandardConfig;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.showSpinner = false;

    this.titleField = new FormControl(this.task ? this.task.title : '', Validators.required);
    this.descriptionField = new FormControl(this.task ? this.task.description : '', Validators.required);
    this.enabledField = new FormControl(this.task ? this.task.enabled : true);

    this._form = new FormGroup({
      title: this.titleField,
      description: this.descriptionField,
      enabled: this.enabledField
    });
  }

  public onSubmit(): void {
    if (!this._form.valid || !this.projectId) return;
    this.showSpinner = true;
    const request = deserializeFix(CustomTaskRequest, this._form.value);
    const requestMethod = this.task ? this._service.updateCustomTask(this.projectId, this.task.id, request) :
      this._service.addCustomTask(this.projectId, request);
    requestMethod.subscribe((response: CustomTaskResponse) => {
      if (response) {
        if (this.task) this.updateTask(response);
        else this.addTask(response);
        this._service.getCustomTasks(this.projectId).subscribe();
        if (this.isOffline) {
          OfflineUtil.downloadFile(this.projectId, TasksEndpoints.PROJECT_CUSTOM_TASKS.replace(TasksEndpoints.PROJECT_ID, this.projectId),
            () => {
            }, () => {
            });
        }
      }
      this.close.emit();
    });
  }

  private addTask(task: CustomTaskResponse): void {
    if (!this.tasks) this.tasks = [];
    this.tasks.push(task);
    this.tasksChange.emit(this.tasks);
  }

  private updateTask(task: CustomTaskResponse): void {
    const index = this.tasks.indexOf(this.task);
    if (index < 0) return;
    this.tasks[index] = task;
    this.tasksChange.emit(this.tasks);
  }
}
