import {JsonProperty} from '@dhkatz/json-ts';
import {OfflineStatus} from '../../../communication/enums/offline-status.enum';
import {UserOfflineEntry} from './UserOfflineEntry';
import {EnumConverter} from '../../../../globals/api/json/enum-converter';

export class OfflineDBEntry {

  @JsonProperty('p')
  private _project: string;

  @JsonProperty({name: 's', converter: EnumConverter.converter(OfflineStatus)})
  private _status: OfflineStatus;

  @JsonProperty('id')
  private _versionId: string;

  @JsonProperty('t')
  private _projectTitle: string;

  @JsonProperty('c')
  private _categoryId: string;

  @JsonProperty({name: 'u', type: UserOfflineEntry})
  private _userEntry: UserOfflineEntry[];

  public constructor() {
    this._project = undefined;
    this._status = undefined;
    this._versionId = undefined;
    this._projectTitle = undefined;
    this._categoryId = undefined;
    this._userEntry = undefined;
  }

  public get project(): string {
    return this._project;
  }

  public set project(value: string) {
    this._project = value;
  }

  public get status(): OfflineStatus {
    return this._status;
  }

  public set status(value: OfflineStatus) {
    this._status = value;
  }

  public get versionId(): string {
    return this._versionId;
  }

  public set versionId(value: string) {
    this._versionId = value;
  }

  public get projectTitle(): string {
    return this._projectTitle;
  }

  public set projectTitle(value: string) {
    this._projectTitle = value;
  }

  public get categoryId(): string {
    return this._categoryId;
  }

  public set categoryId(value: string) {
    this._categoryId = value;
  }

  public get userEntry(): UserOfflineEntry[] {
    return this._userEntry;
  }

  public set userEntry(value: UserOfflineEntry[]) {
    this._userEntry = value;
  }
}
