import {JsonProperty} from '@dhkatz/json-ts';

export class CopyrightsResponse {

  @JsonProperty('copy')
  private _copy: string;

  @JsonProperty('color')
  private _color: string;

  public constructor() {
    this._copy = undefined;
    this._color = undefined;
  }

  public get copy(): string {
    return this._copy;
  }

  public get color(): string {
    return this._color;
  }
}
