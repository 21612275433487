import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {AbstractDialogComponent} from '../../../../../../../../../../../globals/dialogs/abstracts/abstract-dialog.component';
import {DialogsService} from '../../../../../../../../../../../globals/dialogs/dialogs.service';
import {IconResponse} from '../../../../../../../../interactive/api/data/icon-response';
import {IconService} from '../../../../../modules/custom-icons/api/service/icon.service';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html'
})
export class IconComponent extends AbstractDialogComponent implements OnInit {

  public iconField: FormControl;

  public icons: IconResponse[];

  @Input()
  public icon: string;

  @Output()
  public iconChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public error: boolean;

  public selected: IconResponse;

  public showSpinner = false;

  private _filesLoadCount: number;

  public constructor(private readonly _translate: TranslateService, private readonly _service: IconService,
                     @Inject(MAT_DIALOG_DATA) data: any, protected readonly dialogs: DialogsService) {
    super(data);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._service.getIcons().subscribe((response: IconResponse[]) => {
      if (!response) return;
      this.icons = response;
      if (!this.icon) return;
      this.selected = this.icons.find((icon: IconResponse) => icon.id === this.icon);
    });
  }

  protected createFormControls(): void {
    this.iconField = new FormControl(this.icon ? this.icon : '');
  }

  protected createForm(): void {
    this.form = new FormGroup({
      icon: this.iconField
    });
  }

  public getIconImage(id): string {
    if (!this.icons) return undefined;
    const element = this.icons.find((icon: IconResponse) => icon.id === id);
    return !element ? undefined : element.icon;
  }

  public createIcon(files: FileList): void {
    if (files.length === 0) return;
    this.showSpinner = true;
    this._filesLoadCount = files.length;
    for (let i = 0; i < files.length; i++) this.uploadFile(files.item(i));
  }

  private uploadFile(file: File): void {
    this._service.uploadIcon(file).subscribe((response: IconResponse) => {
      this.icons.unshift(response);
      this.iconField.setValue(response.id);
      this.icon = this.iconField.value;
      this.selected = this.icons.find((icon: IconResponse) => icon.id === this.icon);
      this.changeIcon();
    }, () => this.showSpinner = --this._filesLoadCount > 0, () => this.showSpinner = --this._filesLoadCount > 0);
  }

  public onSubmit(): void {
    if (!this.form || !this.form.valid) return;
  }

  public changeIcon(): void {
    this.error = false;
    this.icon = this.iconField.value;
    this.selected = this.icons.find((icon: IconResponse) => icon.id === this.icon);
    this.iconChange.emit(this.iconField.value);
  }

  public delete(): void {
    if (!this.iconField.value) return;
    this.dialogs.showAlert(this._translate.instant('project.interactive.icons.removeConfirmTitle'),
      this._translate.instant('project.interactive.icons.removeConfirmDescription')).subscribe((decision: boolean) => {
      if (!decision) return;
      this._service.deleteIcon(this.iconField.value).subscribe(() => {
        const index = this.icons.indexOf(this.selected);
        if (index === -1) return;
        this.icons.splice(index, 1);
        this.icon = '';
        this.selected = undefined;
        this.changeIcon();
        this.form.get('icon').reset();
      }, (error: HttpErrorResponse) => {
        if (error.error.key === 'custom_icon_is_used') this.iconField.setErrors({file_used: true});
      });
    });
  }
}
