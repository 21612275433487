<div *ngIf="(normal || none) && showSpinner" class="image-spinner">
  <mat-spinner [strokeWidth]="4" [diameter]="40"></mat-spinner>
</div>
<div [ngClass]="{'preloader': true, 'loaded': !showSpinner}">
  <img *ngIf="normal && endpoint"
       [src]="normal | fileUrl : endpoint"
       [srcset]="(normal | fileUrl : endpoint) + ' 1x' +
     (retina ? ','+ (retina | fileUrl : endpoint) + ' 2x' : '')"
       (load)="showSpinner = false; loadComplete.emit();" (error)="showSpinner = false; showNone = true">
  <img class="preloader" *ngIf="(!normal || showNone) && none"
       [src]="'/assets/images/' + none + '.jpg'" [srcset]="'/assets/images/' + none + '.jpg 1x,' +
     ' /assets/images/' + none +  '_2x.jpg 2x'" (load)="showSpinner = false">
</div>