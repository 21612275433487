import {Injectable} from '@angular/core';
import {LoadingProgressComponent} from '../loading-progress.component';
import {LoadingComplete} from './interfaces/loading-complete';

@Injectable({
  providedIn: 'root'
})
export class LoadingProgressService {

  private _loadingComplete: LoadingComplete[] = [];
  private _progress: LoadingProgressComponent;

  public constructor() {
  }

  public addLoadingCompleteListener(listener: LoadingComplete): void {
    this._loadingComplete.push(listener);
  }

  public removeLoadingCompleteListener(listener: LoadingComplete): void {
    const index: number = this._loadingComplete.indexOf(listener);
    if (index === -1) return;
    this._loadingComplete.splice(index, 1);
  }

  public loadingComplete(): void {
    this._progress = undefined;
    if (this._loadingComplete.length === 0) return;
    this._loadingComplete.forEach((listener: LoadingComplete) => {
      try {
        listener.onLoadingComplete();
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public init(progress: LoadingProgressComponent): void {
    this._progress = progress;
  }

// CONFIGS <-----------------------------------------------------------------------------------------------------------------------> CONFIGS

  public addConfigsToLoad(count: number): void {
    if (this._progress) this._progress.addConfigsToLoad(count);
  }

  public configLoaded(): void {
    if (this._progress) this._progress.configLoaded();
  }

// FILES <---------------------------------------------------------------------------------------------------------------------------> FILES

  public addFilesToLoad(count: number): void {
    if (this._progress) this._progress.addFilesToLoad(count);
  }

  public fileLoaded(): void {
    if (this._progress) this._progress.fileLoaded();
  }

// CACHES <-------------------------------------------------------------------------------------------------------------------------> CACHES

  public setCacheCount(count: number): void {
    if (this._progress) this._progress.setCacheCount(count);
  }

  public addElementsForCache(count: number): void {
    if (this._progress) this._progress.addElementsForCache(count);
  }

  public elementCached(): void {
    if (this._progress) this._progress.elementCached();
  }

  public cacheComplete(): void {
    if (this._progress) this._progress.cacheComplete();
  }
}
