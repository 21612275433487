import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthorizationComponent} from '../authorization/authorization.component';

@Component({
  selector: 'app-authorization-dialog',
  templateUrl: '../authorization/authorization.component.html',
  styleUrls: ['../authorization/authorization.component.scss']
})

export class AuthorizationDialogComponent extends AuthorizationComponent {

  public constructor(private readonly _dialogRef: MatDialogRef<AuthorizationDialogComponent>,
                     @Inject(MAT_DIALOG_DATA) private readonly _data: any, router: Router) {
    super(router);
    if (!this._data) return;
    if (this._data.screen) this.screen = this._data.screen;
    if (this._data.path) this.redirect = this._data.path;
    this.changeScreen(this.screen);
  }

  public loginSuccess(): void {
    super.loginSuccess();
    this._dialogRef.close(true);
  }

  public get isDialog(): boolean {
    return true;
  }

  public closeDialog(): void {
    this._dialogRef.close();
  }
}
