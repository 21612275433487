<div class="container-box">
  <div class="account-part password-container">
    <span class="box-title">{{'profile.password.title' | translate}}</span>
    <button *ngIf="!enableEdit" class="submit-button change-password-btn" (click)="enableEdit = true">
      {{'profile.password.changePassword' | translate}}
    </button>
    <form *ngIf="enableEdit" [ngClass]="{'loading': showSpinner}">
      <mat-form-field>
        <input matInput [type]="hideActual ? 'password' : 'text'"
               [placeholder]="'profile.password.placeholder.actualPassword' | translate"
               [formControl]="actualPasswordField">
        <mat-icon *ngIf="form.enabled" matSuffix
                  (click)="hideActual = !hideActual">{{hideActual ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error *ngIf="actualPasswordField.hasError('required')"
                   [innerHTML]="'system.form.error.required' | translate">
        </mat-error>
        <mat-error
          *ngIf="actualPasswordField.hasError('password') && !actualPasswordField.hasError('required')"
          [innerHTML]="'profile.password.error.incorrect' | translate">
        </mat-error>
      </mat-form-field>
      <mat-form-field class="shorten-password-field">
        <input matInput [type]="hideNew ? 'password' : 'text'"
               [placeholder]="'profile.password.placeholder.newPassword' | translate"
               [formControl]="passwordField">
        <mat-icon *ngIf="form.enabled" matSuffix
                  (click)="hideNew = !hideNew">{{hideNew ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-icon matSuffix class="generate" (click)="generatePassword()">vpn_key</mat-icon>
        <mat-error *ngIf="passwordField.hasError('required')"
                   [innerHTML]="'system.form.error.required' | translate">
        </mat-error>
        <mat-error *ngIf="passwordField.hasError('viewerWeakPassword') && !passwordField.hasError('required')">
          {{'profile.password.error.viewerWeakPassword' | translate}}
        </mat-error>
        <mat-error *ngIf="passwordField.hasError('panelWeakPassword') && !passwordField.hasError('required')">
          {{'profile.password.error.panelWeakPassword' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput [type]="hideNew ? 'password' : 'text'"
               [placeholder]="'profile.password.placeholder.repeatNewPassword' | translate"
               [formControl]="confirmPasswordField">
        <mat-error *ngIf="confirmPasswordField.hasError('mustMatch')"
                   [innerHTML]="'profile.password.error.match' | translate">
        </mat-error>
      </mat-form-field>
      <div class="buttons">
        <button class="cancel-button" (click)="cancel()">{{'system.label.cancel' | translate}}</button>
        <button class="submit-button" (click)="submit()">{{'system.label.save' | translate}}</button>
      </div>
    </form>
    <app-spinner *ngIf="showSpinner" class="full"></app-spinner>
  </div>
</div>
