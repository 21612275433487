<div class="container-box">
  <div class="account-part institutions-container">
    <div class="box-title">
      {{'profile.institutions.title' | translate}}
      <mat-icon class="edit-icon" *ngIf="!enableEdit" (click)="enableEdit = true">edit</mat-icon>
    </div>
    <div [ngClass]="{'loading': showSpinner}">
      <mat-form-field *ngIf="enableEdit" class="w-100">
        <input matInput type="text" [placeholder]="'profile.address.placeholder.schoolName' | translate"
               [formControl]="institutionField" [matAutocomplete]="auto"
               (ngModelChange)="findInstitution($event)">
        <mat-autocomplete #auto="matAutocomplete" class="institutions-assign">
          <mat-option class="institution-select" *ngFor="let institution of institutionsFound"
                      [value]="institution?.title" (onSelectionChange)="assignToInstitution(institution)">
            {{institution?.title?.trim()}}
            <small>{{institution?.streetName?.trim()}} {{institution?.streetNo | addressNo:institution?.apartmentNo}},
              {{institution?.city?.trim()}}, {{institution?.province?.trim()}}</small>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div [ngClass]="{'institution-assigned': true, preview: !enableEdit}">
        <div *ngFor="let institution of institutionsAssign; let i = index;">
          <mat-icon *ngIf="enableEdit" (click)="removeAssigned(i)">close</mat-icon>
          {{institution?.title?.trim()}}
          <small>{{institution?.streetName?.trim()}} {{institution?.streetNo | addressNo:institution?.apartmentNo}},
            {{institution?.city?.trim()}}, {{institution?.province?.trim()}}</small>
        </div>
        <div *ngIf="!showSpinner && !institutionsAssign?.length && !enableEdit" class="center-box-container">
          {{'profile.institutions.empty' | translate}}
        </div>
      </div>
      <div class="buttons" *ngIf="enableEdit">
        <button class="cancel-button" (click)="cancel()">{{'system.label.cancel' | translate}}</button>
        <button class="submit-button" (click)="submit()">{{'system.label.save' | translate}}</button>
      </div>
    </div>
    <app-spinner *ngIf="showSpinner" class="full"></app-spinner>
  </div>
</div>
