import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ViewerConfig} from '../../../../../../../../../configs/viewer-config';
import {AbstractDialogComponent} from '../../../../../../../../../globals/dialogs/abstracts/abstract-dialog.component';
import {LinkType} from '../../../../../../interactive/api/data/points/enums/link-type.enum';
import {PointService} from '../../../../../../interactive/modules/points/api/services/point.service';
import {CustomPointType} from '../../../api/enum/custom-point-type.enum';
import {EmbedPointDialogComponent} from './parts/embed-point-dialog/embed-point-dialog.component';
import {ImagePointDialogComponent} from './parts/image-point-dialog/image-point-dialog.component';
import {TextPointDialogComponent} from './parts/text-point-dialog/text-point-dialog.component';

@Component({
  selector: 'app-dialog-point',
  templateUrl: './dialog-point.component.html',
  styleUrls: ['./dialog-point.component.scss']
})

export class DialogPointComponent extends AbstractDialogComponent implements OnInit {

  public customPointType = CustomPointType;
  public typeField: FormControl;
  public keys = Object.keys;

  private layerId: string;
  private projectId: string;
  private x: number;
  private y: number;

  public constructor(private readonly _service: PointService, private readonly _dialogRef: MatDialogRef<DialogPointComponent>,
                     @Inject(MAT_DIALOG_DATA) data: any, private readonly _dialog: MatDialog) {
    super(data);
    this.layerId = this.getValue('layerId');
    this.projectId = this.getValue('projectId');
    this.x = this.getValue('x');
    this.y = this.getValue('y');
  }

  protected createFormControls(): void {
    this.typeField = new FormControl('', [Validators.required]);
  }

  protected createForm(): void {
    this.form = new FormGroup({
      typeField: this.typeField,
    });
  }

  public onSubmit(): void {
    if (!this.form || !this.form.valid) return;
    const params = {
      layerId: this.layerId,
      projectId: this.projectId,
      x: this.x,
      y: this.y
    };
    this._dialogRef.close();

    switch (this.typeField.value) {
      case CustomPointType.text:
        this._dialog.open(TextPointDialogComponent, ViewerConfig.getPointDialogSettings(params));
        break;
      case CustomPointType.image:
        this._dialog.open(ImagePointDialogComponent, ViewerConfig.getPointDialogSettings(params));
        break;
      case CustomPointType.em_movie:
        params['type'] = [LinkType.youtube, LinkType.vimeo];
        this._dialog.open(EmbedPointDialogComponent, ViewerConfig.getPointDialogSettings(params));
        break;
      case CustomPointType.em_sound:
        params['type'] = [LinkType.soundcloud];
        this._dialog.open(EmbedPointDialogComponent, ViewerConfig.getPointDialogSettings(params));
        break;
    }

  }

  public closeDialog(): void {
    this._dialogRef.close();
  }

  public typeChange(type: CustomPointType): void {
    this.typeField.setValue(type);
  }
}
