import {JsonProperty} from '@dhkatz/json-ts';
import {ArrayNumberConverter} from '../../../../../../../globals/api/json/array-number-converter';
import {AbstractPreRenderData} from './abstract-pre-render-data';

export abstract class AbstractCombinedPreRenderData extends AbstractPreRenderData {

  @JsonProperty({name: 'i', converter: ArrayNumberConverter.converter, type: Object})
  private _index: number[];

  @JsonProperty('a')
  protected activeIndex: number;

  protected constructor() {
    super();
    this._index = undefined;
    this.activeIndex = undefined;
  }

  public get index(): number[] {
    return this._index;
  }

  public set index(value: number[]) {
    this._index = value;
  }

  public get active(): boolean {
    return this.activeIndex >= 0;
  }
}
