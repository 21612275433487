import {JsonProperty} from '@dhkatz/json-ts';
import {RegisterRequest} from '../../../authorization/api/data/register-request';

export class RegisterEvent {

  @JsonProperty('email')
  private _email: string;

  @JsonProperty('firstName')
  private _firstName: string;

  @JsonProperty('lastName')
  private _lastName: string;

  @JsonProperty('institutionName')
  private _institutionName: string;

  @JsonProperty('streetName')
  private _streetName: string;

  @JsonProperty('streetNumber')
  private _streetNumber: string;

  @JsonProperty('apartmentNumber')
  private _apartmentNumber: string;

  @JsonProperty('postCode')
  private _postCode: string;

  @JsonProperty('city')
  private _city: string;

  @JsonProperty('state')
  private _state: string;

  @JsonProperty('country')
  private _country: string;

  @JsonProperty('taxId')
  private _taxId: string;

  @JsonProperty('phoneNo')
  private _phoneNo: string;

  @JsonProperty('fromInvitation')
  private _fromInvitation: boolean;

  public constructor(request: RegisterRequest) {
    this._email = request.email;
    this._fromInvitation = request.fromInvitation;
    if (!request.addressData) return;
    this._firstName = request.addressData.firstName;
    this._lastName = request.addressData.lastName;
    this._institutionName = request.addressData.institutionName;
    this._streetName = request.addressData.streetName;
    this._streetNumber = request.addressData.streetNumber;
    this._apartmentNumber = request.addressData.apartmentNumber;
    this._postCode = request.addressData.postCode;
    this._city = request.addressData.city;
    this._state = request.addressData.state;
    this._country = request.addressData.country;
    this._taxId = request.addressData.taxId;
    this._phoneNo = request.addressData.phoneNo;
  }
}
