export enum RenderWorkerMessage {
  init = <any>'init',
  draw = <any>'draw',
  resize = <any>'resize',
  layers = <any>'layers',
  loaded = <any>'loaded',
  clear = <any>'clear',
  fonts = <any>'fonts',
  cache = <any>'cache',
  cacheSave = <any>'cacheSave',
  cacheCalc = <any>'cacheCalc',
  loadCalc = <any>'loadCalc',
  stopLoading = <any>'stopLoading'
}
