import {Size} from '../../../../../../../globals/api/data/size';
import {RectSize} from '../../../../../../../globals/elements/rect-size';
import {CanDraw} from '../../interfaces/can-draw';
import {Part} from '../../parts/interfaces/part';

export abstract class AbstractLevel<T extends Part> implements CanDraw {

  protected parts: T[];
  protected defaultSize: Size;
  private _canDraw: boolean;

  protected constructor(protected readonly sizes: Size[], protected readonly nextSizes: Size[]) {
    this.parts = [];
  }

  public get canDraw(): boolean {
    return this._canDraw;
  }

  public draw(viewRect: RectSize): void {
    if (!this.canDrawThisLevel(viewRect.width) || !this.defaultSize) return;
    const scaleRect = new RectSize(viewRect);
    scaleRect.width = viewRect.width / this.defaultSize.width;
    scaleRect.height = viewRect.height / this.defaultSize.height;
    this.parts.forEach((part: T) => part.draw(scaleRect, viewRect));
  }

  protected canDrawThisLevel(viewWidth: number): boolean {
    this.sizes.some((size, index) => {
      return this._canDraw = viewWidth <= +size.width && (this.nextSizes[index] === undefined || viewWidth > +this.nextSizes[index].width);
    });
    if (!this._canDraw) this.stopLoading();
    return this._canDraw;
  }

  protected abstract stopLoading(): void;

  public clear(): void {
    this._canDraw = false;
    this.parts.forEach((part: T) => part.clear());
  }

  public destroy(): void {
    this.parts.forEach((part: T) => part.destroy());
    this.parts = [];
  }
}
