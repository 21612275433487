import {Component} from '@angular/core';
import {ViewerConfig} from '../../../../configs/viewer-config';

@Component({
  selector: 'app-subscription-demo',
  templateUrl: './subscription-demo.component.html',
  styleUrls: ['./subscription-demo.component.scss']
})
export class SubscriptionDemoComponent {

  public readonly subscriptionsUrl = ViewerConfig.subscriptionsUrl;

  public constructor() {
  }
}
