import {HttpErrorResponse} from '@angular/common/http';
import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {PasswordUtil} from '../../../../../utils/password-util';
import {ServiceWorkerService} from '../../../../../web-workers/service/service-worker.service';
import {UpdateMyPasswordRequest} from '../../../../api/data/update-my-password-request';
import {AccountService} from '../../../../api/service/account.service';
import {AbstractAccountPart} from '../abstracts/abstract-account-part';
import {deserializeFix} from '../../../../../utils/deserialize-util';

@Component({
  selector: 'app-password-data',
  templateUrl: './password-data.component.html',
  styleUrls: ['./password-data.component.scss']
})
export class PasswordDataComponent extends AbstractAccountPart {

  public actualPasswordField: FormControl;
  public passwordField: FormControl;
  public confirmPasswordField: FormControl;
  public hideActual = true;
  public hideNew = true;

  public constructor(service: AccountService, snackBar: MatSnackBar, serviceWorker: ServiceWorkerService,
                     private readonly _translate: TranslateService) {
    super(service, snackBar, serviceWorker);
  }

  protected createForm(): void {
    this.form = new FormGroup({
      oldPassword: this.actualPasswordField,
      password: this.passwordField,
      confirmPassword: this.confirmPasswordField
    }, {
      validators: PasswordUtil.mustMatch('password', 'confirmPassword')
    });
  }

  protected createFormControls(): void {
    this.actualPasswordField = new FormControl('', [Validators.required]);
    this.passwordField = new FormControl('', [Validators.required, this.isCreatorOrHigher ?
      PasswordUtil.passwordStrength(2, 2, 2, 2, 10, 'panelWeakPassword') :
      PasswordUtil.passwordStrength(1, 1, 1, 0, 6, 'viewerWeakPassword')]);
    this.confirmPasswordField = new FormControl();
  }

  public submit(): void {
    if (this.form.invalid) return;
    this.showSpinner = true;
    this.service.changePassword(deserializeFix(UpdateMyPasswordRequest, this.form.value)).subscribe(() => {
      this.cancel();
      ViewerConfig.openSnackBar(this.snackBar, this._translate.instant('profile.password.successToast'));
    }, (error: HttpErrorResponse) => {
      switch (error.error.key) {
        case 'incorrect_password':
          this.actualPasswordField.setErrors({password: true});
          break;
        case 'weak_password':
          this.passwordField.setErrors({weakPassword: true});
          break;
      }
      this.showSpinner = false;
    }, () => this.showSpinner = false);
  }

  public cancel(): void {
    this.form.reset();
    this.enableEdit = false;
  }

  public generatePassword(): void {
    const password = this.isCreatorOrHigher ?
      PasswordUtil.generatePassword(2, 2, 2, 2, 10, 20) :
      PasswordUtil.generatePassword(1, 1, 1, 1, 6, 15);
    this.passwordField.setValue(password);
    this.confirmPasswordField.setValue(password);
  }
}
