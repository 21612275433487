export class BrowserUtil {

  private static readonly ASYNC_LOAD = ('createImageBitmap' in self);

  private static _asyncRender = true;
  private static _info: any;

  public static init(info: any): void {
    this._info = info;
    this.setAsyncRender();
    this.setCss();
  }

  private static setAsyncRender(): void {
    if (navigator.hardwareConcurrency < 4) {
      this._asyncRender = false;
      return;
    }
    const canvas = document.createElement('canvas');
    try {
      (canvas as any).transferControlToOffscreen().getContext('2d');
      this._asyncRender = true;
    } catch (e) {
      this._asyncRender = false;
    }
    canvas.remove();
  }

  private static setCss(): void {
    const classList = document.body.classList;
    classList.add(this._info.browser.toLowerCase());
    classList.add(this._info.os.toLowerCase());
  }

  public static get asyncRender(): boolean {
    return this._asyncRender;
  }

  public static get asyncLoad(): boolean {
    return this.ASYNC_LOAD;
  }

}
