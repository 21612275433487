import {JsonProperty} from '@dhkatz/json-ts';
import {ArrayStringConverter} from '../../../globals/api/json/array-string-converter';
import {AddressData} from './address-data';

export class AddressDataRequest extends AddressData {

  @JsonProperty({name: 'institutions', converter: ArrayStringConverter.converter, type: Object})
  private _institutions: string[];

  public constructor() {
    super();
    this._institutions = undefined;
  }

  public get institutions(): string[] {
    return this._institutions;
  }

  public set institutions(value: string[]) {
    this._institutions = value;
  }
}
