import {JsonProperty} from '@dhkatz/json-ts';
import {Gradient} from '../models/gradient';
import {DrawElement} from './draw-element';

export class VectorFillElement extends DrawElement {

  @JsonProperty('fS')
  private _fillStyle: string;

  @JsonProperty({name: 'fG', type: Gradient})
  private _fillGradient: Gradient;

  protected constructor() {
    super();
    this._fillStyle = undefined;
    this._fillGradient = undefined;
  }

  public get fillStyle(): string {
    return this._fillStyle;
  }

  public get fillGradient(): Gradient {
    return this._fillGradient;
  }
}
