import {RectSize} from '../../../../../globals/elements/rect-size';
import {CacheRequest} from '../../../../../utils/models/cache-request';
import {LoadingProgressService} from '../../../screens/version/components/loading-progress/service/loading-progress.service';
import {LayerResponse} from '../api/data/layer-response';
import {VectorLayerResponse} from '../api/data/layer-response/layers/vector-layer-response';
import {AbstractLayersConstructor} from './abstracts/abstract-layers-constructor';
import {CacheConstructor} from './cache-constructor';
import {PreRenderConstructor} from './pre-render-constructor';

export class LayersConstructor extends AbstractLayersConstructor<PreRenderConstructor, CacheConstructor> {

  public constructor(id: string, version: number, data: LayerResponse, container: HTMLElement, canvas: HTMLCanvasElement,
                     loading: LoadingProgressService, private readonly _isOffline: boolean) {
    super(id, version, data, container, canvas, loading);
  }

  protected setConstructor(data: LayerResponse, cacheRequest: CacheRequest, canvas: HTMLCanvasElement): void {
    this.construct = new PreRenderConstructor(data, cacheRequest, canvas, this, this._isOffline);
    this.setDisabledLayers();
  }

  protected setCacheLayer(cacheRequest: CacheRequest, layer: VectorLayerResponse): void {
    this.cacheConstructors.push(new CacheConstructor(cacheRequest, layer, this.levelSizes, this.partSize, this, this._isOffline));
  }

  public draw(): void {
    if (!this.construct) return;
    this.construct.draw(new RectSize(this.viewRect));
  }

  public setDisabledLayers(layers?: boolean[]): void {
    if (layers) this.disabledLayers = layers;
    else layers = this.disabledLayers;
    if (!this.construct) return;
    this.construct.setDisabledLayers(layers);
  }

  public resize(width: number, height: number): void {
    if (!this.construct) return;
    this.construct.resize(width, height);
  }

  protected fullCacheComplete(): void {
    super.fullCacheComplete();
    this.construct.ready = true;
    this.draw();
  }

  public destroy(): void {
    if (this.cacheConstructors.length) this.cacheConstructors.forEach((cache: CacheConstructor) => cache.destroy());
    if (this.construct) this.construct.destroy();
    this.cacheConstructors = undefined;
    this.construct = undefined;
  }
}
