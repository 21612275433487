import {ICustomConverter} from '@dhkatz/json-ts';

export class EnumConverter<T> implements ICustomConverter<T> {

  public static converter<T>(type: T): EnumConverter<T> {
    return new EnumConverter<T>(type);
  }

  private constructor(private readonly _type: T) {
  }

  public fromJson(data: string): T {
    return data === undefined ? undefined : this._type[data];
  }

  public toJson(data: T): string {
    return data === undefined ? undefined : data.toString();
  }
}
