import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Role} from '../../../authorization/api/data/enums/role.enum';
import {AbstractRoleComponent} from '../../../globals/screens/abstracts/abstract-role.component';
import {SecurityWorkerLogin} from '../../../web-workers/service/interfaces/security-worker-login';
import {SecurityWorkerLogout} from '../../../web-workers/service/interfaces/security-worker-logout';
import {ServiceWorkerCommunicationService} from '../../../web-workers/service/service-worker-communication.service';
import {ServiceWorkerService} from '../../../web-workers/service/service-worker.service';
import {ApprovalResponse} from '../../api/data/approval-response';
import {AccountService} from '../../api/service/account.service';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss']
})
export class ApprovalsComponent extends AbstractRoleComponent implements OnInit, OnDestroy, SecurityWorkerLogin, SecurityWorkerLogout {

  @Input()
  public showApprovals: boolean;

  @Output()
  public showApprovalsChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public requiredApprovals: ApprovalResponse[] = [];
  public optionalApprovals: ApprovalResponse[] = [];
  public showSpinner: boolean;

  private _form: FormGroup;

  public constructor(private readonly service: AccountService, private readonly _workerCommunication: ServiceWorkerCommunicationService,
                     workerService: ServiceWorkerService) {
    super(workerService);
    this._workerCommunication.addSecurityWorkerLoginListener(this);
    this._workerCommunication.addSecurityWorkerLogoutListener(this);
  }

  public onSecurityWorkerLogin(email?: string, role?: Role): void {
    this.getApprovals();
  }

  public ngOnInit(): void {
    if (this.isLoggedIn) this.getApprovals();
  }

  private getApprovals(): void {
    this.service.getApprovals().subscribe((response: ApprovalResponse[]) => {
      if (!response || !response.length) return;
      this._form = new FormGroup({});
      this.requiredApprovals = [];
      this.optionalApprovals = [];
      response.forEach((approval: ApprovalResponse) => {
        if (approval.required) {
          approval.field = new FormControl(undefined, [Validators.required]);
          this.requiredApprovals.push(approval);
        } else {
          approval.field = new FormControl();
          this.optionalApprovals.push(approval);
        }
        this._form.addControl(approval.type, approval.field);
        if (this.requiredApprovals.length) this.setShowApprovals(true);
      });
    });
  }

  private setShowApprovals(value: boolean): void {
    if (this.isCreatorOrHigher) return;
    this.showApprovals = value;
    this.showApprovalsChange.emit(value);
  }

  public onSecurityWorkerLogout(): void {
    this._form = undefined;
    this.requiredApprovals = [];
    this.optionalApprovals = [];
    this.setShowApprovals(false);
  }

  public ngOnDestroy(): void {
    this._workerCommunication.removeSecurityWorkerLoginListener(this);
    this._workerCommunication.removeSecurityWorkerLogoutListener(this);
  }

  public onSubmit(): void {
    if (this._form.invalid) return;
    this.showSpinner = true;
    const request = [];
    Object.keys(this._form.value).forEach((param: string) => {
      if (this._form.value[param]) request.push(param);
    });
    this.service.setApprovals(request).subscribe(() => this.onSecurityWorkerLogout(),
      () => this.showSpinner = false, () => this.showSpinner = false);
  }
}
