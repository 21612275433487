import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize, serialize} from '@dhkatz/json-ts';
import {JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {InteractiveResponse} from '../../../../../interactive/api/data/interactive-response';
import {CustomInteractiveEndpoints} from '../custom-interactive-endpoints';
import {CustomInteractiveRequest} from '../data/custom-interactive-request';
import {deserializeFix} from '../../../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class CustomInteractiveService {
  public constructor(private readonly _http: HttpClient) { //
  }

  public getLayersList(projectId: string): Observable<InteractiveResponse> {
    return this._http.get<JsonObject>(CustomInteractiveEndpoints.CUSTOM_INTERACTIVES
      .replace(CustomInteractiveEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonObject) => deserializeFix(InteractiveResponse, response)));
  }

  public addLayer(projectId: string, request: CustomInteractiveRequest): Observable<string> {
    return this._http.post<string>(CustomInteractiveEndpoints.CUSTOM_INTERACTIVE_NEW
      .replace(CustomInteractiveEndpoints.PROJECT_ID, projectId), serialize(request));
  }

  public removeLayer(projectId: string, layerId: string): Observable<string> {
    return this._http.delete<string>(CustomInteractiveEndpoints.CUSTOM_INTERACTIVE
      .replace(CustomInteractiveEndpoints.PROJECT_ID, projectId).replace(CustomInteractiveEndpoints.LAYER_ID, layerId));
  }

  public updateWeight(projectId: string, layerId: string, weight: number): Observable<void> {
    return this._http.put<void>(CustomInteractiveEndpoints.CUSTOM_INTERACTIVE_WEIGHT
      .replace(CustomInteractiveEndpoints.PROJECT_ID, projectId).replace(CustomInteractiveEndpoints.LAYER_ID, layerId), weight);
  }

  public updateEnabled(projectId: string, layerId: string, enabled: boolean): Observable<void> {
    return this._http.put<void>(CustomInteractiveEndpoints.CUSTOM_INTERACTIVE_ENABLED
      .replace(CustomInteractiveEndpoints.PROJECT_ID, projectId).replace(CustomInteractiveEndpoints.LAYER_ID, layerId), String(enabled));
  }

  public updateLayer(projectId: string, layerId: string, request: CustomInteractiveRequest): Observable<string> {
    return this._http.put<string>(CustomInteractiveEndpoints.CUSTOM_INTERACTIVE
      .replace(CustomInteractiveEndpoints.PROJECT_ID, projectId).replace(CustomInteractiveEndpoints.LAYER_ID, layerId), serialize(request));
  }
}
