import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MessagesService} from '../../../../api/service/messages.service';
import {MessageResponse} from '../../../../api/data/message-response';
import {MessagesCommunicationService} from '../../../../communication/messages-communication.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnChanges {

  @Input()
  public selected: MessageResponse;

  @Output()
  public readChanged: EventEmitter<void> = new EventEmitter<void>();

  public message: string;
  public showSpinner: boolean;

  public constructor(private readonly _service: MessagesService, private readonly _communication: MessagesCommunicationService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.selected) return;
    this.message = undefined;
    this.showSpinner = true;
    this._service.getContent(this.selected.id).subscribe((response: string) => {
      this.message = response;
      // if (this.selected.read) return;
      this.selected.read = true;
      this.readChanged.emit();
      this._communication.callOnMessageChanged();
    }, () => this.showSpinner = false, () => this.showSpinner = false);
  }
}
