import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthorizationService} from '../../../../api/services/authorization.service';
import {AbstractAuthorizationComponent} from '../abstracts/abstract-authorization.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends AbstractAuthorizationComponent {

  @Input()
  public email = '';

  public emailField: FormControl;
  public showConfirm: boolean;

  private _form: FormGroup;

  public constructor(private _service: AuthorizationService) {
    super();
  }

  protected createFormControls(): void {
    this.emailField = new FormControl(this.email, [Validators.required, Validators.email]);
    if (this.email) this.emailField.disable();
  }

  protected createForm(): void {
    this._form = new FormGroup({
      email: this.emailField
    });
  }

  public onSubmit(): void {
    if (this._form.invalid) return;
    this.changeShowSpinner(true);
    this._service.resetPassword(this.emailField.value).subscribe(() => this.showConfirm = true,
      (error: HttpErrorResponse) => {
        this.changeShowSpinner(false);
        this.error.emit(error.error);
      }, () => this.changeShowSpinner(false));
  }
}
