import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {ServiceWorkerService} from '../../../../../web-workers/service/service-worker.service';
import {UpdateMyEmailRequest} from '../../../../api/data/update-my-email-request';
import {AccountService} from '../../../../api/service/account.service';
import {AbstractAccountPart} from '../abstracts/abstract-account-part';
import {deserializeFix} from '../../../../../utils/deserialize-util';

@Component({
  selector: 'app-email-data',
  templateUrl: './email-data.component.html',
  styleUrls: ['./email-data.component.scss']
})
export class EmailDataComponent extends AbstractAccountPart {

  @Input()
  public email: string;

  public emailField: FormControl;
  public passwordField: FormControl;
  public hide = true;

  public constructor(service: AccountService, snackBar: MatSnackBar, serviceWorker: ServiceWorkerService,
                     private readonly _translate: TranslateService) {
    super(service, snackBar, serviceWorker);
  }

  protected createForm(): void {
    this.form = new FormGroup({
      email: this.emailField,
      password: this.passwordField
    });
  }

  protected createFormControls(): void {
    this.emailField = new FormControl(this.email, [Validators.required, Validators.email]);
    this.passwordField = new FormControl('', [Validators.required]);
  }

  public submit(): void {
    if (this.emailField.value === this.email) {
      this.cancel();
      return;
    }
    if (this.form.invalid) return;
    this.showSpinner = true;
    this.service.changeEmail(deserializeFix(UpdateMyEmailRequest, this.form.value)).subscribe(() => {
      ViewerConfig.openSnackBar(this.snackBar,
        this._translate.instant('profile.email.successToast', {email: this.emailField.value}));
      this.cancel();
    }, (error: HttpErrorResponse) => {
      switch (error.error.key) {
        case 'incorrect_password':
          this.passwordField.setErrors({password: true});
          break;
        case 'email_exists':
          this.emailField.setErrors({emailExists: true});
          break;
        case 'invalid_email':
          this.emailField.setErrors({email: true});
          break;
      }
      this.showSpinner = false;
    }, () => this.showSpinner = false);
  }

  public cancel(): void {
    this.form.reset();
    this.emailField.setValue(this.email);
    this.enableEdit = false;
  }
}
