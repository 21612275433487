import {JsonProperty} from '@dhkatz/json-ts';
import {TitleItem} from '../../../globals/api/data/title-item';

export class CategoryResponse extends TitleItem {

  @JsonProperty('childes')
  private _childes: boolean;

  public constructor() {
    super();
    this._childes = undefined;
  }

  public get childes(): boolean {
    return this._childes;
  }
}
