<div class="messages-icon" [matBadge]="unreadMessages" matBadgePosition="below before" matBadgeColor="warn"
     [matBadgeHidden]="(unreadMessages === 0)" [matMenuTriggerFor]="messagesMenu" (menuOpened)="getMessages()">
  <img src="/assets/images/messages_icon.png"
       srcset="/assets/images/messages_icon.png 1x, /assets/images/messages_icon_2x.png 2x">
</div>
<mat-menu class="top-menu-expanded" #messagesMenu="matMenu" [overlapTrigger]="false">
  <h3 class="messages-menu-title">{{'messages.title' | translate}}</h3>
  <div class="message-container">
    <app-spinner *ngIf="showSpinner"></app-spinner>
    <p *ngIf="!showSpinner && !messages?.length" class="error-info">{{'messages.error.noMessages' | translate}}</p>
    <div [ngClass]="{'messages-menu': true, unread: !message?.read}" *ngFor="let message of messages" [innerHTML]="message?.title"
         (click)="goToMessages(message?.id)">
    </div>
  </div>
  <button class="button-read-more" (click)="goToMessages()">{{'messages.more' | translate}}</button>
</mat-menu>
