import {JsonProperty} from '@dhkatz/json-ts';
import {ArrayNumberConverter} from '../../../../../../../../globals/api/json/array-number-converter';
import {EnumConverter} from '../../../../../../../../globals/api/json/enum-converter';
import {MapNumberStringConverter} from '../../../json/map-number-string-converter';
import {GradientType} from '../enums/gradient-type.enum';

export class Gradient {

  @JsonProperty({name: 't', converter: EnumConverter.converter(GradientType)})
  private _type: GradientType;

  @JsonProperty({name: 'd', converter: ArrayNumberConverter.converter, type: Object})
  private _draw: number[];

  @JsonProperty({name: 'c', converter: MapNumberStringConverter.converter})
  private _colors: Map<number, string>;

  public constructor() {
    this._type = undefined;
    this._draw = undefined;
    this._colors = undefined;
  }

  public get type(): GradientType {
    return this._type;
  }

  public get draw(): number[] {
    return this._draw;
  }

  public get colors(): Map<number, string> {
    return this._colors;
  }
}
