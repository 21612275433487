import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractLevelResponse} from './abstracts/abstract-level-response';

export class VectorLevelResponse extends AbstractLevelResponse {

  @JsonProperty('d')
  private _data: string;

  public constructor() {
    super();
    this._data = undefined;
  }

  public get data(): string {
    return this._data;
  }
}
