import {JsonProperty} from '@dhkatz/json-ts';
import {VectorFillBorderElement} from '../abstracts/vector-fill-border-element';
import {VectorStrokeElement} from '../interfaces/vector-stroke-element';
import {DrawPath} from './draw-path';

export class Path extends VectorFillBorderElement implements VectorStrokeElement {

  @JsonProperty('mL')
  private _miterLimit: number;

  @JsonProperty({name: 'd', type: DrawPath})
  private _draw: DrawPath[];

  public constructor() {
    super();
    this._miterLimit = undefined;
    this._draw = undefined;
  }

  public get miterLimit(): number {
    return this._miterLimit;
  }

  public get draw(): DrawPath[] {
    return this._draw;
  }
}
