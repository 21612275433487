import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ViewerConfig} from '../../../../../../../../../../../configs/viewer-config';
import {PointType} from '../../../../../../../../interactive/api/data/points/enums/point-type.enum';
import {ImagePointResponse} from '../../../../../../../../interactive/api/data/points/image-point-response';
import {ImagePointRequest} from '../../../../../../../../interactive/modules/points/api/data/image-point-request';
import {PointService} from '../../../../../../../../interactive/modules/points/api/services/point.service';
import {CustomInteractivesCommunicationService} from '../../../../../communiaction/custom-interactives-communication.service';
import {ImagePointFileResponse} from '../../../../../modules/custom-images/api/data/image-point-file-response';
import {ImagePointFileService} from '../../../../../modules/custom-images/api/service/image-point-file.service';
import {AbstractCustomPointComponent} from '../abstracts/abstract-custom-point.component';
import {ChooseImageDialogComponent} from './dialog/choose-image-dialog/choose-image-dialog.component';

@Component({
  selector: 'app-image-point-dialog',
  templateUrl: './image-point-dialog.component.html',
  styleUrls: ['./image-point-dialog.component.scss']
})
export class ImagePointDialogComponent extends AbstractCustomPointComponent<ImagePointRequest, ImagePointResponse> implements OnInit {

  public file: ImagePointFileResponse;
  public noImageError = false;
  public removePoint = false;

  public constructor(private readonly _fileService: ImagePointFileService, private readonly _dialog: MatDialog, service: PointService,
                     communication: CustomInteractivesCommunicationService, @Inject(MAT_DIALOG_DATA) data: any,
                     dialogRef: MatDialogRef<ImagePointDialogComponent>) {
    super(service, communication, dialogRef, data);
    this.pointType = PointType.image;
    if (this.point) this._fileService.getImage(this.point.fileId).subscribe((file: ImagePointFileResponse) => {
      if (file) this.file = file;
    });
  }

  protected getRequest(): ImagePointRequest {
    const request = new ImagePointRequest();
    request.file = this.file.id;
    return request;
  }

  public chooseFile(): void {
    this._dialog.open(ChooseImageDialogComponent, ViewerConfig.getPointDialogSettings({
      file: this.file ? this.file.id : undefined
    })).afterClosed().subscribe((file: ImagePointFileResponse) => {
      if (!file) this.file = undefined;
      this.file = file;
      this.noImageError = false;
    });
  }

  public onSubmit(): void {
    if (!this.file) {
      this.noImageError = true;
      return;
    }
    super.onSubmit();
  }
}
