<header mat-dialog-title class="add-point-header">
  <ng-container *ngIf="!point">
    {{'project.interactive.points.title.add' | translate}}
  </ng-container>
  <ng-container *ngIf="point">
    {{'project.interactive.points.title.edit' | translate}}
  </ng-container>
  {{'project.interactive.points.title.textPoint' | translate}}
  <span class="close" (click)="closeDialog()"><mat-icon>close</mat-icon></span>
</header>
<mat-dialog-content class="add-point-container" *ngIf="!removePoint">
  <form>
    <app-icon [(icon)]="iconId" [error]="iconError"></app-icon>

    <label class="field-label">{{'project.interactive.points.labels.title' | translate}}</label>
    <ck-editor [config]="basicWysiwygConfig" [formControl]="titleField" skin="moono-lisa"
               [language]="'system.langCode' | translate" [fullPage]="false"></ck-editor>
    <mat-error *ngIf="titleField.hasError('required')">
      {{'project.interactive.points.error.titleIsRequired' | translate}}
    </mat-error>
    <label class="field-label">{{'project.interactive.points.labels.description' | translate}}</label>
    <ck-editor skin="moono-lisa" [config]="wysiwygConfig" [formControl]="descriptionField"
               [language]="'system.langCode' | translate" [fullPage]="false"></ck-editor>
    <mat-error *ngIf="descriptionField.hasError('required')">
      {{'project.interactive.points.error.descriptionIsRequired' | translate}}
    </mat-error>
    <mat-accordion class="add-point-text-more">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'project.interactive.points.info.moreFields' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field>
          <input placeholder="{{'project.interactive.points.labels.source' | translate}}" matInput
                 [formControl]="sourceField">
        </mat-form-field>
        <mat-form-field>
          <input placeholder="{{'project.interactive.points.labels.sourceLink' | translate}}" matInput [formControl]="sourceUrlField">
        </mat-form-field>
        <mat-form-field>
          <input placeholder="{{'project.interactive.points.labels.copyrights' | translate}}" matInput [formControl]="copyrightsField">
        </mat-form-field>
        <mat-form-field>
          <input placeholder="{{'project.interactive.points.labels.copyrightsLink' | translate}}" matInput [formControl]="copyrightsUrlField">
        </mat-form-field>

      </mat-expansion-panel>
    </mat-accordion>

    <footer class="buttons interactive-points-footer ">
      <button *ngIf="point" mat-button class="cancel-button" (click)="removePoint = true">
        {{'project.interactive.points.button.delete' | translate}}
      </button>
      <button mat-button class="submit-button" (click)="onSubmit()">
        <ng-container *ngIf="!point">
          {{'project.interactive.points.button.add' | translate}}
        </ng-container>
        <ng-container *ngIf="point">
          {{'project.interactive.points.button.save' | translate}}
        </ng-container>
      </button>
    </footer>
  </form>
</mat-dialog-content>
<app-point-remove *ngIf="removePoint" (close)="removePoint = false" [projectId]="projectId" [layerId]="layerId"
                  [point]="point"></app-point-remove>

