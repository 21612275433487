import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ErrorResponse} from '../../api/data/error-response';

@Component({
  selector: 'app-response-error-dialog',
  templateUrl: './response-error.component.html',
  styleUrls: ['./response-error.component.scss']
})
export class ResponseErrorComponent {

  public title: string;
  public description: string;

  public constructor(@Inject(MAT_DIALOG_DATA) public readonly data: ErrorResponse, private readonly _translate: TranslateService) {
    if (!data) {
      this.title = this._translate.instant('system.responseError.error.unknown.title');
      this.description = this._translate.instant('system.responseError.error.unknown.description');
      return;
    }
    this.title = this.getTranslation('system.responseError.error.' + data.key + '.title', data.error,
      'system.responseError.error.unknown.title');
    this.description = this.getTranslation('system.responseError.error.' + data.key + '.description', data.message,
      'system.responseError.error.unknown.description');
  }

  private getTranslation(key: string, serverMessage: string, defaultKey: string): string {
    let text = this._translate.instant(key);
    if (!text || text === key) text = serverMessage;
    return text || this._translate.instant(defaultKey);
  }
}
