<mat-card>
  <mat-card-title></mat-card-title>
  <div [ngClass]="{'single-data': true, 'pro': isInstitution, 'single-data-name': true}">
    <p class="data-label">{{'subscriptions.list.name' | translate}}</p>
    <p class="data">{{name}}</p>
  </div>
  <div [ngClass]="{'single-data': true, 'pro': isInstitution, 'single-data-type': true}">
    <p class="data-label">{{'subscriptions.list.type' | translate}}</p>
    <p [ngClass]="['data']">
      {{'subscriptions.list.types.' + type?.toString().toLocaleLowerCase() | translate}}
    </p>
  </div>
  <div [ngClass]="{'single-data': true, 'pro': isInstitution, 'single-data-status': true}">
    <p class="data-label">{{'subscriptions.list.status' | translate}}</p>
    <p [ngClass]="['data', status?.toString().toLocaleLowerCase()]">
      {{'subscriptions.list.statuses.' + status?.toString().toLocaleLowerCase() | translate}}
    </p>
  </div>
  <div [ngClass]="{'single-data': true, 'pro': isInstitution, 'single-data-expired': true}">
    <p class="data-label">{{'subscriptions.list.expired' | translate}}</p>
    <p class="data">{{startDate.toLocaleDateString()}} -
      <ng-container *ngIf="unlimited">{{'subscriptions.list.unlimited' | translate}}</ng-container>
      <ng-container *ngIf="!unlimited">{{expirationDate?.toLocaleDateString()}}</ng-container>
    </p>
  </div>
  <div *ngIf="isInstitution" [ngClass]="{'single-data': true, 'pro': isInstitution, 'single-data-plus': true}">
    <p class="data-label">{{'subscriptions.list.schoolPlus' | translate}}</p>
    <p class="data" *ngIf="schoolPlus">{{'system.label.yes' | translate}}</p>
    <p class="data" *ngIf="!schoolPlus">{{'system.label.no' | translate}}</p>
  </div>
  <div [ngClass]="{'single-data': true, 'demo': true, 'pro': isInstitution, 'single-data-demo': true}">
    <p class="data-label">{{'subscriptions.list.demo' | translate}}</p>
    <p class="data" *ngIf="demo">{{'system.label.yes' | translate}}</p>
    <p class="data" *ngIf="!demo">{{'system.label.no' | translate}}</p>
  </div>
  <div [ngClass]="{'single-data': true, 'pro': isInstitution, 'single-data-scope': true}">
    <p class="data-label">{{'subscriptions.list.titles' | translate}}</p>
    <p class="data">{{scope}}</p>
  </div>
</mat-card>
