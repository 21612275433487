import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {VideoService} from '../../../api/services/video.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnChanges {

  @Input()
  public projectId: string;

  public movie: string;

  public constructor(private _service: VideoService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.movie = undefined;
    if (!this.projectId) return;
    this._service.getVideo(this.projectId).subscribe((response: string) => this.movie = response);
  }
}
