import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {SubscriptionAccessService} from '../../../../../subscriptions/services/subscription-access.service';
import {ServiceWorkerService} from '../../../../../web-workers/service/service-worker.service';
import {ProjectResponse} from '../../../../api/data/project-response';
import {CarriageCommunicationService} from '../../communication/carriage-communication.service';
import {CarriageInfo} from '../../communication/data/carriage-info';
import {OnCarriageEvent} from '../../communication/interfaces/on-carriage-event';

@Component({
  selector: 'app-list-element',
  templateUrl: './list-element.component.html',
  styleUrls: ['./list-element.component.scss']
})
export class ListElementComponent implements OnInit, OnDestroy, OnCarriageEvent {

  @Input()
  public project: ProjectResponse;

  @Input()
  public index: number;

  @Input()
  public carriageIndex: number;

  @Input()
  public categoryId: string;

  @Input()
  public inDialog = false;

  @Input()
  public isAvailable;

  @Output()
  public closeDialog: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public scroll: EventEmitter<void> = new EventEmitter<void>();

  public info = new CarriageInfo();

  private _startTouch: TouchEvent;

  public constructor(private readonly _router: Router, private readonly _communication: CarriageCommunicationService,
                     private readonly _worker: ServiceWorkerService, private readonly _dialog: MatDialog,
                     private readonly _subscriptionAccess: SubscriptionAccessService) {
  }

  public ngOnInit(): void {
    if (this.inDialog) this._communication.addOnCarriageDialogEventListener(this);
    else this._communication.addOnCarriageEventListener(this);
  }

  public onCarriageEventChange(info: CarriageInfo): void {
    this.info = info;
  }

  public setSelected(): void {
    this.setEvent();
    this.scroll.emit();
  }

  @HostListener('touchstart', ['$event'])
  private touchStart(event: any): void {
    this._startTouch = event;
  }

  @HostListener('touchend', ['$event'])
  private touchEnd(event: any): void {
    if (event.cancelable) event.preventDefault();
    const startTouch = this._startTouch;
    this._startTouch = undefined;
    if (!startTouch || event.timeStamp - startTouch.timeStamp > 500) return;
    const moveX = Math.abs(startTouch.changedTouches.item(0).clientX - event.changedTouches.item(0).clientX);
    const moveY = Math.abs(startTouch.changedTouches.item(0).clientY - event.changedTouches.item(0).clientY);
    if (moveX < 10 && moveY < 10) this.setSelected();
  }

  private setEvent(): void {
    this.info.carriage = this.carriageIndex;
    this.info.element = this.index;

    if (this.inDialog) this._communication.onCarriageDialogEventChange(this.info);
    else this._communication.onCarriageEventChange(this.info);
  }

  public isThis(): boolean {
    return this.info.element === this.index && this.info.carriage === this.carriageIndex;
  }

  public ngOnDestroy(): void {
    this._communication.removeOnCarriageEventListener(this);
    this._communication.removeOnCarriageDialogEventListener(this);
  }
}
