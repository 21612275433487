import {Size} from '../../../../../../globals/api/data/size';
import {AbstractImageElement} from './abstracts/abstract-image-element';

export class PreRenderElement extends AbstractImageElement {

  public constructor(col: number, row: number, partSize: Size, width: number, height: number) {
    super(col, row, partSize);
    this.width = width;
    this.height = height;
  }
}
