import {Component, Input} from '@angular/core';
import {AbstractRoleComponent} from '../../../../../globals/screens/abstracts/abstract-role.component';
import {ServiceWorkerService} from '../../../../../web-workers/service/service-worker.service';
import {SubscriptionStatus} from '../../../../api/data/enums/subscription-status';
import {SubscriptionType} from "../../../../api/data/enums/subscription-type";

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent extends AbstractRoleComponent {

  @Input()
  public id: string;

  @Input()
  public name: string;

  @Input()
  public type: SubscriptionType;

  @Input()
  public status: SubscriptionStatus;

  @Input()
  public unlimited: boolean;

  @Input()
  public startDate: Date;

  @Input()
  public expirationDate: Date;

  @Input()
  public demo: boolean;

  @Input()
  public schoolPlus: boolean;

  @Input()
  public scope: number;

  public constructor(workerService: ServiceWorkerService) {
    super(workerService);
  }
}
