import {Component} from '@angular/core';
import {ViewerConfig} from '../../../../configs/viewer-config';

@Component({
  selector: 'app-subscription-no-access',
  templateUrl: './subscription-no-access.component.html',
  styleUrls: ['./subscription-no-access.component.scss']
})
export class SubscriptionNoAccessComponent {

  public readonly subscriptionsUrl = ViewerConfig.subscriptionsUrl;

  public constructor() {
  }
}
