import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'translateSplit'
})
export class TranslateSplitPipe implements PipeTransform {

  public transform(value: any, index: number): any {
    return value.split('|')[index];
  }
}
