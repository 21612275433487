import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatExpansionPanel} from '@angular/material/expansion';
import {DragulaService} from 'ng2-dragula';
import {Subscription} from 'rxjs/internal/Subscription';
import {OfflineUtil} from '../../../../../../../../../utils/offline-util';
import {CustomTaskResponse} from '../../../../../api/data/custom-task-response';
import {TaskType} from '../../../../../api/data/enums/task-type.enum';
import {TasksService} from '../../../../../api/service/tasks.service';
import {TasksEndpoints} from '../../../../../api/tasks-endpoints';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})
export class TasksListComponent implements OnDestroy {

  @Input()
  public init: boolean;

  @Input()
  public projectId: string;

  @Input()
  public isOffline: string;

  @Input()
  public tasks: CustomTaskResponse[];

  @Output()
  public tasksChange = new EventEmitter<CustomTaskResponse[]>();

  @Output()
  public update = new EventEmitter<CustomTaskResponse>();

  @Output()
  public remove = new EventEmitter<CustomTaskResponse>();

  public type = TaskType;
  private _draggedSubscriber = new Subscription();

  public constructor(private readonly _drag: DragulaService, private readonly _service: TasksService) {
    this._drag.createGroup('tasks-bag', {});
    this._draggedSubscriber.add(this._drag.dropModel('tasks-bag')
      .subscribe(({targetModel, item}) => this.onWeightChange(targetModel, item)));
  }

  private onWeightChange(targetModel: CustomTaskResponse[], task: CustomTaskResponse): void {
    if (!this.projectId) return;
    this._service.updateCustomTaskWeight(this.projectId, task.id, targetModel.indexOf(task)).subscribe(() => {
      this._service.getCustomTasks(this.projectId).subscribe(() => {
        if (this.isOffline) {
          OfflineUtil.downloadFile(this.projectId, TasksEndpoints.PROJECT_CUSTOM_TASKS.replace(TasksEndpoints.PROJECT_ID, this.projectId),
            () => {
            }, () => {
            });
        }
        this.tasksChange.emit(targetModel);
      });
    });
  }

  public ngOnDestroy(): void {
    this._drag.destroy('tasks-bag');
  }

  public expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
    if (this.isExpansionIndicator(event.target as HTMLElement)) return;
    event.stopPropagation();
    matExpansionPanel.toggle();
  }

  private isExpansionIndicator(target: HTMLElement): boolean {
    if (!target) return true;
    if (target.classList.contains('unclickable')) return false;
    return this.isExpansionIndicator(target.parentElement);
  }

  public enableChanged(task: CustomTaskResponse, event: MatCheckboxChange): void {
    this._service.updateCustomTaskEnabled(this.projectId, task.id, event.checked).subscribe(() => {
      this._service.getCustomTasks(this.projectId).subscribe(() => {
        task.enabled = event.checked;
      });
    });
  }
}
