<img *ngIf="!workerService.isLoggedIn" class="user-icon" src="/assets/images/profile_menu.png"
     [matMenuTriggerFor]="userMenu"
     srcset="/assets/images/profile_menu.png 1x, /assets/images/profile_menu_2x.png 2x">
<img *ngIf="workerService.isLoggedIn" class="user-icon" src="/assets/images/profile_menu_on.png"
     [matMenuTriggerFor]="userMenu"
     srcset="/assets/images/profile_menu_on.png 1x, /assets/images/profile_menu_on_2x.png 2x">
<mat-menu class="top-menu-expanded" #userMenu="matMenu" [overlapTrigger]="false">
  <button *ngIf="workerService.isLoggedIn" mat-menu-item class="mat-button" (click)="goToAccount()">
    <img width="24" height="24" src="/assets/images/profile.png"
         srcset="/assets/images/profile.png 1x, /assets/images/profile_2x.png 2x">
    <span>{{'system.userMenu.profile' | translate}}</span>
  </button>
  <button *ngIf="workerService.isLoggedIn && isInstitutionOrLower && !isAgent" mat-menu-item class="mat-button"
          (click)="goToAccountManage()">
    <img width="24" height="24" src="/assets/images/manage.png"
         srcset="/assets/images/manage.png 1x, /assets/images/manage_2x.png 2x">
    <span>{{'system.userMenu.management' | translate}}</span>
  </button>
  <button *ngIf="workerService.isLoggedIn && isInstitutionOrLower" mat-menu-item class="mat-button"
          (click)="goToAccountSubscriptions()">
    <img width="24" height="24" src="/assets/images/subscriptions.png"
         srcset="/assets/images/subscriptions.png 1x, /assets/images/subscriptions_2x.png 2x">
    <span>{{'system.userMenu.subscriptions' | translate}}</span>
  </button>
  <button *ngIf="workerService.isLoggedIn" mat-menu-item class="mat-button" (click)="logout()">
    <img width="24" height="24" src="/assets/images/logout.png"
         srcset="/assets/images/logout.png 1x, /assets/images/logout_2x.png 2x">
    <span>{{'system.userMenu.logout' | translate}}</span>
  </button>
  <button *ngIf="!workerService.isLoggedIn" mat-menu-item class="mat-button" (click)="register()" [disabled]="disabled">
    <img width="24" height="24" src="/assets/images/register.png"
         srcset="/assets/images/register.png 1x, /assets/images/register_2x.png 2x">
    <span>{{'system.userMenu.register' | translate}}</span>
  </button>
  <button *ngIf="!workerService.isLoggedIn" mat-menu-item class="mat-button" (click)="login()" [disabled]="disabled">
    <img width="24" height="24" src="/assets/images/login.png"
         srcset="/assets/images/login.png 1x, /assets/images/login_2x.png 2x">
    <span>{{'system.userMenu.login' | translate}}</span>
  </button>
</mat-menu>
