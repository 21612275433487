import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'html'
})
export class HtmlPipe implements PipeTransform {

  public constructor(private readonly _sanitizer: DomSanitizer) {
  }

  public transform(value: string): any {
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }
}
