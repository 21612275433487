import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CategoryResponse} from '../../../../../category/api/data/category-response';
import {RoutingConfig} from '../../../../../configs/routing-config';
import {DragService} from '../../../../../services/drag-service/drag.service';
import {Drag} from '../../../../../services/drag-service/interafces/drag';
import {SubscriptionAccessService} from '../../../../../subscriptions/services/subscription-access.service';
import {ScrollUtil} from '../../../../../utils/scroll-util';
import {ServiceWorkerService} from '../../../../../web-workers/service/service-worker.service';
import {ProjectResponse} from '../../../../api/data/project-response';
import {ProjectService} from '../../../../api/services/project.service';
import {CarriageCommunicationService} from '../../communication/carriage-communication.service';
import {CarriageInfo} from '../../communication/data/carriage-info';
import {OnCarriageEvent} from '../../communication/interfaces/on-carriage-event';

@Component({
  selector: 'app-carriage',
  templateUrl: './carriage.component.html',
  styleUrls: ['./carriage.component.scss']
})
export class CarriageComponent implements OnInit, OnDestroy, OnCarriageEvent, Drag, OnChanges {

  @Input()
  public mainCategoryId: string;

  @Input()
  public category: CategoryResponse;

  @Input()
  public carriageSize = 6;

  @Input()
  public carriageIndex: number;

  @Input()
  public projectsList: ProjectResponse[];

  @Input()
  public inDialog = false;

  @Output()
  public closeDialog: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('carriage', {static: true})
  public element: ElementRef;

  public projectsPartialList: ProjectResponse[] = [];
  public carriageController = '';
  public info = new CarriageInfo();
  public previewContent: boolean;
  public previewContentShow: boolean;
  public showSpinner: boolean;

  private _listPosition = 0;
  private _slide: number;

  public constructor(private readonly _service: ProjectService, private readonly _router: Router, private readonly _template: ElementRef,
                     private readonly _communication: CarriageCommunicationService, private readonly _dragService: DragService,
                     private readonly _subscriptionAccess: SubscriptionAccessService, private readonly _worker: ServiceWorkerService) {
  }

  public ngOnInit(): void {
    if (this.inDialog) this._communication.addOnCarriageDialogEventListener(this);
    else this._communication.addOnCarriageEventListener(this);
    if (this.projectsList && this.projectsList.length) this.initList();
    if (!this.category) return;
    this.showSpinner = true;
    const method = this.category.childes ? this._service.getCategoryProjects(this.category.id) :
      this._service.getDirectCategoryProjects(this.category.id);
    method.subscribe((response: ProjectResponse[]) => {
      this.projectsList = response;
      this.initList();
      if (this.element) this._dragService.addDrag(this, true);
    }, () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.carriageSize && this.projectsList) this.initList();
  }

  public onDrag(event: any, x?: number, y?: number, startInfo?: any): void {
    if (this.projectsList.length <= this.carriageSize || this.carriageController) return;
    this._slide = x;
    const width = this.element.nativeElement.clientWidth;
    let percent = -100 / this.carriageSize * (this.carriageSize + 1);
    percent -= (startInfo.x - event.clientX) / width * 100;
    this.element.nativeElement.style.transform = 'translate3d(' + percent + '%, 0, 0)';
  }

  public onStartDrag(event: any): void {
    this.element.nativeElement.style.transition = 'none';
    this._slide = undefined;
  }

  public onStopDrag(event: any): void {
    this.element.nativeElement.style.transition = '';
    this.element.nativeElement.style.transform = '';
    if (!this._slide || Math.abs(this._slide) < 10) return;
    if (this._slide < 0) this.moveRight();
    else this.moveLeft();
  }

  public onCarriageEventChange(info: CarriageInfo): void {
    this.info = info;
    if (!this.info || this.info.carriage !== this.carriageIndex) {
      this.closePreview();
      return;
    }
    this.previewContent = true;
    setTimeout(() => this.previewContentShow = true, 100);
  }

  public selectClass(index: number): string {
    return this.info.carriage !== this.carriageIndex || this.info.element !== index ? '' :
      'carriage-element-container drag selected';
  }

  private initList(): void {
    if (!this.projectsList || !this.projectsList.length) return;
    this.listPosition = -this.carriageSize - 1;
    this.projectsPartialList = this.showArrows ? this.takePart(this.projectsList, this.listPosition, this.carriageSize * 3 + 2) :
      this.projectsList;
  }

  public get showArrows(): boolean {
    if (!this.projectsList || !this.projectsList.length) return false;
    return this.projectsList.length > this.carriageSize;
  }

  public moveLeft(): void {
    this.carriageController = 'slide-left';
    this.listPosition = this.listPosition - this.carriageSize;
    const newElements = this.takePart(this.projectsList, this.listPosition, this.carriageSize);
    this.projectsPartialList.splice(0, 0, ...newElements);
    this.projectsPartialList.splice(this.projectsPartialList.length - this.carriageSize);
    setTimeout(() => {
      this.carriageController = '';
      this._communication.onCarriageEventChange(this.info);
    }, 100);
  }

  public moveRight(): void {
    this.carriageController = 'slide-right';
    this.listPosition = this.listPosition + this.carriageSize;
    const newElements = this.takePart(this.projectsList, this.listPosition + this.carriageSize + 1);
    this.projectsPartialList.push(...newElements);
    this.projectsPartialList.splice(0, this.carriageSize);
    setTimeout(() => {
      this.carriageController = '';
      this._communication.onCarriageEventChange(this.info);
    }, 100);
  }

  private set listPosition(position: number) {
    this._listPosition = this.defineListPosition(position);
  }

  private get listPosition(): number {
    return this._listPosition;
  }

  private defineListPosition(position: number): number {
    if (position < 0) return this.defineListPosition(this.projectsList.length + position);
    if (position >= this.projectsList.length) return this.defineListPosition(position - this.projectsList.length);
    return position;
  }

  private takePart(list: ProjectResponse[], sourceIndex: number, size?: number): ProjectResponse[] {
    const newPart: ProjectResponse[] = [];
    if (size === undefined) size = this.carriageSize;
    for (let i = 0; i < size; i++) {
      const index = this.defineListPosition(sourceIndex + i);
      newPart.push(list[index]);
    }
    return newPart;
  }

  public closePreview(): void {
    if (!this.previewContentShow) return;
    this.previewContentShow = false;
    setTimeout(() => this.previewContent = false, 500);
  }

  public scroll(): void {
    let container = this._template.nativeElement.parentNode.parentNode;
    const carriages = container.children;
    container = container.parentNode;
    if (carriages.length < 2) {
      ScrollUtil.scrollTo(container, 0, 500);
      return;
    }
    let height = 0;
    for (const element of carriages) {
      height = height ? Math.min(height, element.clientHeight) : element.clientHeight;
    }
    ScrollUtil.scrollTo(container, height * this.carriageIndex, 500);
  }

  public openCategory(): void {
    if (!this.clickableCategory) return;
    this._router.navigate([RoutingConfig.CATEGORY.replace(RoutingConfig.CATEGORY_ID_PARAM, this.category.id)]);
  }

  public get clickableCategory(): boolean {
    if (!this.category || !this.category.id) return false;
    return this.category.id !== this.mainCategoryId;
  }

  public ngOnDestroy(): void {
    this._communication.removeOnCarriageEventListener(this);
    this._communication.removeOnCarriageDialogEventListener(this);
    this._dragService.removeDrag(this);
  }

  public isAvailable(projectId: string): boolean {
    return !this._worker.isLoggedIn || this._subscriptionAccess.haveAccess(projectId);
  }
}
