<div class="center-box">
    <div *ngIf="!showSpinner" class="center-box-container">
        <h1>{{'mainCategory.choose' | translate}}</h1>
        <div *ngFor="let item of data" class="carriage-element" (click)="openCategory(item.id)">
            <div class="element-image">
                <app-image-preload [normal]="item.image" [retina]="item.retinaImage" [endpoint]="'RESOURCES'"
                                   [none]="'empty_cat'"></app-image-preload>
            </div>
            <div class="category-label">
                {{item.title}}
            </div>
        </div>
    </div>
    <app-spinner *ngIf="showSpinner" class="full"></app-spinner>
</div>
