import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {TitleItem} from '../../../../../globals/api/data/title-item';
import {DialogsService} from '../../../../../globals/dialogs/dialogs.service';
import {AccountService} from '../../../../api/service/account.service';
import {AbstractManagementPartComponent} from '../abstracts/abstract-management-part-component';

@Component({
  selector: 'app-institution-students',
  templateUrl: './institution-students.component.html',
  styleUrls: ['./institution-students.component.scss']
})
export class InstitutionStudentsComponent extends AbstractManagementPartComponent<TitleItem> {

  public readonly displayedColumns: string[] = ['title', 'actions'];

  public constructor(service: AccountService, private readonly _dialogs: DialogsService, private readonly _snackBar: MatSnackBar,
                     private readonly _translate: TranslateService) {
    super(service);
  }

  public getNextPage(page: number): void {
    this.showSpinner = true;
    this.service.getStudentsForInstitution(page, this.searchField.value).subscribe((response: TitleItem[]) =>
        this.list = this.listController.getUpdatedList(page, response, this.list, TitleItem),
      () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public removeStudent(index: number): void {
    const student = this.list[index];
    this._dialogs.showAlert(this._translate.instant('assignmentManagement.student.assignedStudents.removeAlert.title'),
      this._translate.instant('assignmentManagement.student.assignedStudents.removeAlert.description',
        {name: student.title})).subscribe((decision: boolean) => {
      if (!decision) return;
      this.showSpinner = true;
      this.service.removeStudentFromInstitution(student.id).subscribe(() => {
        this.list.splice(index, 1);
        this.list = [...this.list];
        ViewerConfig.openSnackBar(this._snackBar, this._translate.instant('assignmentManagement.student.assignedStudents.removeToast',
          {name: student.title}));
      }, () => this.showSpinner = false, () => this.showSpinner = false);
    });
  }
}
