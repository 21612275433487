import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ViewerConfig} from '../../../../../../configs/viewer-config';
import {OfflineService} from '../../../../../../offline/services/offline-service/offline.service';
import {PrintService} from '../../../../../../print/communication/print.service';
import {ServiceWorkerService} from '../../../../../../web-workers/service/service-worker.service';
import {DraggableWindow} from '../../../../screens/version/components/interfaces/draggable-window';
import {LoadingProgressService} from '../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {AbstractWindowComponent, contentAnimations, windowAnimations} from '../../../abstracts/screens/abstract-window.component';
import {CustomInteractivesCommunicationService} from '../../../layer-manager/modules/custom-interactives-manager/communiaction/custom-interactives-communication.service';
import {ViewChange} from '../../../layer/communication/interfaces/view-change';
import {LayersCommunicationService} from '../../../layer/communication/layers-communication.service';
import {BibliographyResponse} from '../../api/data/bibliography-response';
import {BibliographyOfflineService} from '../../api/service/bibliography-offline.service';
import {BibliographyService} from '../../api/service/bibliography.service';

@Component({
  selector: 'app-bibliography',
  animations: [windowAnimations, contentAnimations],
  templateUrl: './bibliography.component.html',
  styleUrls: ['./bibliography.component.scss']
})
export class BibliographyComponent extends AbstractWindowComponent implements OnInit, ViewChange, DraggableWindow {

  @Input()
  public versionId: string;

  @Input()
  public projectId: string;

  @Input()
  public windows: Map<string, DraggableWindow>;

  public bibliography: BibliographyResponse[];

  public constructor(private readonly _bibliographyService: BibliographyService,
                     private readonly _bibliographyOfflineService: BibliographyOfflineService, private readonly _cdr: ChangeDetectorRef,
                     private readonly _offline: OfflineService, layersCommunication: LayersCommunicationService,
                     loading: LoadingProgressService, print: PrintService, workerService: ServiceWorkerService,
                     customInteractivesCommunicationService: CustomInteractivesCommunicationService) {
    super(layersCommunication, customInteractivesCommunicationService, loading, print, workerService);
    this.showWindow = false;
  }

  public ngOnInit(): void {
    this.windows['BibliographyWindow'] = this;
    this.afterViewInit();
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      const requestMethod = result
        ? this._bibliographyOfflineService.getBibliography(this.projectId)
        : this.getOnlineMethod();
      requestMethod.subscribe((response: BibliographyResponse[]) => {
        this.bibliography = response;
        this.loading.configLoaded();
      });
    });
  }

  private getOnlineMethod(): Observable<BibliographyResponse[]> {
    return this.projectId === undefined ? this._bibliographyService.getBibliographyPreview(this.versionId) :
      this._bibliographyService.getBibliography(this.projectId);
  }

  public afterViewInit(): void {
    this.windowParams.x = ViewerConfig.LEFT_BAR_SIZE + 10;
    this.windowParams.y = ViewerConfig.TOP_BAR_SIZE + 10;
    super.afterViewInit();
    this._cdr.detectChanges();
  }

  public customInteractiveEditModeDisabled(): void {
  }
}
