import {JsonProperty} from '@dhkatz/json-ts';
import {DateToMilliSecondsConverter} from '../json/date-to-milli-seconds-converter';

export class ErrorResponse {

  @JsonProperty('status')
  private _status: number;

  @JsonProperty('error')
  private _error: string;

  @JsonProperty('message')
  private _message: string;

  @JsonProperty('key')
  private _key: string;

  @JsonProperty('code')
  private _code: number;

  @JsonProperty({name: 'timestamp', converter: DateToMilliSecondsConverter.converter})
  private _timestamp: Date;

  public constructor() {
    this._status = undefined;
    this._error = undefined;
    this._message = undefined;
    this._key = undefined;
    this._code = undefined;
    this._timestamp = undefined;
  }

  public get status(): number {
    return this._status;
  }

  public set status(value: number) {
    this._status = value;
  }

  public get error(): string {
    return this._error;
  }

  public set error(value: string) {
    this._error = value;
  }

  public get message(): string {
    return this._message;
  }

  public set message(value: string) {
    this._message = value;
  }

  public get key(): string {
    return this._key;
  }

  public set key(value: string) {
    this._key = value;
  }

  public get code(): number {
    return this._code;
  }

  public set code(value: number) {
    this._code = value;
  }

  public get timestamp(): Date {
    return this._timestamp;
  }

  public set timestamp(value: Date) {
    this._timestamp = value;
  }
}
