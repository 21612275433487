import {JsonProperty} from '@dhkatz/json-ts';

export class ImagePointFileResponse {
  @JsonProperty('id')
  private _id: string;

  @JsonProperty('preview')
  private _preview: string;

  @JsonProperty('used')
  private _used: boolean;

  public constructor() {
    this._id = undefined;
    this._preview = undefined;
    this._used = undefined;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get preview(): string {
    return this._preview;
  }

  public set preview(value: string) {
    this._preview = value;
  }

  public set used(value: boolean) {
    this._used = value;
  }

  public get used(): boolean {
    return this._used;
  }

}
