<div class="account-container profile-container">
  <div class="center-box">
    <div class="boxes" *ngIf="!showSpinner && accountResponse">
      <div class="left-boxes">
        <app-role-data *ngIf="isInstitutionOrLower && !isAgent"></app-role-data>
        <app-email-data [email]="accountResponse?.email"></app-email-data>
        <app-password-data></app-password-data>
      </div>
      <div class="right-boxes">
        <app-address-data [addressData]="accountResponse?.addressData"
                          [institutions]="accountResponse.institutions"></app-address-data>
        <app-institution-assign *ngIf="isTeacherOrLower"
                                [institutions]="accountResponse.institutions"></app-institution-assign>
      </div>
    </div>
    <app-spinner *ngIf="showSpinner || !accountResponse" class="full"></app-spinner>
  </div>
</div>
