import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ErrorResponse} from '../../../../../globals/api/data/error-response';
import {AuthorizationScreens} from '../../../enums/authorization-screens.enum';

@Component({template: ''})
export abstract class AbstractAuthorizationComponent implements OnInit {

  @Input()
  public showSpinner: boolean;

  @Output()
  public showSpinnerChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public screen: EventEmitter<AuthorizationScreens> = new EventEmitter<AuthorizationScreens>();

  @Output()
  public error: EventEmitter<ErrorResponse> = new EventEmitter<ErrorResponse>();

  public screensEnum = AuthorizationScreens;
  protected form: FormGroup;

  protected constructor() {
  }

  public ngOnInit(): void {
    this.createFormControls();
    this.createForm();
  }

  protected abstract createFormControls(): void;

  protected abstract createForm(): void;

  public abstract onSubmit(): void;

  public changeScreen(screen: AuthorizationScreens): void {
    this.screen.emit(screen);
  }

  protected changeShowSpinner(value: boolean): void {
    this.showSpinner = value;
    this.showSpinnerChange.emit(this.showSpinner);
  }
}
