import {Role} from '../../../authorization/api/data/enums/role.enum';
import {ServiceWorkerService} from '../../../web-workers/service/service-worker.service';

export abstract class AbstractRoleComponent {

  protected constructor(public readonly workerService: ServiceWorkerService) {
  }

  public get isLoggedIn(): boolean {
    return this.workerService.isLoggedIn;
  }

  protected get role(): Role {
    return this.workerService.role;
  }

  public get isAdmin(): boolean {
    return this.role === Role.ADMIN;
  }

  public get isModerator(): boolean {
    return this.role === Role.MODERATOR;
  }

  public get isCreator(): boolean {
    return this.role === Role.CREATOR;
  }

  public get isInstitution(): boolean {
    return this.role === Role.INSTITUTION;
  }

  public get isAgent(): boolean {
    return this.role === Role.AGENT;
  }

  public get isTeacher(): boolean {
    return this.role === Role.TEACHER;
  }

  public get isUser(): boolean {
    return this.role === Role.USER;
  }

  public get isModeratorOrHigher(): boolean {
    return this.isModerator || this.isAdmin;
  }

  public get isCreatorOrHigher(): boolean {
    return this.isCreator || this.isModeratorOrHigher;
  }

  public get isInstitutionOrHigher(): boolean {
    return this.isInstitution || this.isCreatorOrHigher;
  }

  public get isAgentOrHigher(): boolean {
    return this.isAgent || this.isInstitutionOrHigher;
  }

  public get isTeacherOrHigher(): boolean {
    return this.isTeacher || this.isAgentOrHigher;
  }

  public get isUserOrHigher(): boolean {
    return this.isUser || this.isTeacherOrHigher;
  }

  public get isAdminOrLower(): boolean {
    return this.isAdmin || this.isModeratorOrLower;
  }

  public get isModeratorOrLower(): boolean {
    return this.isModerator || this.isCreatorOrLower;
  }

  public get isCreatorOrLower(): boolean {
    return this.isCreator || this.isInstitutionOrLower;
  }

  public get isInstitutionOrLower(): boolean {
    return this.isInstitution || this.isAgentOrLower;
  }

  public get isAgentOrLower(): boolean {
    return this.isAgent || this.isTeacherOrLower;
  }

  public get isTeacherOrLower(): boolean {
    return this.isTeacher || this.isUser;
  }

  public roleCanEdit(role: Role): boolean {
    switch (role) {
      case Role.USER:
        return this.isUserOrHigher;
      case Role.TEACHER:
        return this.isTeacherOrHigher;
      case Role.AGENT:
        return this.isAgentOrHigher;
      case Role.INSTITUTION:
        return this.isInstitutionOrHigher;
      case Role.CREATOR:
        return this.isCreatorOrHigher;
      case Role.MODERATOR:
        return this.isModeratorOrHigher;
      case Role.ADMIN:
        return this.isAdmin;
    }
  }
}
