import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Json, JsonArray} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PanelsResponse} from '../data/panels-response';
import {PanelsEndpoints} from '../panels-endpoints';

@Injectable({
  providedIn: 'root'
})
export class PanelsService {

  public constructor(private readonly _http: HttpClient) {
  }

  public getPanels(projectId: string): Observable<PanelsResponse[]> {
    return this._http.get<JsonArray>(PanelsEndpoints.PANELS_ENTRY
      .replace(PanelsEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserialize(PanelsResponse, value))));
  }

  public getPanelsPreview(versionId: string): Observable<PanelsResponse[]> {
    return this._http.get<JsonArray>(PanelsEndpoints.PANELS_ENTRY_PREVIEW
      .replace(PanelsEndpoints.VERSION_ID, versionId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserialize(PanelsResponse, value))));
  }
}
