import {Pipe, PipeTransform} from '@angular/core';
import {FileSystemUtil} from '../../utils/file-system-util';

@Pipe({
  name: 'offline'
})
export class OfflinePipe implements PipeTransform {

  public constructor() {
  }

  public transform(value: string, data?: any): string {
    if (data.offline === undefined) return '';
    if (!data.offline) return value;
    return FileSystemUtil.getOfflineUrl(data.project, value);
  }
}
