import {OnSearch} from '../globals/interfaces/on-search';

export class SearchController {

  private _searchTimeout: any;
  private readonly _component: OnSearch;

  public constructor(component: OnSearch, private readonly _delay: number = 500) {
    this._component = component;
  }

  public search(value?: string): void {
    this.clear();
    this._component.startSearch();
    this._searchTimeout = setTimeout(() => this._component.sendSearch(value), this._delay);
  }

  public forceSearch(value?: string): void {
    this.clear();
    this._component.startSearch();
    this._component.sendSearch(value);
  }

  public clear(): void {
    clearTimeout(this._searchTimeout);
  }
}
