import {JsonProperty} from '@dhkatz/json-ts';

export class ChangePasswordRequest {

  @JsonProperty('token')
  private _token: string;

  @JsonProperty('password')
  private _password: string;

  public constructor() {
    this._token = undefined;
    this._password = undefined;
  }

  public get token(): string {
    return this._token;
  }

  public set token(value: string) {
    this._token = value;
  }

  public get password(): string {
    return this._password;
  }

  public set password(value: string) {
    this._password = value;
  }
}
