import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {RoutingConfig} from '../../../configs/routing-config';
import {ViewerConfig} from '../../../configs/viewer-config';
import {OnElementScroll} from '../../../globals/interfaces/on-element-scroll';
import {OnSearch} from '../../../globals/interfaces/on-search';
import {ListController} from '../../../utils/list-controller';
import {SearchController} from '../../../utils/search-controller';
import {MessageResponse} from '../../api/data/message-response';
import {MessagesService} from '../../api/service/messages.service';
import {MessagesCommunicationService} from '../../communication/messages-communication.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnElementScroll, OnDestroy, OnSearch {

  @ViewChild('scrollElement', {static: true})
  public activeScrollable: ElementRef;

  public selected: MessageResponse;
  public messages: MessageResponse[] = [];
  public showSpinner = true;
  public searchController: SearchController;

  public searchField = new FormControl('');

  private _listController: ListController;
  private _messageId: string;
  private _request: Subscription;

  public constructor(private readonly _service: MessagesService, private readonly _communication: MessagesCommunicationService,
                     private readonly _route: ActivatedRoute, private readonly _router: Router) {
  }

  public ngOnInit(): void {
    this._route.paramMap.subscribe((value: any) => {
      this._messageId = value.params[RoutingConfig.MESSAGE_ID];
      this.selectMessage();
    });
    this._listController = new ListController(this, ViewerConfig.MESSAGES_LIST_SIZE, this.activeScrollable.nativeElement);
    this.searchController = new SearchController(this);
  }

  public readChanged(): void {
    this.selected.read = true;
    const messages = this.messages;
    this.messages = messages;
  }

  public markAllAsRead(): void {
    if (!this.messages) return;
    this._service.markAllAsRead().subscribe(() => {
      this.messages.forEach((value) => value.read = true);
      this._communication.callOnMessageChanged();
    });
  }

  public getNextPage(page: number): void {
    this.showSpinner = true;
    this._request = this._service.search(this.searchField.value, page).subscribe((response: MessageResponse[]) => {
      this.messages = this._listController.getUpdatedList(page, response, this.messages, MessageResponse);
      this.selectMessage();
    }, () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public ngOnDestroy(): void {
    if (this._request) this._request.unsubscribe();
    this.searchController.clear();
    this.searchController = undefined;
    this._listController.destroy();
    this._listController = undefined;
  }

  public sendSearch(): void {
    this._listController.resetPageCounter();
    this.getNextPage(1);
  }

  public startSearch(): void {
    this.showSpinner = true;
    if (this._request) this._request.unsubscribe();
    this.messages = [];
  }

  private selectMessage(): void {
    if (!this.messages.length) return;
    if (this.selected && this.selected.id === this._messageId) return;
    if (!this._messageId) {
      this.onMessageClick(this.messages[0].id);
      return;
    }
    this.selected = this.messages.find((item: MessageResponse) => item.id === this._messageId);
    if (this.selected) return;
    this._service.getMessage(this._messageId).subscribe((response: MessageResponse) => this.selected = response);
  }

  public onMessageClick(messageId: string): void {
    this._router.navigate([RoutingConfig.MESSAGE.replace(RoutingConfig.MESSAGE_ID_PARAM, messageId)]);
  }
}
