import {RectSize} from '../../../../../../../globals/elements/rect-size';
import {CanDraw} from '../../interfaces/can-draw';
import {Part} from '../interfaces/part';

export abstract class AbstractPart<T extends RectSize> implements Part {

  protected constructor(protected data: T, private readonly _canvas: HTMLCanvasElement, protected readonly canDrawLevel: CanDraw) {
  }

  public canDraw(scaleRect: RectSize): boolean {
    if (!this.canDrawLevel.canDraw) return false;
    const minX = this.data.x * scaleRect.width + scaleRect.x;
    const minY = this.data.y * scaleRect.height + scaleRect.y;
    const maxX = this.data.width * scaleRect.width + minX;
    const maxY = this.data.height * scaleRect.height + minY;
    return maxX > 0 && minX < this._canvas.width && maxY > 0 && minY < this._canvas.height;
  }

  public abstract destroy(): void;

  public abstract draw(scaleRect?: RectSize, viewRect?: RectSize): void;

  public abstract clear(): void;
}
