import {serialize} from '@dhkatz/json-ts';
import {Size} from '../../../../../globals/api/data/size';
import {CacheRequest} from '../../../../../utils/models/cache-request';
import {RenderWorkerMessage} from '../../../../../web-workers/enums/render-worker-message.enum';
import {LoadingProgressService} from '../../../screens/version/components/loading-progress/service/loading-progress.service';
import {FontResponse} from '../../fonts/api/data/font-response';
import {LayerResponse} from '../api/data/layer-response';
import {VectorLayerResponse} from '../api/data/layer-response/layers/vector-layer-response';
import {AbstractLayersConstructor} from './abstracts/abstract-layers-constructor';

export class AsyncLayersConstructor extends AbstractLayersConstructor<Worker, Worker> {

  public constructor(id: string, version: number, data: LayerResponse, container: HTMLElement, canvas: HTMLCanvasElement,
                     private readonly _fonts: FontResponse[], loading: LoadingProgressService, private readonly _isOffline: boolean) {
    super(id, version, data, container, canvas, loading);
  }

  protected setConstructor(data: LayerResponse, cacheRequest: CacheRequest, canvas: HTMLCanvasElement): void {
    this.construct = new Worker('preRender.worker.js');
    this.construct.onmessage = (event: MessageEvent) => {
      if (this[event.data.type + 'Complete']) this[event.data.type + 'Complete'](event.data.message);
    };
    const offscreenCanvas = (canvas as any).transferControlToOffscreen();
    this.construct.postMessage({
      type: RenderWorkerMessage.init, message: {
        data: serialize(data),
        cacheRequest: serialize(cacheRequest),
        canvas: offscreenCanvas,
        offline: this._isOffline
      }
    }, [offscreenCanvas]);
    this.setDisabledLayers();
  }

  protected setCacheLayer(cacheRequest: CacheRequest, layer: VectorLayerResponse): void {
    if (this._fonts && this._fonts.length) this.loadCalcComplete(this._fonts.length * 2);
    const worker = new Worker('cache.worker.js');
    this.cacheConstructors.push(worker);
    worker.onmessage = (event: MessageEvent) => {
      if (!this[event.data.type + 'Complete']) return;
      if (!event.data.message) this[event.data.type + 'Complete'](worker);
      else this[event.data.type + 'Complete'](event.data.message);
    };
    worker.postMessage({
      type: RenderWorkerMessage.init, message: {
        cacheRequest: serialize(cacheRequest),
        data: serialize(layer),
        fonts: this._fonts ? this._fonts.map((font: FontResponse) => serialize(font)) : this._fonts,
        levelSizes: this.levelSizes.map((size: Size) => serialize(size)),
        partSize: serialize(this.partSize),
        offline: this._isOffline
      }
    });
  }

  public cacheSaveComplete(cache: Worker): void {
    cache.terminate();
    super.cacheSaveComplete(cache);
  }

  protected fullCacheComplete(): void {
    if (!this.cacheConstructors) return;
    this.cacheConstructors.forEach((worker: Worker) => {
      try {
        worker.terminate();
      } catch (e) {
      }
    });
    super.fullCacheComplete();
    this.construct.postMessage({type: RenderWorkerMessage.cache});
    this.draw();
  }

  public draw(): void {
    if (!this.construct) return;
    this.construct.postMessage({type: RenderWorkerMessage.draw, message: serialize(this.viewRect)});
  }

  public setDisabledLayers(layers?: boolean[]): void {
    if (layers) this.disabledLayers = layers;
    else layers = this.disabledLayers;
    if (!this.construct) return;
    this.construct.postMessage({type: RenderWorkerMessage.layers, message: layers});
  }

  public resize(width: number, height: number): void {
    if (!this.construct) return;
    this.construct.postMessage({type: RenderWorkerMessage.resize, message: {width, height}});
  }

  public destroy(): void {
    if (this.cacheConstructors.length) this.cacheConstructors.forEach((cache: Worker) => cache.terminate());
    if (this.construct) this.construct.terminate();
    this.cacheConstructors = undefined;
    this.construct = undefined;
  }
}
