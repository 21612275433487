import {JsonProperty} from '@dhkatz/json-ts';

export class MiniMapResponse {

  @JsonProperty('w')
  private _width: number;

  @JsonProperty('h')
  private _height: number;

  @JsonProperty('f')
  private _file: string;

  public constructor() {
    this._width = undefined;
    this._height = undefined;
    this._file = undefined;
  }

  public get width(): number {
    return this._width;
  }

  public get height(): number {
    return this._height;
  }

  public get file(): string {
    return this._file;
  }
}
