<div class="system-dialog">
  <div [ngClass]="{'loading': showSpinner}">
    <header>{{'assignmentManagement.teacher.assignedTeachers.inviteDialog.title' | translate}}
      <span class="close" (click)="closeDialog()"><mat-icon>close</mat-icon></span>
    </header>
    <div class="system-dialog-body">
      <form>
        <mat-form-field>
          <textarea matInput
                    [placeholder]="'assignmentManagement.teacher.assignedTeachers.inviteDialog.placeholder' | translate"
                    [formControl]="emailsField"></textarea>
          <mat-hint>{{'assignmentManagement.teacher.assignedTeachers.inviteDialog.hint' | translate}}
          </mat-hint>
          <mat-error *ngIf="emailsField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
          <mat-error *ngIf="emailsField.hasError('emailsRequired')">
            {{'assignmentManagement.teacher.assignedTeachers.inviteDialog.error.emailsRequired' | translate}}
          </mat-error>
          <mat-error *ngIf="emailsField.hasError('emails')" translate
                     [translateParams]="{emails: emailsField.getError('emails')}">
            assignmentManagement.teacher.assignedTeachers.inviteDialog.error.emails
          </mat-error>
          <mat-error *ngIf="emailsField.hasError('email')" translate
                     [translateParams]="{email: emailsField.getError('email')}">
            assignmentManagement.teacher.assignedTeachers.inviteDialog.error.email
          </mat-error>
        </mat-form-field>
        <button class="submit-button" (click)="onSubmit()">
          {{'assignmentManagement.teacher.assignedTeachers.inviteDialog.inviteLabel' | translate}}
        </button>
      </form>
    </div>
  </div>
  <app-spinner *ngIf="showSpinner" class="full primary"></app-spinner>
</div>
