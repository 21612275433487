import {Endpoints} from '../../../../../../../globals/api/endpoints';

export class CustomInteractiveEndpoints extends Endpoints {
  public static readonly CUSTOM_INTERACTIVES = CustomInteractiveEndpoints.VIEWER + '/custom-interactives/' + Endpoints.PROJECT_ID;

  public static readonly CUSTOM_INTERACTIVE_NEW = CustomInteractiveEndpoints.VIEWER + '/custom-interactive/' + Endpoints.PROJECT_ID;
  public static readonly CUSTOM_INTERACTIVE = CustomInteractiveEndpoints.CUSTOM_INTERACTIVE_NEW +
    '/' + Endpoints.LAYER_ID;

  public static readonly CUSTOM_INTERACTIVE_WEIGHT = CustomInteractiveEndpoints.CUSTOM_INTERACTIVE + '/weight';
  public static readonly CUSTOM_INTERACTIVE_ENABLED = CustomInteractiveEndpoints.CUSTOM_INTERACTIVE + '/enabled';
}
