import {AfterViewInit, Component, Inject, NgZone, OnDestroy, PLATFORM_ID} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {device_pixel_ratio} from 'javascript-retina-detect';
import {ViewerConfig} from '../../../../../../../../configs/viewer-config';
import {AbstractPointResponse} from '../../../../api/data/points/abstracts/abstract-point-response';
import {EmbedPointResponse} from '../../../../api/data/points/embed-point-response';
import {ImagePointResponse} from '../../../../api/data/points/image-point-response';
import {MoviePointResponse} from '../../../../api/data/points/movie-point-response';
import {SoundPointResponse} from '../../../../api/data/points/sound-point-response';
import {ChartPointResponse} from '../../../../api/data/points/chart-point-response';
import {PointType} from '../../../../api/data/points/enums/point-type.enum';
import {LinkType} from '../../../../api/data/points/enums/link-type.enum';
import {Chart} from '../../../../../../../../charts/chart';

@Component({
  selector: 'app-point-details',
  templateUrl: './point-details.component.html',
  styleUrls: ['./point-details.component.scss']
})
export class PointDetailsComponent implements AfterViewInit, OnDestroy {

  public readonly pointType = PointType;
  public readonly linkType = LinkType;

  public data: AbstractPointResponse;
  public isOffline: boolean;
  public projectId: string;

  public readonly config = ViewerConfig;
  public imageLoaded: boolean;
  public imageShow: boolean;
  public portraitImage: boolean;

  private _chart?: Chart;

  public constructor(@Inject(MAT_DIALOG_DATA) input: any, @Inject(PLATFORM_ID) platformId, zone: NgZone) {
    this.data = input.point;
    this.isOffline = input.offline;
    this.projectId = input.project;
    if (this.data?.type !== PointType.chart || !this.asChartPoint.chart) return;
    this._chart = Chart.builder(platformId, zone).setId('chart').setData(this.asChartPoint);
  }

  public get asMoviePoint(): MoviePointResponse {
    return this.data as MoviePointResponse;
  }

  public get asImagePoint(): string {
    const imagePoint = (this.data as ImagePointResponse);
    return device_pixel_ratio() > 1 && imagePoint.retinaFile ? imagePoint.retinaFile : imagePoint.file;
  }

  public get asEmbedPoint(): EmbedPointResponse {
    return this.data as EmbedPointResponse;
  }

  public get asChartPoint(): ChartPointResponse {
    return this.data as ChartPointResponse;
  }

  public get asSoundPoint(): SoundPointResponse {
    return this.data as SoundPointResponse;
  }

  public onImageLoaded(event: Event): void {
    const target = event.target as HTMLImageElement;
    this.portraitImage = target.width / target.height < 1;
    this.imageLoaded = true;
    setTimeout(() => this.imageShow = true, 50);
  }

  public ngAfterViewInit(): void {
    this._chart?.render();
  }

  public ngOnDestroy(): void {
    this._chart?.clear();
  }
}
