<div class="progress-bg">
    <div id="progress">
        <div>
            {{'project.progress.downloading' | translate}}
            <span *ngIf="startLoading">{{loadingProgress}} %</span>
            <app-spinner *ngIf="!startLoading" [strokeWidth]="2" [diameter]="15"></app-spinner>
            <mat-progress-bar [mode]="startLoading ? 'determinate' : 'indeterminate'"
                              [value]="loadingProgress"></mat-progress-bar>
        </div>
        <div *ngIf="showCache">
          <ng-container *ngIf="startCache">{{'project.progress.processing' | translate}}</ng-container>
          <ng-container *ngIf="!startCache">{{'project.progress.analyzing' | translate}}</ng-container>
            <span *ngIf="startCache">{{cachingProgress}} %</span>
            <app-spinner *ngIf="!startCache" [strokeWidth]="2" [diameter]="15"></app-spinner>
            <mat-progress-bar [mode]="startCache ? 'determinate' : 'indeterminate'"
                              [value]="cachingProgress"></mat-progress-bar>
        </div>
    </div>
</div>
