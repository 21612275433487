import {Injectable} from '@angular/core';
import {ChangePanelStatus} from './interfaces/change-panel-status';
import {PanelHide} from './interfaces/panel-hide';
import {PanelsLoaded} from './interfaces/panels-loaded';

@Injectable({
  providedIn: 'root'
})
export class PanelsCommunicationService {

  private _panelsLoadedListeners: PanelsLoaded[] = [];
  private _changePanelStatusListeners: ChangePanelStatus[] = [];
  private _panelHideListeners: PanelHide[] = [];

  public constructor() {
  }

  public addPanelLoadedListener(listener: PanelsLoaded): void {
    this._panelsLoadedListeners.push(listener);
  }

  public removePanelLoadedListener(listener: PanelsLoaded): void {
    const index: number = this._panelsLoadedListeners.indexOf(listener);
    if (index === -1) return;
    this._panelsLoadedListeners.splice(index, 1);
  }

  public panelsLoaded(panels: string[]): void {
    if (this._panelsLoadedListeners.length === 0) {
      setTimeout(() => this.panelsLoaded(panels), 100);
      return;
    }
    this._panelsLoadedListeners.forEach((listener: PanelsLoaded) => {
      try {
        listener.onPanelsLoaded(panels);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public addChangePanelStatusListener(listener: ChangePanelStatus): void {
    this._changePanelStatusListeners.push(listener);
  }

  public removeChangePanelStatusListener(listener: ChangePanelStatus): void {
    const index: number = this._changePanelStatusListeners.indexOf(listener);
    if (index === -1) return;
    this._changePanelStatusListeners.splice(index, 1);
  }

  public changePanelStatus(panel: string): void {
    if (this._changePanelStatusListeners.length === 0) return;
    this._changePanelStatusListeners.forEach((listener: ChangePanelStatus) => {
      try {
        listener.onChangePanelStatus(panel);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public addPanelHideListener(listener: PanelHide): void {
    this._panelHideListeners.push(listener);
  }

  public removePanelHideListener(listener: PanelHide): void {
    const index: number = this._panelHideListeners.indexOf(listener);
    if (index === -1) return;
    this._panelHideListeners.splice(index, 1);
  }

  public panelHide(): void {
    if (this._panelHideListeners.length === 0) return;
    this._panelHideListeners.forEach((listener: PanelHide) => {
      try {
        listener.onPanelHide();
      } catch (exception) {
        console.log(exception);
      }
    });
  }
}
