import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {AddressData} from '../../../../../account/api/data/address-data';
import {InstitutionsResponse} from '../../../../../account/institutions/api/data/institutions-response';
import {InstitutionsService} from '../../../../../account/institutions/api/service/institutions.service';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {TagManagerService} from '../../../../../services/tag-manager-service/tag-manager.service';
import {deserializeFix} from '../../../../../utils/deserialize-util';
import {PasswordUtil} from '../../../../../utils/password-util';
import {ViewerValidators} from '../../../../../utils/viewer-validators';
import {Role} from '../../../../api/data/enums/role.enum';
import {RegisterRequest} from '../../../../api/data/register-request';
import {AuthorizationService} from '../../../../api/services/authorization.service';
import {AbstractAuthorizationComponent} from '../abstracts/abstract-authorization.component';
import {AccountType} from './accountType.enum';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false, showError: true},
  }]
})
export class RegistrationComponent extends AbstractAuthorizationComponent implements OnInit {

  @Input()
  public email: string;

  @Input()
  public role: Role;

  @Input()
  public token: string;

  @Input()
  public registrationOnly: boolean;

  @Output()
  public registrationOnlyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public registered: EventEmitter<void> = new EventEmitter<void>();

  public accountTypeField: FormControl;
  public roleField: FormControl;
  public emailField: FormControl;
  public passwordField: FormControl;
  public firstNameField: FormControl;
  public lastNameField: FormControl;
  public confirmPasswordField: FormControl;
  public subscriptionField: FormControl;
  public institutionNameField: FormControl;
  public institutionCodeField: FormControl;
  public streetNameField: FormControl;
  public streetNumberField: FormControl;
  public apartmentNumberField: FormControl;
  public postCodeField: FormControl;
  public cityField: FormControl;
  public stateField: FormControl;
  public taxIdField: FormControl;
  public phoneNoField: FormControl;
  public approvalsField: FormControl;

  public readonly roles = Role;
  public readonly accountTypes = AccountType;
  public hide = true;
  public showConfirm: boolean;
  public showLoginConfirm: boolean;
  public institutionsAssigned: InstitutionsResponse[] = [];

  public accountTypeGroup: FormGroup;
  public accountDetailsGroup: FormGroup;
  public moreDetailsGroup: FormGroup;

  public institutions: InstitutionsResponse[] = [];

  private _token: string;

  public constructor(private readonly _service: AuthorizationService, private readonly _route: ActivatedRoute,
                     private readonly _tagManagerService: TagManagerService, private readonly _institutionsService: InstitutionsService) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._route.queryParams.subscribe((params) => this._token = params['tok']);
    this.findInstitution('');
  }

  public findInstitution(search: string): void {
    this._institutionsService.search(search).subscribe((response: InstitutionsResponse[]) => this.institutions = response);
  }

  protected createForm(): void {
    this.accountTypeGroup = new FormGroup({
      accountType: this.accountTypeField
    });
    this.accountDetailsGroup = new FormGroup({
      role: this.roleField,
      email: this.emailField,
      password: this.passwordField,
      confirmPassword: this.confirmPasswordField,
      coupon: this.subscriptionField,
      institutionCode: this.institutionCodeField,
      approvals: this.approvalsField
    }, {
      validators: PasswordUtil.mustMatch('password', 'confirmPassword')
    });
    this.moreDetailsGroup = new FormGroup({
      firstName: this.firstNameField,
      lastName: this.lastNameField,
      institutionName: this.institutionNameField,
      streetName: this.streetNameField,
      streetNumber: this.streetNumberField,
      apartmentNumber: this.apartmentNumberField,
      postCode: this.postCodeField,
      city: this.cityField,
      state: this.stateField,
      taxId: this.taxIdField,
      phoneNo: this.phoneNoField
    });
  }

  private getRoleFieldsValidation(): ValidatorFn[] {
    return !!this.role ? [] : [Validators.required];
  }

  protected createFormControls(): void {
    this.accountTypeField = new FormControl('', this.getRoleFieldsValidation());
    this.roleField = new FormControl('', this.getRoleFieldsValidation());
    this.emailField = new FormControl(this.email, {
      validators: [Validators.required, Validators.email],
      asyncValidators: [ViewerValidators.userExists(this._service)]
    });
    if (this.email) this.emailField.disable();
    this.firstNameField = new FormControl('', [Validators.required]);
    this.lastNameField = new FormControl('', [Validators.required]);
    this.passwordField = new FormControl('', [Validators.required,
      PasswordUtil.passwordStrength(1, 1, 1, 0, 6)]);
    this.subscriptionField = new FormControl('', {asyncValidators: [ViewerValidators.subscriptionCouponValid(this._service, this.roleField, this.accountTypeField)]});
    this.confirmPasswordField = new FormControl();
    this.institutionNameField = new FormControl();
    this.institutionCodeField = new FormControl();
    this.streetNameField = new FormControl();
    this.streetNumberField = new FormControl();
    this.apartmentNumberField = new FormControl();
    this.postCodeField = new FormControl();
    this.cityField = new FormControl();
    this.stateField = new FormControl();
    this.taxIdField = new FormControl();
    this.phoneNoField = new FormControl();
    this.approvalsField = new FormControl(new Set<string>(), [ViewerValidators.requiredInSet('privacy', 'terms', 'eula')]);
  }

  public generatePassword(): void {
    const password = PasswordUtil.generatePassword(1, 1, 1, 1, 6, 15);
    this.passwordField.setValue(password);
    this.confirmPasswordField.setValue(password);
  }

  private getRole(): Role {
    if (this.role) return this.role;
    return this.accountTypeField?.value === AccountType.MULTIPLE ? Role.INSTITUTION : this.roleField?.value;
  }

  public onSubmit(): void {
    if (this.accountTypeGroup.invalid || this.accountDetailsGroup.invalid || this.moreDetailsGroup.invalid) return;
    this.changeShowSpinner(true);
    const request = deserializeFix(RegisterRequest, this.accountDetailsGroup.value);
    request.role = this.getRole();
    request.email = this.emailField.value;
    request.approvals = Array.from(this.approvalsField.value);
    if (this.token) request.token = this.token;
    request.fromInvitation = !!this.email;
    request.addressData = deserializeFix(AddressData, this.moreDetailsGroup.value);
    if (request.role !== Role.INSTITUTION) {
      request.addressData.institutionName = undefined;
      request.addressData.taxId = undefined;
    }
    request.institutionCode = this.institutionCodeField.value;
    if (this.institutionsAssigned) request.institutions = this.institutionsAssigned.map((value: InstitutionsResponse) => value.id);
    this._service.register(request).subscribe((mailSent: boolean) => {
      this.registered.emit();
      this._tagManagerService.pushRegisterEvent(request);
      if (mailSent) this.showConfirm = true;
      else {
        this.showLoginConfirm = true;
        this.registrationOnlyChange.emit(false);
      }
    }, (error: HttpErrorResponse) => {
      this.changeShowSpinner(false);
      switch (error.error.key) {
        case 'user_exists':
          this.emailField.setErrors({exists: true});
          break;
        case 'invalid_email':
          this.emailField.setErrors({email: true});
          break;
        case 'weak_password':
          this.passwordField.setErrors({weakPassword: true});
          break;
        case 'subscription_coupon_not_found':
          this.subscriptionField.setErrors({coupon_not_found: true});
          break;
        case 'subscription_coupon_used':
          this.subscriptionField.setErrors({coupon_used: true});
          break;
        case 'subscription_coupon_inactive':
          this.subscriptionField.setErrors({coupon_inactive: true});
          break;
        case 'subscription_coupon_invalid_role':
          this.subscriptionField.setErrors({coupon_invalid_role: true});
          break;
        default:
          this.error.emit(error.error);
          break;
      }
    }, () => this.changeShowSpinner(false));
  }

  public accountTypeChange(accountType: AccountType): void {
    const actualAccountType = this.accountTypeField.value;
    this.accountTypeField.setValue(accountType);
    if (actualAccountType === accountType) return;
    if (accountType === AccountType.MULTIPLE) this.setAccountTypeMultipleValidators();
    else this.setAccountTypeSingleValidators();
    this.accountDetailsGroup.updateValueAndValidity();
    this.moreDetailsGroup.updateValueAndValidity();
    this.institutionsAssigned = [];
    this.findInstitution('');
  }

  private setAccountTypeSingleValidators(): void {
    this.roleField.reset();
    this.removeValidators(this.institutionNameField);
    this.removeValidators(this.streetNameField);
    this.removeValidators(this.streetNumberField);
    this.removeValidators(this.postCodeField);
    this.removeValidators(this.cityField);
    this.removeValidators(this.stateField);
    this.removeValidators(this.taxIdField);
    this.removeValidators(this.phoneNoField);
  }

  private removeValidators(field: FormControl): void {
    field.clearValidators();
    field.reset();
  }

  private setAccountTypeMultipleValidators(): void {
    this.roleField.setValue(Role.INSTITUTION);
    this.setValidators(this.institutionNameField);
    this.setValidators(this.streetNameField);
    this.setValidators(this.streetNumberField);
    this.setValidators(this.postCodeField);
    this.setValidators(this.cityField);
    this.setValidators(this.stateField);
    this.setValidators(this.taxIdField);
    this.setValidators(this.phoneNoField);
  }

  private setValidators(field: FormControl): void {
    field.setValidators([Validators.required]);
    field.reset();
  }

  public openPrivatePolicy(event: any): void {
    window.open(ViewerConfig.privacyUrl, '_blank');
    event.preventDefault();
  }

  public openTermsAndConditions(event: any): void {
    window.open(ViewerConfig.termsUrl, '_blank');
    event.preventDefault();
  }

  public openEULA(event: any): void {
    window.open(ViewerConfig.eulaUrl, '_blank');
    event.preventDefault();
  }

  public assignToInstitution(institution: InstitutionsResponse): void {
    if (this.accountTypeField.value === AccountType.MULTIPLE) {
      this.fillInstitutionData(institution);
      return;
    }
    this.institutionNameField.setValue('');
    if (this.institutionsAssigned.find((assigned: InstitutionsResponse) => assigned.id === institution.id)) return;
    this.institutionsAssigned.push(institution);
    setTimeout(() => this.findInstitution(''), 100);
  }

  public fillInstitutionData(institution: InstitutionsResponse): void {
    this.streetNameField.setValue(institution.streetName ? institution.streetName.trim() : '');
    this.streetNumberField.setValue(institution.streetNo ? institution.streetNo.trim() : '');
    this.apartmentNumberField.setValue(institution.apartmentNo ? institution.apartmentNo.trim() : '');
    this.postCodeField.setValue(institution.postCode ? institution.postCode.trim() : '');
    this.cityField.setValue(institution.city ? institution.city.trim() : '');
    this.stateField.setValue(institution.province ? institution.province.trim() : '');
    this.taxIdField.setValue(institution.taxId ? institution.taxId.trim() : '');
    this.institutionsAssigned = [institution];
  }

  public removeAssigned(index: number): void {
    this.institutionsAssigned.splice(index, 1);
  }

  public setApproval(approval: string, checked: boolean): void {
    let approvals = this.approvalsField.value;
    if (!approvals) approvals = new Set<string>();
    if (checked) approvals.add(approval);
    else approvals.delete(approval);
    this.approvalsField.setValue(approvals);
  }
}
