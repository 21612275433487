import {BrowserUtil} from '../../../../../../utils/browser-util';
import {LayerEndpoints} from '../../api/layer-endpoints';
import {PreRenderData} from '../data/interfaces/pre-render-data';
import {PreRenderDrawPool} from '../drawers/interfaces/pre-render-draw-pool';
import {LoadingPool} from '../loader/loading-pool';
import {PreRender} from './interfaces/pre-render';

export class RasterPreRenderPart implements PreRender {

  private _loading: boolean;
  private _imageData: ImageBitmap | HTMLImageElement;
  private _initLoad: boolean;

  public constructor(private _data: PreRenderData, private readonly _drawPool: PreRenderDrawPool, private readonly _loader: LoadingPool,
                     private readonly _offline: boolean) {
    if (!this._data.preLoading) return;
    this._initLoad = true;
    this.loadImage();
  }

  private loadImage(): void {
    if (this._loading) return;
    this._loading = true;
    this._loader.load(LayerEndpoints.FILES + this._data.data, (imageData: ImageBitmap | HTMLImageElement) => {
      this._imageData = imageData;
      this._loading = false;
      if (!this._initLoad) this._drawPool.reDraw();
    }, () => this._loading = false, this._data.preLoading, this._offline);
  }

  public draw(): boolean {
    this._initLoad = false;
    if (!this._data.data) return true;
    if (this._imageData === undefined) {
      this.loadImage();
      return false;
    }
    this._drawPool.addToDraw(this._imageData);
    return true;
  }

  public stopLoading(): void {
    if (this._data.preLoading) return;
    this._loading = false;
  }

  public clear(): void {
    if (this._data.preLoading) return;
    this.destroy();
  }

  public destroy(): void {
    this._loading = false;
    if (!this._imageData) return;
    if (BrowserUtil.asyncLoad && this._imageData instanceof ImageBitmap) this._imageData.close();
    else (this._imageData as HTMLImageElement).remove();
    this._imageData = undefined;
  }

  public setDisabledLayers(layers: boolean[]): boolean {
    const changed = this._data.setDisabledLayers(layers);
    if (changed) this.clear();
    return changed;
  }
}
