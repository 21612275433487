import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractDialogComponent} from '../../../../../../../../../../../../../globals/dialogs/abstracts/abstract-dialog.component';
import {ImagePointFileResponse} from '../../../../../../../modules/custom-images/api/data/image-point-file-response';
import {ImagePointFileService} from '../../../../../../../modules/custom-images/api/service/image-point-file.service';

@Component({
  selector: 'app-choose-image-dialog',
  templateUrl: './choose-image-dialog.component.html',
  styleUrls: ['./choose-image-dialog.component.scss']
})
export class ChooseImageDialogComponent extends AbstractDialogComponent implements OnInit {

  public imagesList: ImagePointFileResponse[];
  public selected: ImagePointFileResponse;
  public newSelect: ImagePointFileResponse;
  public imageForDelete: ImagePointFileResponse;
  public showSpinner = true;
  public fileUsedError = false;
  public removeImageConfirm = false;
  public viewError: any;

  public constructor(private readonly _fileService: ImagePointFileService,
                     protected readonly dialogRef: MatDialogRef<ChooseImageDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    super(data);
  }

  public ngOnInit(): void {
    this._fileService.getImages().subscribe((response: ImagePointFileResponse[]) => {
      this.imagesList = response;
      const selectedId = this.getValue('file');
      if (!selectedId) return;
      this.selected = this.imagesList.find((image: ImagePointFileResponse) => image.id === selectedId);
    }, () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public uploadFile(files: FileList): void {
    if (files.length === 0) return;
    this.fileUsedError = false;
    this._fileService.uploadImage(files.item(0)).subscribe((response: ImagePointFileResponse) => {
      if (!response) return;
      this.imagesList.push(response);
    });
  }

  protected createFormControls(): void {
  }

  protected createForm(): void {
  }

  public onSubmit(): void {
    this.selected = this.newSelect;
    this.dialogRef.close(this.selected);
  }

  public select(event: MouseEvent, image: ImagePointFileResponse): void {
    if ((<Element>event.target).innerHTML === 'delete') return;
    this.newSelect = image;
  }

  public closeDialog(): void {
    this.dialogRef.close(this.selected);
  }

  public confirmDeleteFile(image: ImagePointFileResponse): void {
    if (this.viewError) clearTimeout(this.viewError);
    if (image.used) {
      this.fileUsedError = true;
      this.viewError = setTimeout(() => this.fileUsedError = false, 2500);
      return;
    }
    this.removeImageConfirm = true;
    this.imageForDelete = image;
  }

  public cancel(): void {
    this.removeImageConfirm = false;
  }

  public deleteFile(image: ImagePointFileResponse): void {
    this._fileService.deleteImage(image.id).subscribe(() => {
      if (image === this.selected) this.selected = undefined;
      if (image === this.newSelect) {
        this.newSelect = undefined;
        this.selected = undefined;
      }
      const index = this.imagesList.indexOf(image);
      if (index === -1) return;
      this.imagesList.splice(index, 1);
      this.removeImageConfirm = false;
    }, (error: HttpErrorResponse) => {
      if (error.error.key === 'file_used') this.fileUsedError = true;
    });
  }

  public isSelected(id: string): boolean {
    if (this.newSelect) return this.newSelect.id === id;
    else if (this.selected) return this.selected.id === id;
    else return false;
  }
}
