<div class="subscription-container account-container">
  <div class="center-box">
    <div class="boxes">
      <app-subscription-coupon (subscriptionAdded)="newSubscription($event)"
                               (showSpinner)="showSpinner = $event"></app-subscription-coupon>
      <mat-accordion
        *ngIf="activeSubscriptions?.length || expiredSubscriptions?.length || cancelledSubscriptions?.length">
        <div class="subscription-list" *ngIf="activeSubscriptions?.length">
          <app-subscription *ngFor="let element of activeSubscriptions" [demo]="element?.demo"
                            [expirationDate]="element?.expirationDate"
                            [id]="element?.id" [name]="element?.name" [type]="element?.type"
                            [scope]="element?.scope" [startDate]="element?.startDate"
                            [status]="element?.status" [unlimited]="element?.unlimited"
                            [schoolPlus]="element?.schoolPlus"></app-subscription>
        </div>
        <div class="subscription-list" *ngIf="expiredSubscriptions?.length">
          <app-subscription *ngFor="let element of expiredSubscriptions" [demo]="element?.demo"
                            [expirationDate]="element?.expirationDate"
                            [id]="element?.id" [name]="element?.name" [type]="element?.type"
                            [scope]="element?.scope" [startDate]="element?.startDate"
                            [status]="element?.status" [unlimited]="element?.unlimited"
                            [schoolPlus]="element?.schoolPlus"></app-subscription>
        </div>
        <div class="subscription-list" *ngIf="cancelledSubscriptions?.length">
          <app-subscription *ngFor="let element of cancelledSubscriptions" [demo]="element?.demo"
                            [expirationDate]="element?.expirationDate"
                            [id]="element?.id" [name]="element?.name" [type]="element?.type"
                            [scope]="element?.scope" [startDate]="element?.startDate"
                            [status]="element?.status" [unlimited]="element?.unlimited"
                            [schoolPlus]="element?.schoolPlus"></app-subscription>
        </div>
      </mat-accordion>
      <div
        *ngIf="!showSpinner && !activeSubscriptions?.length && !expiredSubscriptions?.length && !cancelledSubscriptions?.length"
        class="center-box-container">{{'subscriptions.empty' | translate}}
      </div>
    </div>
    <app-spinner *ngIf="showSpinner" class="full"></app-spinner>
  </div>
</div>
