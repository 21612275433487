import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DialogsService} from '../../../../globals/dialogs/dialogs.service';
import {SubscriptionAccessService} from '../../../../subscriptions/services/subscription-access.service';
import {ServiceWorkerService} from '../../../../web-workers/service/service-worker.service';
import {OfflineStatus} from '../../../communication/enums/offline-status.enum';
import {OfflineService} from '../../../services/offline-service/offline.service';
import {AbstractOfflineComponent} from '../abstracts/abstract-offline.component';

@Component({
  selector: 'app-manage-offline',
  templateUrl: './manage-offline.component.html',
  styleUrls: ['./manage-offline.component.scss']
})
export class ManageOfflineComponent extends AbstractOfflineComponent implements OnInit {

  @Input()
  public projectId: string;

  @Input()
  public projectTitle: string;

  @Input()
  public categoryId: string;

  @Input()
  public email: string;

  @Input()
  public lastUpdated: Date;

  @Input()
  public validUntil: Date;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  public hasAccess: boolean;
  public needRefresh: boolean;

  public constructor(dialogs: DialogsService, router: Router, offlineService: OfflineService,
                     subscriptionAccess: SubscriptionAccessService, ngZone: NgZone,
                     worker: ServiceWorkerService, translate: TranslateService) {
    super(translate, dialogs, router, worker, offlineService, subscriptionAccess);
  }

  public ngOnInit(): void {
    this.showSpinner = true;
    this.offlineService.checkProject(this.projectId).then((offlineStatus: OfflineStatus) => {
      this.status = offlineStatus;
      if (this.status === OfflineStatus.NO_OFFLINE) return;
      this.hasAccess = this.isCreatorOrHigher || !this.validUntil ? true : this.validUntil.getMilliseconds() < new Date().getMilliseconds();
      const needRefreshDate = new Date(this.lastUpdated);
      needRefreshDate.setDate(needRefreshDate.getDate() + 14);
      this.needRefresh = needRefreshDate.valueOf() < new Date().valueOf();
      this.showSpinner = false;
    }, () => this.showSpinner = false);
  }

  public downloadProjectFiles(): void {
    super.downloadProject(this.projectId, this.email, this.projectTitle, this.categoryId, true);
    this.lastUpdated = new Date();
    this.needRefresh = false;
  }

  public remove(projectId: string, projectName: string): void {
    super.remove(projectId, projectName, () => this.removed.emit());
  }
}
