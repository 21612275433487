import {JsonProperty} from '@dhkatz/json-ts';
import {MapNumberBooleanConverter} from '../../../../api/json/map-number-boolean-converter';

export abstract class AbstractCombinedPreRenderDataPart {

  @JsonProperty({name: 'i', converter: MapNumberBooleanConverter.converter})
  private _index: Map<number, boolean>;

  protected constructor() {
    this._index = undefined;
  }

  public get index(): Map<number, boolean> {
    return this._index;
  }

  public set index(value: Map<number, boolean>) {
    this._index = value;
  }

  public setDisabledLayers(layers: boolean[]): boolean {
    for (const entry of Array.from(this._index.entries())) {
      if (layers[entry[0]] === undefined) layers[entry[0]] = false;
      if (entry[1] === layers[entry[0]]) return false;
    }
    return true;
  }
}
