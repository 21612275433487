import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {serialize} from '@dhkatz/json-ts';
import {Observable} from 'rxjs';
import {PointType} from '../../../../api/data/points/enums/point-type.enum';
import {AbstractCustomPointRequest} from '../data/abstracts/abstract-custom-point-request';
import {PositionRequest} from '../data/position-request';
import {PointEndpoints} from '../point-endpoints';

@Injectable({
  providedIn: 'root'
})
export class PointService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public createPoint<Request extends AbstractCustomPointRequest>(projectId: string, layerId: string, type: PointType, request: Request):
    Observable<void> {
    return this._http.post<void>(PointEndpoints.POINTS_TYPE.replace(PointEndpoints.PROJECT_ID, projectId)
      .replace(PointEndpoints.LAYER_ID, layerId).replace(PointEndpoints.POINT_TYPE, type.toString()), serialize(request));
  }

  public removePoint(projectId: string, layerId: string, type: PointType, pointId: string): Observable<string> {
    return this._http.delete<string>(PointEndpoints.POINT.replace(PointEndpoints.PROJECT_ID, projectId)
      .replace(PointEndpoints.LAYER_ID, layerId).replace(PointEndpoints.POINT_TYPE, type.toString())
      .replace(PointEndpoints.POINT_ID, pointId));
  }

  public updatePointPosition(projectId: string, layerId: string, type: PointType, pointId: string, request: PositionRequest):
    Observable<void> {
    return this._http.put<void>(PointEndpoints.POINT_POSITION.replace(PointEndpoints.PROJECT_ID, projectId)
      .replace(PointEndpoints.LAYER_ID, layerId).replace(PointEndpoints.POINT_TYPE, type.toString())
      .replace(PointEndpoints.POINT_ID, pointId), serialize(request));
  }

  public updatePoint<Request extends AbstractCustomPointRequest>(projectId: string, layerId: string, type: PointType, pointId: string,
                                                                 request: Request): Observable<void> {
    return this._http.put<void>(PointEndpoints.POINT.replace(PointEndpoints.PROJECT_ID, projectId).replace(PointEndpoints.LAYER_ID, layerId)
      .replace(PointEndpoints.POINT_TYPE, type.toString()).replace(PointEndpoints.POINT_ID, pointId), serialize(request));
  }
}
