import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {ActivatedRoute, Router} from '@angular/router';
import {Role} from '../../../../authorization/api/data/enums/role.enum';
import {RoutingConfig} from '../../../../configs/routing-config';
import {OfflineService} from '../../../../offline/services/offline-service/offline.service';
import {IdbUtil} from '../../../../utils/idb-util';
import {OfflineUtil} from '../../../../utils/offline-util';
import {ServiceWorkerService} from '../../../../web-workers/service/service-worker.service';
import {ProjectService} from '../../../api/services/project.service';
import {VersionResponse} from '../../api/data/version-response';
import {VersionOfflineService} from '../../api/services/version-offline.service';
import {VersionService} from '../../api/services/version.service';
import {ActualLevelChange} from '../../modules/layer/communication/interfaces/actual-level-change';
import {LayersCommunicationService} from '../../modules/layer/communication/layers-communication.service';
import {VersionModule} from '../../modules/version-modules.enum';
import {DraggableWindow} from './components/interfaces/draggable-window';
import {LoadingComplete} from './components/loading-progress/service/interfaces/loading-complete';
import {LoadingProgressService} from './components/loading-progress/service/loading-progress.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit, LoadingComplete, OnDestroy, ActualLevelChange {

  @ViewChild('slidemenu', {static: true})
  public slideMenu: MatSlideToggle;

  @ViewChild('menuTrigger', {read: MatMenuTrigger, static: true})
  public trigger: MatMenuTrigger;

  public windows: Map<string, DraggableWindow>;
  public isExpanded = false;

  public isLoading = true;
  public isPreview = false;
  public isModerator = false;
  public opacity = 0;
  public actualLevel = 0;
  public inactive = false;

  public projectId: string;
  public versionId: string;

  public version: VersionResponse;
  public moduleType = VersionModule;

  public constructor(private readonly _projectService: ProjectService, private readonly _versionService: VersionService,
                     private readonly _versionOfflineService: VersionOfflineService,
                     private readonly _router: Router, private readonly _route: ActivatedRoute,
                     private readonly _layersCommunication: LayersCommunicationService, private readonly _loading: LoadingProgressService,
                     private readonly _serviceWorker: ServiceWorkerService, private readonly _ngZone: NgZone,
                     private readonly _offline: OfflineService) {
    this.windows = new Map<string, DraggableWindow>();
    this._layersCommunication.addActualLevelChangeListener(this);
    this._loading.addLoadingCompleteListener(this);
    const role = this._serviceWorker.role;
    this.isModerator = role === Role.ADMIN || role === Role.MODERATOR || role === Role.CREATOR;
  }

  public ngOnInit(): void {
    this._route.paramMap.subscribe((value: any) => this.init(value.params));
  }

  private init(params: any): void {
    this.projectId = undefined;
    this.versionId = undefined;
    this.isPreview = undefined;
    this.version = undefined;
    this.isLoading = undefined;
    this.opacity = 0;
    setTimeout(() => {
      this.isLoading = true;
      this.projectId = params[RoutingConfig.PROJECT_ID];
      this.versionId = params[RoutingConfig.VERSION_ID];
      if (this.versionId) this.isPreview = true;
      if (OfflineUtil.isOfflineAvailable()) {
        this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
          if (!result) {
            this.getVersionData();
            return;
          }
          this.getVersionDataFromOffline();
        });
        return;
      }
      this.getVersionData();
    }, 0);
  }

  public onActualLevelChange(level: number): void {
    this.actualLevel = level;
  }

  private getVersionDataFromOffline(): void {
    this._ngZone.run(() => {
      this._versionOfflineService.getVersion(this.projectId).subscribe((response: VersionResponse) => {
        this.version = response;
        this._loading.addConfigsToLoad(this.version.modules.length);
      });
    });
  }

  private getVersionData(): void {
    const requestMethod = this.isPreview ? this._versionService.getVersionPreview(this.versionId) :
      this._versionService.getVersion(this.projectId);
    requestMethod.subscribe((response: VersionResponse) => {
      this.version = response;
      IdbUtil.idbKeyVal(IdbUtil.IDB_ONLINE_VERSION).set(this.projectId, this.version.versionId)
        .catch((reason) => console.log(reason));
      this._loading.addConfigsToLoad(this.version.modules.length);
    }, () => {
    });
  }

  public onLoadingComplete(): void {
    this.isLoading = false;
    this.opacity = 1;
  }

  public toggle(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target.className.includes('toggle')) return;
    this.isExpanded = !this.isExpanded;
    if (this.slideMenu) this.slideMenu.toggle();
  }

  public showWindow(window: string): void {
    Object.keys(this.windows).forEach((key) => {
      if (key === window) {
        this.windows[key].showWindow = !this.windows[key].showWindow;
        this.windows[key].element.nativeElement.style.zIndex = 2;
        return;
      }
      this.windows[key].element.nativeElement.style.zIndex = 1;
    });
  }

  public checkWindow(window: string): boolean {
    if (this.windows[window])
      return this.windows[window].showWindow;
  }

  public ngOnDestroy(): void {
    this._layersCommunication.removeActualLevelChangeListener(this);
    this._loading.removeLoadingCompleteListener(this);
  }

  public onPanelActive(panelActive: boolean): void {
    this.inactive = panelActive;
  }
}
