import {Pipe, PipeTransform} from '@angular/core';
import {device_pixel_ratio} from 'javascript-retina-detect';

@Pipe({
  name: 'retina'
})
export class RetinaPipe implements PipeTransform {

  public transform(data: any, args?: any): string {
    return device_pixel_ratio() > 1 && data.retina ? data.retina : data.normal;
  }
}
