import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {ProjectModule} from '../../../../api/data/enums/project-module.enum';
import {ProjectResponse} from '../../../../api/data/project-response';
import {CarriageCommunicationService} from '../../communication/carriage-communication.service';
import {CarriageInfo} from '../../communication/data/carriage-info';

@Component({
  selector: 'app-element-details',
  templateUrl: './element-details.component.html',
  styleUrls: ['./element-details.component.scss']
})
export class ElementDetailsComponent implements OnChanges {

  @Input()
  public project: ProjectResponse;

  @Input()
  public categoryId: string;

  @Input()
  public inDialog = false;

  @Input()
  public isAvailable;

  @Output()
  public closeDialog: EventEmitter<void> = new EventEmitter<void>();

  public actualProject: ProjectResponse;
  public active: boolean;
  public projectModule = ProjectModule;
  public readonly subscriptionsUrl = ViewerConfig.subscriptionsUrl;

  private _delay = 0;

  public constructor(private readonly _router: Router, private readonly _communication: CarriageCommunicationService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.active = false;
    if (!this.project) return;
    setTimeout(() => {
      this.actualProject = this.project;
      this.active = true;
      this._delay = 500;
    }, this._delay);
  }

  public closePreview(): void {
    if (this.inDialog) this._communication.onCarriageDialogEventChange(new CarriageInfo());
    else this._communication.onCarriageEventChange(new CarriageInfo());
  }
}
