import {JsonProperty} from '@dhkatz/json-ts';

export class Header {

  @JsonProperty('alg')
  private _algorithm: string;

  @JsonProperty('typ')
  private _type: string;

  public constructor() {
    this._algorithm = undefined;
    this._type = undefined;
  }

  public get algorithm(): string {
    return this._algorithm;
  }

  public set algorithm(value: string) {
    this._algorithm = value;
  }

  public get type(): string {
    return this._type;
  }

  public set type(value: string) {
    this._type = value;
  }
}
