<div class="zoom-bar">
    <div class="icon" (click)="zoomIn()">
        <img width="24" height="24" src="/assets/images/plus.png"
             srcset="/assets/images/plus.png 1x, /assets/images/plus_2x.png 2x">
    </div>
    <div class="zoom drag" #zoomrange>
        <div class="vl drag">
        </div>
        <div class="zoom-grab drag" #zoomgrab>
        </div>
    </div>
    <div class="icon" (click)="zoomOut()">
        <img width="24" height="24" src="/assets/images/minus.png"
             srcset="/assets/images/minus.png 1x, /assets/images/minus_2x.png 2x">
    </div>
</div>
