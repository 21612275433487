import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {serialize} from '@dhkatz/json-ts';
import {TranslateService} from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import {RoutingConfig} from '../../../../configs/routing-config';
import {DialogsService} from '../../../../globals/dialogs/dialogs.service';
import {AbstractRoleComponent} from '../../../../globals/screens/abstracts/abstract-role.component';
import {SubscriptionAccessService} from '../../../../subscriptions/services/subscription-access.service';
import {deserializeFix} from '../../../../utils/deserialize-util';
import {IdbUtil} from '../../../../utils/idb-util';
import {OfflineUtil} from '../../../../utils/offline-util';
import {ServiceWorkerService} from '../../../../web-workers/service/service-worker.service';
import {OfflineStatus} from '../../../communication/enums/offline-status.enum';
import {OnFileDownload} from '../../../communication/interfaces/on-file-download';
import {OfflineDBEntry} from '../../../services/offline-service/data/OfflineDBEntry';
import {UserOfflineEntry} from '../../../services/offline-service/data/UserOfflineEntry';
import {OfflineService} from '../../../services/offline-service/offline.service';

@Component({template: ''})
export abstract class AbstractOfflineComponent extends AbstractRoleComponent implements OnInit, OnFileDownload, OnDestroy {

  public showSpinner = true;

  public progress = 0;

  public status: OfflineStatus;

  public projectId: string;

  public offlineAvailable = OfflineUtil.isOfflineAvailable();

  protected constructor(private readonly _translate: TranslateService, protected readonly dialogs: DialogsService,
                        protected readonly router: Router, worker: ServiceWorkerService, protected readonly offlineService: OfflineService,
                        protected readonly subscriptionAccess: SubscriptionAccessService) {
    super(worker);
    this.offlineService.addOnFileDownloadListener(this);
  }

  public ngOnInit(): void {
  }

  public openProject(projectId: string, categoryId: string): void {
    const path = RoutingConfig.PROJECT.replace(RoutingConfig.CATEGORY_ID_PARAM, categoryId)
      .replace(RoutingConfig.PROJECT_ID_PARAM, projectId);
    this.router.navigate([path]);
  }

  public downloadProject(projectId: string, email: string, projectTitle: string, categoryId: string, force: boolean): void {
    this.offlineService.downloadProject(projectId, email, projectTitle, categoryId, force);
    this.status = OfflineStatus.DOWNLOADING;
  }

  public removeProjectFromOffline(projectId: string, onFulfilled?: () => void): void {
    this.showSpinner = true;
    IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).get(projectId).then((value) => {
      if (!value) return;
      const entry = deserializeFix(OfflineDBEntry, value);
      const email = this.workerService.isLoggedInOnline ? this.workerService.email : localStorage.getItem('offlineEmail');

      if (entry.userEntry.length === 1 && entry.userEntry[0].email === CryptoJS.MD5(email).toString(CryptoJS.enc.Hex)) {
        this.deleteEntry(projectId, onFulfilled);
        return;
      }

      this.deleteUserEntry(entry, projectId, onFulfilled);
    });
  }

  private deleteUserEntry(entry, projectId: string, onFulfilled: () => void): void {
    entry.userEntry = entry.userEntry
      .filter((userEntry: UserOfflineEntry) => userEntry.email !== CryptoJS.MD5(this.workerService.email).toString(CryptoJS.enc.Hex));
    IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).set(projectId, serialize(entry)).then(() => this.onFulfilled(onFulfilled));
  }

  private deleteEntry(projectId: string, onFulfilled: () => void): void {
    IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).delete(projectId)
      .then(() => OfflineUtil.removeFromOffline(projectId, () => this.onFulfilled(onFulfilled)),
        (err: any) => console.log('Error during removing project from offline: ', err));
  }

  private onFulfilled(onFulfilled: () => void): void {
    this.status = OfflineStatus.NO_OFFLINE;
    this.showSpinner = false;
    if (onFulfilled) onFulfilled();
  }

  public getProgress(): string {
    return this.progress && this.progress < 100 ? this.progress.toFixed() : '0';
  }

  public onFileDownload(projectId: string, progress: number): void {
    if (projectId !== this.projectId) return;
    this.progress = progress;
    this.status = progress < 100 ? OfflineStatus.DOWNLOADING : OfflineStatus.DOWNLOADED;
  }

  public ngOnDestroy(): void {
    this.offlineService.removeOnFileDownloadListner(this);
  }

  public remove(projectId: string, projectName: string, onFulfilled?: () => void): void {
    this.dialogs.showAlert(this._translate.instant('offlineManagement.removeAlert.title'),
      this._translate.instant('offlineManagement.removeAlert.description', {name: projectName}))
        .subscribe((decision: boolean) => {
          if (!decision) return;
          this.removeProjectFromOffline(projectId, onFulfilled);
        });
  }

  public get isDownloaded(): boolean {
    return this.status === OfflineStatus.DOWNLOADED || this.isUpdateAvailable;
  }

  public get isDownloadAvailable(): boolean {
    return this.status !== OfflineStatus.DOWNLOADED;
  }

  public get isDownloadInProgress(): boolean {
    return this.status === OfflineStatus.DOWNLOADING;
  }

  public get isUpdateAvailable(): boolean {
    return this.status === OfflineStatus.UPDATE_AVAILABLE;
  }
}
