<h3 [ngClass]="{'category-list': true, 'clickabele': clickableCategory}"
    (click)="openCategory()">{{category?.title ? category?.title : '&nbsp;'}}</h3>
<div class="carriage-container" [ngClass]="{'static': !showArrows}">
    <div #carriage *ngIf="projectsPartialList"
         [ngClass]="'carriage drag ' + carriageController + (showArrows ? '' : ' static')">
        <app-list-element *ngFor="let item of projectsPartialList; let i = index" [index]="i" [project]="item"
                          [categoryId]="category?.id ? category?.id : mainCategoryId" [carriageIndex]="carriageIndex"
                          [ngClass]="'carriage-element-container drag' + selectClass(i)" (scroll)="scroll()"
                          [inDialog]="inDialog" [isAvailable]="isAvailable(item.id)" (closeDialog)="closeDialog.emit()">
        </app-list-element>
    </div>
    <span *ngIf="showArrows" class="move-carriage move-right" (click)="moveRight()">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </span>
    <span *ngIf="showArrows" class="move-carriage move-left" (click)="moveLeft()">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </span>
    <app-spinner *ngIf="showSpinner" class="full"></app-spinner>
</div>
<div *ngIf="previewContent" [ngClass]="{'element-preview': true, 'show': previewContentShow}">
    <app-element-details [project]="projectsPartialList[info.element]" [inDialog]="inDialog"
                         (closeDialog)="closeDialog.emit()"
                         [categoryId]="category?.id ? category?.id : mainCategoryId"
                         [isAvailable]="isAvailable(projectsPartialList[info.element]?.id)"></app-element-details>
</div>
