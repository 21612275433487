import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ViewerConfig} from '../../../../../../configs/viewer-config';
import {OfflineService} from '../../../../../../offline/services/offline-service/offline.service';
import {PrintFormat} from '../../../../../../print/communication/enums/print-format.enum';
import {PrintFormatChange} from '../../../../../../print/communication/interfaces/print-format-change';
import {PrintPreviewChange} from '../../../../../../print/communication/interfaces/print-preview-change';
import {PrintService} from '../../../../../../print/communication/print.service';
import {LoadingProgressService} from '../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {ScaleChange} from '../../../layer/communication/interfaces/scale-change';
import {LayersCommunicationService} from '../../../layer/communication/layers-communication.service';
import {ScaleResponse} from '../../api/data/scale-response';
import {ScaleOfflineService} from '../../api/services/scale-offline.service';
import {ScaleService} from '../../api/services/scale.service';
import {MeasurementSystem} from '../../communication/enums/measurement-system.enum';
import {ProjectorDimensions} from '../../communication/enums/projector-dimensions.enum';
import {ScreenType} from '../../communication/enums/screen-type.enum';
import {ScaleSettingsChange} from '../../communication/interfaces/scale-settings-change';
import {ScaleCommunicationService} from '../../communication/scale-communication.service';
import {ScaleSettingsComponent} from './dialogs/scale-settings/scale-settings.component';

@Component({
  selector: 'app-scale',
  templateUrl: './scale.component.html',
  styleUrls: ['./scale.component.scss']
})
export class ScaleComponent implements OnInit, OnDestroy, ScaleChange, ScaleSettingsChange, PrintPreviewChange, PrintFormatChange {

  @Input()
  public versionId: string;

  @Input()
  public projectId: string;

  private isOffline: boolean;

  public scale: string;

  private _viewScale: number;
  private _dpi: number;
  private _printDpi: number;
  private _scaleData: ScaleResponse;
  private _printPreview: boolean;

  public constructor(private readonly _scaleService: ScaleService, private readonly _scaleOfflineService: ScaleOfflineService,
                     private readonly _communication: ScaleCommunicationService,
                     private readonly _layersCommunication: LayersCommunicationService, private readonly _loading: LoadingProgressService,
                     private readonly _dialog: MatDialog, private readonly _cdr: ChangeDetectorRef, private readonly _print: PrintService,
                     private readonly _offline: OfflineService) {
    this._communication.addScaleConfigChangeListener(this);
    this._layersCommunication.addScaleChangeListener(this);
    this._print.addPrintPreviewChangeListener(this);
    this._print.addPrintFormatChangeListener(this);
  }

  public ngOnInit(): void {
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      this.isOffline = result;
      setTimeout(() => {
        const requestMethod = this.isOffline
          ? this._scaleOfflineService.getScale(this.projectId)
          : this.getOnlineMethod();
        requestMethod.subscribe((response: ScaleResponse) => {
          this._scaleData = response;
          this.onScreenTypeChange();
          this._loading.configLoaded();
        });
      }, 0);
    });
  }

  private getOnlineMethod(): Observable<ScaleResponse> {
    return this.projectId === undefined
      ? this._scaleService.getScalePreview(this.versionId)
      : this._scaleService.getScale(this.projectId);
  }

  public onScaleChange(scale: number): void {
    this._viewScale = scale;
    this.setScale();
    this._cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this._communication.removeScaleConfigChangeListener(this);
    this._layersCommunication.removeScaleChangeListener(this);
    this._print.removePrintPreviewChangeListener(this);
    this._print.removePrintFormatChangeListener(this);
  }

  private setDpi(diagonal: number): void {
    this._dpi = Math.sqrt(Math.pow(window.screen.width, 2) + Math.pow(window.screen.height, 2)) / diagonal;
  }

  private setScale(): void {
    if (!this._scaleData) return;
    const rounding = Math.pow(10, this._scaleData.rounding);
    const dpi = this._printPreview ? this._printDpi : this._dpi;
    const calculatedScale = Math.round(dpi / (this._viewScale * this._scaleData.width) * this._scaleData.scale / rounding) * rounding;
    this.scale = calculatedScale.toLocaleString();
  }

  public onScreenTypeChange(): void {
    if (this._communication.screenType === ScreenType.screen) this.setScreen();
    else this.setProjector();
  }

  public onPrintPreviewChange(enabled: boolean): void {
    this._printPreview = enabled;
    if (!this._printDpi) this.setPrintDpi(PrintFormat.A4);
    this.setScale();
  }

  public onPrintFormatChange(format: PrintFormat): void {
    this.setPrintDpi(format);
    this.setScale();
  }

  private setPrintDpi(format: PrintFormat): void {
    let sheetWidth = -20;
    switch (format) {
      case PrintFormat.A4:
        sheetWidth += 297;
        break;
      case PrintFormat.A3:
        sheetWidth += 410;
        break;
    }
    this._printDpi = document.body.clientWidth / (sheetWidth / 25.4);
  }

  private setScreen(): void {
    this.setDpi(this._communication.screenDiagonal);
    this.setScale();
  }

  private setProjector(): void {
    let diagonal;
    switch (this._communication.projectorDimension) {
      case ProjectorDimensions.width:
        diagonal = Math.sqrt(Math.pow(this._communication.projectorSize, 2) + Math.pow(this._communication.projectorSize *
          (window.screen.height / window.screen.width), 2));
        break;
      case ProjectorDimensions.height:
        diagonal = Math.sqrt(Math.pow(this._communication.projectorSize * (window.screen.width / window.screen.height), 2) +
          Math.pow(this._communication.projectorSize, 2));
        break;
      case ProjectorDimensions.diagonal:
        diagonal = this._communication.projectorSize;
        break;
    }
    if (this._communication.projectorMeasure === MeasurementSystem.cm) diagonal /= 2.54;
    this.setDpi(diagonal);
    this.setScale();
  }

  public openSettingsDialog(): void {
    this._dialog.open(ScaleSettingsComponent, ViewerConfig.getScaleDialogOptions());
  }
}
