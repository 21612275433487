<form>
  <div class="title">{{'project.scale.settings.title' | translate}}:</div>
  <div class="settings-block">
    <div class="settings-icon" (click)="screenType = screenTypes.screen"
         [ngClass]="{'inactive': screenType === screenTypes.projector}">
      <mat-icon (click)="screenType = screenTypes.screen">visibility</mat-icon>
    </div>
    <div class="settings-option" (click)="screenType = screenTypes.screen">
      <div class="description">
        {{'project.scale.settings.screen.description' | translate}}<br/>
        <small>{{'project.scale.settings.screen.hint' | translate}}</small>
      </div>
      <input class="screen-diag" [formControl]="screenDiagonalField">
      <small>{{'project.scale.settings.screen.suffix' | translate}}</small>
    </div>
  </div>
  <div class="settings-block" (click)="screenType = screenTypes.projector">
    <div class="settings-icon" [ngClass]="{'inactive': screenType === screenTypes.screen}">
      <mat-icon (click)="screenType = screenTypes.projector">visibility</mat-icon>
    </div>
    <div class="settings-option">
      <div class="description">
        {{'project.scale.settings.projector.description' | translate}}<br/>
        <small>{{'project.scale.settings.projector.hint' | translate}}
        </small>
      </div>
      <input class="screen-diag" [formControl]="projectorSizeField">
      <select class="screen-unit" [formControl]="projectorMeasureField">
        <option *ngFor="let type of projectorMeasures" [value]="type.key">
          {{ type.value }}
        </option>
      </select>
      <select class="screen-type" [formControl]="projectorDimensionField">
        <option *ngFor="let type of projectorDimensions" [value]="type.key">
          {{ type.value }}
        </option>
      </select>
      <small>{{'project.scale.settings.projector.suffix' | translate}}</small>
    </div>
  </div>
  <div class="buttons">
    <button mat-button class="cancel-button" mat-dialog-close>{{'system.label.cancel' | translate}}</button>
    <button mat-button class="submit-button" mat-dialog-close (click)="onSubmit()">{{'system.label.confirm' | translate}}</button>
  </div>
</form>
