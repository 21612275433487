<ng-container *ngIf="!showConfirm">
  <div class="system-dialog-body">
    <div class="login">
      <form>
        <mat-error *ngIf="credentialsError" class="response-error"
                   [innerHTML]="'authorization.error.invalidCredentials' | translate">
        </mat-error>
        <mat-error *ngIf="unconfirmedError" class="response-error">
          <span [outerHTML]="'authorization.error.unactivatedAccount' | translate"></span>
          <button class="cancel-button"
                  (click)="resend()">{{'authorization.error.unactivatedAccountLabel' | translate}}</button>
        </mat-error>
        <mat-form-field>
          <input type="email" [placeholder]="'system.form.placeholder.email' | translate" matInput
                 [formControl]="emailField" (ngModelChange)="clearResponseErrors()">
          <mat-error *ngIf="emailField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
          <mat-error *ngIf="emailField.hasError('email') && !emailField.hasError('required')"
                     [innerHTML]="'profile.email.error.incorrect' | translate">
          </mat-error>
          <mat-error *ngIf="emailField.hasError('accountDisabled')"
                     [innerHTML]="'authorization.error.blockedAccount' | translate">
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input [type]="hide ? 'password' : 'text'" [placeholder]="'system.form.placeholder.password' | translate"
                 matInput [formControl]="passwordField" (ngModelChange)="clearResponseErrors()">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-error *ngIf="passwordField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
        </mat-form-field>
        <div class="login-controls">
          <mat-checkbox type="checkbox"
                        [formControl]="autoLoginField">{{'authorization.label.remember' | translate}}</mat-checkbox>
          <span class="forgot-password-link link" (click)="changeScreen(screensEnum.resetPassword)">
            {{'resetPassword.label.forgotPassword' | translate}}
            </span>
        </div>
        <button class="submit-button" (click)="onSubmit()">{{'authorization.label.login' | translate}}</button>
      </form>
    </div>
  </div>
  <hr>
  <footer>
    <div>{{'register.info.noAccount' | translate | translateSplit:0}}
      <span class="link" (click)="changeScreen(screensEnum.registration)">
        {{'register.info.noAccount' | translate | translateSplit:1}}
      </span>
    </div>
  </footer>
</ng-container>
<ng-container *ngIf="showConfirm">
  <div class="system-dialog-body">
    <div class="confirm">
      <p [innerHTML]="'register.info.registerConfirm' | translate:{email: emailField.value || resendEmail}"></p>
      <p [innerHTML]="'system.spamCheck' | translate"></p>
    </div>
  </div>
  <hr>
  <footer>
    <span class="link" (click)="showConfirm = false">{{'authorization.label.backToLogin' | translate}}</span>
  </footer>
</ng-container>
