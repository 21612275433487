<div class="center-box">
  <div class="center-box-container">
    <app-authorization *ngIf="status === confirmStatus.success" [screen]="screensEnum.changePassword"
                       [redirect]="'/'"
                       (error)="handleError($event)"></app-authorization>
    <div id="confirm" *ngIf="status !== confirmStatus.success">
      <ng-container *ngIf="status === confirmStatus.invalid">
        <mat-icon class="error">error_outline</mat-icon>
        {{'resetPassword.info.invalid' | translate}}
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.expired">
        <mat-icon class="error">error_outline</mat-icon>
        {{'resetPassword.info.expired' | translate}}
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.used">
        <mat-icon class="warn">warning</mat-icon>
        {{'resetPassword.info.used' | translate}}

      </ng-container>
      <footer>
        <button class="submit-button" [routerLink]="'/'">{{'system.label.goToMain' | translate}}</button>
      </footer>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner" class="full"></app-spinner>
