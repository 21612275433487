import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {AuthRequest} from '../../../../api/data/auth-request';
import {AuthorizationService} from '../../../../api/services/authorization.service';
import {AbstractAuthorizationComponent} from '../abstracts/abstract-authorization.component';
import {deserializeFix} from '../../../../../utils/deserialize-util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AbstractAuthorizationComponent {

  @Input()
  public email = '';

  @Output()
  public loginSuccess: EventEmitter<void> = new EventEmitter<void>();

  public emailField: FormControl;
  public passwordField: FormControl;
  public autoLoginField: FormControl;
  public hide = true;
  public credentialsError: boolean;
  public unconfirmedError: boolean;
  public showConfirm: boolean;
  public resendEmail: string;

  public constructor(private readonly _http: HttpClient, private readonly _service: AuthorizationService,
                     private readonly _snackBar: MatSnackBar, private readonly _translate: TranslateService) {
    super();
  }

  protected createFormControls(): void {
    this.emailField = new FormControl(this.email, [Validators.required, Validators.email]);
    if (this.email) this.emailField.disable();
    this.passwordField = new FormControl('', [Validators.required]);
    this.autoLoginField = new FormControl(false);
  }

  protected createForm(): void {
    this.form = new FormGroup({
      email: this.emailField,
      password: this.passwordField,
      isAutoLogin: this.autoLoginField
    });
  }

  public onSubmit(): void {
    if (!this.form.valid) return;
    this.clearResponseErrors();
    this.changeShowSpinner(true);
    const request = deserializeFix(AuthRequest, this.form.value);
    request.email = this.emailField.value;
    request.isPlain = true;
    this._service.sendAuthRequest(request).subscribe(() => {
      this.loginSuccess.emit();
      ViewerConfig.openSnackBar(this._snackBar, this._translate.instant('authorization.loginToast'));
    }, (error: HttpErrorResponse) => {
      this.changeShowSpinner(false);
      switch (error.error.key) {
        case 'invalid_email':
          this.emailField.setErrors({email: true});
          break;
        case 'invalid_login_credentials':
          this.credentialsError = true;
          break;
        case 'account_disabled':
          this.emailField.setErrors({accountDisabled: true});
          break;
        case 'account_unconfirmed':
          this.resendEmail = this.emailField.value;
          this.unconfirmedError = true;
          break;
        default:
          this.error.emit(error.error);
          break;
      }
    }, () => this.changeShowSpinner(false));
  }

  public resend(): void {
    if (!this.resendEmail) return;
    this.changeShowSpinner(true);
    this._service.resendRegisterToken(this.resendEmail).subscribe(() => {
      this.showConfirm = true;
      this.clearResponseErrors();
      this.form.reset();
      this.hide = true;
    }, () => this.changeShowSpinner(false), () => this.changeShowSpinner(false));
  }

  public clearResponseErrors(): void {
    this.credentialsError = false;
    this.unconfirmedError = false;
  }
}
