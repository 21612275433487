import {Component, NgZone} from '@angular/core';
import {LoadingProgressService} from './service/loading-progress.service';

@Component({
  selector: 'app-loading-progress',
  templateUrl: './loading-progress.component.html',
  styleUrls: ['./loading-progress.component.scss']
})
export class LoadingProgressComponent {

  public loadingProgress = 0;
  public cachingProgress = 0;
  public configsLoaded: boolean;
  public startLoading: boolean;
  public startCache: boolean;
  public showCache: boolean;

  private _configsToLoad = 0;
  private _configsLoaded = 0;
  private _complete: boolean;

  private _filesToLoad = 0;
  private _filesLoaded = 0;
  private _loaded: boolean;
  private _loadingProgress = 0;
  private _loadingInterval: any;

  private _cacheCount = 2;
  private _elementsForCache = 1;
  private _elementsCached = 0;
  private _cached: boolean;
  private _cachingProgress = 0;
  private _cachingInterval: any;

  public constructor(private _loading: LoadingProgressService, private readonly _ngZone: NgZone) {
    this._loading.init(this);
  }

// CONFIGS <-----------------------------------------------------------------------------------------------------------------------> CONFIGS

  public addConfigsToLoad(count: number): void {
    this._configsToLoad += count;
  }

  public configLoaded(): void {
    if (++this._configsLoaded < this._configsToLoad) return;
    setTimeout(() => {
      this.configsLoaded = true;
      this.addFilesToLoad();
      this.addElementsForCache();
      this.checkComplete();
    }, 0);
  }

// FILES <---------------------------------------------------------------------------------------------------------------------------> FILES

  public addFilesToLoad(count: number = 0): void {
    this._filesToLoad += count;
    if (!this._filesToLoad || !this.configsLoaded) return;
    if (!this.startLoading) {
      this.startLoading = true;
      this._loadingInterval = setInterval(() => this.loadingProgress = this._loadingProgress, 150);
    }
    this.setLoadingProgress();
  }

  public fileLoaded(): void {
    this._ngZone.run(() => {
      this._filesLoaded++;
      this.setLoadingProgress();
    });
  }

  private setLoadingProgress(): void {
    this._loadingProgress = this.getProgress(this._filesLoaded, this._filesToLoad);
    if (!this.configsLoaded || this._filesLoaded < this._filesToLoad) return;
    this._loaded = true;
    this.checkComplete();
  }

// CACHES <-------------------------------------------------------------------------------------------------------------------------> CACHES

  public setCacheCount(count: number): void {
    if (count > 0) this.showCache = true;
    this._cacheCount = count;
  }

  public addElementsForCache(count: number = 0): void {
    this._elementsForCache += count;
    if (!this._elementsForCache || !this.configsLoaded || --this._cacheCount > 0) return;
    if (!this.startCache) {
      this.startCache = true;
      this._cachingInterval = setInterval(() => this.cachingProgress = this._cachingProgress, 150);
    }
    this.setCachingProgress();
  }

  public elementCached(): void {
    this._elementsCached++;
    this.setCachingProgress();
  }

  public cacheComplete(): void {
    this._elementsCached = this._elementsForCache;
    this._cacheCount = 0;
    this.setCachingProgress();
  }

  private setCachingProgress(): void {
    this._ngZone.run(() => {
      this._cachingProgress = this.getProgress(this._elementsCached, this._elementsForCache);
      if (!this.configsLoaded || this._elementsCached < this._elementsForCache) return;
      this._cached = true;
      this.checkComplete();
    });
  }

// COMPLETE <---------------------------------------------------------------------------------------------------------------------> COMPLETE

  private getProgress(ready: number, total: number): number {
    return Math.floor(ready / total * 100);
  }

  private checkComplete(): void {
    if (this._complete || !this.configsLoaded || (this.startLoading && !this._loaded) || (this.startCache && !this._cached) ||
      this._cacheCount > 0) return;
    if (this._loadingInterval !== undefined) clearInterval(this._loadingInterval);
    if (this._cachingInterval !== undefined) clearInterval(this._cachingInterval);
    this.loadingProgress = 100;
    this.cachingProgress = 100;
    this._complete = true;
    setTimeout(() => this._loading.loadingComplete(), 100);
  }
}
