import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractLayerResponse} from '../../../layer/api/data/layer-response/layers/abstracts/abstract-layer-response';
import {PointsListConverter} from '../json/points-list-converter';
import {AbstractPointResponse} from './points/abstracts/abstract-point-response';

export class InteractiveLayerResponse extends AbstractLayerResponse {

  @JsonProperty('id')
  private _id: string;

  @JsonProperty('t')
  private _title: string;

  @JsonProperty('e')
  private _enabled: boolean;

  @JsonProperty('i')
  private _index: number;

  @JsonProperty({name: 'd', converter: PointsListConverter.converter, type: Object})
  private _details: AbstractPointResponse[];

  public constructor() {
    super();
    this._id = undefined;
    this._title = undefined;
    this._index = undefined;
    this._details = undefined;
    this._enabled = undefined;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get index(): number {
    return this._index;
  }

  public get details(): AbstractPointResponse[] {
    return this._details;
  }

  public get enabled(): boolean {
    return this._enabled;
  }

  public set enabled(value: boolean) {
    this._enabled = value;
  }
}
