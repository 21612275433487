import {JsonProperty} from '@dhkatz/json-ts';
import {ScaledRasterElement} from '../../elements/scaled-raster-element';
import {AbstractCombinedPreRenderDataPart} from './abstracts/abstract-combined-pre-render-data-part';

export class ScaledCombinedPreRenderDataPart extends AbstractCombinedPreRenderDataPart {

  @JsonProperty({name: 'd', type: ScaledRasterElement})
  private _data: ScaledRasterElement[];

  public constructor() {
    super();
    this._data = undefined;
  }

  public get data(): ScaledRasterElement[] {
    return this._data;
  }

  public set data(value: ScaledRasterElement[]) {
    this._data = value;
  }
}
