import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {device_pixel_ratio} from 'javascript-retina-detect';
import {Observable} from 'rxjs';
import {ViewerConfig} from '../../../../../../configs/viewer-config';
import {OfflineService} from '../../../../../../offline/services/offline-service/offline.service';
import {PrintService} from '../../../../../../print/communication/print.service';
import {DragComponent} from '../../../../../../services/drag-service/components/drag.component';
import {DragService} from '../../../../../../services/drag-service/drag.service';
import {PinchComponent} from '../../../../../../services/pinch-service/components/pinch.component';
import {PinchService} from '../../../../../../services/pinch-service/pinch.service';
import {ServiceWorkerService} from '../../../../../../web-workers/service/service-worker.service';
import {DraggableWindow} from '../../../../screens/version/components/interfaces/draggable-window';
import {LoadingProgressService} from '../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {AbstractWindowComponent, contentAnimations, windowAnimations} from '../../../abstracts/screens/abstract-window.component';
import {DisableInteractives} from '../../../layer-manager/communication/interfaces/disable-interactives';
import {DisableLayers} from '../../../layer-manager/communication/interfaces/disable-layers';
import {LayerManagerCommunicationService} from '../../../layer-manager/communication/layer-manager-communication.service';
import {CustomInteractivesCommunicationService} from '../../../layer-manager/modules/custom-interactives-manager/communiaction/custom-interactives-communication.service';
import {ViewChange} from '../../../layer/communication/interfaces/view-change';
import {LayersCommunicationService} from '../../../layer/communication/layers-communication.service';
import {LegendResponse} from '../../api/data/legend-response';
import {LegendOfflineService} from '../../api/services/legend-offline.service';
import {LegendService} from '../../api/services/legend.service';

@Component({
  selector: 'app-legend',
  animations: [windowAnimations, contentAnimations],
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LegendComponent extends AbstractWindowComponent implements OnDestroy, OnInit, DisableLayers, DisableInteractives, ViewChange,
  DraggableWindow {

  @Input()
  public versionId: string;

  @Input()
  public projectId: string;

  @Input()
  public windows: Map<string, DraggableWindow>;

  public isOffline: boolean;

  @ViewChild('topResizer', {static: true})
  private _topResizerRef: ElementRef;

  @ViewChild('content', {static: true})
  private _contentRef: ElementRef;

  @ViewChild('bottomResizer', {static: true})
  private _bottomResizerRef: ElementRef;

  public legend: LegendResponse[] = [];
  public displayedColumns: string[] = ['icon', 'title'];

  private readonly _minimalHeight = 200;

  private _disabledLayers: boolean[] = [];
  private _disabledInteractives: boolean[] = [];
  private _disabledCustomInteractives: boolean[] = [];
  private _contentStartHeight: number;
  private _windowStartY: number;
  private _topResizerDrag: DragComponent;
  private _bottomResizerDrag: DragComponent;
  private _pinch: PinchComponent;
  private _contentDiff: number;

  public constructor(private readonly _legendService: LegendService, private readonly _legendOfflineService: LegendOfflineService,
                     private readonly _dragService: DragService, private readonly _pinchService: PinchService,
                     private readonly _layerManagerCommunication: LayerManagerCommunicationService,
                     private readonly _sanitizer: DomSanitizer, private readonly _offline: OfflineService,
                     layersCommunication: LayersCommunicationService, loading: LoadingProgressService, print: PrintService,
                     workerService: ServiceWorkerService, customInteractivesCommunicationService: CustomInteractivesCommunicationService) {
    super(layersCommunication, customInteractivesCommunicationService, loading, print, workerService);
    this._layerManagerCommunication.addDisableLayersListener(this);
    this._layerManagerCommunication.addOnDisableInteractiveListener(this);
  }

  public onLoadingComplete(): void {
    this.initHeight();
    super.onLoadingComplete();
  }

  private topOnDrag(event: any, startInfo: any): void {
    let posY = startInfo.offsetY + event.clientY - startInfo.y;
    const maxHeight = this.windowParams.y - ViewerConfig.TOP_BAR_SIZE + this._contentRef.nativeElement.clientHeight + 1;
    posY = this.getResizePosY(-posY, maxHeight);
    this.resizeContent(posY);
    if (this._contentRef.nativeElement.clientHeight < parseFloat(this._contentRef.nativeElement.style.maxHeight)) return;
    const y = this._windowStartY - posY;
    this.element.nativeElement.style.top = y + 'px';
    this.windowParams.y = y;
  }

  private bottomOnDrag(event: any, startInfo: any): void {
    let posY = startInfo.offsetY + event.clientY - startInfo.y;
    const maxHeight = window.innerHeight - this._windowStartY - this._contentDiff - ViewerConfig.BOTTOM_BAR_SIZE - 1;
    posY = this.getResizePosY(posY, maxHeight);
    this.resizeContent(posY);
  }

  private getResizePosY(posY: number, maxHeight: number): number {
    if (this._contentStartHeight + posY < this._minimalHeight) return this._minimalHeight - this._contentStartHeight;
    return this._contentStartHeight + posY > maxHeight ? maxHeight - this._contentStartHeight : posY;
  }

  public onPinch(distanceDiff: number): void {
    this.elementOnStartDrag(undefined);
    this.resizeContent(distanceDiff);
    this.updateDivParams();
  }

  private resizeContent(posY: number): void {
    let height = this._contentStartHeight + posY;
    if (height > window.innerHeight - ViewerConfig.BOTTOM_BAR_SIZE - ViewerConfig.TOP_BAR_SIZE - this._contentDiff)
      height = window.innerHeight - ViewerConfig.BOTTOM_BAR_SIZE - ViewerConfig.TOP_BAR_SIZE - this._contentDiff;
    else if (height < this._minimalHeight) height = this._minimalHeight;
    this._contentRef.nativeElement.style.maxHeight = height + 'px';
  }

  private elementOnStartDrag(event: any): void {
    this.setOnTop(this);
    this._contentStartHeight = this._contentRef.nativeElement.clientHeight;
    this._windowStartY = this.windowParams.y;
  }

  public ngOnInit(): void {
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      this.isOffline = result;
      this.afterViewInit();
      this.windows['LegendWindow'] = this;
      setTimeout(() => {
        const requestMethod = this.isOffline
          ? this._legendOfflineService.getLegend(this.projectId)
          : this.getOnlineMethod();
        requestMethod.subscribe((response: LegendResponse[]) => {
          if (!response) return;
          this.legend = response;
          this.loading.configLoaded();
        });
      }, 0);
      this.initHeight();
      this._topResizerDrag = new DragComponent(this._topResizerRef,
        (event: any, x?: number, y?: number, startInfo?: any) => this.topOnDrag(event, startInfo),
        (event: any) => this.elementOnStartDrag(event));
      this._dragService.addDrag(this._topResizerDrag);
      this._bottomResizerDrag = new DragComponent(this._bottomResizerRef,
        (event: any, x?: number, y?: number, startInfo?: any) => this.bottomOnDrag(event, startInfo),
        (event: any) => this.elementOnStartDrag(event));
      this._dragService.addDrag(this._bottomResizerDrag);
      this._pinch = new PinchComponent(this._contentRef,
        (distanceDiff: number, center?: { x: number; y: number }, distance?: number) => this.onPinch(distanceDiff));
      this._pinchService.addPinch(this._pinch);
    });
  }

  private getOnlineMethod(): Observable<LegendResponse[]> {
    return this.projectId === undefined
      ? this._legendService.getLegendPreview(this.versionId)
      : this._legendService.getLegend(this.projectId);
  }

  private initHeight(): void {
    this._contentDiff = this.element.nativeElement.clientHeight - this._contentRef.nativeElement.clientHeight;
    this._contentRef.nativeElement.style.maxHeight = window.innerHeight * 0.5 - ViewerConfig.BOTTOM_BAR_SIZE -
      ViewerConfig.TOP_BAR_SIZE + 'px';
  }

  public afterViewInit(): void {
    this.setStartPosition();
    super.afterViewInit();
  }

  public setStartPosition(): void {
    this.updateSize();
    this.windowParams.y = this.viewHeight - this.windowParams.height + ViewerConfig.TOP_BAR_SIZE - 10;
    this.windowParams.x = this.viewWidth - this.windowParams.width + ViewerConfig.LEFT_BAR_SIZE - 10;
  }

  public showIfActiveLayer(element: LegendResponse): boolean {
    if (!element.layers && !element.interactives) return true;
    if (!element.layers) return false;
    for (const item of element.layers) {
      if (!this._disabledLayers[item]) return true;
    }
    return false;
  }

  public showIfActiveInteractive(element: LegendResponse): boolean {
    if (!element.layers && !element.interactives) return true;
    if (!element.interactives) return false;
    for (const item of element.interactives) {
      if (!this._disabledInteractives[item]) return true;
    }
    return false;
  }

  public onDisableLayers(layers: boolean[]): void {
    this._disabledLayers = layers;
  }

  public onDisableInteractives(interactives: boolean[], customInteractives: boolean[]): void {
    this._disabledInteractives = interactives;
    this._disabledCustomInteractives = customInteractives;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._dragService.removeDrag(this._topResizerDrag);
    this._dragService.removeDrag(this._bottomResizerDrag);
    this._pinchService.removePinch(this._pinch);
    this._layerManagerCommunication.removeDisableLayersListener(this);
    this._layerManagerCommunication.removeDisableInteractivesListener(this);
  }

  public onViewChange(width: number, height: number): void {
    if (this.printPreview) return;
    if (this._contentRef.nativeElement.clientHeight < this._minimalHeight)
      this._contentRef.nativeElement.style.maxHeight = this._minimalHeight + 'px';
    super.onViewChange(width, height);
    if (this._contentRef.nativeElement.clientHeight > this.windowParams.height - this._contentDiff)
      this._contentRef.nativeElement.style.maxHeight = (this.windowParams.height - this._contentDiff) + 'px';
  }

  public getIconHeight(item: LegendResponse, element: HTMLImageElement): string {
    if (!item || !item.retinaIcon || device_pixel_ratio() <= 1) return 'auto';
    return (element.naturalHeight / 2) + 'px';
  }
}
