import {LayerType} from '../../../../../../globals/api/data/enums/layer-type.enum';
import {Size} from '../../../../../../globals/api/data/size';
import {RectSize} from '../../../../../../globals/elements/rect-size';
import {IdbVectorCacheUtil} from '../../../../../../utils/idb-cache-util';
import {CacheRequest} from '../../../../../../utils/models/cache-request';
import {LoadingProgressService} from '../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {LayerResponse} from '../../api/data/layer-response';
import {AbstractLayerResponse} from '../../api/data/layer-response/layers/abstracts/abstract-layer-response';
import {VectorLayerResponse} from '../../api/data/layer-response/layers/vector-layer-response';
import {PrepareComplete} from '../interfaces/prepare-complete';

export abstract class AbstractLayersConstructor<Construct, Cache> implements PrepareComplete {

  public readonly levelSizes: Size[];
  public readonly partSize: Size;

  private readonly _maxSize: Size;
  protected readonly viewRect: RectSize;
  protected construct: Construct;
  protected cacheConstructors: Cache[] = [];
  protected disabledLayers: boolean[] = [];

  private _scale = 0;

  protected constructor(id: string, version: number, data: LayerResponse, protected readonly container: HTMLElement,
                        canvas: HTMLCanvasElement, protected readonly loading: LoadingProgressService) {
    this.levelSizes = data.sizes;
    this.partSize = data.part;
    this._maxSize = data.sizes[0];
    this.viewRect = new RectSize().setRectSize(0, 0, 0, 0);
    this.scale = 0;

    const cacheRequest = this.getCacheRequest(id, version, data);
    setTimeout(() => {
      this.setConstructor(data, cacheRequest, canvas);
      this.checkCache(cacheRequest, data.layers);
    }, 0);
  }

// PREPARE <-----------------------------------------------------------------------------------------------------------------------> PREPARE

  private getCacheRequest(id: string, version: number, data: LayerResponse): CacheRequest {
    const layers: number[] = [];
    data.layers.forEach((layer: AbstractLayerResponse) => {
      if (layer.type !== LayerType.vector) return;
      const vectorLayer = layer as VectorLayerResponse;
      if (!vectorLayer.details || !vectorLayer.details.length) return;
      layers.push(vectorLayer.index);
    });

    return new CacheRequest().setRequest(id, version, layers);
  }

  private checkCache(cacheRequest: CacheRequest, layers: AbstractLayerResponse[]): void {
    const vectorLayers = layers.filter((layer: AbstractLayerResponse) => layer.type === LayerType.vector)
      .map((layer: AbstractLayerResponse) => layer as VectorLayerResponse)
      .filter((layer: VectorLayerResponse) => layer.details && layer.details.length);

    IdbVectorCacheUtil.checkCache(cacheRequest, vectorLayers).then((layersForCache: VectorLayerResponse[]) => {
      if (!layersForCache.length) this.fullCacheComplete();
      else {
        this.loadCalcComplete(layersForCache.map((layer: VectorLayerResponse) => layer.details.length)
          .reduce((a: number, b: number) => a + b));
        this.loading.setCacheCount(layersForCache.length);
        layersForCache.forEach((layer: VectorLayerResponse) => this.setCacheLayer(cacheRequest, layer));
      }
    }).catch((reason: any) => {
      console.log(reason);
      this.fullCacheComplete();
    });
  }

  public drawComplete(): void {
    this.loading.elementCached();
  }

  public loadedComplete(): void {
    this.loading.fileLoaded();
  }

  public cacheComplete(): void {
    this.loading.elementCached();
  }

  public cacheSaveComplete(cache: Cache): void {
    if (!this.cacheConstructors) return;
    const index = this.cacheConstructors.indexOf(cache);
    if (index >= 0) this.cacheConstructors.splice(index, 1);
    if (!this.cacheConstructors.length) setTimeout(() => this.fullCacheComplete(), 0);
  }

  protected fullCacheComplete(): void {
    this.cacheConstructors = [];
    this.loading.cacheComplete();
  }

  public cacheCalcComplete(elements: number): void {
    this.loading.addElementsForCache(elements);
  }

  public loadCalcComplete(elements: number): void {
    this.loading.addFilesToLoad(elements);
  }

  protected abstract setConstructor(data: LayerResponse, cacheRequest: CacheRequest, canvas: HTMLCanvasElement): void;

  protected abstract setCacheLayer(cacheRequest: CacheRequest, layer: VectorLayerResponse): void;

  public abstract draw(): void;

  public abstract setDisabledLayers(layers?: boolean[]): void;

  public abstract resize(width: number, height: number): void;

  public abstract destroy(): void;

// POSITION <---------------------------------------------------------------------------------------------------------------------> POSITION

  public get x(): number {
    return this.viewRect.x;
  }

  public set x(value: number) {
    const width = this.viewRect.width;
    if (value > 0 && width >= this.container.offsetWidth) value = 0;
    if (value < this.container.offsetWidth - width && width > this.container.offsetWidth) value = this.container.offsetWidth - width;
    if (this.container.offsetWidth >= width) value = (this.container.offsetWidth - width) / 2;
    this.viewRect.x = value;
  }

  public get y(): number {
    return this.viewRect.y;
  }

  public set y(value: number) {
    const height = this.viewRect.height;
    if (value > 0 && height >= this.container.offsetHeight) value = 0;
    if (value < this.container.offsetHeight - height && height > this.container.offsetHeight)
      value = this.container.offsetHeight - height;
    if (this.container.offsetHeight >= height) value = (this.container.offsetHeight - height) / 2;
    this.viewRect.y = value;
  }

  public get width(): number {
    return this.viewRect.width;
  }

  public set width(value: number) {
    this.scale = value / this._maxSize.width;
  }

  public get height(): number {
    return this.viewRect.height;
  }

  public set height(value: number) {
    this.scale = value / this._maxSize.height;
  }

  public get scale(): number {
    return this._scale;
  }

  public set scale(value: number) {
    if (value > 1) value = 1;
    const minimalScale = this.minimalScale;
    if (value < minimalScale) value = minimalScale;
    this._scale = value;
    this.viewRect.width = this._maxSize.width * value;
    this.viewRect.height = this._maxSize.height * value;
    this.x = this.viewRect.x;
    this.y = this.viewRect.y;
  }

  public recalculate(): void {
    this.scale = this._scale;
  }

  public get minimalScale(): number {
    return Math.min(this.container.offsetWidth / this._maxSize.width, this.container.offsetHeight / this._maxSize.height);
  }

  public get maxSize(): Size {
    return this._maxSize;
  }
}
