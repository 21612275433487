import {deserialize, serialize} from '@dhkatz/json-ts';
import {BrowserUtil} from '../../../../../../utils/browser-util';
import {CacheRequest} from '../../../../../../utils/models/cache-request';
import {LoadType} from './enums/load-type.enum';
import {LoadingStatus} from './enums/loading-status.enum';
import {Loader} from './loader';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

export class LoadingPool {

  private _raster: any[] = [];
  private _cached: any[] = [];
  private _rasterLoader: Loader | Worker;
  private _cachedLoader: Loader | Worker;

  public constructor(private readonly _loadComplete: any, private readonly _rasterTasks: number = 1,
                     private readonly _cachedTasks: number = 1) {
  }

  public load(data: string | CacheRequest, success: any, error?: any, preLoading?: boolean, offline?: boolean): void {
    const element = {data, success, error, status: LoadingStatus.waiting, preLoading};
    if (data instanceof CacheRequest) {
      this._cached.splice(0, 0, element);
      this.loadCachedPool();
      return;
    }
    this._raster.splice(0, 0, element);
    this.loadRasterPool(offline);
  }

  private loadRasterPool(offline: boolean): void {
    const loadingCount = this._rasterTasks - this.getLoadingCount(this._raster);
    if (loadingCount <= 0) return;
    const loadingPool = this.getLoadingPool(this._raster, loadingCount);
    if (!loadingPool.length) return;
    this.initRasterLoader();
    loadingPool.forEach((element: any) => {
      element.status = LoadingStatus.loading;
      if (this._rasterLoader instanceof Worker) this._rasterLoader.postMessage({
        type: LoadType.raster,
        message: {
          data: element.data,
          offline
        }
      });
      else this._rasterLoader.loadRaster(element.data, offline, (imageData: ImageBitmap | HTMLImageElement) => {
        this.success(this._raster, imageData, element);
        this.loadRasterPool(offline);
      }, () => {
        this.error(this._raster, element);
        this.loadRasterPool(offline);
      });
    });
  }

  private initRasterLoader(): void {
    if (!this._rasterLoader) this._rasterLoader = BrowserUtil.asyncLoad ? this.initWorker() : new Loader();
  }

  private loadCachedPool(): void {
    const loadingCount = this._cachedTasks - this.getLoadingCount(this._cached);
    if (loadingCount <= 0) return;
    const loadingPool = this.getLoadingPool(this._cached, loadingCount);
    if (!loadingPool.length) return;
    this.initCachedLoader();
    loadingPool.forEach((element: any) => {
      element.status = LoadingStatus.loading;
      if (this._cachedLoader instanceof Worker)
        this._cachedLoader.postMessage({type: LoadType.cached, message: serialize(element.data)});
      else this._cachedLoader.loadCached(element.data, (imageData: ImageBitmap | HTMLImageElement) => {
        this.success(this._cached, imageData, element);
        this.loadCachedPool();
      }, () => {
        this.error(this._cached, element);
        this.loadCachedPool();
      });
    });
  }

  private initCachedLoader(): void {
    if (!this._cachedLoader) this._cachedLoader = BrowserUtil.asyncLoad ? this.initWorker() : new Loader();
  }

  private initWorker(): Worker {
    const worker = new Worker('loading.worker.js');
    worker.onmessage = (event: MessageEvent) => {
      if (this[event.data.type]) this[event.data.type](event.data.message);
    };
    return worker;
  }

  private rasterSuccess(message: any): void {
    this.success(this._raster, message.imageData, this.getElement(this._raster, message.data));
    this.loadRasterPool(message.offline);
  }

  private rasterError(message: any): void {
    this.error(this._raster, this.getElement(this._raster, message));
    this.loadRasterPool(message.offline);
  }

  private cachedSuccess(message: any): void {
    this.success(this._cached, message.imageData, this.getElement(this._cached, deserializeFix(CacheRequest, message.data)));
    this.loadCachedPool();
  }

  private cachedError(message: any): void {
    this.error(this._cached, this.getElement(this._cached, deserializeFix(CacheRequest, message)));
    this.loadCachedPool();
  }

  private getElement(list: any[], data: string | CacheRequest): any {
    return list.find((element: any) => data instanceof CacheRequest && element.data instanceof CacheRequest
      ? element.data.equal(data) : element.data === data && element.status === LoadingStatus.loading);
  }

  private getLoadingCount(list: any[]): number {
    return list.filter((element: any) => element.status === LoadingStatus.loading).length;
  }

  private getLoadingPool(list: any[], length: number): any[] {
    const pool: any[] = [];
    if (!list.length) return pool;
    for (const element of list) {
      if (element.status !== LoadingStatus.waiting) continue;
      pool.push(element);
      if (pool.length === length) return pool;
    }
    return pool;
  }

  private success(list: any[], imageData: ImageBitmap | HTMLImageElement, element: any): void {
    if (!element || !list.includes(element)) this.clearImageData(imageData);
    else {
      element.status = LoadingStatus.complete;
      if (!element.success) this.clearImageData(imageData);
      else element.success(imageData);
    }
    if (element && element.preLoading) this._loadComplete();
  }

  private clearImageData(imageData: ImageBitmap | HTMLImageElement): void {
    if (BrowserUtil.asyncLoad && imageData instanceof ImageBitmap) imageData.close();
    else (imageData as HTMLImageElement).remove();
  }

  private error(list: any[], element: any): void {
    if (!element) return;
    if (element.preLoading) this._loadComplete();
    if (!list.includes(element)) return;
    element.status = LoadingStatus.complete;
    if (element.error) element.error();
  }

  public destroy(): void {
    this._cached = [];
    this._raster = [];
    this._rasterLoader = this.destroyLoader(this._rasterLoader);
    this._cachedLoader = this.destroyLoader(this._cachedLoader);
  }

  private destroyLoader(loader: Worker | Loader): Worker | Loader {
    if (loader && loader instanceof Worker) loader.terminate();
    return undefined;
  }

  public stopLoading(): void {
    this._cached = this._raster.filter((element: any) => element.status === LoadingStatus.loading);
    this._raster = this._raster.filter((element: any) => (element.preLoading && element.status !== LoadingStatus.complete) ||
      element.status === LoadingStatus.loading);
  }

  public clear(): void {
    this._cached = [];
    this._raster = this._raster.filter((element: any) => element.preLoading && !element.complete);
  }
}
