import {JsonProperty} from '@dhkatz/json-ts';
import * as CryptoJS from 'crypto-js';
import {ArrayNumberConverter} from '../../globals/api/json/array-number-converter';

export class CacheRequest {

  @JsonProperty('id')
  private _id: string;

  @JsonProperty('version')
  private _version: number;

  @JsonProperty({name: 'layers', converter: ArrayNumberConverter.converter, type: Object})
  private _layers: number[];

  @JsonProperty('layer')
  private _layer: number;

  @JsonProperty('level')
  private _level: number;

  @JsonProperty('row')
  private _row: number;

  @JsonProperty('col')
  private _col: number;

  public constructor() {
    this._id = undefined;
    this._version = undefined;
    this._layers = undefined;
    this._layer = undefined;
    this._level = undefined;
    this._row = undefined;
    this._col = undefined;
  }

  public setRequest(id: string, version: number, layers: number[]): CacheRequest {
    this._id = id;
    this._version = version;
    this._layers = layers;
    return this;
  }

  public setLayer(value: number): CacheRequest {
    this._layer = value;
    return this;
  }

  public setLevel(value: number): CacheRequest {
    this._level = value;
    return this;
  }

  public setRow(value: number): CacheRequest {
    this._row = value;
    return this;
  }

  public setCol(value: number): CacheRequest {
    this._col = value;
    return this;
  }

  public get row(): number {
    return this._row;
  }

  public get col(): number {
    return this._col;
  }

  public get db(): string {
    return this._id;
  }

  public get version(): number {
    return this._version;
  }

  public get collections(): string[] {
    return this._layers.map((value: number) => CryptoJS.MD5('l' + value).toString(CryptoJS.enc.Hex));
  }

  public get collection(): string {
    return CryptoJS.MD5('l' + this._layer).toString(CryptoJS.enc.Hex);
  }

  public get cacheCollection(): string {
    return this._id + '_' + this._layer;
  }

  public get key(): string {
    return CryptoJS.MD5(this._level + '_' + this._col + '_' + this._row).toString(CryptoJS.enc.Hex);
  }

  public clone(): CacheRequest {
    return new CacheRequest().setRequest(this._id, this._version, this._layers).setLayer(this._layer).setLevel(this._level)
      .setCol(this._col).setRow(this._row);
  }

  public equal(request: CacheRequest): boolean {
    // if (this._id === request._id && this._version === request._version && this._layer === request._layer && this._level === request._level)
    //   console.log(this._row, request._row, this._row === request._row, this._col, request._col, this._col === request._col)
    return this._id === request._id && this._version === request._version && this._layer === request._layer &&
      this._level === request._level && this._row === request._row && this._col === request._col;
  }
}
