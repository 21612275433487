<div class="container-box">
  <div class="account-part role-container">
    <div class="box-title">
      {{'profile.role.title' | translate}}
      <mat-icon class="edit-icon" *ngIf="!enableEdit" (click)="enableEdit = true">edit</mat-icon>
    </div>
    <form *ngIf="enableEdit" [ngClass]="{'loading': showSpinner}">
      <mat-form-field>
        <mat-label>{{'profile.role.placeholder' | translate}}</mat-label>
        <mat-select [formControl]="roleField" [panelClass]="'role-edit'">
          <mat-option *ngFor="let element of roles" [value]="element">
            {{'profile.role.type.' + element?.toString().toLowerCase() | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="buttons">
        <button class="cancel-button" (click)="cancel()">{{'system.label.cancel' | translate}}</button>
        <button class="submit-button" (click)="submit()">{{'profile.role.label' | translate}}</button>
      </div>
    </form>
    <div *ngIf="!enableEdit" class="single-data">
      {{'profile.role.title' | translate}}: <span
      class="data">{{'profile.role.type.' + role?.toString().toLowerCase() | translate}}</span>
    </div>
    <app-spinner *ngIf="showSpinner" class="full"></app-spinner>
  </div>
</div>
