import {OfflineDBEntry} from '../../../../../../offline/services/offline-service/data/OfflineDBEntry';
import {BrowserUtil} from '../../../../../../utils/browser-util';
import {IdbVectorCacheUtil} from '../../../../../../utils/idb-cache-util';
import {IdbUtil} from '../../../../../../utils/idb-util';
import {CacheRequest} from '../../../../../../utils/models/cache-request';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

export class Loader {

  public constructor() {
  }

  public loadRaster(data: string, offline: boolean, success: any, error: any): void {
    if (offline) {
      this.loadRasterOffline(data, success, error);
      return;
    }
    if (BrowserUtil.asyncLoad) this.asyncLoadRaster(data, success, error);
    else this.syncLoadRaster(data, success, error);
  }

  private error(error: any): void {
    if (error) error();
  }

  private success(success: any, imageData: ImageBitmap | HTMLImageElement): void {
    if (success) success(imageData);
    else {
      if (BrowserUtil.asyncLoad && imageData instanceof ImageBitmap) imageData.close();
      else (imageData as HTMLImageElement).remove();
    }
  }

  private asyncLoadRaster(data: string, success: any, error: any): void {
    fetch(new Request(data, {
      credentials: 'omit',
      mode: 'cors'
    })).then((response: Response) => {
      if (response.status < 200 || response.status > 206) return;
      return response.blob().then((blob: Blob) => this.asyncConvertBlob(blob, success, error)).catch((reason: any) => {
        console.log(reason, data);
        this.error(error);
      });
    }).catch((reason: any) => {
      console.log(reason, data);
      this.error(error);
    });
  }

  private syncLoadRaster(data: string, success: any, error: any): void {
    const image = document.createElement('img');
    image.onload = () => this.success(success, image);
    image.onerror = (reason: any) => {
      console.log(reason, data, image);
      this.error(error);
    };
    image.src = data;
  }

  public loadCached(data: CacheRequest, success: any, error: any): void {
    if (BrowserUtil.asyncLoad || !document) this.asyncLoadCached(data, success, error);
    else this.syncLoadCached(data, success, error);
  }

  private asyncLoadCached(data: CacheRequest, success: any, error: any): void {
    IdbVectorCacheUtil.cache(data).get().then((blob: Blob) => {
      return blob ? this.asyncConvertBlob(blob, success, error) : this.error(error);
    }).catch((reason: any) => {
      console.log(reason, data);
      this.error(error);
    });
  }

  private syncLoadCached(data: CacheRequest, success: any, error: any): void {
    IdbVectorCacheUtil.cache(data).get().then((blob: Blob) => {
      return blob ? this.syncConvertBlob(blob, success, error) : this.error(error);
    }).catch((reason: any) => {
      console.log(reason, data);
      this.error(error);
    });
  }

  private asyncConvertBlob(blob: Blob, success: any, error: any): Promise<any> {
    return createImageBitmap(blob).then((imageData: ImageBitmap) => this.success(success, imageData))
      .catch((reason: any) => {
        console.log(reason, blob);
        this.error(error);
      });
  }

  private syncConvertBlob(blob: Blob, success: any, error: any): Promise<any> {
    return this.createImage(blob, error).then((imageData: HTMLImageElement) => this.success(success, imageData))
      .catch((reason: any) => {
        console.log(reason, blob);
        this.error(error);
      });
  }

  private async createImage(blob: Blob, error: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let dataURL;
      dataURL = URL.createObjectURL(blob);
      const img = document.createElement('img');
      img.onload = () => resolve(img);
      img.onerror = (reason: any) => {
        reject();
        console.log(reason, blob);
        this.error(error);
      };
      img.src = dataURL;
    });
  }

  private loadRasterOffline(data: string, success: any, error: any): void {
    const versionID = data.split('/')[5];
    setTimeout(() => {
      IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION)
        .getKeyForCondition((value: any) => deserializeFix(OfflineDBEntry, value).versionId === versionID)
        .then((projectId: string) => {
          OfflineUtil.loadFileSync(projectId, data).then((file: Blob) => {
            if (file) this.asyncConvertBlob(file.slice(0, file.size, file.type), success, error)
              .catch((reason) => console.log(reason));
          });
        });
    }, 0);
  }
}
