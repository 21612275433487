import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {OfflineService} from '../../../../../offline/services/offline-service/offline.service';
import {CopyrightsResponse} from '../../api/data/copyrights-response';
import {CopyrightsOfflineService} from '../../api/services/copyrights-offline.service';
import {CopyrightsService} from '../../api/services/copyrights.service';

@Component({
  selector: 'app-copyrights',
  templateUrl: './copyrights.component.html',
  styleUrls: ['./copyrights.component.scss']
})
export class CopyrightsComponent implements OnInit {

  @Input()
  public versionId: string;

  @Input()
  public projectId: string;

  public isOffline: boolean;

  public copyrights: CopyrightsResponse;

  public constructor(private _copyrightsService: CopyrightsService, private readonly _copyrightsOfflineService: CopyrightsOfflineService,
                     private _sanitizer: DomSanitizer, private readonly _offline: OfflineService) {
  }

  public ngOnInit(): void {
    setTimeout(() => this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      this.isOffline = result;
      setTimeout(() => {
        const requestMethod = this.isOffline
          ? this._copyrightsOfflineService.getCopyrights(this.projectId)
          : this.getOnlineMethod();
        requestMethod.subscribe((response: CopyrightsResponse) => this.copyrights = response);
      }, 0);
    }), 0);
  }

  private getOnlineMethod(): Observable<CopyrightsResponse> {
    return this.projectId === undefined
      ? this._copyrightsService.getCopyrightsPreview(this.versionId)
      : this._copyrightsService.getCopyrights(this.projectId);
  }

  public getCopyrightsStyle(): any {
    const color = !this.copyrights || !this.copyrights.color ? '#000' : this.copyrights.color;
    return this._sanitizer.bypassSecurityTrustStyle(`color: ${color};`);
  }
}
