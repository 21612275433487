import {JsonProperty} from '@dhkatz/json-ts';

export class UpdateMyEmailRequest {

  @JsonProperty('email')
  private _email: string;

  @JsonProperty('password')
  private _password: string;

  public constructor() {
    this._email = undefined;
    this._password = undefined;
  }

  public get email(): string {
    return this._email;
  }

  public set email(value: string) {
    this._email = value;
  }

  public get password(): string {
    return this._password;
  }

  public set password(value: string) {
    this._password = value;
  }
}
