import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plain'
})
export class PlainPipe implements PipeTransform {

  public transform(value: string): any {
    return value ? String(value).replace(/<[^>]+>/gm, '')
      .replace(new RegExp('&quot;', 'g'), '"') : '';
  }
}
