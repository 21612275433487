<div class="manage-offline" *ngIf="!showSpinner">
  <div *ngIf="!hasAccess" class="single-data error">
    <div class="icon"><mat-icon>clear</mat-icon></div>
    <p class="data-label">{{'offlineManagement.notification.expired.title' | translate}}</p>
    <p class="data">{{'offlineManagement.notification.expired.description' | translate}}</p>
  </div>
  <div *ngIf="hasAccess && workerService.isLoggedIn && isUpdateAvailable" class="single-data warn">
    <p class="data-label">{{'offlineManagement.notification.update.title' | translate}}</p>
    <p class="data">{{'offlineManagement.notification.update.description' | translate}}</p>
  </div>
  <div *ngIf="hasAccess && needRefresh" class="single-data warn">
    <div class="icon"><mat-icon>update</mat-icon></div>
    <p class="data-label">{{'offlineManagement.notification.neeRefresh.title' | translate}}</p>
    <p class="data" *ngIf="workerService.isLoggedIn">{{'offlineManagement.notification.neeRefresh.refresh' | translate}}</p>
    <p class="data" *ngIf="!workerService.isLoggedIn">{{'offlineManagement.notification.neeRefresh.login' | translate}}</p>
  </div>
  <div *ngIf="isDownloadInProgress" class="single-data">
    <div class="icon download"><img height="18" src="/assets/images/downloaded.png"
                           srcset="/assets/images/downloaded.png 1x, /assets/images/downloaded_2x.png 2x"
                           width="18"></div>
    <p class="data-label">{{'offlineManagement.progress.title' | translate}}</p>
    <p class="data" *ngIf="progress > 0" translate [translateParams]="{progress: getProgress()}">offlineManagement.progress.progress</p>
    <p class="data" *ngIf="progress === 0">{{'offlineManagement.progress.preparing' | translate}}...</p>
  </div>
  <div class="menu" *ngIf="!isDownloadInProgress" [matMenuTriggerFor]="offlineMenu">
    <mat-icon class="top-menu">more_horiz</mat-icon>
    <mat-menu #offlineMenu="matMenu" [overlapTrigger]="false" class="top-menu-expanded">
      <button (click)="openProject(projectId, categoryId)"
              *ngIf="isDownloaded && !isDownloadInProgress && hasAccess && !needRefresh" class="mat-button"
              mat-menu-item>
        <mat-icon fontSet="material-icons-outlined">play_arrow</mat-icon>{{'offlineManagement.menu.open' | translate}}
      </button>
      <button (click)="downloadProjectFiles()" *ngIf="workerService.isLoggedInOnline && isDownloaded"
              [disabled]="isDownloadInProgress" class="mat-button" mat-menu-item>
        <img height="24" src="/assets/images/refresh.png"
             srcset="/assets/images/refresh.png 1x, /assets/images/refresh_2x.png 2x" width="24">
        <ng-container *ngIf="isUpdateAvailable">{{'offlineManagement.menu.update' | translate}}</ng-container>
        <ng-container *ngIf="!isUpdateAvailable">{{'offlineManagement.menu.refresh' | translate}}</ng-container>
      </button>
      <button (click)="remove(projectId, projectTitle)"
              *ngIf="isDownloaded && !isDownloadInProgress && hasAccess" class="mat-button" mat-menu-item>
        <img height="24" src="/assets/images/delete.png"
             srcset="/assets/images/delete.png 1x, /assets/images/delete_2x.png 2x" width="24">{{'offlineManagement.menu.remove' | translate}}
      </button>
    </mat-menu>
  </div>
</div>
<app-spinner *ngIf="showSpinner" class="full"></app-spinner>


