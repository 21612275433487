import {ElementRef} from '@angular/core';
import {Drag} from '../interafces/drag';

export class DragComponent implements Drag {

  private readonly _componentTarget: ElementRef;
  private readonly _onDrag: (event: any, x?: number, y?: number, startInfo?: any) => void;
  private readonly _onStartDrag: (event: any) => void;
  private readonly _onStopDrag: (event: any) => void;

  public constructor(componentTarget: ElementRef, onDrag: (event: any, x?: number, y?: number, startInfo?: any) => void,
                     onStartDrag?: (event: any) => void, onStopDrag?: (event: any) => void) {
    this._componentTarget = componentTarget;
    this._onDrag = onDrag;
    this._onStartDrag = onStartDrag;
    this._onStopDrag = onStopDrag;
  }

  public get element(): ElementRef {
    return this._componentTarget;
  }

  public onDrag(event: any, x?: number, y?: number, startEvent?: any): void {
    if (this._onDrag) this._onDrag(event, x, y, startEvent);
  }

  public onStartDrag(event: any): void {
    if (this._onStartDrag) this._onStartDrag(event);
  }

  public onStopDrag(event: any): void {
    if (this._onStopDrag) this._onStopDrag(event);
  }
}
