export class CarriageInfo {

  private _element: number;
  private _carriage: number;

  public constructor() {
    this._element = undefined;
    this._carriage = undefined;
  }

  public get element(): number {
    return this._element;
  }

  public set element(value: number) {
    this._element = value;
  }

  public get carriage(): number {
    return this._carriage;
  }

  public set carriage(value: number) {
    this._carriage = value;
  }

  public get isEmpty(): boolean {
    return this._carriage === undefined && this._element === undefined;
  }
}
