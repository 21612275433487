import {JsonProperty} from '@dhkatz/json-ts';
import {Size} from '../../../../../../globals/api/data/size';
import {VectorsListConverter} from '../json/vectors-list-converter';
import {DrawElement} from './vector-file-response/abstracts/draw-element';

export class VectorFileResponse {

  @JsonProperty({name: 's', type: Size})
  private _size: Size;

  @JsonProperty({name: 'd', converter: VectorsListConverter.converter, type: Object})
  private _data: DrawElement[];

  public constructor() {
    this._size = undefined;
    this._data = undefined;
  }

  public get size(): Size {
    return this._size;
  }

  public get data(): DrawElement[] {
    return this._data;
  }
}
