import {ElementRef, Injectable} from '@angular/core';
import {PinchService} from '../pinch-service/pinch.service';
import {Drag} from './interafces/drag';

@Injectable({
  providedIn: 'root'
})
export class DragService {

  public constructor(private readonly _pinchService: PinchService) {
    window.onmousemove = DragService.onDrag;
    window.onmouseup = DragService.onStopDrag;
  }

  private static readonly _dragList: Map<Drag, any> = new Map<Drag, any>();

  private static onDrag(event: MouseEvent): void {
    if (!DragService._dragList.size) return;
    DragService._dragList.forEach((info: any, drag: Drag) => {
      if (!info || !info.active || !info.startEvent || !drag) return;
      const posX = event.clientX - info.lastEvent.clientX;
      const posY = event.clientY - info.lastEvent.clientY;
      info.lastEvent = event;
      drag.onDrag(event, posX, posY, info.startInfo);
    });
  }

  private static onStopDrag(event: MouseEvent): void {
    if (!DragService._dragList.size) return;
    DragService._dragList.forEach((info: any, drag: Drag) => {
      if (!info || !info.active || !info.startEvent || !drag) return;
      info.startEvent = undefined;
      info.lastEvent = undefined;
      info.active = undefined;
      info.startInfo = undefined;
      drag.onStopDrag(event);
    });
  }

  public addDrag(drag: Drag, withoutMouse?: boolean, withPinch?: boolean): void {
    const info: any = {events: new Map<string, any>(), withPinch};
    if (!withoutMouse) info.events.set('mousedown', (event: MouseEvent) => this.onStartDrag(event, drag));
    info.events.set('touchstart', (event: TouchEvent) => this.onTouchStartDrag(event, drag));
    info.events.set('touchmove', (event: TouchEvent) => this.onTouchDrag(event, drag));
    info.events.set('touchend', (event: TouchEvent) => this.onTouchStopDrag(event, drag));
    info.events.set('touchcancel', (event: TouchEvent) => this.onTouchStopDrag(event, drag));
    info.events.forEach((value: any, key: string) => drag.element.nativeElement.addEventListener(key, value));
    DragService._dragList.set(drag, info);
    if (withPinch) this._pinchService.addPinch(drag as any, true);
  }

  public removeDrag(drag: Drag): void {
    const info = DragService._dragList.get(drag);
    if (info && info.events)
      info.events.forEach((value: any, key: string) => drag.element.nativeElement.removeEventListener(key, value));
    DragService._dragList.delete(drag);
    if (info && info.withPinch) this._pinchService.removePinch(drag as any);
  }

  private onStartDrag(event: MouseEvent, drag: Drag): void {
    const info = DragService._dragList.get(drag);
    const element = event.target as HTMLElement;
    if (!element.classList.contains('drag') || !info) return;
    info.lastEvent = event;
    info.startEvent = event;
    info.startInfo = this.getStartInfo(event, drag);
    info.active = true;
    drag.onStartDrag(event);
  }

  private onTouchStartDrag(event: TouchEvent, drag: Drag): void {
    const info = DragService._dragList.get(drag);
    if (!info) return;
    if (info.withPinch) this._pinchService.onStartPinch(event, drag as any);
    const element = event.target as HTMLElement;
    if (!this.isPrimaryTouch(event) || !element.classList.contains('drag')) return;
    info.diff = this.getTouchesDiff(event.changedTouches.item(0), this.getPrimaryTouch(event, info.primary), info.diff);
    info.primary = this.getPrimaryTouchIdentifier(event);
    if (event.targetTouches.length > 1) return;
    info.lastEvent = event;
    info.startEvent = event;
    info.startInfo = this.getStartInfo(event.changedTouches.item(0), drag);
    drag.onStartDrag(event.changedTouches.item(0));
  }

  private onTouchDrag(event: TouchEvent, drag: Drag): void {
    const info = DragService._dragList.get(drag);
    if (!info) return;
    if (!info.startEvent || !this.isPrimaryTouch(event)) return;
    const posX = event.changedTouches.item(0).clientX - info.lastEvent.changedTouches.item(0).clientX - info.diff.x;
    const posY = event.changedTouches.item(0).clientY - info.lastEvent.changedTouches.item(0).clientY - info.diff.y;
    info.diff = {x: 0, y: 0};
    info.lastEvent = event;
    drag.onDrag(event.targetTouches.item(0), posX, posY, info.startInfo);
    if (info.withPinch) this._pinchService.onPinch(event, drag as any, {x: posX, y: posY});
  }

  private onTouchStopDrag(event: TouchEvent, drag: Drag): void {
    const info = DragService._dragList.get(drag);
    if (!info) return;
    if (info.withPinch) this._pinchService.onStopPinch(event, drag as any);
    if (!this.isPrimaryTouch(event) || !info.startEvent) return;
    info.primary = this.getPrimaryTouchIdentifier(event);
    info.diff = this.getTouchesDiff(this.getPrimaryTouch(event, info.primary), event.changedTouches.item(0), info.diff);
    if (event.targetTouches.length) return;
    info.startEvent = undefined;
    info.lastEvent = undefined;
    info.startInfo = undefined;
    info.primary = undefined;
    info.diff = {x: 0, y: 0};
    drag.onStopDrag(event.changedTouches.item(0));
  }

  private getStartInfo(event: any, drag: Drag): { x: number, y: number, offsetX: number, offsetY: number } {
    const offset = this.getElementOffset(drag.element, event);
    return {x: event.clientX, y: event.clientY, offsetX: offset.x, offsetY: offset.y};
  }

  private isPrimaryTouch(event: TouchEvent): boolean {
    const currentIdentifier = event.changedTouches.item(0).identifier;
    for (let i = 0; i < event.targetTouches.length; i++) {
      if (currentIdentifier > event.targetTouches.item(i).identifier) return false;
    }
    return true;
  }

  private getPrimaryTouchIdentifier(event: TouchEvent): number {
    let primaryIdentifier = 9999999;
    for (let i = 0; i < event.targetTouches.length; i++) {
      primaryIdentifier = Math.min(primaryIdentifier, event.targetTouches.item(0).identifier);
    }
    return primaryIdentifier;
  }

  private getPrimaryTouch(event: TouchEvent, identifier?: number): Touch {
    let primary = event.targetTouches.item(0);
    for (let i = 0; i < event.targetTouches.length; i++) {
      if (event.targetTouches.item(i).identifier === identifier) return event.targetTouches.item(i);
      if (primary.identifier > event.targetTouches.item(i).identifier) primary = event.targetTouches.item(i);
    }
    return primary;
  }

  private getTouchesDiff(newPrimary: Touch, oldPrimary: Touch, diff: any): any {
    if (!diff) diff = {x: 0, y: 0};
    return !newPrimary || !oldPrimary || newPrimary.identifier === oldPrimary.identifier ? diff :
      {x: diff.x + newPrimary.clientX - oldPrimary.clientX, y: diff.y + newPrimary.clientY - oldPrimary.clientY};
  }

  public getElementOffset(element: ElementRef, event: any): { x: number, y: number } {
    return {
      x: event.clientX - element.nativeElement.getBoundingClientRect().left,
      y: event.clientY - element.nativeElement.getBoundingClientRect().top
    };
  }

  public getElementPosition(element: ElementRef): { x: number, y: number } {
    return {
      x: element.nativeElement.getBoundingClientRect().left,
      y: element.nativeElement.getBoundingClientRect().top
    };
  }
}
