import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PointType} from '../../../../../../../../interactive/api/data/points/enums/point-type.enum';
import {TextPointResponse} from '../../../../../../../../interactive/api/data/points/text-point-response';
import {TextPointRequest} from '../../../../../../../../interactive/modules/points/api/data/text-point-request';
import {PointService} from '../../../../../../../../interactive/modules/points/api/services/point.service';
import {CustomInteractivesCommunicationService} from '../../../../../communiaction/custom-interactives-communication.service';
import {AbstractCustomPointComponent} from '../abstracts/abstract-custom-point.component';

@Component({
  selector: 'app-text-point-dialog',
  templateUrl: './text-point-dialog.component.html',
  styleUrls: ['./text-point-dialog.component.scss']
})
export class TextPointDialogComponent extends AbstractCustomPointComponent<TextPointRequest, TextPointResponse> implements OnInit {

  public removePoint = false;

  public constructor(service: PointService, communication: CustomInteractivesCommunicationService,
                     @Inject(MAT_DIALOG_DATA) data: any, dialogRef: MatDialogRef<TextPointDialogComponent>) {
    super(service, communication, dialogRef, data);
    this.pointType = PointType.text;
  }

  protected getRequest(): TextPointRequest {
    return new TextPointRequest();
  }
}
