import {Component, OnInit} from '@angular/core';
import {AbstractRoleComponent} from '../../../globals/screens/abstracts/abstract-role.component';
import {ServiceWorkerService} from '../../../web-workers/service/service-worker.service';
import {SubscriptionStatus} from '../../api/data/enums/subscription-status';
import {SubscriptionResponse} from '../../api/data/subscription-response';
import {SubscriptionService} from '../../api/service/subscription.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent extends AbstractRoleComponent implements OnInit {

  public activeSubscriptions: SubscriptionResponse[] = [];
  public expiredSubscriptions: SubscriptionResponse[] = [];
  public cancelledSubscriptions: SubscriptionResponse[] = [];

  public showSpinner = true;

  public constructor(private readonly _service: SubscriptionService, workerService: ServiceWorkerService) {
    super(workerService);
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this._service.getSubscriptions().subscribe((response: SubscriptionResponse[]) => {
        response.forEach((subscription: SubscriptionResponse) => {
          switch (subscription.status) {
            case SubscriptionStatus.ACTIVE:
              this.activeSubscriptions.push(subscription);
              break;
            case SubscriptionStatus.EXPIRED:
              this.expiredSubscriptions.push(subscription);
              break;
            case SubscriptionStatus.CANCELLED:
              this.cancelledSubscriptions.push(subscription);
              break;
          }
          this.activeSubscriptions.sort(
            (first: SubscriptionResponse, second: SubscriptionResponse) => this.getForSort(second) - this.getForSort(first));
        });
      }, () => this.showSpinner = false, () => this.showSpinner = false);
    });
  }

  private getForSort(subscription: SubscriptionResponse): number {
    return subscription.expirationDate ? subscription.expirationDate.getTime() : Number.MAX_VALUE;
  }

  public newSubscription(subscription: SubscriptionResponse): void {
    if (subscription == null) return;
    switch (subscription.status) {
      case SubscriptionStatus.ACTIVE:
        this.activeSubscriptions.push(subscription);
        break;
      case SubscriptionStatus.EXPIRED:
        this.expiredSubscriptions.push(subscription);
        break;
      case SubscriptionStatus.CANCELLED:
        this.cancelledSubscriptions.push(subscription);
        break;
    }
  }
}
