import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {VersionResponse} from '../data/version-response';
import {VersionEndpoints} from '../version-endpoints';
import {deserializeFix} from '../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getVersion(projectId: string): Observable<VersionResponse> {
    return this._http.get<JsonObject>(VersionEndpoints.VERSION.replace(VersionEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonObject) => deserializeFix(VersionResponse, response)));
  }

  public getVersionPreview(versionId: string): Observable<VersionResponse> {
    return this._http.get<JsonObject>(VersionEndpoints.VERSION_PREVIEW.replace(VersionEndpoints.VERSION_ID, versionId))
      .pipe(map((response: JsonObject) => deserializeFix(VersionResponse, response)));
  }
}
