<div class="system-dialog offline-authorization-container">
  <header>{{'offlineManagement.loginDialog.title' | translate}}
    <span class="close" (click)="closeDialog()"><mat-icon>close</mat-icon></span>
  </header>
  <div class="system-dialog-body">
    <form>
      <mat-form-field>
        <input [formControl]="emailField" matInput [placeholder]="'system.form.placeholder.email' | translate"
               type="email">
        <mat-error *ngIf="emailField.hasError('required')"
                   [innerHTML]="'profile.email.error.required' | translate">
        </mat-error>
        <mat-error *ngIf="emailField.hasError('email') && !emailField.hasError('required')"
                   [innerHTML]="'profile.email.error.incorrect' | translate">
        </mat-error>
      </mat-form-field>
      <button class="submit-button" (click)="onSubmit()">{{'system.label.save' | translate}}</button>
    </form>
  </div>
</div>
