<button (click)="openProject()">{{'offlineManagement.menu.open' | translate}}</button>
<div class="icon-button download" (click)="downloadProjectFiles(false)"
     *ngIf="!showSpinner && offlineAvailable && workerService?.isLoggedIn && status && (isDownloadAvailable || !hasAccess) && !isDownloadInProgress && !isUpdateAvailable">
  <img
    height="20" src="/assets/images/downloaded.png"
    srcset="/assets/images/downloaded.png 1x, /assets/images/downloaded_2x.png 2x"
    width="20"
    [matTooltip]="'offlineManagement.menu.tooltip.download' | translate"
    matTooltipPosition="above">
</div>
<div class="icon-button reload" matBadge="!" matBadgePosition="above before" matBadgeColor="warn"
     [matBadgeHidden]="!isUpdateAvailable" (click)="downloadProjectFiles(true)"
     *ngIf="!showSpinner && offlineAvailable && workerService?.isLoggedInOnline && status && hasAccess && !isDownloadInProgress && isDownloaded">
  <img *ngIf="isUpdateAvailable"
       height="20" src="/assets/images/reload.png"
       srcset="/assets/images/reload.png 1x, /assets/images/reload_2x.png 2x"
       width="20"
       [matTooltip]="'offlineManagement.menu.tooltip.update' | translate"
       matTooltipPosition="above">
  <img *ngIf="!isUpdateAvailable"
       height="20" src="/assets/images/reload.png"
       srcset="/assets/images/reload.png 1x, /assets/images/reload_2x.png 2x"
       width="20"
       [matTooltip]="'offlineManagement.menu.tooltip.reDownload' | translate"
       matTooltipPosition="above">
</div>
<div class="icon-button delete" (click)=" remove(project?.id, project?.title)"
     *ngIf="!showSpinner && offlineAvailable && status && isDownloaded && hasAccess">
  <img
    height="24" src="/assets/images/delete.png"
    srcset="/assets/images/delete.png 1x, /assets/images/delete_2x.png 2x"
    width="24"
    [matTooltip]="'offlineManagement.menu.tooltip.remove' | translate"
    matTooltipPosition="above">
</div>
<app-spinner *ngIf="showSpinner" [strokeWidth]="2" [diameter]="20"></app-spinner>
<ng-container *ngIf="status && isDownloadInProgress">
  <mat-progress-bar [value]="getProgress()"
                    [mode]="progress > 0 ? 'determinate' : 'indeterminate'"></mat-progress-bar>
  <p class="progress-info" *ngIf="progress > 0">{{getProgress() + '%'}}</p>
  <p class="progress-info" *ngIf="progress === 0">{{'offlineManagement.progress.preparing' | translate}}...</p>
</ng-container>
