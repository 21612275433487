import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Json, JsonArray, JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IconResponse} from '../../../../../../../interactive/api/data/icon-response';
import {CustomIconsEndpoints} from '../custom-icons-endpoints';
import {deserializeFix} from '../../../../../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getIcons(): Observable<IconResponse[]> {
    return this._http.get<JsonArray>(CustomIconsEndpoints.ICONS)
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(IconResponse, value))));
  }

  public uploadIcon(fileToUpload: File): Observable<IconResponse> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this._http.post<JsonObject>(CustomIconsEndpoints.ICONS, formData)
      .pipe(map((response: JsonObject) => deserializeFix(IconResponse, response)));
  }

  public deleteIcon(id: string): Observable<void> {
    return this._http.delete<void>(CustomIconsEndpoints.ICON.replace(CustomIconsEndpoints.ICON_ID, id));
  }
}
