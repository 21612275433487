<mat-dialog-content class="add-point-container">
  <div class="container">
    <p>{{'project.interactive.custom.info.removePoint'| translate}}</p>
    <footer class="buttons interactive-points-footer">
      <button mat-button class="cancel-button"
              (click)="closeRemoveDialog()">{{'system.label.cancel' | translate}}</button>
      <button mat-button class="submit-button" (click)="delete()">{{'system.label.remove' | translate}}</button>
    </footer>
  </div>
</mat-dialog-content>
