import {JsonProperty} from '@dhkatz/json-ts';
import {ArrayNumberConverter} from '../../../../../../../../globals/api/json/array-number-converter';
import {DrawElement} from '../abstracts/draw-element';
import {VectorBorderElement} from '../interfaces/vector-border-element';
import {Gradient} from './gradient';

export class Line extends DrawElement implements VectorBorderElement {

  @JsonProperty('sS')
  private _strokeStyle: string;

  @JsonProperty({name: 'sG', type: Gradient})
  private _strokeGradient: Gradient;

  @JsonProperty('lW')
  private _lineWidth: number;

  @JsonProperty({name: 'dA', converter: ArrayNumberConverter.converter, type: Object})
  private _dashArray: number[];

  @JsonProperty({name: 'd', converter: ArrayNumberConverter.converter, type: Object})
  private _draw: number[];

  public constructor() {
    super();
    this._strokeStyle = undefined;
    this._strokeGradient = undefined;
    this._lineWidth = undefined;
    this._dashArray = undefined;
    this._draw = undefined;
  }

  public get strokeStyle(): string {
    return this._strokeStyle;
  }

  public get strokeGradient(): Gradient {
    return this._strokeGradient;
  }

  public get lineWidth(): number {
    return this._lineWidth;
  }

  public get dashArray(): number[] {
    return this._dashArray;
  }

  public get draw(): number[] {
    return this._draw;
  }
}
