<ng-container *ngIf="ready && !showSpinner">
  <div class="left-bar bar">
  </div>
  <div class="top-bar bar">
    <app-back *ngIf="showBack" [backLink]="backLink"></app-back>
    <app-breadcrumb [categoryId]="categoryId" [projectId]="projectId" [versionId]="versionId" [(title)]="title"
                    (backLink)="backLink = $event"></app-breadcrumb>
    <app-top-menu [projectId]="projectId" [showPrint]="showPrint" (reload)="reloadProject()"
                  [showReload]="showReload">
    </app-top-menu>
    <app-user-menu [disabled]="disableAccount"></app-user-menu>
    <app-search-button></app-search-button>
    <app-messages-button *ngIf="isLoggedIn"></app-messages-button>
  </div>
  <div class="right-bar bar">
  </div>
  <div class="bottom-bar bar">
    <app-logo></app-logo>
  </div>
  <router-outlet *ngIf="canShow && !noAccess && !isReloading && !showApprovals"></router-outlet>
  <app-approvals [(showApprovals)]="showApprovals"></app-approvals>
  <div *ngIf="(!canShow || noAccess) && !showApprovals" class="center-box">
    <div class="center-box-container">
      <app-authorization *ngIf="!canShow"></app-authorization>
      <app-subscription-no-access *ngIf="noAccess"></app-subscription-no-access>
    </div>
  </div>
  <app-spinner *ngIf="isReloading" class="full"></app-spinner>
</ng-container>
<app-spinner *ngIf="!ready || showSpinner" class="full"></app-spinner>
