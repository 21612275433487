import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OfflineUtil} from '../../../../../../../../../utils/offline-util';
import {CustomTaskResponse} from '../../../../../api/data/custom-task-response';
import {TasksService} from '../../../../../api/service/tasks.service';
import {TasksEndpoints} from '../../../../../api/tasks-endpoints';

@Component({
  selector: 'app-task-remove',
  templateUrl: './task-remove.component.html',
  styleUrls: ['./task-remove.component.scss']
})
export class TaskRemoveComponent {

  @Input()
  public projectId: string;

  @Input()
  public task: CustomTaskResponse;

  @Input()
  public tasks: CustomTaskResponse[];

  @Input()
  public isOffline: boolean;

  @Output()
  public tasksChange = new EventEmitter<CustomTaskResponse[]>();

  @Output()
  public close = new EventEmitter<void>();

  public showSpinner = false;

  public constructor(private readonly _service: TasksService) {
  }

  public onSubmit(): void {
    if (!this.task || !this.projectId) return;
    this.showSpinner = true;
    this._service.removeCustomTask(this.projectId, this.task.id).subscribe(() => {
      const index = this.tasks.indexOf(this.task);
      if (index < 0) return;
      this.tasks.splice(index, 1);
      this._service.getCustomTasks(this.projectId).subscribe(() => {
        if (this.isOffline) {
          OfflineUtil.downloadFile(this.projectId, TasksEndpoints.PROJECT_CUSTOM_TASKS.replace(TasksEndpoints.PROJECT_ID, this.projectId),
            () => {
            }, () => {
            });
        }
        this.close.emit();
      });
    }, () => this.showSpinner = false, () => this.showSpinner = false);
  }
}
