import {JsonProperty} from '@dhkatz/json-ts';
import {DateToMilliSecondsConverter} from '../../../../globals/api/json/date-to-milli-seconds-converter';

export class UserOfflineEntry {

  @JsonProperty('e')
  private _email: string;

  @JsonProperty({name: 'l', converter: DateToMilliSecondsConverter.converter})
  private _lastUpdateDate: Date;

  @JsonProperty({name: 'v', converter: DateToMilliSecondsConverter.converter})
  private _validUntilDate: Date;

  @JsonProperty('k')
  private _token: string;

  public constructor() {
    this._email = undefined;
    this._lastUpdateDate = undefined;
    this._validUntilDate = undefined;
    this._token = undefined;
  }

  public get email(): string {
    return this._email;
  }

  public set email(value: string) {
    this._email = value;
  }

  public get lastUpdateDate(): Date {
    return this._lastUpdateDate;
  }

  public set lastUpdateDate(value: Date) {
    this._lastUpdateDate = value;
  }

  public get validUntilDate(): Date {
    return this._validUntilDate;
  }

  public set validUntilDate(value: Date) {
    this._validUntilDate = value;
  }

  public get token(): string {
    return this._token;
  }

  public set token(value: string) {
    this._token = value;
  }
}
