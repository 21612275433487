import {JsonProperty} from '@dhkatz/json-ts';
import {DateToSecondsConverter} from '../../../../../../globals/api/json/date-to-seconds-converter';

export class Payload {

  @JsonProperty('iss')
  private _issuer: string;

  @JsonProperty('sub')
  private _subject: string;

  @JsonProperty('aud')
  private _audience: string;

  @JsonProperty({name: 'exp', converter: DateToSecondsConverter.converter})
  private _expired: Date;

  @JsonProperty({name: 'nbf', converter: DateToSecondsConverter.converter})
  private _notBefore: Date;

  @JsonProperty({name: 'iat', converter: DateToSecondsConverter.converter})
  private _issuedAt: Date;

  @JsonProperty('jti')
  private _id: string;

  public constructor() {
    this._issuer = undefined;
    this._subject = undefined;
    this._audience = undefined;
    this._expired = undefined;
    this._notBefore = undefined;
    this._issuedAt = undefined;
    this._id = undefined;
  }

  public get issuer(): string {
    return this._issuer;
  }

  public set issuer(value: string) {
    this._issuer = value;
  }

  public get subject(): string {
    return this._subject;
  }

  public set subject(value: string) {
    this._subject = value;
  }

  public get audience(): string {
    return this._audience;
  }

  public set audience(value: string) {
    this._audience = value;
  }

  public get expired(): Date {
    return this._expired;
  }

  public set expired(value: Date) {
    this._expired = value;
  }

  public get notBefore(): Date {
    return this._notBefore;
  }

  public set notBefore(value: Date) {
    this._notBefore = value;
  }

  public get issuedAt(): Date {
    return this._issuedAt;
  }

  public set issuedAt(value: Date) {
    this._issuedAt = value;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public setExpiredMilliSeconds(milliSeconds: number): void {
    this.expired = new Date(Date.now() + milliSeconds);
  }

  public setExpiredSeconds(seconds: number): void {
    this.setExpiredMilliSeconds(seconds * 1000);
  }

  public setExpiredMinutes(minutes: number): void {
    this.setExpiredSeconds(minutes * 60);
  }

  public setExpiredHours(hours: number): void {
    this.setExpiredMinutes(hours * 60);
  }

  public setExpiredDays(days: number): void {
    this.setExpiredHours(days * 24);
  }

  public setExpiredWeeks(weeks: number): void {
    this.setExpiredDays(weeks * 7);
  }

  public isExpired(miliseconds?: number): boolean {
    if (this._expired === undefined) return false;
    return this._expired.getTime() < (miliseconds ? Date.now() + miliseconds : Date.now());
  }
}
