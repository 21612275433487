import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Observable, Subscriber} from 'rxjs';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {CustomTaskResponse} from '../data/custom-task-response';
import {TaskResponse} from '../data/task-response';
import {TasksEndpoints} from '../tasks-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class TasksOfflineService {

  public constructor() {
  }

  public getTasks(projectId: string): Observable<TaskResponse[]> {
    const customTasksPath = TasksEndpoints.PROJECT_CUSTOM_TASKS.replace(TasksEndpoints.PROJECT_ID, projectId);
    return new Observable<TaskResponse[]>((subscriber: Subscriber<TaskResponse[]>) => {
      OfflineUtil.loadText(projectId, customTasksPath,
        (file: string) => subscriber.next(JSON.parse(file).map((task) => deserializeFix(CustomTaskResponse, task)))
        , () => subscriber.error());
    });
  }

  public getCustomTasks(projectId: string): Observable<CustomTaskResponse[]> {
    const path = TasksEndpoints.PROJECT_CUSTOM_TASKS.replace(TasksEndpoints.PROJECT_ID, projectId);
    return new Observable<CustomTaskResponse[]>((subscriber: Subscriber<CustomTaskResponse[]>) => {
      OfflineUtil.loadText(projectId, path,
        (file: string) => subscriber.next(JSON.parse(file).map((task) => deserializeFix(CustomTaskResponse, task))),
        () => subscriber.error());
    });
  }
}
