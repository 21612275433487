import {ICustomConverter} from '@dhkatz/json-ts';

export class ArrayEnumConverter<T> implements ICustomConverter<T[]> {

  public static converter<T>(type: T): ArrayEnumConverter<T> {
    return new ArrayEnumConverter<T>(type);
  }

  private constructor(private readonly _type: T) {
  }

  public fromJson(data: string[]): T[] {
    return data === undefined ? undefined : data.map(value => this._type[value] as any);
  }

  public toJson(data: T[]): string[] {
    return data === undefined ? undefined : data.map(value => value.toString());
  }
}
