import {ICustomConverter} from '@dhkatz/json-ts';

export class ArrayStringConverter implements ICustomConverter<string[]> {

  public static readonly converter = new ArrayStringConverter();

  public fromJson(data: string[]): string[] {
    return data;
  }

  public toJson(data: string[]): string[] {
    return data;
  }
}
