import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractPointResponse} from './abstract-point-response';

export abstract class AbstractFilePointResponse extends AbstractPointResponse {

  @JsonProperty('f')
  private _file: string;

  protected constructor() {
    super();
    this._file = undefined;
  }

  public get file(): string {
    return this._file;
  }
}
