import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Json, JsonArray} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {FontResponse} from '../data/font-response';
import {FontsEndpoints} from '../fonts-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class FontsService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getFonts(projectId: string): Observable<FontResponse[]> {
    return this._http.get<JsonArray>(FontsEndpoints.FONT.replace(FontsEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(FontResponse, value))));
  }

  public getFontsPreview(versionId: string): Observable<FontResponse[]> {
    return this._http.get<JsonArray[]>(FontsEndpoints.FONT_PREVIEW.replace(FontsEndpoints.VERSION_ID, versionId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(FontResponse, value))));
  }
}
