import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Json, JsonArray} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {LayerManagerResponse} from '../data/layer-manager-response';
import {LayerManagerEndpoints} from '../layer-manager-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class LayerManagerService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getLayerManager(projectId: string): Observable<LayerManagerResponse[]> {
    return this._http.get<JsonArray>(LayerManagerEndpoints.LAYER_MANAGER.replace(LayerManagerEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(LayerManagerResponse, value))));
  }

  public getLayerManagerPreview(versionId: string): Observable<LayerManagerResponse[]> {
    return this._http.get<JsonArray>(LayerManagerEndpoints.LAYER_MANAGER_PREVIEW
      .replace(LayerManagerEndpoints.VERSION_ID, versionId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(LayerManagerResponse, value))));
  }
}
