import * as CryptoJS from 'crypto-js';
import {DB, deleteDb, openDb, UpgradeDB} from 'idb';
import {VectorLayerResponse} from '../project/version/modules/layer/api/data/layer-response/layers/vector-layer-response';
import {CacheStatus} from './enums/cache-status.enum';
import {CacheRequest} from './models/cache-request';

export class IdbVectorCacheUtil {

  public static readonly IDB_INFO_CACHE = CryptoJS.MD5('info').toString(CryptoJS.enc.Hex);

  public static cache(request: CacheRequest, tableName?: string): any {
    const table = tableName ? tableName : request.collection;
    return {
      async get(): Promise<Blob> {
        const db = await IdbVectorCacheUtil.getDb(request)
          .catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        return db.transaction(table).objectStore(table).get(request.key).then((result: any) => {
          db.close();
          return result;
        }).catch((reason: any) => {
          return this.onError(db, reason);
        });
      },
      async getAll(): Promise<any> {
        const db = await IdbVectorCacheUtil.getDb(request)
          .catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        const keys = await db.transaction(table).objectStore(table).getAllKeys();
        const result = {};
        for (const key of keys) {
          result[key] = await db.transaction(table).objectStore(table).get(key).catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        }
        db.close();
        return result;
      },
      async contain(): Promise<boolean> {
        const db = await IdbVectorCacheUtil.getDb(request)
          .catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        return db.transaction(table).objectStore(table).get(request.key).then((result: any) => {
          db.close();
          return !!result;
        }).catch((reason: any) => {
          db.close();
          console.log(reason);
          return false;
        });
      },
      async set(val: Blob): Promise<void> {
        const db = await IdbVectorCacheUtil.getDb(request)
          .catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        const tx = db.transaction(table, 'readwrite');
        tx.objectStore(table).put(val, request.key).catch((reason: any) => {
          console.log(reason);
          return reason;
        });
        return tx.complete.then((result: any) => {
          db.close();
          return result;
        }).catch((reason: any) => {
          return this.onError(db, reason);
        });
      },
      async setKeyVal(key: string, val: any): Promise<void> {
        const db = await IdbVectorCacheUtil.getDb(request)
          .catch((reason: any) => {
            console.log(reason);
            return reason;
          });
        const tx = db.transaction(table, 'readwrite');
        tx.objectStore(table).put(val, key).catch((reason: any) => {
          console.log(reason);
          return reason;
        });
        return tx.complete.then((result: any) => {
          db.close();
          return result;
        }).catch((reason: any) => {
          return this.onError(db, reason);
        });
      },
      async deleteDb(): Promise<any> {
        return deleteDb(request.db).catch((reason: any) => {
          console.log(reason);
          return reason;
        });
      }
    };
  }

  public static setStatus(request: CacheRequest, status: CacheStatus): Promise<any> {
    return IdbVectorCacheUtil.cache(request, IdbVectorCacheUtil.IDB_INFO_CACHE).setKeyVal(request.cacheCollection, {
      status,
      version: request.version
    }).catch((reason: any) => {
      console.log(reason);
      return reason;
    });
  }

// VECTOR CACHE <-------------------------------------------------------------------------------------------------------------> VECTOR CACHE

  private static getDb(request: CacheRequest): Promise<DB> {
    return openDb(request.db, request.version, (upgradeDB: UpgradeDB) => {
      const oldCollections = upgradeDB.objectStoreNames;
      if (oldCollections) for (let i = 0; i < oldCollections.length; i++) upgradeDB.deleteObjectStore(oldCollections.item(i));
      request.collections.forEach((table: string) => upgradeDB.createObjectStore(table));
      upgradeDB.createObjectStore(this.IDB_INFO_CACHE);
    });
  }

  public static async checkCache(request: CacheRequest, layers: VectorLayerResponse[]): Promise<VectorLayerResponse[]> {
    return this.getDb(request)
      .then(() => this.checkDbInfos(request, layers))
      .catch((reason: any) => {
        console.log(reason);
        return this.removeDb(request, layers);
      });
  }

  private static async checkDbInfos(request: CacheRequest, layers?: VectorLayerResponse[]): Promise<VectorLayerResponse[]> {
    return IdbVectorCacheUtil.cache(request, this.IDB_INFO_CACHE).getAll()
      .then((result: any) => this.checkInfos(result, request, layers))
      .catch((reason: any) => {
        console.log(reason);
        this.removeDb(request, layers);
        setTimeout(() => window.location.reload(), 100);
        return layers;
      });
  }

  private static async checkInfos(result: any, request: CacheRequest, layers: VectorLayerResponse[]): Promise<VectorLayerResponse[]> {
    const keys = Object.keys(result);
    if (!keys.length) return layers;
    const cached: VectorLayerResponse[] = [];
    for (const key of keys) await this.checkInfo(key, result, request, layers, cached);
    return layers.filter((layer: VectorLayerResponse) => !cached.includes(layer));
  }

  private static async checkInfo(key: string, result: any, request: CacheRequest, layers: VectorLayerResponse[],
                                 cached: VectorLayerResponse[]): Promise<any> {
    if (result[key].version !== request.version) return IdbVectorCacheUtil.cache(request, this.IDB_INFO_CACHE).delete(key)
      .catch((reason: any) => {
        console.log(reason);
        return reason;
      });
    const layer: VectorLayerResponse = this.getLayer(key, layers);
    if (!layer) return IdbVectorCacheUtil.cache(request, this.IDB_INFO_CACHE).delete(key)
      .catch((reason: any) => {
        console.log(reason);
        return reason;
      });
    if (result[key].status !== CacheStatus.complete) return undefined;
    cached.push(layer);
  }

  private static getLayer(key: string, layers: VectorLayerResponse[]): VectorLayerResponse {
    const index = +key.split('_')[1];
    return layers.find((layer: VectorLayerResponse) => layer.index === index);
  }

  private static async removeDb(request: CacheRequest, layers?: VectorLayerResponse[]): Promise<VectorLayerResponse[]> {
    return deleteDb(request.db)
      .then(() => layers)
      .catch((reason: any) => {
        console.log(reason);
        return layers;
      });
  }

// REVOKE SPACE <-------------------------------------------------------------------------------------------------------------> REVOKE SPACE

  public static revokeCacheForId(id: string): void {
    deleteDb(id).catch((reason: any) => console.log(reason));
  }

  private onError(db, reason: any): any {
    db.close();
    console.log(reason);
    return reason;
  }
}
