<div class="container-box">
  <div class="account-part address-container">
    <div class="box-title">
      {{'profile.address.title' | translate}}
      <mat-icon class="edit-icon" *ngIf="!enableEdit" (click)="enableEdit = true">edit</mat-icon>
    </div>
    <form *ngIf="enableEdit" [ngClass]="{'clearfix': true, 'loading': showSpinner}">
      <div class="left-controls">
        <mat-form-field>
          <input matInput type="text" [placeholder]="'profile.address.placeholder.name' | translate"
                 [formControl]="nameField">
          <mat-error *ngIf="nameField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
        </mat-form-field>
      </div>
      <div class="right-controls">
        <mat-form-field>
          <input matInput type="text" [placeholder]="'profile.address.placeholder.surname' | translate"
                 [formControl]="surnameField">
          <mat-error *ngIf="surnameField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field *ngIf="isInstitution">
        <input matInput type="text" [placeholder]="'profile.address.placeholder.schoolName' | translate"
               [formControl]="institutionNameField" [matAutocomplete]="auto" (ngModelChange)="findInstitution($event)">
        <mat-autocomplete #auto="matAutocomplete" class="institutions-assign">
          <mat-option class="institution-select" *ngFor="let institution of institutionsFound"
                      [value]="institution?.title" (onSelectionChange)="assignToInstitution(institution)">
            {{institution?.title?.trim()}}
            <small>
              {{institution?.streetName?.trim()}} {{institution?.streetNo | addressNo:institution?.apartmentNo}},
              {{institution?.city?.trim()}}, {{institution?.province?.trim()}}</small>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="institutionNameField.hasError('required')"
                   [innerHTML]="'system.form.error.required' | translate">
        </mat-error>
      </mat-form-field>
      <div class="left-controls">
        <mat-form-field>
          <input matInput type="text" [placeholder]="'profile.address.placeholder.street' | translate"
                 [formControl]="streetNameField">
          <mat-error *ngIf="streetNameField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" [placeholder]="'profile.address.placeholder.apartmentNumber' | translate"
                 [formControl]="apartmentNumberField">
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" [placeholder]="'profile.address.placeholder.city' | translate"
                 [formControl]="cityField">
          <mat-error *ngIf="cityField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="number" [placeholder]="'profile.address.placeholder.phone' | translate"
                 [formControl]="phoneNoField">
          <mat-error *ngIf="phoneNoField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
        </mat-form-field>
      </div>
      <div class="right-controls">
        <mat-form-field>
          <input matInput type="text" [placeholder]="'profile.address.placeholder.streetNumber' | translate"
                 [formControl]="streetNumberField">
          <mat-error *ngIf="streetNumberField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" [placeholder]="'profile.address.placeholder.zipCode' | translate"
                 [formControl]="postCodeField">
          <mat-error *ngIf="postCodeField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" [placeholder]="'profile.address.placeholder.state' | translate"
                 [formControl]="stateField">
          <mat-error *ngIf="stateField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isInstitution">
          <input matInput type="number" [placeholder]="'profile.address.placeholder.taxID' | translate"
                 [formControl]="taxIdField">
          <mat-error *ngIf="taxIdField.hasError('required')"
                     [innerHTML]="'system.form.error.required' | translate">
          </mat-error>
        </mat-form-field>
      </div>
      <div class="buttons">
        <button class="cancel-button" (click)="cancel()">{{'system.label.cancel' | translate}}</button>
        <button class="submit-button" (click)="submit()">{{'system.label.save' | translate}}</button>
      </div>
    </form>
    <div *ngIf="!enableEdit" class="data">
      <div class="left-controls">
        <div class="single-data">
          <p class="data-label">{{'profile.address.placeholder.name' | translate}}:</p>
          <p class="data">{{addressData.firstName}}</p>
        </div>
      </div>
      <div class="right-controls">
        <div class="single-data">
          <p class="data-label">{{'profile.address.placeholder.surname' | translate}}:</p>
          <p class="data">{{addressData.lastName}}</p>
        </div>
      </div>
      <div class="single-data" *ngIf="isInstitution">
        <p class="data-label">{{'profile.address.placeholder.schoolName' | translate}}:</p>
        <p class="data">{{addressData.institutionName}}</p>
      </div>
      <div class="left-controls">
        <div class="single-data">
          <p class="data-label">{{'profile.address.placeholder.street' | translate}}:</p>
          <p class="data">{{addressData.streetName}}</p>
        </div>
        <div class="single-data">
          <p class="data-label">{{'profile.address.placeholder.apartmentNumber' | translate}}:</p>
          <p class="data">{{addressData.apartmentNumber}}</p>
        </div>
        <div class="single-data">
          <p class="data-label">{{'profile.address.placeholder.city' | translate}}:</p>
          <p class="data">{{addressData.city}}</p>
        </div>
        <div class="single-data">
          <p class="data-label">{{'profile.address.placeholder.phone' | translate}}:</p><span
          class="data">{{addressData.phoneNo}}</span>
        </div>
      </div>
      <div class="right-controls">
        <div class="single-data">
          <p class="data-label">{{'profile.address.placeholder.streetNumber' | translate}}:</p><span
          class="data">{{addressData.streetNumber}}</span>
        </div>
        <div class="single-data">
          <p class="data-label">{{'profile.address.placeholder.zipCode' | translate}}:</p><span
          class="data">{{addressData.postCode}}</span>
        </div>
        <div class="single-data">
          <p class="data-label">{{'profile.address.placeholder.state' | translate}}:</p><span
          class="data">{{addressData.state}}</span>
        </div>
        <div class="single-data" *ngIf="isInstitution">
          <p class="data-label">{{'profile.address.placeholder.taxID' | translate}}:</p>
          <p class="data">{{addressData.taxId}}</p>
        </div>
      </div>
    </div>
    <app-spinner *ngIf="showSpinner" class="full"></app-spinner>
  </div>
</div>
