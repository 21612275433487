import {Component, OnInit} from '@angular/core';
import {AbstractRoleComponent} from '../../../globals/screens/abstracts/abstract-role.component';
import {SubscriptionService} from '../../../subscriptions/api/service/subscription.service';
import {ServiceWorkerService} from '../../../web-workers/service/service-worker.service';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent extends AbstractRoleComponent implements OnInit {

  public showSpinner;
  public hasSchoolPlus = false;

  public constructor(private readonly _subscriptionService: SubscriptionService, workerService: ServiceWorkerService) {
    super(workerService);
  }

  public ngOnInit(): void {
    this.showSpinner = true;
    this._subscriptionService.hasSchoolPlusSubscription().subscribe((response: boolean) => {
      this.hasSchoolPlus = response;
      this.showSpinner = false;
    }, () => this.showSpinner = false, () => this.showSpinner = false);
  }
}
