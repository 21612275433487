import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Json, JsonArray} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {TitleItem} from '../../../globals/api/data/title-item';
import {TagEndpoints} from '../tag-endpoints';
import {deserializeFix} from '../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getTags(tagsIds: string[]): Observable<TitleItem[]> {
    return this._http.get<JsonArray>(TagEndpoints.TAGS, {params: {ids: tagsIds}})
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(TitleItem, value))));
  }

  public getTagsForPublishedVersions(): Observable<TitleItem[]> {
    return this._http.get<JsonArray>(TagEndpoints.TAGS_FOR_PUBLISHED_VERSIONS)
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(TitleItem, value))));
  }
}
