import {Injectable} from '@angular/core';
import {AbstractPointResponse} from '../../interactive/api/data/points/abstracts/abstract-point-response';
import {AbstractLayersConstructor} from '../constructor/abstracts/abstract-layers-constructor';
import {ActualLevelChange} from './interfaces/actual-level-change';
import {LayerClick} from './interfaces/layer-click';
import {MinimalScaleChange} from './interfaces/minimal-scale-change';
import {ScaleCenterChange} from './interfaces/scale-center-change';
import {ScaleChange} from './interfaces/scale-change';
import {ViewChange} from './interfaces/view-change';
import {ViewMove} from './interfaces/view-move';

@Injectable({
  providedIn: 'root'
})
export class LayersCommunicationService {

  private _viewChangeListeners: ViewChange[] = [];
  private _viewMoveListeners: ViewMove[] = [];
  private _scaleChangeListeners: ScaleChange[] = [];
  private _scaleCenterChangeListeners: ScaleCenterChange[] = [];
  private _minimalScaleChangeListeners: MinimalScaleChange[] = [];
  private _actualLevelChangeListeners: ActualLevelChange[] = [];
  private _layerClickListeners: LayerClick[] = [];

  private _viewChangeCounter = 0;
  private _moveCounter = 0;
  private _scaleChangeCounter = 0;
  private _scaleCenterChangeCounter = 0;

  private _constructor: AbstractLayersConstructor<any, any>;

  public constructor() {
  }

  public addViewChangeListener(listener: ViewChange): void {
    this._viewChangeListeners.push(listener);
  }

  public removeViewChangeListener(listener: ViewChange): void {
    const index: number = this._viewChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._viewChangeListeners.splice(index, 1);
  }

  public viewChange(width: number, height: number): void {
    if (this._viewChangeListeners.length === 0 || isNaN(width) || isNaN(height)) return;
    this._viewChangeCounter++;
    for (const listener of this._viewChangeListeners) {
      if (this._viewChangeCounter === 0) return;
      try {
        listener.onViewChange(width, height);
      } catch (exception) {
        console.log(exception);
      }
    }
    this._viewChangeCounter = 0;
  }

  public addViewMoveListener(listener: ViewMove): void {
    this._viewMoveListeners.push(listener);
  }

  public removeViewMoveListener(listener: ViewMove): void {
    const index: number = this._viewMoveListeners.indexOf(listener);
    if (index === -1) return;
    this._viewMoveListeners.splice(index, 1);
  }

  public viewMove(x: number, y: number): void {
    if (this._viewMoveListeners.length === 0 || isNaN(x) || isNaN(y)) return;
    this._moveCounter++;
    for (const listener of this._viewMoveListeners) {
      if (this._moveCounter === 0) return;
      try {
        listener.onViewMove(x, y);
      } catch (exception) {
        console.log(exception);
      }
    }
    this._moveCounter = 0;
  }

  public addScaleChangeListener(listener: ScaleChange): void {
    this._scaleChangeListeners.push(listener);
  }

  public removeScaleChangeListener(listener: ScaleChange): void {
    const index: number = this._scaleChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._scaleChangeListeners.splice(index, 1);
  }

  public scaleChange(scale: number): void {
    if (this._scaleChangeListeners.length === 0 || isNaN(scale)) return;
    this._scaleChangeCounter++;
    for (const listener of this._scaleChangeListeners) {
      if (this._scaleChangeCounter === 0) return;
      try {
        listener.onScaleChange(scale);
      } catch (exception) {
        console.log(exception);
      }
    }
    this._scaleChangeCounter = 0;
  }

  public addScaleCenterChangeListener(listener: ScaleCenterChange): void {
    this._scaleCenterChangeListeners.push(listener);
  }

  public removeScaleCenterChangeListener(listener: ScaleCenterChange): void {
    const index: number = this._scaleCenterChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._scaleCenterChangeListeners.splice(index, 1);
  }

  public scaleCenterChange(scale: number): void {
    if (this._scaleCenterChangeListeners.length === 0 || isNaN(scale)) return;
    this._scaleCenterChangeCounter++;
    for (const listener of this._scaleCenterChangeListeners) {
      if (this._scaleCenterChangeCounter === 0) return;
      try {
        listener.onScaleCenterChange(scale);
      } catch (exception) {
        console.log(exception);
      }
    }
    this._scaleCenterChangeCounter = 0;
  }

  public addMinimalScaleChangeListener(listener: MinimalScaleChange): void {
    this._minimalScaleChangeListeners.push(listener);
  }

  public removeMinimalScaleChangeListener(listener: MinimalScaleChange): void {
    const index: number = this._minimalScaleChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._minimalScaleChangeListeners.splice(index, 1);
  }

  public minimalScaleChange(minimalScale: number): void {
    if (this._minimalScaleChangeListeners.length === 0 || isNaN(minimalScale)) return;
    this._minimalScaleChangeListeners.forEach((listener: MinimalScaleChange) => {
      try {
        listener.onMinimalScaleChange(minimalScale);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public addActualLevelChangeListener(listener: ActualLevelChange): void {
    this._actualLevelChangeListeners.push(listener);
  }

  public removeActualLevelChangeListener(listener: ActualLevelChange): void {
    const index: number = this._actualLevelChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._actualLevelChangeListeners.splice(index, 1);
  }

  public actualLevelChange(level: number): void {
    if (this._actualLevelChangeListeners.length === 0 || isNaN(level)) return;
    this._actualLevelChangeListeners.forEach((listener: ActualLevelChange) => {
      try {
        listener.onActualLevelChange(level);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public addLayerClickListener(listener: LayerClick): void {
    this._layerClickListeners.push(listener);
  }

  public removeLayerClickListener(listener: LayerClick): void {
    const index: number = this._layerClickListeners.indexOf(listener);
    if (index === -1) return;
    this._layerClickListeners.splice(index, 1);
  }

  public layerClick(x: number, y: number): void {
    if (this._layerClickListeners.length === 0 || isNaN(x) || isNaN(y)) return;
    this._layerClickListeners.forEach((listener: LayerClick) => {
      try {
        listener.onLayerClick(this.calculateXDimension(x), this.calculateYDimension(y));
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  private calculateXDimension(x: number): number {
    return this.getDimension((-this._constructor.x + x) / this._constructor.scale, 0, this._constructor.maxSize.width);
  }

  private calculateYDimension(y: number): number {
    return this.getDimension((-this._constructor.y + y) / this._constructor.scale, 0, this._constructor.maxSize.height);
  }

  public get constructor(): AbstractLayersConstructor<any, any> {
    return this._constructor;
  }

  public set constructor(value: AbstractLayersConstructor<any, any>) {
    this._constructor = value;
  }

  public getScaledDrag(drag: number): number {
    if (!this._constructor) return 0;
    return drag / this._constructor.scale;
  }

  public setScaledDimension(point: AbstractPointResponse, dragX: number, dragY: number, element: any): void {
    if (!this._constructor || !this._constructor.maxSize) return;
    const maxSize = this._constructor.maxSize;
    point.x = this.getDimension(dragX, element.clientWidth, maxSize.width);
    point.y = this.getDimension(dragY, element.clientHeight, maxSize.height);
  }

  private getDimension(point: number, image: number, max: number): number {
    if (point + image > max) return max - image;
    if (point < 0) return 0;
    return point;
  }
}
