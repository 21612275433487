import {FileSystemUtil} from './file-system-util';

type StringCallback = (file: string) => void;

export class OfflineUtil {
  public static OFFLINE_STORAGE_SIZE = FileSystemUtil.FILE_SYSTEM_STORAGE_SIZE;

  public static isOfflineAvailable(): boolean {
    return FileSystemUtil.isFileSystemAvailAble();
  }

  public static downloadFile(projectId: string, path: string, success, error): void {
    fetch(new Request(path, {
      credentials: 'omit',
      mode: 'cors'
    })).then((response: Response) => {
      if (response.status < 200 || response.status > 206) return;
      response.blob().then((blob: Blob) => {
        if (FileSystemUtil.isFileSystemAvailAble()) FileSystemUtil.saveToFileSystem(projectId, path, blob, success, error);
      });
    }).catch((reason: any) => {
      console.log(reason, path);
      error();
    });
  }

  public static loadFileSync(projectId: string, path: string): Promise<Blob> {
    if (!FileSystemUtil.isFileSystemAvailAble()) return;
    const fs = self.webkitRequestFileSystemSync(self.PERSISTENT, 1024);
    return new Promise(((resolve, reject) => {
      setTimeout(() => {
        const file = FileSystemUtil.loadFileFromFileSystemSync(projectId, path, fs.root) as Blob;
        resolve(file);
      }, 0);
    }));
  }

  public static loadText(projectId: string, path: string, successCallback: StringCallback, errorCallback?: any): void {
    if (FileSystemUtil.isFileSystemAvailAble()) FileSystemUtil.loadTextFileFromFileSystem(projectId, path, successCallback, errorCallback);
  }

  public static removeFromOffline(projectId: string, success?, error?): void {
    if (FileSystemUtil.isFileSystemAvailAble()) FileSystemUtil.removeFromOffline(projectId, success, error);
  }
}
