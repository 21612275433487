import {ICustomConverter} from '@dhkatz/json-ts';

export class DateToMilliSecondsConverter implements ICustomConverter<Date> {

  public static converter = new DateToMilliSecondsConverter();

  public fromJson(data: number): Date {
    return data === undefined ? undefined : new Date(data);
  }

  public toJson(data: Date): number {
    return data === undefined ? undefined : data.getTime();
  }
}
