import {Injectable, NgZone} from '@angular/core';
import {serialize} from '@dhkatz/json-ts';
import * as CryptoJS from 'crypto-js';
import {Role} from '../../../authorization/api/data/enums/role.enum';
import {CategoryEndpoints} from '../../../category/api/category-endpoints';
import {Endpoints} from '../../../globals/api/endpoints';
import {ProjectEndpoints} from '../../../project/api/project-endpoints';
import {ProjectService} from '../../../project/api/services/project.service';
import {CopyrightsEndpoints} from '../../../project/modules/copyrights/api/copyrights-endpoints';
import {VersionResponse} from '../../../project/version/api/data/version-response';
import {VersionService} from '../../../project/version/api/services/version.service';
import {VersionEndpoints} from '../../../project/version/api/version-endpoints';
import {BibliographyEndpoints} from '../../../project/version/modules/bibliography/api/bibliography-endpoints';
import {FontFileResponse} from '../../../project/version/modules/fonts/api/data/font-file-response';
import {FontResponse} from '../../../project/version/modules/fonts/api/data/font-response';
import {FontsEndpoints} from '../../../project/version/modules/fonts/api/fonts-endpoints';
import {FontsService} from '../../../project/version/modules/fonts/api/services/fonts.service';
import {InteractiveEndpoints} from '../../../project/version/modules/interactive/api/interactive-endpoints';
import {LayerManagerEndpoints} from '../../../project/version/modules/layer-manager/api/layer-manager-endpoints';
import {
  CustomInteractiveEndpoints
} from '../../../project/version/modules/layer-manager/modules/custom-interactives-manager/api/custom-interactive-endpoints';
import {LayerEndpoints} from '../../../project/version/modules/layer/api/layer-endpoints';
import {LegendEndpoints} from '../../../project/version/modules/legend/api/legend-endpoints';
import {MiniMapEndpoints} from '../../../project/version/modules/mini-map/api/mini-map-endpoints';
import {ScaleEndpoints} from '../../../project/version/modules/scale/api/scale-endpoints';
import {TasksEndpoints} from '../../../project/version/modules/tasks/api/tasks-endpoints';
import {VersionModule} from '../../../project/version/modules/version-modules.enum';
import {SubscriptionAccessService} from '../../../subscriptions/services/subscription-access.service';
import {deserializeFix} from '../../../utils/deserialize-util';
import {FileSystemUtil} from '../../../utils/file-system-util';
import {IdbUtil} from '../../../utils/idb-util';
import {OfflineUtil} from '../../../utils/offline-util';
import {SecurityWorkerLogin} from '../../../web-workers/service/interfaces/security-worker-login';
import {SecurityWorkerLogout} from '../../../web-workers/service/interfaces/security-worker-logout';
import {ServiceWorkerCommunicationService} from '../../../web-workers/service/service-worker-communication.service';
import {ServiceWorkerService} from '../../../web-workers/service/service-worker.service';
import {JWT} from '../../../web-workers/workers/service-worker/interceptors/security-interceptor/jwt/jwt';
import {Payload} from '../../../web-workers/workers/service-worker/interceptors/security-interceptor/jwt/payload';
import {FileService} from '../../api/services/file-service/file.service';
import {OfflineStatus} from '../../communication/enums/offline-status.enum';
import {OnFileDownload} from '../../communication/interfaces/on-file-download';
import {OfflineDBEntry} from './data/OfflineDBEntry';
import {OfflineProjectEntry} from './data/OfflineProjectEntry';
import {UserOfflineEntry} from './data/UserOfflineEntry';

@Injectable({
  providedIn: 'root'
})
export class OfflineService implements SecurityWorkerLogin, SecurityWorkerLogout {
  // DATA KRYTYCZNYCH ZMIAN WYMUSZAJACYCH AKTUALIZACJĘ
  private static readonly NEW_VERSION_DATE = new Date('2023-04-15T14:00:00'); // TODO date of critical changes in offline

  public static SALT = 'dAJjee2mkV@Hk$9YmxUh8K0rutRe83V5q&ovoc#!aNw2pxyVV8EJ^npZ1afSFjX7QvBtvrGa*U#wGLsQB9rkP#UY8ADz6$DG3fml';

  public urlList: string[] = [VersionEndpoints.VERSION, CopyrightsEndpoints.COPYRIGHTS];

  public offlineWorker: Worker;

  private _onFileDownloadListeners: OnFileDownload[] = [];

  private _progressMap: Map<string, number> = new Map<string, number>();

  private _offlineProjects: string[] = [];

  public constructor(private readonly _versionService: VersionService, private readonly _fileService: FileService,
                     private readonly _subscriptionAccess: SubscriptionAccessService, private readonly _worker: ServiceWorkerService,
                     private readonly _workerCommunication: ServiceWorkerCommunicationService,
                     private readonly _ngZone: NgZone, private readonly _projectService: ProjectService,
                     private readonly _fontsService: FontsService) {
    this._workerCommunication.addSecurityWorkerLoginListener(this);
    this._workerCommunication.addSecurityWorkerLogoutListener(this);
    IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).getAll().then((result: any) => {
      const keys = Object.keys(result);
      keys.map((key: string) => this.getOfflineProjectEntryForUser(deserializeFix(OfflineDBEntry, result[key]), this._worker.email))
        .filter((entry: OfflineProjectEntry) => entry && entry.status === OfflineStatus.DOWNLOADING)
        .forEach((entry: OfflineProjectEntry) =>
          this.downloadProject(entry.project, entry.email, entry.projectTitle, entry.categoryId, true, true));
    });
  }

  public downloadProject(projectId: string, email: string, projectTitle: string, categoryId: string, force: boolean,
                         emailHashed = false): void {
    if (!this.offlineWorker) this.offlineWorker = this.initWorker();
    if (!OfflineUtil.isOfflineAvailable()) return;
    if (force) {
      this.download(projectId, emailHashed, email, projectTitle, categoryId);
      return;
    }
    IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).get(projectId).then((value: any) => {
      if (!value) {
        this.download(projectId, emailHashed, email, projectTitle, categoryId);
        return;
      }
      const offlineEntry = this.cloneOfflineEntry(deserializeFix(OfflineDBEntry, value), projectId, email);
      IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).set(projectId, serialize(offlineEntry))
        .then(() => this.callOnFileDownloadListeners(projectId, 100));
    });
  }

  private cloneOfflineEntry(oldEntry: OfflineDBEntry, projectId: string, email: string): OfflineDBEntry {
    const offlineEntry = new OfflineDBEntry();
    offlineEntry.project = oldEntry.project;
    offlineEntry.status = OfflineStatus.DOWNLOADED;
    offlineEntry.versionId = oldEntry.versionId;
    offlineEntry.projectTitle = oldEntry.projectTitle;
    offlineEntry.categoryId = oldEntry.categoryId;
    const userEntry = this.createUserEntry(projectId, CryptoJS.MD5(email).toString(CryptoJS.enc.Hex));
    offlineEntry.userEntry = oldEntry.userEntry;
    offlineEntry.userEntry.push(userEntry);
    return offlineEntry;
  }

  private download(projectId: string, emailHashed, email: string, projectTitle: string, mainCategoryId: string): void {
    FileSystemUtil.checkAndRequestFileSystem(() => {
      this._progressMap.set(projectId, 0);
      this._versionService.getVersion(projectId).subscribe((response: VersionResponse) => {
        this._fileService.listFiles(projectId).subscribe((fileList: string[]) => {
          this.getModulesUrls(response.modules, projectId);
          this._projectService.getProjectCategories(projectId).subscribe((categories: string[]) => {
            categories.forEach((categoryId: string) => {
              this.urlList.push(CategoryEndpoints.CATEGORY_PATH.replace(CategoryEndpoints.CATEGORY_ID, categoryId));
            });
            this.urlList.push(...fileList.map((path: string) => path.replace('viewer', Endpoints.VIEWER)));
            const userEmail = emailHashed ? email : CryptoJS.MD5(email).toString(CryptoJS.enc.Hex);
            const offlineEntry = this.createOfflineEntry(projectId, response, userEmail, projectTitle, mainCategoryId);
            IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).set(projectId, serialize(offlineEntry));
            this.offlineWorker.postMessage({
              type: 'download',
              message: {
                urlList: this.urlList,
                projectId,
                versionId: response.versionId,
                projectTitle,
                categoryId: mainCategoryId,
                email: userEmail,
                projectValidityDate: this._subscriptionAccess.getProjectValidityDate(projectId),
                token: this.getOfflineToken(projectId, userEmail)
              }
            });
          });
        });
      });
    });
  }

  private createOfflineEntry(projectId: string, versionResponse: VersionResponse, userEmail: string, projectTitle: string,
                             categoryId: string): OfflineDBEntry {
    const offlineEntry = new OfflineDBEntry();
    offlineEntry.project = projectId;
    offlineEntry.status = OfflineStatus.DOWNLOADING;
    offlineEntry.versionId = versionResponse.versionId;
    offlineEntry.projectTitle = projectTitle;
    offlineEntry.categoryId = categoryId;
    const userEntry = this.createUserEntry(projectId, userEmail);
    offlineEntry.userEntry = [userEntry];
    return offlineEntry;
  }

  private createUserEntry(projectId: string, email: string): UserOfflineEntry {
    const userEntry = new UserOfflineEntry();
    userEntry.lastUpdateDate = new Date();
    userEntry.validUntilDate = this._subscriptionAccess.getProjectValidityDate(projectId);
    userEntry.email = email;
    userEntry.token = this.getOfflineToken(projectId, email);
    return userEntry;
  }

  protected getModulesUrls(modules: VersionModule[], projectId: string): void {
    this.urlList = [VersionEndpoints.VERSION, CopyrightsEndpoints.COPYRIGHTS];
    this.urlList.push(ProjectEndpoints.PROJECT_TITLE);
    if (modules.includes(VersionModule.fonts)) this.includeFonts(projectId);
    if (modules.includes(VersionModule.interactive)) {
      this.urlList.push(InteractiveEndpoints.INTERACTIVE);
      this.urlList.push(CustomInteractiveEndpoints.CUSTOM_INTERACTIVES);
    }
    if (modules.includes(VersionModule.layer)) this.urlList.push(LayerEndpoints.LAYER);
    if (modules.includes(VersionModule.layerManager)) this.urlList.push(LayerManagerEndpoints.LAYER_MANAGER);
    if (modules.includes(VersionModule.legend)) this.urlList.push(LegendEndpoints.LEGEND);
    if (modules.includes(VersionModule.miniMap)) this.urlList.push(MiniMapEndpoints.MINI_MAP);
    if (modules.includes(VersionModule.scale)) this.urlList.push(ScaleEndpoints.SCALE);
    if (modules.includes(VersionModule.tasks)) {
      this.urlList.push(TasksEndpoints.TASK);
      this.urlList.push(TasksEndpoints.PROJECT_CUSTOM_TASKS);
    }
    if (modules.includes(VersionModule.bibliography)) this.urlList.push(BibliographyEndpoints.BIBLIOGRAPHY_ENTRY);
  }

  private includeFonts(projectId: string): void {
    this.urlList.push(FontsEndpoints.FONT);
    this._fontsService.getFonts(projectId).subscribe((response: FontResponse[]) => {
      if (!response) return;
      response.forEach((font: FontResponse) => {
        font.files.forEach((file: FontFileResponse) => this.urlList.push(Endpoints.FILES + file.file));
      });
    });
  }

  public getOfflineToken(projectId: string, email: string): string {
    const payload = new Payload();
    payload.id = projectId;
    payload.setExpiredWeeks(2);
    return JWT.getToken(payload, email + '.' + OfflineService.SALT);
  }

  public checkProject(projectId: string): Promise<OfflineStatus> {
    return IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).get(projectId).then((value: any) => {
      if (!value) return OfflineStatus.NO_OFFLINE;
      const entry = deserializeFix(OfflineDBEntry, value);
      if (entry.status === OfflineStatus.DOWNLOADING) return OfflineStatus.DOWNLOADING;
      return IdbUtil.idbKeyVal(IdbUtil.IDB_ONLINE_VERSION).get(projectId).then((onlineVersion: string) => {
        if (this.isForceUpdateNeeded(entry)) {
          this.deleteEntry(projectId);
          return OfflineStatus.NO_OFFLINE;
        }
        return onlineVersion !== value.id ? OfflineStatus.UPDATE_AVAILABLE : OfflineStatus.DOWNLOADED;
      }).catch((err) => {
        console.log(err);
        return OfflineStatus.NO_OFFLINE;
      });
    }).catch((err) => {
      console.log(err);
      return OfflineStatus.NO_OFFLINE;
    });
  }

  private isForceUpdateNeeded(entry: OfflineDBEntry): boolean {
    const entryForUser = this.getUserEntryForUser(entry, this._worker.email);
    return entryForUser && entryForUser.lastUpdateDate < OfflineService.NEW_VERSION_DATE;
  }

  public initWorker(): Worker {
    const worker = new Worker('offline.worker.js');
    worker.onmessage = (event: MessageEvent) => {
      if (this[event.data.type]) this[event.data.type](event.data.message);
    };
    return worker;
  }

  protected downloadError(): void {
    this.offlineWorker.terminate();
  }

  public downloadSuccess(message: any): void {
    this.callOnFileDownloadListeners(message.p, message.progress);
  }

  public getProgress(projectId: string): number {
    const progress = this._progressMap.get(projectId);
    return progress ? progress : 0;
  }

  public getOfflineProjectEntryForUser(dbEntry: OfflineDBEntry, email: string): OfflineProjectEntry {
    if (!dbEntry) return;
    const userEntry = dbEntry.userEntry.filter((entry: UserOfflineEntry) => entry.email === CryptoJS.MD5(email)
      .toString(CryptoJS.enc.Hex))[0];
    if (!userEntry) return;
    const projectEntry = new OfflineProjectEntry();
    projectEntry.project = dbEntry.project;
    projectEntry.status = dbEntry.status;
    projectEntry.versionId = dbEntry.versionId;
    projectEntry.projectTitle = dbEntry.projectTitle;
    projectEntry.categoryId = dbEntry.categoryId;
    projectEntry.email = userEntry.email;
    projectEntry.lastUpdateDate = userEntry.lastUpdateDate;
    projectEntry.validUntilDate = userEntry.validUntilDate;
    projectEntry.token = userEntry.token;
    return projectEntry;
  }

  public getUserEntryForUser(dbEntry: OfflineDBEntry, email: string): UserOfflineEntry {
    if (!dbEntry) return;
    return dbEntry.userEntry.filter((userEntry: UserOfflineEntry) => userEntry.email === CryptoJS.MD5(email)
      .toString(CryptoJS.enc.Hex))[0];
  }

  // COMUNNICATION

  public addOnFileDownloadListener(listener: OnFileDownload): void {
    this._onFileDownloadListeners.push(listener);
  }

  public removeOnFileDownloadListner(listener: OnFileDownload): void {
    const index: number = this._onFileDownloadListeners.indexOf(listener);
    if (index === -1) return;
    this._onFileDownloadListeners.splice(index, 1);
  }

  private callOnFileDownloadListeners(projectId: string, progress: number): void {
    if (!this._onFileDownloadListeners.length) return;
    this._onFileDownloadListeners.forEach((listener: OnFileDownload) => {
      try {
        this._ngZone.run(() => listener.onFileDownload(projectId, progress));
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public hasOfflineAccess(projectId: string): Promise<boolean> {
    if (!projectId || !OfflineUtil.isOfflineAvailable()) return Promise.resolve(false);
    if (this._offlineProjects.includes(projectId)) return Promise.resolve(true);
    return IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).get(projectId).then((result: any) => {
      if (!result) return false;
      const offlineEntry = deserializeFix(OfflineDBEntry, result);
      const hasAccess = this.hasAccess(offlineEntry);
      if (hasAccess) this._offlineProjects.push(projectId);
      return hasAccess;
    });
  }

  private hasAccess(offlineEntry: OfflineDBEntry): boolean {
    const email = this._worker.email ? this._worker.email : localStorage.getItem('offlineEmail');
    const hashedEmail = CryptoJS.MD5(email).toString(CryptoJS.enc.Hex);
    if (!offlineEntry.userEntry) return false;
    const entryForUser = offlineEntry.userEntry.filter((entry: UserOfflineEntry) => entry.email === hashedEmail)[0];
    if (!entryForUser) return false;
    const payload = JWT.getPayload(entryForUser.token, hashedEmail + '.' + OfflineService.SALT);
    return payload && !payload.isExpired() && payload.id === offlineEntry.project;
  }

  private deleteEntry(projectId: string): void {
    IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).delete(projectId).then(() => {
      OfflineUtil.removeFromOffline(projectId);
    }, (err: any) => console.log('Error during removing project from offline: ', err));
  }

  public checkOfflineAccessForUser(): void {
    this._offlineProjects = [];
    if (!OfflineUtil.isOfflineAvailable()) return;
    IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).getAll().then((results: any[]) => {
      if (!results) return;
      results.forEach((result: any) => {
        const offlineEntry = deserializeFix(OfflineDBEntry, result);
        if (this.hasAccess(offlineEntry)) this._offlineProjects.push(offlineEntry.project);
      });
    });
  }

  public onSecurityWorkerLogin(email?: string, role?: Role): void {
    this.checkOfflineAccessForUser();
  }

  public onSecurityWorkerLogout(): void {
    this.checkOfflineAccessForUser();
  }
}
