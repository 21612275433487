import {Injectable} from '@angular/core';
import {OnMessageChanged} from './intrfaces/on-message-changed';

@Injectable({
  providedIn: 'root'
})
export class MessagesCommunicationService {

  private _onMessageChangedListeners: OnMessageChanged[] = [];

  public constructor() {
  }

  public addOnMessageChangedListener(listener: OnMessageChanged): void {
    this._onMessageChangedListeners.push(listener);
  }

  public removeOnMessagesChangedListener(listener: OnMessageChanged): void {
    const index: number = this._onMessageChangedListeners.indexOf(listener);
    if (index === -1) return;
    this._onMessageChangedListeners.splice(index, 1);
  }

  public callOnMessageChanged(): void {
    if (this._onMessageChangedListeners.length === 0) return;
    for (const listener of this._onMessageChangedListeners) {
      try {
        listener.onMessagesChanged();
      } catch (exception) {
        console.log(exception);
      }
    }
  }
}
