import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Json, JsonArray, JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MessageResponse} from '../data/message-response';
import {MessagesEndpoints} from '../messages-endpoints';
import {deserializeFix} from '../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getShortList(): Observable<MessageResponse[]> {
    return this._http.get<JsonArray>(MessagesEndpoints.MESSAGES, {params: {limit: '3', title: '', page: '1'}})
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(MessageResponse, value))));
  }

  public search(title: string, page: number): Observable<MessageResponse[]> {
    return this._http.get<JsonArray>(MessagesEndpoints.MESSAGES, {params: {title, page: page.toString()}})
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(MessageResponse, value))));
  }

  public getUnreadCount(): Observable<number> {
    return this._http.get<number>(MessagesEndpoints.MESSAGES_COUNT);
  }

  public getMessage(id: string): Observable<MessageResponse> {
    return this._http.get<JsonObject>(MessagesEndpoints.MESSAGE.replace(MessagesEndpoints.MESSAGE_ID, id))
      .pipe(map((response: JsonObject) => deserializeFix(MessageResponse, response)));
  }

  public getContent(id: string): Observable<string> {
    return this._http.get<string>(MessagesEndpoints.MESSAGE_CONTENT.replace(MessagesEndpoints.MESSAGE_ID, id));
  }

  public markAllAsRead(): Observable<void> {
    return this._http.put<void>(MessagesEndpoints.MESSAGES_MARK_ALL_AS_READ, '');
  }
}
