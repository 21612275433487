import {JsonProperty} from '@dhkatz/json-ts';

export class ScaleResponse {

  @JsonProperty('s')
  private _scale: number;

  @JsonProperty('r')
  private _rounding: number;

  @JsonProperty('w')
  private _width: number;

  @JsonProperty('h')
  private _height: number;

  public constructor() {
    this._scale = undefined;
    this._rounding = undefined;
    this._width = undefined;
    this._height = undefined;
  }

  public get scale(): number {
    return this._scale;
  }

  public get rounding(): number {
    return this._rounding;
  }

  public get width(): number {
    return this._width;
  }

  public get height(): number {
    return this._height;
  }
}
