import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Subscriber} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {OfflineUtil} from '../../../../../utils/offline-util';
import {LegendEndpoints} from '../../../../version/modules/legend/api/legend-endpoints';
import {CopyrightsEndpoints} from '../copyrights-endpoints';
import {CopyrightsResponse} from '../data/copyrights-response';
import {deserializeFix} from '../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class CopyrightsOfflineService {

  public constructor() {
  }

  public getCopyrights(projectId: string): Observable<CopyrightsResponse> {
    const path = CopyrightsEndpoints.COPYRIGHTS.replace(LegendEndpoints.PROJECT_ID, projectId);
    return new Observable<CopyrightsResponse>((subscriber: Subscriber<CopyrightsResponse>) =>
      OfflineUtil.loadText(projectId, path, (file: string) =>
        subscriber.next(deserializeFix(CopyrightsResponse, JSON.parse(file)))
      ));
  }
}
