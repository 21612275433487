import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({template: ''})
export abstract class AbstractDialogComponent implements OnInit {

  protected form: FormGroup;

  protected constructor(@Inject(MAT_DIALOG_DATA) protected data: any) {
  }

  public ngOnInit(): void {
    this.createFormControls();
    this.createForm();
  }

  protected abstract createFormControls(): void;

  protected abstract createForm(): void;

  public abstract onSubmit(): void;

  protected getValue(...path: string[]): any {
    if (this.data === undefined || this.data === null) return '';
    let data = this.data;
    for (const element of path) {
      if (data[element] === undefined || this.data === null) return '';
      data = data[element];
    }
    return data;
  }
}
