import {AfterViewInit, Component, Inject, Input, NgZone, OnInit, PLATFORM_ID} from '@angular/core';
import {Chart} from '../../../../../../../charts/chart';
import {PanelChartResponse} from '../../../api/data/panel-chart-response';

@Component({
  selector: 'app-panel-charts',
  templateUrl: './panel-charts.component.html',
  styleUrls: ['./panel-charts.component.scss']
})
export class PanelChartsComponent implements OnInit, AfterViewInit {

  @Input()
  public panelCharts: PanelChartResponse[];

  @Input()
  public columns: number;

  private charts: Chart[] = [];

  public constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {
  }

  public ngOnInit(): void {
    this.panelCharts.forEach((panelChart) => {
      this.charts.push(Chart.builder(this.platformId, this.zone).setId(panelChart.title).setData(panelChart));
    });
  }

  public ngAfterViewInit(): void {
    this.charts.forEach((chart: Chart) => chart.render());
  }

  public getFlex(): string {
    if (this.columns <= 1) return 'flex: 100%; max-width: 100%';
    const width = 100 / this.columns;
    return 'flex: ' + width + '%; max-width: ' + width + '%';
  }
}
