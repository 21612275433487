<div class="container-box">
  <div class="account-part email-container">
    <div class="box-title">
      {{'profile.email.title' | translate}}
      <mat-icon class="edit-icon" *ngIf="!enableEdit" (click)="enableEdit = true">edit</mat-icon>
    </div>
    <form *ngIf="enableEdit" [ngClass]="{'loading': showSpinner}">
      <mat-form-field>
        <input matInput id="email" type="email" [placeholder]="'system.form.placeholder.email' | translate"
               [formControl]="emailField">
        <mat-error *ngIf="emailField.hasError('email') && !emailField.hasError('required')"
                   [innerHTML]="'profile.email.error.incorrect' | translate">
        </mat-error>
        <mat-error *ngIf="emailField.hasError('emailExists')"
                   [innerHTML]="'profile.email.error.exists' | translate">
        </mat-error>
        <mat-error *ngIf="emailField.hasError('required')"
                   [innerHTML]="'profile.email.error.required' | translate">
        </mat-error>
      </mat-form-field>
      <mat-form-field color="white">
        <input matInput id="password" [type]="hide ? 'password' : 'text'"
               [placeholder]="'system.form.placeholder.password' | translate" [formControl]="passwordField">
        <mat-icon *ngIf="form.enabled" matSuffix
                  (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error *ngIf="passwordField.hasError('required')"
                   [innerHTML]="'system.form.error.required' | translate">
        </mat-error>
        <mat-error *ngIf="passwordField.hasError('password') && !passwordField.hasError('required')"
                   [innerHTML]="'profile.password.error.incorrect' | translate">
        </mat-error>
      </mat-form-field>
      <div class="buttons">
        <button class="cancel-button" (click)="cancel()">{{'system.label.cancel' | translate}}</button>
        <button class="submit-button" (click)="submit()">{{'system.label.save' | translate}}</button>
      </div>
    </form>
    <div *ngIf="!enableEdit" class="single-data">
      {{'profile.email.emailAddress' | translate}}: <span class="data">{{email}}</span>
    </div>
    <app-spinner *ngIf="showSpinner" class="full"></app-spinner>
  </div>
</div>
