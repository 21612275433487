import {JsonProperty} from '@dhkatz/json-ts';

export class AbstractCustomPointRequest {

  @JsonProperty('title')
  private _title: string;

  @JsonProperty('icon')
  private _icon: string;

  @JsonProperty('source')
  private _source: string;

  @JsonProperty('sourceUrl')
  private _sourceUrl: string;

  @JsonProperty('copyrights')
  private _copyrights: string;

  @JsonProperty('copyrightsUrl')
  private _copyrightsUrl: string;

  @JsonProperty('description')
  private _description: string;

  @JsonProperty('x')
  private _x: number;

  @JsonProperty('y')
  private _y: number;

  protected constructor() {
    this._title = undefined;
    this._icon = undefined;
    this._source = undefined;
    this._sourceUrl = undefined;
    this._copyrights = undefined;
    this._copyrightsUrl = undefined;
    this._description = undefined;
    this._x = undefined;
    this._y = undefined;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get icon(): string {
    return this._icon;
  }

  public set icon(value: string) {
    this._icon = value;
  }

  public get source(): string {
    return this._source;
  }

  public set source(value: string) {
    this._source = value;
  }

  public get sourceUrl(): string {
    return this._sourceUrl;
  }

  public set sourceUrl(value: string) {
    this._sourceUrl = value;
  }

  public get copyrights(): string {
    return this._copyrights;
  }

  public set copyrights(value: string) {
    this._copyrights = value;
  }

  public get copyrightsUrl(): string {
    return this._copyrightsUrl;
  }

  public set copyrightsUrl(value: string) {
    this._copyrightsUrl = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get x(): number {
    return this._x;
  }

  public set x(value: number) {
    this._x = value;
  }

  public get y(): number {
    return this._y;
  }

  public set y(value: number) {
    this._y = value;
  }
}
