import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractCombinedPreRenderData} from './abstracts/abstract-combined-pre-render-data';
import {PreRenderData} from './interfaces/pre-render-data';
import {CombinedPreRenderDataPart} from './parts/combined-pre-render-data-part';

export class CombinedPreRenderData extends AbstractCombinedPreRenderData implements PreRenderData {

  @JsonProperty({name: 'd', type: CombinedPreRenderDataPart})
  private _data: CombinedPreRenderDataPart[];

  public constructor() {
    super();
    this._data = undefined;
  }

  public get data(): string {
    return this.active ? this._data[this.activeIndex].data : undefined;
  }

  public getData(): CombinedPreRenderDataPart[] {
    return this._data;
  }

  public setData(value: CombinedPreRenderDataPart[]): void {
    this._data = value;
  }

  public get preLoading(): boolean {
    return false;
  }

  public setDisabledLayers(layers: boolean[]): boolean {
    const prevActive = this.activeIndex;
    this.activeIndex = this._data.findIndex((part: CombinedPreRenderDataPart) => part.setDisabledLayers(layers));
    return prevActive !== this.activeIndex;
  }
}
