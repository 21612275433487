import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractDialogComponent} from '../../../../../../../globals/dialogs/abstracts/abstract-dialog.component';
import {ViewerValidators} from '../../../../../../../utils/viewer-validators';
import {AccountService} from '../../../../../../api/service/account.service';

@Component({
  selector: 'app-invite-teacher-dialog',
  templateUrl: './invite-teacher-dialog.component.html',
  styleUrls: ['./invite-teacher-dialog.component.scss']
})
export class InviteTeacherDialogComponent extends AbstractDialogComponent {

  public emailsField: FormControl;
  public showSpinner: boolean;

  public constructor(private readonly _service: AccountService, private readonly _dialogRef: MatDialogRef<InviteTeacherDialogComponent>,
                     @Inject(MAT_DIALOG_DATA) data: any) {
    super(data);
  }

  protected createForm(): void {
    this.form = new FormGroup({
      emails: this.emailsField,
    });
  }

  protected createFormControls(): void {
    this.emailsField = new FormControl('', [Validators.required, ViewerValidators.emails]);
  }

  public closeDialog(): void {
    this._dialogRef.close();
  }

  public onSubmit(): void {
    if (this.form.invalid) return;
    this.showSpinner = true;
    const emails = (this.emailsField.value as string).toLowerCase().split(/[\s,;]+/).map((email: string) => email.trim())
      .filter((email: string) => email !== '');
    this._service.inviteToInstitution(emails).subscribe(() => this._dialogRef.close(true),
      (error: HttpErrorResponse) => {
        switch (error.error.key) {
          case 'invalid_email':
            this.emailsField.setErrors({email: error.error.message});
            break;
        }
        this.showSpinner = false;
      }, () => this.showSpinner = false);
  }
}
