import {MatSnackBar} from '@angular/material/snack-bar';
import {PointType} from '../project/version/modules/interactive/api/data/points/enums/point-type.enum';

export class ViewerConfig {

  public static readonly TOP_BAR_SIZE = 40;
  public static readonly LEFT_BAR_SIZE = 40;
  public static readonly BOTTOM_BAR_SIZE = 35;
  public static readonly RIGHT_BAR_SIZE = 25;
  public static readonly LIST_SIZE = 10;
  public static readonly MESSAGES_LIST_SIZE = 20;

  public static readonly subscriptionsUrl = 'https://meridianprime.online/cennik/';
  public static readonly helpUrl = 'https://meridianprime.online/pomoc/';
  public static readonly privacyUrl = 'https://meridianprime.online/polityka-prywatnosci';
  public static readonly termsUrl = 'https://meridianprime.online/regulamin';
  public static readonly eulaUrl = 'https://meridianprime.online/licencja';

  public static readonly youtubePlayer: any = {
    rel: 0,
    iv_load_policy: 3,
    modestbranding: 1,
    showinfo: 0
  };

  public static readonly wysiwygStandardConfig: any = {
    removeButtons: 'Save,NewPage,Preview,Print,Templates,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,' +
      'Button,ImageButton,HiddenField,CopyFormatting,RemoveFormat,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Flash,Image,Table,' +
      'HorizontalRule,Smiley,PageBreak,Iframe,Maximize,ShowBlocks,About,Styles,BgColor',
    height: 200,
    toolbarGroups: [
      {name: 'document', groups: ['mode', 'document', 'doctools']},
      {name: 'clipboard', groups: ['clipboard', 'undo']},
      {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
      {name: 'forms', groups: ['forms']},
      {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
      {name: 'paragraph', groups: ['list']},
      {name: 'insert', groups: ['insert']},
      {name: 'styles', groups: ['styles']},
      {name: 'tools', groups: ['tools']},
      {name: 'others', groups: ['others']},
      {name: 'colors', groups: ['colors']}
    ]
  };

  public static readonly wysiwygBasicConfig: any = {
    removeButtons: 'Save,NewPage,Preview,Print,Templates,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,' +
      'Button,ImageButton,HiddenField,CopyFormatting,RemoveFormat,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Flash,Image,Table,' +
      'HorizontalRule,Smiley,PageBreak,Iframe,Maximize,ShowBlocks,About',
    height: 50,
    toolbarGroups: [
      {name: 'document', groups: ['mode']},
      {name: 'clipboard', groups: ['clipboard', 'undo']},
      {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
      {name: 'insert', groups: ['insert']}
    ]
  };

  public static readonly dialogSubscriptionOptions: any = {
    width: '650px',
    panelClass: 'subscription-modalbox',
    autoFocus: false
  };

  private static readonly _dialogOptions: any = {
    width: '400px',
    panelClass: 'system-modalbox',
    autoFocus: false
  };

  private static readonly _authDialogOptions: any = {
    width: '800px',
    panelClass: 'system-modalbox',
    autoFocus: false
  };

  private static readonly _messagesDialogOptions: any = {
    width: '800px',
    panelClass: 'system-modalbox',
    autoFocus: false
  };

  private static readonly _mailDialogOptions: any = {
    width: '400px',
    panelClass: 'system-modalbox',
    autoFocus: false
  };

  private static readonly _dialogInteractiveSettings: any = {
    width: '600px',
    panelClass: 'interactive-settings',
    maxHeight: '100vh',
    autoFocus: false,
    disableClose: true
  };

  private static readonly _dialogScaleOptions: any = {
    width: '500px',
    autoFocus: false
  };

  private static readonly _dialogTasksSettings: any = {
    width: '600px',
    panelClass: 'tasks-settings',
    maxHeight: '100vh',
    autoFocus: false,
    disableClose: true
  };

  private static readonly _dialogPointComponent: any = {
    width: '600px',
    panelClass: 'add-point',
    maxHeight: '100vh',
    autoFocus: false,
    disableClose: true
  };

  private static readonly _interactiveDialogOptions: any = {
    width: '650px',
    panelClass: 'interactive-modalbox',
  };

  private static readonly _interactiveImageDialogOptions: any = {
    width: '800px',
    panelClass: 'interactive-modalbox',
  };

  private static readonly _searchDialogOptions: any = {
    width: '100vw',
    height: '100vh',
    maxWidth: '100vw',
    padding: '0',
    panelClass: 'search-modalbox',
    autoFocus: false
  };

  public static getDialogOptions(data?: any): any {
    const options = Object.assign({}, this._dialogOptions);
    if (!data) return options;
    options.data = data;
    return options;
  }

  public static getAuthDialogOptions(data?: any): any {
    const options = Object.assign({}, this._authDialogOptions);
    if (!data) return options;
    options.data = data;
    return options;
  }

  public static getMailDialogOptions(data?: any): any {
    const options = Object.assign({}, this._mailDialogOptions);
    if (!data) return options;
    options.data = data;
    return options;
  }

  public static getScaleDialogOptions(data?: any): any {
    const options = Object.assign({}, this._dialogScaleOptions);
    if (!data) return options;
    options.data = data;
    return options;
  }

  public static getTasksDialogSettings(data?: any): any {
    const options = Object.assign({}, this._dialogTasksSettings);
    if (!data) return options;
    options.data = data;
    return options;
  }

  public static getPointDialogSettings(data?: any): any {
    const options = Object.assign({}, this._dialogPointComponent);
    if (!data) return options;
    options.data = data;
    return options;
  }

  public static getInteractiveDialogSettings(data?: any): any {
    const options = Object.assign({}, this._dialogInteractiveSettings);
    if (!data) return options;
    options.data = data;
    return options;
  }

  public static getInteractiveDialogOptions(data?: any): any {
    let options = Object.assign({}, this._interactiveDialogOptions);
    if (!data) return options;
    if (data.point && data.point.type === PointType.image)
      options = Object.assign({}, this._interactiveImageDialogOptions);
    options.data = data;
    return options;
  }

  public static getSearchDialogOptions(data?: any): any {
    const options = Object.assign({}, this._searchDialogOptions);
    if (!data) return options;
    options.data = data;
    return options;
  }

  public static openSnackBar(snackBar: MatSnackBar, text: string): void {
    snackBar.open(text, null, {
      duration: 3000,
    });
  }

  public static onYoutubePlayerReady(player: any, movieId: string): void {
    player.loadVideoById(movieId);
    player.stopVideo();
  }

  public static onYouTubeStateChange(event: any): void {
    if (!event.data) event.target.stopVideo();
  }
}
