import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {ScaleResponse} from '../data/scale-response';
import {ScaleEndpoints} from '../scale-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class ScaleService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getScale(projectId: string): Observable<ScaleResponse> {
    return this._http.get<JsonObject>(ScaleEndpoints.SCALE.replace(ScaleEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonObject) => deserializeFix(ScaleResponse, response)));
  }

  public getScalePreview(versionId: string): Observable<ScaleResponse> {
    return this._http.get<JsonObject>(ScaleEndpoints.SCALE_PREVIEW.replace(ScaleEndpoints.VERSION_ID, versionId))
      .pipe(map((response: JsonObject) => deserializeFix(ScaleResponse, response)));
  }
}
