import {JsonProperty} from '@dhkatz/json-ts';

export abstract class AbstractPartResponse {

  @JsonProperty('r')
  private _row: number;

  @JsonProperty('c')
  private _col: number;

  public constructor() {
    this._row = undefined;
    this._col = undefined;
  }

  public get row(): number {
    return this._row;
  }

  public get col(): number {
    return this._col;
  }
}
