<mat-icon [matMenuTriggerFor]="topMenu" class="top-menu">menu</mat-icon>
<mat-menu class="top-menu-expanded" #topMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item class="mat-button" (click)="fullScreenUtil.toggleFullScreen()">
    <img *ngIf="!fullScreenUtil.isFullScreen()" width="24" height="24"
         src="/assets/images/fullscreen.png"
         srcset="/assets/images/fullscreen.png 1x, /assets/images/fullscreen_2x.png 2x">
    <img *ngIf="fullScreenUtil.isFullScreen()" width="24" height="24" src="/assets/images/fullscreen.png"
         srcset="/assets/images/exit_fullscreen.png 1x, /assets/images/exit_fullscreen_2x.png 2x">
    <span *ngIf="fullScreenUtil.isFullScreen()">{{'system.topMenu.disableFillScreen' | translate}}</span>
    <span *ngIf="!fullScreenUtil.isFullScreen()">{{'system.topMenu.enableFullScreen' | translate}}</span>
  </button>
  <button *ngIf="showPrint" mat-menu-item class="mat-button" (click)="print()">
    <img width="24" height="24" src="/assets/images/print.png"
         srcset="/assets/images/print.png 1x, /assets/images/print_2x.png 2x">
    <span>{{'system.topMenu.print' | translate}}</span>
  </button>
  <button *ngIf="showReload" (click)="reload.emit()" class="mat-button" mat-menu-item>
    <img height="24" src="/assets/images/reload.png"
         srcset="/assets/images/reload.png 1x, /assets/images/reload_2x.png 2x" width="24">
    <span>{{'system.topMenu.reload' | translate}}</span>
  </button>
  <button *ngIf="fileSystemUtil.isFileSystemAvailAble()" (click)="goToOfflineManagement()" class="mat-button"
          mat-menu-item>
    <img height="24" src="/assets/images/downloaded.png"
         srcset="/assets/images/downloaded.png 1x, /assets/images/downloaded_2x.png 2x" width="24">
    <span>{{'system.topMenu.offline' | translate}}</span>
  </button>
  <button mat-menu-item class="mat-button" [matMenuTriggerFor]="languages">
    <img width="24" height="24" src="/assets/images/language.png"
         srcset="/assets/images/language.png 1x, /assets/images/language_2x.png 2x">
    <span>{{'system.topMenu.language' | translate}}</span>
  </button>
  <button mat-menu-item class="mat-button" (click)="openHelp()">
    <img width="24" height="24" src="/assets/images/help.png"
         srcset="/assets/images/help.png 1x, /assets/images/help_2x.png 2x">
    <span>{{'system.topMenu.help' | translate}}</span>
  </button>
</mat-menu>

<mat-menu class="top-menu-expanded" #languages="matMenu">
  <button mat-menu-item class="mat-button" (click)="changeLanguage('pl')">
    <span [ngClass]="{active: ('system.langCode' | translate) === 'pl'}">Polski</span>
  </button>
  <button mat-menu-item class="mat-button" (click)="changeLanguage('en')">
    <span [ngClass]="{active: ('system.langCode' | translate) === 'en'}">English</span>
  </button>
</mat-menu>
