import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize, serialize} from '@dhkatz/json-ts';
import {Json, JsonArray, JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthResponse} from '../../../authorization/api/data/auth-response';
import {Role} from '../../../authorization/api/data/enums/role.enum';
import {TitleItem} from '../../../globals/api/data/title-item';
import {AccountEndpoints} from '../account-endpoints';
import {AccountResponse} from '../data/account-response';
import {AddressDataRequest} from '../data/address-data-request';
import {ApprovalResponse} from '../data/approval-response';
import {TeacherResponse} from '../data/teacher-response';
import {UpdateMyEmailRequest} from '../data/update-my-email-request';
import {UpdateMyPasswordRequest} from '../data/update-my-password-request';
import {deserializeFix} from '../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public changeEmail(request: UpdateMyEmailRequest): Observable<void> {
    return this._http.put<void>(AccountEndpoints.ACCOUNTS_MY_EMAIL_CHANGE, serialize(request));
  }

  public changePassword(request: UpdateMyPasswordRequest): Observable<AuthResponse> {
    return this._http.put<JsonObject>(AccountEndpoints.ACCOUNTS_MY_PASSWORD_CHANGE, serialize(request))
      .pipe(map((response: JsonObject) => deserializeFix(AuthResponse, response)));
  }

  public inviteToInstitution(email: string[]): Observable<void> {
    return this._http.post<void>(AccountEndpoints.ACCOUNTS_SEND_INSTITUTION_INVITATION, email);
  }

  public addTeacherToInstitution(code: string): Observable<TitleItem> {
    return this._http.put<JsonObject>(AccountEndpoints.ACCOUNTS_ADD_TEACHER_TO_INSTITUTION, code)
      .pipe(map((response: JsonObject) => deserializeFix(TitleItem, response)));
  }

  public addStudentToInstitution(code: string): Observable<TitleItem> {
    return this._http.put<JsonObject>(AccountEndpoints.ACCOUNTS_ADD_STUDENT_TO_INSTITUTION, code)
      .pipe(map((response: JsonObject) => deserializeFix(TitleItem, response)));
  }

  public getStudentInstitutions(page: number): Observable<TitleItem[]> {
    return this._http.get<JsonArray>(AccountEndpoints.ACCOUNTS_GET_INSTITUTIONS_FOR_STUDENT,
      {params: {page: page.toString()}})
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(TitleItem, value))));
  }

  public getTeacherInstitutions(page: number): Observable<TitleItem[]> {
    return this._http.get<JsonArray>(AccountEndpoints.ACCOUNTS_GET_INSTITUTIONS_FOR_TEACHER,
      {params: {page: page.toString()}})
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(TitleItem, value))));
  }

  public getStudentsForInstitution(page: number, search?: string): Observable<TitleItem[]> {
    const params = {};
    params['page'] = page;
    if (search) params['search'] = search;
    return this._http.get<JsonArray>(AccountEndpoints.ACCOUNTS_INSTITUTION_STUDENTS, {params})
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(TitleItem, value))));
  }

  public getTeachersForInstitution(page: number, search?: string): Observable<TeacherResponse[]> {
    const params = {};
    params['page'] = page;
    if (search) params['search'] = search;
    return this._http.get<JsonArray>(AccountEndpoints.ACCOUNTS_INSTITUTION_TEACHERS, {params})
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(TeacherResponse, value))));
  }

  public getInstitutionCode(institutionId?: string): Observable<string> {
    return institutionId ? this._http.get<string>(AccountEndpoints.ACCOUNTS_INSTITUTION_CODE,
      {params: {institutionId}}) : this._http.get<string>(AccountEndpoints.ACCOUNTS_INSTITUTION_CODE);
  }

  public getAccountDetails(): Observable<AccountResponse> {
    return this._http.get<JsonObject>(AccountEndpoints.ACCOUNTS)
      .pipe(map((response: JsonObject) => deserializeFix(AccountResponse, response)));
  }

  public changeAddressData(addressData: AddressDataRequest): Observable<void> {
    return this._http.put<void>(AccountEndpoints.ACCOUNT_ADDRESS_DATA, serialize(addressData));
  }

  public removeStudentFromInstitution(studentId: string): Observable<void> {
    return this._http.delete<void>(AccountEndpoints.ACCOUNTS_INSTITUTION_REMOVE_STUDENT_FROM_INSTITUTION
      .replace(AccountEndpoints.ACCOUNT_ID, studentId));
  }

  public removeTeacherFromInstitution(teacherId: string): Observable<void> {
    return this._http.delete<void>(AccountEndpoints.ACCOUNTS_INSTITUTION_REMOVE_TEACHER_FROM_INSTITUTION
      .replace(AccountEndpoints.ACCOUNT_ID, teacherId));
  }

  public removeInstitutionFromStudent(institutionId: string): Observable<void> {
    return this._http.delete<void>(AccountEndpoints.ACCOUNTS_INSTITUTION_REMOVE_INSTITUTION_FROM_STUDENT
      .replace(AccountEndpoints.ACCOUNT_ID, institutionId));
  }

  public removeInstitutionFromTeacher(institutionId: string): Observable<void> {
    return this._http.delete<void>(AccountEndpoints.ACCOUNTS_INSTITUTION_REMOVE_INSTITUTION_FROM_TEACHER
      .replace(AccountEndpoints.ACCOUNT_ID, institutionId));
  }

  public roleChange(role: Role): Observable<void> {
    return this._http.post<void>(AccountEndpoints.ACCOUNT_ROLE_CHANGE, role);
  }

  public updateInstitutions(institutions: string[]): Observable<void> {
    return this._http.put<void>(AccountEndpoints.ACCOUNT_INSTITUTIONS, institutions);
  }

  public getApprovals(): Observable<ApprovalResponse[]> {
    return this._http.get<JsonArray>(AccountEndpoints.ACCOUNT_APPROVALS)
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(ApprovalResponse, value))));
  }

  public setApprovals(approvals: string[]): Observable<void> {
    return this._http.put<void>(AccountEndpoints.ACCOUNT_APPROVALS, approvals);
  }
}
