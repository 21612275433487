import {OnElementScroll} from '../globals/interfaces/on-element-scroll';

export class ListController {

  private _enableLoad = true;
  private _pageNumber = 1;
  private _active = true;
  private _event = () => this.onScroll();

  public constructor(private readonly _list: OnElementScroll, private readonly _pageSize: number,
                     private _scrollElement?: HTMLElement) {
    this.setEvent();
    this._list.getNextPage(this._pageNumber);
  }

  private setEvent(): void {
    setTimeout(() => {
      if (!this._scrollElement) this._scrollElement = document.body.querySelector('.mat-drawer-content');
      this._scrollElement.addEventListener('scroll', this._event);
    });
  }

  public set active(value: boolean) {
    this._active = value;
  }

  public getUpdatedList<T>(page: number, newList: T[], list: T[], clazz: new() => T): T[] {
    if (page === 1 || !list) list = [];
    if (!newList) newList = [];
    this._enableLoad = newList.length === this._pageSize;
    list.push(...newList);
    this._pageNumber = page + 1;
    return list;
  }

  public onScroll(): void {
    if (!this._active || !this._enableLoad || !this.isBottom()) return;
    this._enableLoad = false;
    this._list.getNextPage(this._pageNumber);
  }

  public isBottom(): boolean {
    const element = this._scrollElement;
    return element.scrollHeight - element.scrollTop <= element.clientHeight * 1.3;
  }

  public resetPageCounter(): void {
    this._enableLoad = true;
    this._pageNumber = 1;
  }

  public destroy(): void {
    if (this._scrollElement) this._scrollElement.removeEventListener('scroll', this._event);
  }
}
