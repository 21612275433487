import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractLevelResponse} from './abstract-level-response';

export abstract class AbstractRasterLevelResponse extends AbstractLevelResponse {

  @JsonProperty('s')
  private _size: number;

  public constructor() {
    super();
    this._size = undefined;
  }

  public get size(): number {
    return this._size;
  }
}
