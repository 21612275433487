import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {RectSize} from '../../../../../globals/elements/rect-size';
import {AbstractRoleComponent} from '../../../../../globals/screens/abstracts/abstract-role.component';
import {PrintPreviewChange} from '../../../../../print/communication/interfaces/print-preview-change';
import {PrintService} from '../../../../../print/communication/print.service';
import {Drag} from '../../../../../services/drag-service/interafces/drag';
import {ServiceWorkerService} from '../../../../../web-workers/service/service-worker.service';
import {LoadingComplete} from '../../../screens/version/components/loading-progress/service/interfaces/loading-complete';
import {LoadingProgressService} from '../../../screens/version/components/loading-progress/service/loading-progress.service';
import {CustomInteractivesCommunicationService} from '../../layer-manager/modules/custom-interactives-manager/communiaction/custom-interactives-communication.service';
import {CustomInteractivesEditMode} from '../../layer-manager/modules/custom-interactives-manager/communiaction/interface/custom-interactives-edit-mode';
import {ViewChange} from '../../layer/communication/interfaces/view-change';
import {LayersCommunicationService} from '../../layer/communication/layers-communication.service';

export const windowAnimations = trigger('fadeAnimation', [
  state('close', style({
    opacity: 0,
    display: 'none'
  })),
  state('preOpen', style({
    opacity: 0,
    display: 'block'
  })),
  state('open', style({
    opacity: 1,
  })),
  transition('open => close', animate(150)),
  transition('preOpen => open', animate(150)),
]);

export const contentAnimations = trigger('slideAnimation', [
  state('hide', style({
    height: 0,
    display: 'none',
  })),
  state('preShow', style({
    height: 0,
    display: 'block',
  })),
  state('show', style({
    height: '*',
  })),
  transition('show => hide', animate(150)),
  transition('preShow => show', animate(150)),
]);

@Component({template: ''})
export abstract class AbstractWindowComponent extends AbstractRoleComponent implements OnDestroy, LoadingComplete, ViewChange, Drag,
  PrintPreviewChange, CustomInteractivesEditMode {

  private static readonly _windows: AbstractWindowComponent[] = [];

  @ViewChild('window', {static: true})
  public element: ElementRef;

  public contentState = 'show';
  public windowState = 'open';

  protected viewWidth: number;
  protected viewHeight: number;
  protected windowParams: RectSize;
  protected printPreview: boolean;

  private _showContent = true;
  private _showWindow = true;
  private _customLayersEditMode = false;
  private _startX: number;
  private _startY: number;
  private _dragX: number;
  private _dragY: number;

  protected constructor(protected readonly layersCommunication: LayersCommunicationService,
                        private readonly customLayersCommunication: CustomInteractivesCommunicationService,
                        protected readonly loading: LoadingProgressService, private readonly _print: PrintService,
                        workerService: ServiceWorkerService) {
    super(workerService);
    this.layersCommunication.addViewChangeListener(this);
    this.loading.addLoadingCompleteListener(this);
    this._print.addPrintPreviewChangeListener(this, true);
    this.customLayersCommunication.addCustomInteractiveEditModeListener(this);
    this.windowParams = new RectSize();
    this.addWindow(this);
  }

  public onLoadingComplete(): void {
    setTimeout(() => this.afterViewInit(), 0);
  }

  public get showContent(): boolean {
    return this._showContent;
  }

  public set showContent(value: boolean) {
    const changed = this._showContent !== value;
    this._showContent = value;
    if (changed) this.contentState = value ? 'preShow' : 'hide';
    setTimeout(() => {
      if (value && changed) this.contentState = 'show';
      this.onShowContent(value);
    }, 0);
  }

  public get showWindow(): boolean {
    return this._showWindow;
  }

  public set showWindow(value: boolean) {
    const changed = this._showWindow !== value;
    this._showWindow = value;
    if (changed) this.windowState = value ? 'preOpen' : 'close';
    setTimeout(() => {
      if (value && changed) this.windowState = 'open';
      this.updateDivParams();
      this.onShowWindow(value);
    }, 0);
  }

  protected onShowWindow(value: boolean): void {
  }

  protected onShowContent(value: boolean): void {
  }

  public onPrintPreviewChange(enabled: boolean): void {
    this.printPreview = enabled;
  }

  public ngOnDestroy(): void {
    this.loading.removeLoadingCompleteListener(this);
    this._print.removePrintPreviewChangeListener(this);
    this.customLayersCommunication.removeCustomInteractiveEditModeListener(this);
    this.removeWindow(this);
  }

  private addWindow(window: AbstractWindowComponent): void {
    AbstractWindowComponent._windows.push(window);
  }

  private removeWindow(window: AbstractWindowComponent): void {
    AbstractWindowComponent._windows.splice(AbstractWindowComponent._windows.indexOf(window), 1);
  }

  public setOnTop(window: AbstractWindowComponent): void {
    const activeWindow = AbstractWindowComponent._windows.find((active: AbstractWindowComponent) =>
      active === window);
    for (const element of AbstractWindowComponent._windows) {
      element.element.nativeElement.style.zIndex = 1;
    }
    activeWindow.element.nativeElement.style.zIndex = 2;
  }

  public afterViewInit(): void {
    this.showContent = true;
    this.correctPosition();
  }

  public afterViewChecked(): void {
    this.updateDivParams();
  }

  public onDrag(event: any, x: number, y: number): void {
    this._dragX += x;
    this._dragY += y;
    this.updateDivParams();
  }

  public onStartDrag(event: any): void {
    this._startX = this.windowParams.x;
    this._startY = this.windowParams.y;
    this._dragX = this.windowParams.x;
    this._dragY = this.windowParams.y;
    this.setOnTop(this);
    this.updateDivParams();
  }

  public onStopDrag(event: any): void {
    this._dragX = undefined;
    this._dragY = undefined;
  }

  public updateDivParams(): void {
    if (!this.element) return;
    this.windowParams.x = this._dragX || parseFloat(this.element.nativeElement.style.left);
    this.windowParams.y = this._dragY || parseFloat(this.element.nativeElement.style.top);
    this.updateSize();
    this.correctPosition();
  }

  protected updateSize(): void {
    if (!this.element) return;
    if (this.element.nativeElement.clientWidth > 0) this.windowParams.width = this.element.nativeElement.clientWidth;
    if (this.element.nativeElement.clientHeight > 0) this.windowParams.height = this.element.nativeElement.clientHeight;
  }

  public correctPosition(): void {
    if (this.windowParams.y < ViewerConfig.TOP_BAR_SIZE - 1) this.windowParams.y = ViewerConfig.TOP_BAR_SIZE - 1;
    if (this.windowParams.y + this.windowParams.height > this.viewHeight + ViewerConfig.TOP_BAR_SIZE - 1)
      this.windowParams.y = this.viewHeight - this.windowParams.height + ViewerConfig.TOP_BAR_SIZE - 1;
    if (this.windowParams.x < ViewerConfig.LEFT_BAR_SIZE - 1) this.windowParams.x = ViewerConfig.LEFT_BAR_SIZE - 1;
    if (this.windowParams.x + this.windowParams.width > this.viewWidth + ViewerConfig.LEFT_BAR_SIZE - 1)
      this.windowParams.x = (this.viewWidth - this.windowParams.width + ViewerConfig.LEFT_BAR_SIZE - 1);
    this.moveToPosition();
  }

  protected moveToPosition(): void {
    if (!this.element) return;
    this.element.nativeElement.style.top = this.windowParams.y + 'px';
    this.element.nativeElement.style.left = this.windowParams.x + 'px';
  }

  public onViewChange(width: number, height: number): void {
    if (this.printPreview) return;
    this.viewWidth = width;
    this.viewHeight = height;
    this.updateDivParams();
  }

  public customInteractiveEditModeEnabled(layerId: string, projectId: string): void {
    this.showWindow = false;
  }

  public customInteractiveEditModeDisabled(): void {
    this.showWindow = true;
  }
}
