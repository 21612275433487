import {JsonProperty} from '@dhkatz/json-ts';
import {EnumConverter} from '../../../../../../../globals/api/json/enum-converter';
import {PreRenderType} from '../enums/pre-render-type.enum';

export abstract class AbstractPreRenderData {

  @JsonProperty({name: 't', converter: EnumConverter.converter(PreRenderType)})
  private _type: PreRenderType;

  protected constructor() {
    this._type = undefined;
  }

  public get type(): PreRenderType {
    return this._type;
  }

  public set type(value: PreRenderType) {
    this._type = value;
  }

  public abstract setDisabledLayers(layers: boolean[]): boolean;
}
