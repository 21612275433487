import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatInput} from '@angular/material/input';
import {Subscription} from 'rxjs/internal/Subscription';
import {TitleItem} from '../../../globals/api/data/title-item';
import {OnSearch} from '../../../globals/interfaces/on-search';
import {Rwd} from '../../../globals/services/enums/rwd.enum';
import {RwdService} from '../../../globals/services/rwd.service';
import {TagService} from '../../../tag/api/services/tag.service';
import {SearchController} from '../../../utils/search-controller';
import {ProjectResponse} from '../../api/data/project-response';
import {ProjectService} from '../../api/services/project.service';
import {AbstractProjectsComponent} from '../projects/abstracts/abstract-projects.component';

@Component({
  selector: 'app-search-projects',
  templateUrl: './search-projects.component.html',
  styleUrls: ['./search-projects.component.scss']
})

export class SearchProjectsComponent extends AbstractProjectsComponent implements OnInit, OnDestroy, OnSearch {

  @ViewChild('autoFocus', {static: true})
  public autoFocus: MatInput;

  public selectable = true;
  public removable = true;
  public tagsField = new FormControl();
  public searchField = new FormControl();
  public filteredTags: TitleItem[] = [];
  public selectedTags: TitleItem[] = [];
  public projectsGroups: ProjectResponse[][];
  public showSpinner: boolean;

  private _request: Subscription;
  private _projects: ProjectResponse[];
  private _tags: TitleItem[] = [];
  private _searchController: SearchController;

  public constructor(private readonly _tagService: TagService, private readonly _projectService: ProjectService,
                     private readonly _dialogRef: MatDialogRef<SearchProjectsComponent>,
                     @Inject(MAT_DIALOG_DATA) private readonly _tag: TitleItem,
                     rwd: RwdService) {
    super(rwd);
    this._searchController = new SearchController(this);
  }

  public ngOnInit(): void {
    this.autoFocus.focus();
    this._tagService.getTagsForPublishedVersions().subscribe((response: TitleItem[]) => {
      this._tags = response;
      if (this._tag) this.addTag(this._tag);
      else this.filterTags();
    });
  }

  public removeTag(tag: TitleItem): void {
    const index = this.selectedTags.indexOf(tag);
    if (index >= 0) this.selectedTags.splice(index, 1);
    this.filterTags();
    this.sendSearch();
  }

  public addTag(tag: TitleItem): void {
    this.autoFocus.focus();
    this.selectedTags.push(tag);
    this.tagsField.setValue(null);
    this.filterTags();
    this.sendSearch();
  }

  public filterTags(): void {
    const search = this.tagsField.value && typeof this.tagsField.value === 'string' ?
      this.tagsField.value.toLowerCase().split(' ') : [];
    const selectedTags = this.selectedTags.map((tag: TitleItem) => tag.id);
    this.filteredTags = this._tags.filter((tag: TitleItem) => {
      if (selectedTags.includes(tag.id)) return false;
      for (const word of search) {
        if (word === '') continue;
        if (!tag.title || !tag.title.toLowerCase().includes(word)) return false;
      }
      return true;
    });
  }

  public searchDelay(): void {
    if (this.searchField.value) this._searchController.search();
    else this._searchController.forceSearch();
  }

  public startSearch(): void {
    this.showSpinner = true;
    if (this._request) this._request.unsubscribe();
    this._projects = undefined;
    this.projectsGroups = [];
  }

  public sendSearch(): void {
    this.startSearch();
    const search = this.searchField.value;
    const tags = this.selectedTags.map((tag) => tag.id);
    if ((!tags || tags.length === 0) && !search) {
      this.showSpinner = false;
      return;
    }
    this._request = this._projectService.getSearchProjects(search, tags).subscribe((response) => {
      this._projects = response;
      this.projectsGroups = [];
      this.defineProjectsGroups();
      this.showSpinner = false;
    }, () => this.showSpinner = false);
  }

  private defineProjectsGroups(startIndex?: number): void {
    if (!startIndex) startIndex = 0;
    let endIndex = startIndex + this.carriageSize;
    if (endIndex > this._projects.length) endIndex = this._projects.length;

    const projects: ProjectResponse[] = [];
    for (let i = startIndex; i < endIndex; i++) projects.push(this._projects[i]);
    this.projectsGroups.push(projects);
    if (endIndex < this._projects.length) this.defineProjectsGroups(endIndex);
  }

  public closeDialog(): void {
    this._dialogRef.close();
  }

  public anyProjects(): boolean {
    return !!this._projects && !!this._projects.length;
  }

  public onRwdChange(screen: Rwd): void {
    super.onRwdChange(screen);
    if (!this._projects) return;
    this.projectsGroups = [];
    this.defineProjectsGroups();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._request) this._request.unsubscribe();
    this._searchController.clear();
    this._searchController = undefined;
  }
}
