import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ViewerConfig} from '../../../configs/viewer-config';
import {Endpoints} from '../../../globals/api/endpoints';
import {VersionResponse} from '../../../project/version/api/data/version-response';
import {VersionService} from '../../../project/version/api/services/version.service';
import {deserializeFix} from '../../../utils/deserialize-util';
import {IdbUtil} from '../../../utils/idb-util';
import {SecurityWorkerLogin} from '../../../web-workers/service/interfaces/security-worker-login';
import {SecurityWorkerLogout} from '../../../web-workers/service/interfaces/security-worker-logout';
import {ServiceWorkerCommunicationService} from '../../../web-workers/service/service-worker-communication.service';
import {ServiceWorkerService} from '../../../web-workers/service/service-worker.service';
import {OfflineStatus} from '../../communication/enums/offline-status.enum';
import {OfflineDBEntry} from '../../services/offline-service/data/OfflineDBEntry';
import {OfflineProjectEntry} from '../../services/offline-service/data/OfflineProjectEntry';
import {OfflineService} from '../../services/offline-service/offline.service';
import {EmailDialogComponent} from '../dialogs/email-dialog/email-dialog.component';

@Component({
  selector: 'app-offline-projects',
  templateUrl: './offline-projects.component.html',
  styleUrls: ['./offline-projects.component.scss']
})
export class OfflineProjectsComponent implements OnInit, SecurityWorkerLogin, SecurityWorkerLogout {

  public email: string;
  public resourcesUrl = Endpoints.RESOURCES;
  public projects: OfflineProjectEntry[];

  public showSpinner = true;

  public constructor(public readonly workerService: ServiceWorkerService, private readonly _dialog: MatDialog,
                     private readonly _workerCommunication: ServiceWorkerCommunicationService,
                     private readonly _versionService: VersionService, private readonly _offlineService: OfflineService,
                     private readonly _cdr: ChangeDetectorRef) {
    this._workerCommunication.addSecurityWorkerLoginListener(this);
    this._workerCommunication.addSecurityWorkerLogoutListener(this);
  }

  public ngOnInit(): void {
    if (this.workerService.isLoggedInOnline) {
      this.email = this.workerService.email;
      this.getProjectsForEmail();
      return;
    }
    if (localStorage.getItem('offlineEmail')) {
      this.email = localStorage.getItem('offlineEmail');
      this.getProjectsForEmail();
      return;
    }
    if (!this.email) this.showSpinner = false;
  }

  public removeProject(projectId: string): void {
    const newList = this.projects;
    const index = newList.findIndex((project) => project.project === projectId);
    newList.splice(index, 1);
    this.projects = newList;
    this._cdr.detectChanges();
  }

  private getProjectsForEmail(): void {
    IdbUtil.idbKeyVal(IdbUtil.IDB_OFFLINE_VERSION).getAll().then((result: any) => {
      const keys = Object.keys(result);
      this.projects = [];
      keys.map((key: string) => this._offlineService.getOfflineProjectEntryForUser(deserializeFix(OfflineDBEntry, result[key]), this.email))
        .filter((entry: OfflineProjectEntry) => entry)
        .forEach((entry: OfflineProjectEntry) => {
          this.projects.push(entry);
          if (!this.workerService.isLoggedInOnline) return;
          this._versionService.getVersion(entry.project).subscribe((response: VersionResponse) => {
            IdbUtil.idbKeyVal(IdbUtil.IDB_ONLINE_VERSION).set(entry.project, response.versionId)
              .catch((reason) => console.log(reason));
          });
        });
      this.showSpinner = false;
    });
  }

  public loginOffline(): void {
    if (this.workerService.isLoggedInOnline) return;
    this._offlineService.checkOfflineAccessForUser();
    setTimeout(() => this._dialog.open(EmailDialogComponent, ViewerConfig.getMailDialogOptions({
      disableClose: false
    })).afterClosed().subscribe((email: string) => {
      if (!email || email === '') return;
      this.email = email;
      this.getProjectsForEmail();
      localStorage.setItem('offlineEmail', email);
    }), 0);
  }

  public logoutOffline(): void {
    localStorage.removeItem('offlineEmail');
    this.email = '';
    this._offlineService.checkOfflineAccessForUser();
    this.getProjectsForEmail();
    this.workerService.logout();
  }

  public onSecurityWorkerLogin(): void {
    this.email = this.workerService.email;
    this.getProjectsForEmail();
  }

  public onSecurityWorkerLogout(): void {
    this.email = '';
    this.getProjectsForEmail();
  }

  public isDownloading(project: OfflineProjectEntry): boolean {
    return project.status === OfflineStatus.DOWNLOADING;
  }
}
