import {JsonProperty} from '@dhkatz/json-ts';
import {DateToMilliSecondsConverter} from '../../../globals/api/json/date-to-milli-seconds-converter';

export class SubscriptionProjectResponse {

  @JsonProperty('projectId')
  private _projectId: string;

  @JsonProperty({name: 'normal', converter: DateToMilliSecondsConverter.converter})
  private _normal: Date;

  @JsonProperty({name: 'demo', converter: DateToMilliSecondsConverter.converter})
  private _demo: Date;

  public constructor() {
    this._projectId = undefined;
    this._normal = undefined;
    this._demo = undefined;
  }

  public get projectId(): string {
    return this._projectId;
  }

  public get normal(): Date {
    return this._normal;
  }

  public get demo(): Date {
    return this._demo;
  }
}
