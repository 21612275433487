import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-image-preload',
  templateUrl: './image-preload.component.html',
  styleUrls: ['./image-preload.component.scss'],
})

export class ImagePreloadComponent {

  @Input()
  public normal: string;

  @Input()
  public retina: string;

  @Input()
  public endpoint: string;

  @Input()
  public none: string;

  @Output()
  public loadComplete: EventEmitter<void> = new EventEmitter<void>();

  public showSpinner = true;
  public showNone: boolean;
}
