<div class="management-container account-container">
  <div class="center-box">
    <div class="boxes">
      <app-institution-teachers *ngIf="isInstitution"></app-institution-teachers>
      <app-institution-students *ngIf="isInstitution && hasSchoolPlus"></app-institution-students>
      <app-user-teacher *ngIf="isTeacher"></app-user-teacher>
      <app-user-student *ngIf="isUser"></app-user-student>
    </div>
  </div>
</div>
