import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {VideoEndpoints} from '../video-endpoints';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getVideo(projectId: string): Observable<string> {
    return this._http.get<string>(VideoEndpoints.VIDEO.replace(VideoEndpoints.PROJECT_ID, projectId));
  }
}
