export enum VersionModule {
  layer = 'layer' as any,
  interactive = 'interactive' as any,
  layerManager = 'layerManager' as any,
  legend = 'legend' as any,
  miniMap = 'miniMap' as any,
  scale = 'scale' as any,
  fonts = 'fonts' as any,
  tasks = 'tasks' as any,
  bibliography = 'bibliography' as any,
  panels = 'panels' as any
}
