import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DragService} from '../../../../../../services/drag-service/drag.service';
import {Drag} from '../../../../../../services/drag-service/interafces/drag';
import {MinimalScaleChange} from '../../../../modules/layer/communication/interfaces/minimal-scale-change';
import {ScaleChange} from '../../../../modules/layer/communication/interfaces/scale-change';
import {LayersCommunicationService} from '../../../../modules/layer/communication/layers-communication.service';

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent implements ScaleChange, Drag, OnDestroy, OnInit, MinimalScaleChange {

  @ViewChild('zoomgrab', {static: true})
  public zoomGrab: ElementRef;

  @ViewChild('zoomrange', {static: true})
  public element: ElementRef;
  public scale: number;

  private _minimalScale = 0;

  public constructor(private readonly _layersCommunication: LayersCommunicationService, private readonly _dragService: DragService) {
    this._layersCommunication.addScaleChangeListener(this);
    this._layersCommunication.addMinimalScaleChangeListener(this);
  }

  public ngOnInit(): void {
    if (!this.element) return;
    this._dragService.addDrag(this);
  }

  public onDrag(event: any, x?: number, y?: number): void {
    this.moveGrabber(this._dragService.getElementOffset(this.element, event).y);
  }

  public onStartDrag(event: any): void {
    this.moveGrabber(this._dragService.getElementOffset(this.element, event).y);
  }

  public onStopDrag(event: any): void {
  }

  public onScaleChange(scale: number): void {
    this.scale = scale;
    const runnerHeight = this.element.nativeElement.clientHeight;
    const grabHeight = this.zoomGrab.nativeElement.clientHeight;
    const maxHeight = 1 - this._minimalScale;
    const height = (scale - this._minimalScale) / maxHeight * (runnerHeight - 4 - grabHeight);
    this.zoomGrab.nativeElement.style.bottom = height + 'px';
  }

  public onMinimalScaleChange(minimalScale: number): void {
    this._minimalScale = minimalScale;
    this.onScaleChange(this.scale);
  }

  private moveGrabber(posY: number): void {
    const range = 1 - this._minimalScale;
    const grabHeight = this.zoomGrab.nativeElement.clientHeight;
    const runnerHeight = this.element.nativeElement.clientHeight - grabHeight;
    this.scale = (runnerHeight - (posY - grabHeight / 2)) / runnerHeight * range + this._minimalScale;
    if (this.scale > 1) this.scale = 1;
    if (this.scale < this._minimalScale) this.scale = this._minimalScale;
    this._layersCommunication.scaleCenterChange(this.scale);
  }

  public zoomIn(): void {
    this.scale += 0.01;
    if (this.scale > 1) this.scale = 1;
    this._layersCommunication.scaleCenterChange(this.scale);
  }

  public zoomOut(): void {
    this.scale -= 0.01;
    if (this.scale < this._minimalScale) this.scale = this._minimalScale;
    this._layersCommunication.scaleCenterChange(this.scale);
  }

  public ngOnDestroy(): void {
    this._layersCommunication.removeScaleChangeListener(this);
    this._layersCommunication.removeMinimalScaleChangeListener(this);
    this._dragService.removeDrag(this);
  }
}
