import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Subscriber} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {ScaleResponse} from '../data/scale-response';
import {ScaleEndpoints} from '../scale-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class ScaleOfflineService {

  public constructor() {
  }

  public getScale(projectId: string): Observable<ScaleResponse> {
    const path = ScaleEndpoints.SCALE.replace(ScaleEndpoints.PROJECT_ID, projectId);
    return new Observable<ScaleResponse>((subscriber: Subscriber<ScaleResponse>) =>
      OfflineUtil.loadText(projectId, path, (file: string) => {
        subscriber.next(deserializeFix(ScaleResponse, JSON.parse(file)));
      }));
  }
}
