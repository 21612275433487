export class ScrollUtil {

  private static activeContainers: any[] = [];

  public static scrollTo(container: any, to: number, duration: number): void {
    if (this.activeContainers.includes(container)) return;
    this.activeContainers.push(container);
    const start = container.scrollTop;
    const change = to - start;
    this.animateScroll(container, 0, start, change, duration);
  }

  private static animateScroll(container: any, currentTime: number, start: number, change: number, duration: number): void {
    const increment = 20;
    currentTime += increment;
    container.scrollTop = this.easeInOutQuad(currentTime, start, change, duration);
    if (currentTime < duration) setTimeout(() => this.animateScroll(container, currentTime, start, change, duration), increment);
    else this.removeActiveContainer(container);
  }

  private static easeInOutQuad(currentTime: number, start: number, change: number, duration: number): number {
    currentTime /= duration / 2;
    if (currentTime < 1) return change / 2 * currentTime * currentTime + start;
    currentTime--;
    return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
  }

  private static removeActiveContainer(container: any): void {
    const index = this.activeContainers.indexOf(container);
    if (index > -1) this.activeContainers.splice(index, 1);
  }
}
