import {JsonProperty} from '@dhkatz/json-ts';
import {DateToMilliSecondsConverter} from '../../../globals/api/json/date-to-milli-seconds-converter';
import {EnumConverter} from '../../../globals/api/json/enum-converter';
import {SubscriptionStatus} from './enums/subscription-status';
import {SubscriptionType} from './enums/subscription-type';

export class SubscriptionResponse {

  @JsonProperty('name')
  private _name: string;

  @JsonProperty('id')
  private _id: string;

  @JsonProperty({name: 'type', converter: EnumConverter.converter(SubscriptionType)})
  private _type: SubscriptionType;

  @JsonProperty({name: 'startDate', converter: DateToMilliSecondsConverter.converter})
  private _startDate: Date;

  @JsonProperty({name: 'expirationDate', converter: DateToMilliSecondsConverter.converter})
  private _expirationDate: Date;

  @JsonProperty({name: 'status', converter: EnumConverter.converter(SubscriptionStatus)})
  private _status: SubscriptionStatus;

  @JsonProperty('demo')
  private _demo: boolean;

  @JsonProperty('unlimited')
  private _unlimited: boolean;

  @JsonProperty('schoolPlus')
  private _schoolPlus: boolean;

  @JsonProperty('scope')
  private _scope: number;

  public constructor() {
    this._name = undefined;
    this._id = undefined;
    this._startDate = undefined;
    this._expirationDate = undefined;
    this._status = undefined;
    this._demo = undefined;
    this._unlimited = undefined;
    this._scope = undefined;
    this._schoolPlus = undefined;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get type(): SubscriptionType {
    return this._type;
  }

  public set type(value: SubscriptionType) {
    this._type = value;
  }

  public get startDate(): Date {
    return this._startDate;
  }

  public set startDate(value: Date) {
    this._startDate = value;
  }

  public get expirationDate(): Date {
    return this._expirationDate;
  }

  public set expirationDate(value: Date) {
    this._expirationDate = value;
  }

  public get status(): SubscriptionStatus {
    return this._status;
  }

  public set status(value: SubscriptionStatus) {
    this._status = value;
  }

  public get demo(): boolean {
    return this._demo;
  }

  public set demo(value: boolean) {
    this._demo = value;
  }

  public get unlimited(): boolean {
    return this._unlimited;
  }

  public set unlimited(value: boolean) {
    this._unlimited = value;
  }

  public get scope(): number {
    return this._scope;
  }

  public set scope(value: number) {
    this._scope = value;
  }

  public get schoolPlus(): boolean {
    return this._schoolPlus;
  }

  public set schoolPlus(value: boolean) {
    this._schoolPlus = value;
  }
}
