import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Subscriber} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {MiniMapResponse} from '../data/mini-map-response';
import {MiniMapEndpoints} from '../mini-map-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class MiniMapOfflineService {

  public constructor() {
  }

  public getMiniMap(projectId: string): Observable<MiniMapResponse> {
    const path = MiniMapEndpoints.MINI_MAP.replace(MiniMapEndpoints.PROJECT_ID, projectId);
    return new Observable<MiniMapResponse>((subscriber: Subscriber<MiniMapResponse>) =>
      OfflineUtil.loadText(projectId, path, (file: string) => {
        subscriber.next(deserializeFix(MiniMapResponse, JSON.parse(file)));
      }));
  }
}
