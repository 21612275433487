import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Json, JsonArray} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {FontsEndpoints} from '../../../../version/modules/fonts/api/fonts-endpoints';
import {CarouselEndpoints} from '../carousel-endpoints';
import {RetinaResponse} from '../data/retina-response';
import {deserializeFix} from '../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class CarouselService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getCarousel(projectId: string): Observable<RetinaResponse[]> {
    return this._http.get<JsonArray>(CarouselEndpoints.CAROUSEL.replace(FontsEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(RetinaResponse, value))));
  }
}
