<div class="system-dialog">
  <header>{{(title | translate) || data?.error}}</header>
  <div class="system-dialog-body">
    <p>{{(description | translate) || data?.message}}</p>
    <p *ngIf="data?.timestamp"><strong>{{'system.responseError.date' | translate}}
      :</strong> {{data?.timestamp | date:'dd/MM/yyyy HH:mm:ss'}}</p>
    <p *ngIf="data?.code"><strong>{{'system.responseError.code' | translate}}:</strong> {{data?.code}}</p>
    <div class="buttons">
      <button class="submit-button" mat-dialog-close>{{'system.label.ok' | translate}}</button>
    </div>
  </div>
</div>
