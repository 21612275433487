<header mat-dialog-title>{{'project.interactive.custom.title' | translate}}
  <button>
    <mat-icon [matTooltip]="'project.interactive.custom.buttons.add' | translate"
              matTooltipPosition="above" *ngIf="screen === screens.List" (click)="add()">add
    </mat-icon>
  </button>
</header>
<app-interactive-list *ngIf="cInteractiveLayers" [hidden]="screen!==screens.List" [init]="init"
                      [cInteractiveLayers]="cInteractiveLayers" [projectId]="projectId" (close)="close()"
                      (update)="update($event)" (remove)="remove($event)"></app-interactive-list>
<app-interactive-edit [hidden]="screen!==screens.Edit" [screen]="screen" (close)="screen = screens.List"
                      [cInteractiveLayer]="selected" [projectId]="projectId"></app-interactive-edit>
<app-interactive-remove [hidden]="screen!==screens.Remove" (close)="screen = screens.List"
                        [cInteractiveLayer]="selected" [projectId]="projectId"></app-interactive-remove>
