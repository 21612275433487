import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {ViewerConfig} from '../../../../../../../../../../../configs/viewer-config';
import {AbstractDialogComponent} from '../../../../../../../../../../../globals/dialogs/abstracts/abstract-dialog.component';
import {AbstractPointResponse} from '../../../../../../../../interactive/api/data/points/abstracts/abstract-point-response';
import {PointType} from '../../../../../../../../interactive/api/data/points/enums/point-type.enum';
import {AbstractCustomPointRequest} from '../../../../../../../../interactive/modules/points/api/data/abstracts/abstract-custom-point-request';
import {PointService} from '../../../../../../../../interactive/modules/points/api/services/point.service';
import {CustomInteractivesCommunicationService} from '../../../../../communiaction/custom-interactives-communication.service';

export abstract class AbstractCustomPointComponent<Request extends AbstractCustomPointRequest, Response extends AbstractPointResponse>
  extends AbstractDialogComponent {

  public titleField: FormControl;
  public sourceField: FormControl;
  public sourceUrlField: FormControl;
  public copyrightsField: FormControl;
  public copyrightsUrlField: FormControl;
  public descriptionField: FormControl;
  public wysiwygConfig: any;
  public basicWysiwygConfig: any;
  public iconId: string;

  public iconError = false;

  public point: Response;

  protected projectId: string;
  protected layerId: string;
  protected x: number;
  protected y: number;

  protected pointType: PointType;

  protected constructor(protected readonly service: PointService,
                        protected readonly customInteractiveCommunication: CustomInteractivesCommunicationService,
                        protected readonly dialogRef: MatDialogRef<AbstractCustomPointComponent<Request, Response>>, protected data: any) {
    super(data);
    this.projectId = this.getValue('projectId');
    this.layerId = this.getValue('layerId');
    this.point = this.getValue('point');
    this.wysiwygConfig = ViewerConfig.wysiwygStandardConfig;
    this.basicWysiwygConfig = ViewerConfig.wysiwygBasicConfig;
    if (this.point) {
      this.iconId = this.getValue('iconId');
      this.x = this.point.x;
      this.y = this.point.y;
    } else {
      this.x = this.getValue('x');
      this.y = this.getValue('y');
    }
  }

  protected createFormControls(): void {
    this.titleField = new FormControl(this.point ? this.point.title : '', [Validators.required]);
    this.sourceField = new FormControl(this.point ? this.point.source : '');
    this.sourceUrlField = new FormControl(this.point ? this.point.sourceUrl : '');
    this.copyrightsField = new FormControl(this.point ? this.point.copyrights : '');
    this.copyrightsUrlField = new FormControl(this.point ? this.point.copyrightsUrl : '');
    this.descriptionField = new FormControl(this.point ? this.point.description : '', [Validators.required]);
  }

  protected createForm(): void {
    this.form = new FormGroup({
      title: this.titleField,
      sourceField: this.sourceField,
      sourceUrlField: this.sourceUrlField,
      copyrightsField: this.copyrightsField,
      copyrightsUrlField: this.copyrightsUrlField,
      description: this.descriptionField
    });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    if (!this.iconId) {
      this.iconError = true;
      return;
    }
    if (!this.form.valid) return;
    const request = this.getRequest();
    request.title = this.titleField.value;
    request.description = this.descriptionField.value;
    request.copyrights = this.copyrightsField.value;
    request.copyrightsUrl = this.copyrightsUrlField.value;
    request.source = this.sourceField.value;
    request.sourceUrl = this.sourceUrlField.value;
    request.icon = this.iconId;
    request.x = this.x;
    request.y = this.y;

    const requestMethod = this.point
      ? this.service.updatePoint<Request>(this.projectId, this.layerId, this.pointType, this.point.id, request)
      : this.service.createPoint<Request>(this.projectId, this.layerId, this.pointType, request);

    requestMethod.subscribe(() => {
      this.customInteractiveCommunication.callInteractiveCustomLayersChanged();
      this.dialogRef.close();
    });
  }

  protected abstract getRequest(): Request;
}
