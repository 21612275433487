import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AbstractRoleComponent} from '../../../../../globals/screens/abstracts/abstract-role.component';
import {ServiceWorkerService} from '../../../../../web-workers/service/service-worker.service';
import {AccountService} from '../../../../api/service/account.service';

@Component({template: ''})
export abstract class AbstractAccountPart extends AbstractRoleComponent implements OnInit {

  public enableEdit = false;
  public showSpinner = false;

  protected form: FormGroup;

  protected constructor(protected readonly service: AccountService, protected readonly snackBar: MatSnackBar,
                        serviceWorker: ServiceWorkerService) {
    super(serviceWorker);
  }

  public ngOnInit(): void {
    this.createFormControls();
    this.createForm();
  }

  protected abstract createFormControls(): void;

  protected abstract createForm(): void;

  public abstract submit(): void;

  public abstract cancel(): void;
}
