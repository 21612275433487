import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Json, JsonArray} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {InstitutionsResponse} from '../data/institutions-response';
import {InstitutionsEndpoints} from '../institutions-endpoints';
import {deserializeFix} from '../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class InstitutionsService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public search(name: string): Observable<InstitutionsResponse[]> {
    return this._http.get<JsonArray>(InstitutionsEndpoints.INSTITUTIONS, {params: {name}})
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(InstitutionsResponse, value))));
  }

  public getInstitutions(institutions: string[]): Observable<InstitutionsResponse[]> {
    return this._http.get<JsonArray>(InstitutionsEndpoints.USER_INSTITUTIONS, {params: {ids: institutions}})
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(InstitutionsResponse, value))));
  }
}
