import {JsonProperty} from '@dhkatz/json-ts';
import {ArrayNumberConverter} from '../../../../../../../../globals/api/json/array-number-converter';
import {VectorFillBorderElement} from '../abstracts/vector-fill-border-element';

export class Rect extends VectorFillBorderElement {

  @JsonProperty({name: 'd', converter: ArrayNumberConverter.converter, type: Object})
  private _draw: number[];

  public constructor() {
    super();
    this._draw = undefined;
  }

  public get draw(): number[] {
    return this._draw;
  }
}
