import {Injectable} from '@angular/core';
import {PrintFormat} from './enums/print-format.enum';
import {PrintFormatChange} from './interfaces/print-format-change';
import {PrintLegendChange} from './interfaces/print-legend-change';
import {PrintPreviewChange} from './interfaces/print-preview-change';
import {PrintScaleChange} from './interfaces/print-scale-change';
import {PrintTasksChange} from './interfaces/print-tasks-change';
import {PrintTitleChange} from './interfaces/print-title-change';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  private readonly _printPreviewChangeListeners: PrintPreviewChange[] = [];
  private readonly _printFormatChangeListeners: PrintFormatChange[] = [];
  private readonly _printTitleChangeListeners: PrintTitleChange[] = [];
  private readonly _printScaleChangeListeners: PrintScaleChange[] = [];
  private readonly _printLegendChangeListeners: PrintLegendChange[] = [];
  private readonly _printTasksChangeListeners: PrintTasksChange[] = [];

  public constructor() {
  }

  public addPrintPreviewChangeListener(listener: PrintPreviewChange, onStart?: boolean): void {
    if (onStart) this._printPreviewChangeListeners.unshift(listener);
    else this._printPreviewChangeListeners.push(listener);
  }

  public removePrintPreviewChangeListener(listener: PrintPreviewChange): void {
    const index: number = this._printPreviewChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._printPreviewChangeListeners.splice(index, 1);
  }

  public callPrintPreviewChange(enabled: boolean): void {
    if (!this._printPreviewChangeListeners.length) return;
    this._printPreviewChangeListeners.forEach((listener: PrintPreviewChange) => {
      try {
        listener.onPrintPreviewChange(enabled);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public addPrintFormatChangeListener(listener: PrintFormatChange): void {
    this._printFormatChangeListeners.push(listener);
  }

  public removePrintFormatChangeListener(listener: PrintFormatChange): void {
    const index: number = this._printFormatChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._printFormatChangeListeners.splice(index, 1);
  }

  public callPrintFormatChange(format: PrintFormat): void {
    if (!this._printFormatChangeListeners.length) return;
    this._printFormatChangeListeners.forEach((listener: PrintFormatChange) => {
      try {
        listener.onPrintFormatChange(format);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public addPrintTitleChangeListener(listener: PrintTitleChange): void {
    this._printTitleChangeListeners.push(listener);
  }

  public removePrintTitleChangeListener(listener: PrintTitleChange): void {
    const index: number = this._printTitleChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._printTitleChangeListeners.splice(index, 1);
  }

  public callPrintTitleChange(enabled: boolean): void {
    if (!this._printTitleChangeListeners.length) return;
    this._printTitleChangeListeners.forEach((listener: PrintTitleChange) => {
      try {
        listener.onPrintTitleChange(enabled);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public addPrintScaleChangeListener(listener: PrintScaleChange): void {
    this._printScaleChangeListeners.push(listener);
  }

  public removePrintScaleChangeListener(listener: PrintScaleChange): void {
    const index: number = this._printScaleChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._printScaleChangeListeners.splice(index, 1);
  }

  public callPrintScaleChange(enabled: boolean): void {
    if (!this._printScaleChangeListeners.length) return;
    this._printScaleChangeListeners.forEach((listener: PrintScaleChange) => {
      try {
        listener.onPrintScaleChange(enabled);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public addPrintLegendChangeListener(listener: PrintLegendChange): void {
    this._printLegendChangeListeners.push(listener);
  }

  public removePrintLegendChangeListener(listener: PrintLegendChange): void {
    const index: number = this._printLegendChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._printLegendChangeListeners.splice(index, 1);
  }

  public callPrintLegendChange(enabled: boolean): void {
    if (!this._printLegendChangeListeners.length) return;
    this._printLegendChangeListeners.forEach((listener: PrintLegendChange) => {
      try {
        listener.onPrintLegendChange(enabled);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public addPrintTasksChangeListener(listener: PrintTasksChange): void {
    this._printTasksChangeListeners.push(listener);
  }

  public removePrintTasksChangeListener(listener: PrintTasksChange): void {
    const index: number = this._printTasksChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._printTasksChangeListeners.splice(index, 1);
  }

  public callPrintTasksChange(enabled: boolean): void {
    if (!this._printTasksChangeListeners.length) return;
    this._printTasksChangeListeners.forEach((listener: PrintTasksChange) => {
      try {
        listener.onPrintTasksChange(enabled);
      } catch (exception) {
        console.log(exception);
      }
    });
  }
}
