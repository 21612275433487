<div id="drag-target" #dragTarget>
  <div class="drag layers" #container>
    <canvas class="drag" #canvas></canvas>
  </div>
  <app-interactive *ngIf="showInteractive" [isDrag]="isDrag" [projectId]="projectId" [versionId]="versionId"
                   [isPreview]="isPreview" (isCustomDragChange)="changeCustomDrag($event)">
  </app-interactive>
  <button *ngIf="isEditMode" mat-button class="submit-button exit-edit-mode-btn" (click)="exitEditMode()">
    {{'project.interactive.editMode.exit' | translate}}
  </button>
</div>
