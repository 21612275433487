import {Endpoints} from '../../globals/api/endpoints';

export class ProjectEndpoints extends Endpoints {

  private static readonly PROJECTS = ProjectEndpoints.VIEWER + '/projects';
  public static readonly PROJECTS_CATEGORY = ProjectEndpoints.PROJECTS + '/category/' + ProjectEndpoints.CATEGORY_ID;
  public static readonly PROJECTS_DIRECT = ProjectEndpoints.PROJECTS_CATEGORY + '/direct';
  public static readonly PROJECTS_SEARCH = ProjectEndpoints.PROJECTS + '/search';

  public static readonly PROJECT = ProjectEndpoints.VIEWER + '/project';
  public static readonly PROJECT_TITLE = ProjectEndpoints.PROJECT + '/' + ProjectEndpoints.PROJECT_ID;
  public static readonly PROJECT_CATEGORIES = ProjectEndpoints.PROJECT_TITLE + '/categories';
  public static readonly PROJECT_TITLE_PREVIEW = ProjectEndpoints.PROJECT + '/{versionId}/preview';
}
