import {JsonProperty} from '@dhkatz/json-ts';
import {LinkType} from '../../../../api/data/points/enums/link-type.enum';
import {AbstractCustomPointRequest} from './abstracts/abstract-custom-point-request';
import {EnumConverter} from '../../../../../../../../globals/api/json/enum-converter';

export class EmbedPointRequest extends AbstractCustomPointRequest {

  @JsonProperty({name: 'linkType', converter: EnumConverter.converter(LinkType)})
  private _linkType: LinkType;

  @JsonProperty('link')
  private _link: string;

  public constructor() {
    super();
    this._linkType = undefined;
    this._link = undefined;
  }

  public get linkType(): LinkType {
    return this._linkType;
  }

  public set linkType(value: LinkType) {
    this._linkType = value;
  }

  public get link(): string {
    return this._link;
  }

  public set link(value: string) {
    this._link = value;
  }
}
