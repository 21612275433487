import {ICustomConverter} from '@dhkatz/json-ts';

export class AnyConverter implements ICustomConverter<any> {

  public static readonly converter = new AnyConverter();

  public fromJson(data: any): any {
    return data;
  }

  public toJson(data: any): any {
    return data;
  }
}
