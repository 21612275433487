import {RectSize} from '../../../../../../globals/elements/rect-size';
import {DrawElement} from '../../api/data/vector-file-response/abstracts/draw-element';
import {VectorFillElement} from '../../api/data/vector-file-response/abstracts/vector-fill-element';
import {GradientType} from '../../api/data/vector-file-response/enums/gradient-type.enum';
import {VectorCommandType} from '../../api/data/vector-file-response/enums/vector-command-type.enum';
import {VectorBorderElement} from '../../api/data/vector-file-response/interfaces/vector-border-element';
import {VectorStrokeElement} from '../../api/data/vector-file-response/interfaces/vector-stroke-element';
import {Circle} from '../../api/data/vector-file-response/models/circle';
import {Ellipse} from '../../api/data/vector-file-response/models/ellipse';
import {Gradient} from '../../api/data/vector-file-response/models/gradient';
import {Line} from '../../api/data/vector-file-response/models/line';
import {Path} from '../../api/data/vector-file-response/models/path';
import {Rect} from '../../api/data/vector-file-response/models/rect';
import {Text} from '../../api/data/vector-file-response/models/text';

export class VectorDrawer {

  public constructor(private readonly _ctx: CanvasRenderingContext2D) {
  }

  public draw(data: DrawElement, scaleRect: RectSize): void {
    this[data.type](data, scaleRect);
  }

// DRAW <-----------------------------------------------------------------------------------------------------------------------------> DRAW

  private circle(drawData: DrawElement, scaleRect: RectSize): void {
    const data = drawData as Circle;
    if (!this.isCorrectData(data.draw, 3)) return;
    this._ctx.save();

    this.setOpacityProperty(data);
    this.transform(scaleRect);
    this._ctx.beginPath();

    const drawStroke = this.setBorderProperties(data);
    const drawFill = this.setFillProperty(data);

    this._ctx.arc(data.draw[0], data.draw[1], data.draw[2], 0, 2 * Math.PI);

    if (drawFill) this._ctx.fill();
    if (drawStroke) this._ctx.stroke();
    this._ctx.restore();
  }

  private ellipse(drawData: DrawElement, scaleRect: RectSize): void {
    const data = drawData as Ellipse;
    if (!this.isCorrectData(data.draw, 4)) return;
    this._ctx.save();

    this.setOpacityProperty(data);
    this.transform(scaleRect);
    this._ctx.beginPath();
    this._ctx.translate(data.draw[0] - data.draw[2], data.draw[1] - data.draw[3]);
    this._ctx.scale(data.draw[2], data.draw[3]);
    this._ctx.arc(1, 1, 1, 0, 2 * Math.PI, false);
    this._ctx.restore();

    this._ctx.save();
    this.transform(scaleRect);
    const drawStroke = this.setBorderProperties(data);
    const drawFill = this.setFillProperty(data);
    if (drawFill) this._ctx.fill();
    if (drawStroke) this._ctx.stroke();
    this._ctx.restore();
  }

  private line(drawData: DrawElement, scaleRect: RectSize): void {
    const data = drawData as Line;
    if (!this.isCorrectData(data.draw, 4)) return;
    this._ctx.save();

    this.setOpacityProperty(data);
    this.transform(scaleRect);
    this._ctx.beginPath();
    this.setBorderProperties(data);
    this._ctx.moveTo(data.draw[0], data.draw[1]);
    this._ctx.lineTo(data.draw[2], data.draw[3]);
    this._ctx.stroke();
    this._ctx.restore();
  }

  private path(drawData: DrawElement, scaleRect: RectSize): void {
    const data = drawData as Path;
    this._ctx.save();

    this.setOpacityProperty(data);
    this.transform(scaleRect);
    this._ctx.beginPath();

    const drawStroke = this.setBorderProperties(data);
    const drawFill = this.setFillProperty(data);
    this.setStrokeProperty(data);

    for (const element of data.draw) {
      switch (element.command) {
        case VectorCommandType.M:
          if (this.isCorrectData(element.data, 2)) this._ctx.moveTo(element.data[0], element.data[1]);
          break;
        case VectorCommandType.L:
          if (this.isCorrectData(element.data, 2)) this._ctx.lineTo(element.data[0], element.data[1]);
          break;
        case VectorCommandType.C:
          if (this.isCorrectData(element.data, 6))
            this._ctx.bezierCurveTo(element.data[0], element.data[1], element.data[2], element.data[3], element.data[4], element.data[5]);
          break;
        case VectorCommandType.Q:
          if (this.isCorrectData(element.data, 4))
            this._ctx.quadraticCurveTo(element.data[0], element.data[1], element.data[2], element.data[3]);
          break;
        case VectorCommandType.Z:
          this._ctx.closePath();
          break;
      }
    }
    if (drawFill) this._ctx.fill();
    if (drawStroke) this._ctx.stroke();
    this._ctx.restore();
  }

  private rect(drawData: DrawElement, scaleRect: RectSize): void {
    const data = drawData as Rect;
    if (!this.isCorrectData(data.draw, 4)) return;
    this._ctx.save();

    this.setOpacityProperty(data);
    this.transform(scaleRect);

    const drawStroke = this.setBorderProperties(data);
    const drawFill = this.setFillProperty(data);

    if (drawFill) this._ctx.fillRect(data.draw[0], data.draw[1], data.draw[2], data.draw[3]);
    if (drawStroke) this._ctx.strokeRect(data.draw[0], data.draw[1], data.draw[2], data.draw[3]);

    this._ctx.restore();
  }

  private text(drawData: DrawElement, scaleRect: RectSize): void {
    const data = drawData as Text;
    this._ctx.save();

    this.setOpacityProperty(data);
    this.transform(scaleRect);

    this._ctx.transform(data.transform[0], data.transform[1], data.transform[2], data.transform[3], data.transform[4], data.transform[5]);

    const drawStroke = this.setBorderProperties(data);
    const drawFill = this.setFillProperty(data);

    this._ctx.font = data.size + 'px "' + data.font + '"';

    if (data.text.length > 1 && data.letterSpacing > 0) {
      this.drawWithLineSpacing(data, drawFill, drawStroke);
    } else {
      if (drawFill) this._ctx.fillText(data.text, 0, 0);
      if (drawStroke) this._ctx.strokeText(data.text, 0, 0);
    }
    this._ctx.restore();
  }

// HELPERS <-----------------------------------------------------------------------------------------------------------------------> HELPERS

  private drawWithLineSpacing(data: Text, drawFill: boolean, drawStroke: boolean): void {
    const letters = data.text.split('');
    const spacing = data.letterSpacing;
    let posX = 0;
    for (const letter of letters) {
      if (drawFill) this._ctx.fillText(letter, posX, 0);
      if (drawStroke) this._ctx.strokeText(letter, posX, 0);
      posX += spacing + this._ctx.measureText(letter).width;
    }
  }

  private transform(scaleRect: RectSize): void {
    this._ctx.transform(scaleRect.width, 0, 0, scaleRect.height, scaleRect.x, scaleRect.y);
  }

  private isCorrectData(data: number[], count: number): boolean {
    return data !== undefined && data.length === count;
  }

  private setFillProperty(element: VectorFillElement): boolean {
    if (element.fillStyle === 'none') return false;
    if (element.fillGradient) this._ctx.fillStyle = this.setGradient(element.fillGradient);
    else if (element.fillStyle) this._ctx.fillStyle = element.fillStyle;
    return true;
  }

  private setBorderProperties(element: VectorBorderElement): boolean {
    let drawStroke = false;
    if (element.strokeGradient) {
      this._ctx.strokeStyle = this.setGradient(element.strokeGradient);
      drawStroke = true;
    } else if (element.strokeStyle) {
      this._ctx.strokeStyle = element.strokeStyle;
      drawStroke = true;
    }
    if (element.lineWidth > 0) {
      this._ctx.lineWidth = element.lineWidth;
      drawStroke = true;
    }
    if (this.isCorrectData(element.dashArray, 2)) {
      this._ctx.setLineDash(element.dashArray);
      drawStroke = true;
    }
    return drawStroke;
  }

  private setStrokeProperty(element: VectorStrokeElement): void {
    if (element.miterLimit) this._ctx.miterLimit = element.miterLimit;
  }

  private setOpacityProperty(element: DrawElement): void {
    if (element.opacity) this._ctx.globalAlpha = element.opacity;
  }

  private setGradient(gradient: Gradient): CanvasGradient {
    let canvasGradient: CanvasGradient;
    switch (gradient.type) {
      case GradientType.linear:
        canvasGradient = this._ctx.createLinearGradient(gradient.draw[0], gradient.draw[1], gradient.draw[2], gradient.draw[3]);
        break;
      case GradientType.radial:
        canvasGradient = this._ctx.createRadialGradient(gradient.draw[0], gradient.draw[1], gradient.draw[2], gradient.draw[3],
          gradient.draw[4], gradient.draw[5]);
        break;
    }
    if (!gradient.colors) return canvasGradient;
    gradient.colors.forEach((color: string, offset: number) => {
      canvasGradient.addColorStop(offset, color);
    });
    return canvasGradient;
  }
}
