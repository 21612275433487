import {JsonProperty} from '@dhkatz/json-ts';
import {AddressData} from '../../../account/api/data/address-data';
import {ArrayStringConverter} from '../../../globals/api/json/array-string-converter';
import {Role} from './enums/role.enum';
import {EnumConverter} from '../../../globals/api/json/enum-converter';

export class RegisterRequest {

  @JsonProperty('email')
  private _email: string;

  @JsonProperty('password')
  private _password: string;

  @JsonProperty({name: 'addressData', type: AddressData})
  private _addressData: AddressData;

  @JsonProperty({name: 'role', converter: EnumConverter.converter(Role)})
  private _role: Role;

  @JsonProperty('fromInvitation')
  private _fromInvitation: boolean;

  @JsonProperty('coupon')
  private _coupon: string;

  @JsonProperty('token')
  private _token: string;

  @JsonProperty({name: 'institutions', converter: ArrayStringConverter.converter, type: Object})
  private _institutions: string[];

  @JsonProperty({name: 'approvals', converter: ArrayStringConverter.converter, type: Object})
  private _approvals: string[];

  @JsonProperty('institutionCode')
  private _institutionCode: string;

  public constructor() {
    this._email = undefined;
    this._password = undefined;
    this._addressData = undefined;
    this._coupon = undefined;
    this._role = undefined;
    this._fromInvitation = undefined;
    this._token = undefined;
    this._institutions = undefined;
    this._approvals = undefined;
    this._institutionCode = undefined;
  }

  public get email(): string {
    return this._email;
  }

  public set email(value: string) {
    this._email = value;
  }

  public get password(): string {
    return this._password;
  }

  public set password(value: string) {
    this._password = value;
  }

  public get addressData(): AddressData {
    return this._addressData;
  }

  public set addressData(value: AddressData) {
    this._addressData = value;
  }

  public get coupon(): string {
    return this._coupon;
  }

  public set coupon(value: string) {
    this._coupon = value;
  }

  public get fromInvitation(): boolean {
    return this._fromInvitation;
  }

  public set fromInvitation(value: boolean) {
    this._fromInvitation = value;
  }

  public get role(): Role {
    return this._role;
  }

  public set role(value: Role) {
    this._role = value;
  }

  public get token(): string {
    return this._token;
  }

  public set token(value: string) {
    this._token = value;
  }

  public get institutions(): string[] {
    return this._institutions;
  }

  public set institutions(value: string[]) {
    this._institutions = value;
  }

  public get approvals(): string[] {
    return this._approvals;
  }

  public set approvals(value: string[]) {
    this._approvals = value;
  }

  public get institutionCode(): string {
    return this._institutionCode;
  }

  public set institutionCode(value: string) {
    this._institutionCode = value;
  }
}
