<app-fonts *ngIf="version?.modules?.includes(moduleType.fonts)" [projectId]="projectId"
           [versionId]="versionId"></app-fonts>
<div class="container noselect">
    <div class="left-bar toggle" (click)="toggle($event)">
        <div *ngIf="!isLoading" class="icon small" (click)="toggle($event)">
            <mat-icon class="small toggle" mat-list-icon (click)="toggle($event)">{{isExpanded ? 'chevron_left' :
                'chevron_right'}}
            </mat-icon>
        </div>
        <div *ngIf="!isLoading" class="left-menu-bar">
            <div class="icon" *ngIf="windows['LayerManagerWindow']"
                 [ngClass]="{'active': checkWindow('LayerManagerWindow')}"
                 (click)="showWindow('LayerManagerWindow')">
                <img width="24" height="24" src="/assets/images/layers.png"
                     srcset="/assets/images/layers.png 1x, /assets/images/layers_2x.png 2x">
            </div>
            <!--<div class="icon">-->
            <!--<img src="/assets/images/list.png" srcset="/assets/images/list.png 1x, /assets/images/list_2x.png 2x">-->
            <!--</div>-->
            <div class="icon" *ngIf="windows['LegendWindow']" [ngClass]="{'active': checkWindow('LegendWindow')}"
                 (click)="showWindow('LegendWindow')">
                <img width="24" height="24" src="/assets/images/legend.png"
                     srcset="/assets/images/legend.png 1x, /assets/images/legend_2x.png 2x">
            </div>
            <!--<div class="icon">-->
            <!--<img src="/assets/images/info.png" srcset="/assets/images/info.png 1x, /assets/images/info_2x.png 2x">-->
            <!--</div>-->
            <div class="icon" *ngIf="windows['MiniMapWindow']" [ngClass]="{'active': checkWindow('MiniMapWindow')}"
                 (click)="showWindow('MiniMapWindow')">
                <img width="24" height="24" src="/assets/images/minimap.png"
                     srcset="/assets/images/minimap.png 1x, /assets/images/minimap_2x.png 2x">
            </div>

          <div class="icon" *ngIf="windows['TasksWindow']" [ngClass]="{'active': checkWindow('TasksWindow')}"
               (click)="showWindow('TasksWindow')">
            <img width="24" height="24" src="/assets/images/exercises.png"
                 srcset="/assets/images/exercises.png 1x, /assets/images/exercises_2x.png 2x">
          </div>

          <div class="icon" *ngIf="windows['BibliographyWindow']"
               [ngClass]="{'active': checkWindow('BibliographyWindow')}"
               (click)="showWindow('BibliographyWindow')">
            <img width="24" height="24" src="/assets/images/bibliography.png"
                 srcset="/assets/images/bibliography.png 1x, /assets/images/bibliography_2x.png 2x">
          </div>
          <!--<div class="icon">-->
          <!--<img src="/assets/images/notebook.png" srcset="/assets/images/notebook.png 1x, /assets/images/notebook_2x.png 2x">-->
          <!--</div>-->
          <!--<div class="icon">-->
          <!--<img src="/assets/images/glossary.png" srcset="/assets/images/glossary.png 1x, /assets/images/glossary_2x.png 2x">-->
          <!--</div>-->
        </div>
      <app-zoom [hidden]="isLoading"></app-zoom>
    </div>
  <app-scale [hidden]="isLoading" *ngIf="version?.modules?.includes(moduleType.scale)" [projectId]="projectId"
             [versionId]="versionId"></app-scale>
  <div *ngIf="isModerator && !isLoading" id="current-level">
    {{'project.level' | translate}}: {{actualLevel}}
  </div>
  <div class="right-bar">
    <app-panel-sidebar *ngIf="!isLoading" (panelActiveChange)="onPanelActive($event)"></app-panel-sidebar>
  </div>
  <app-copyrights [hidden]="isLoading" [projectId]="projectId" [versionId]="versionId">
  </app-copyrights>
  <mat-drawer-container *ngIf="!isLoading" class="sidenav" [hasBackdrop]="false">
    <mat-drawer class="sidenav-content" #slidemenu [mode]="'over'">
      <div class="nav-element toggle" (click)="toggle($event)">{{'project.collapse' | translate}}</div>
      <div class="labels">
        <div class="nav-element" *ngIf="windows['LayerManagerWindow']"
             [ngClass]="{'active': checkWindow('LayerManagerWindow')}"
             (click)="showWindow('LayerManagerWindow')" class="nav-element">
          {{'project.layers.title' | translate}}
                </div>
                <!--<div class="nav-element">Lista</div>-->
                <div class="nav-element" *ngIf="windows['LegendWindow']"
                     [ngClass]="{'active': checkWindow('LegendWindow')}"
                     (click)="showWindow('LegendWindow')">
                  {{'project.legend.title' | translate}}
                </div>
                <!--<div class="nav-element">Informacje</div>-->
                <div class="nav-element" *ngIf="windows['MiniMapWindow']"
                     [ngClass]="{'active': checkWindow('MiniMapWindow')}"
                     (click)="showWindow('MiniMapWindow')">
                  {{'project.miniMap.title' | translate}}
                </div>
        <div class="nav-element" *ngIf="windows['TasksWindow']"
             [ngClass]="{'active': checkWindow('TasksWindow')}"
             (click)="showWindow('TasksWindow')">
          {{'project.tasks.title' | translate}}
        </div>
        <div class="nav-element" *ngIf="windows['BibliographyWindow']"
             [ngClass]="{'active': checkWindow('BibliographyWindow')}"
             (click)="showWindow('BibliographyWindow')">
          {{'project.bibliography.title' | translate}}
        </div>
        <!--<div  class="nav-element">Notes</div>-->
        <!--<div class="nav-element">Słowniczek pojęć</div>-->
      </div>
    </mat-drawer>
  </mat-drawer-container>
  <div class="version-elements" [ngStyle]="{opacity: opacity}">
    <div class="overlay" [ngClass]="{'show': inactive}"></div>
    <app-layers *ngIf="version?.modules?.includes(moduleType.layer)" [projectId]="projectId" [versionId]="versionId"
                [version]="version?.version" [showInteractive]="version?.modules?.includes(moduleType.interactive)"
                [fontsReady]="!version?.modules?.includes(moduleType.fonts)" [isPreview]="isPreview"></app-layers>
    <app-mini-map *ngIf="version?.modules?.includes(moduleType.miniMap)" [projectId]="projectId"
                  [versionId]="versionId" [windows]="windows"></app-mini-map>
    <app-legend *ngIf="version?.modules?.includes(moduleType.legend)" [projectId]="projectId"
                [versionId]="versionId" [windows]="windows"></app-legend>
    <app-layer-manager *ngIf="version?.modules?.includes(moduleType.layerManager)" [projectId]="projectId"
                       [versionId]="versionId" [windows]="windows"></app-layer-manager>
    <app-tasks *ngIf="version?.modules?.includes(moduleType.tasks)" [projectId]="projectId"
               [versionId]="versionId" [windows]="windows"></app-tasks>
    <app-bibliography *ngIf="version?.modules?.includes(moduleType.bibliography)" [projectId]="projectId"
                      [versionId]="versionId" [windows]="windows"></app-bibliography>
  </div>
  <app-panels *ngIf="version?.modules?.includes(moduleType.panels)" [projectId]="projectId"
              [versionId]="versionId"></app-panels>
  <app-loading-progress *ngIf="isLoading"></app-loading-progress>
</div>
<app-print-tool [versionModules]="version?.modules"></app-print-tool>
