import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {RoutingConfig} from '../../../../configs/routing-config';
import {ViewerConfig} from '../../../../configs/viewer-config';
import {PrintService} from '../../../../print/communication/print.service';
import {SubscriptionDemoComponent} from '../../../../subscriptions/screens/dialogs/subscription-demo/subscription-demo.component';
import {SubscriptionAccessService} from '../../../../subscriptions/services/subscription-access.service';
import {FileSystemUtil} from '../../../../utils/file-system-util';
import {FullScreenUtil} from '../../../../utils/full-screen-util';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent {

  @Input()
  public projectId: string;

  @Input()
  public showPrint: boolean;

  @Input()
  public showReload: boolean;

  @Output()
  public reload: EventEmitter<void> = new EventEmitter<void>();

  public fullScreenUtil = FullScreenUtil;
  public fileSystemUtil = FileSystemUtil;

  public constructor(private readonly _dialog: MatDialog, private readonly _subscriptionAccess: SubscriptionAccessService,
                     private readonly _print: PrintService, private readonly _router: Router,
                     private readonly _translate: TranslateService) {
  }

  public print(): void {
    if (this._subscriptionAccess.isDemo(this.projectId))
      this._dialog.open(SubscriptionDemoComponent, ViewerConfig.dialogSubscriptionOptions);
    else this._print.callPrintPreviewChange(true);
  }

  public openHelp(): void {
    window.open(ViewerConfig.helpUrl, '_blank');
  }

  public goToOfflineManagement(): void {
    this._router.navigate([RoutingConfig.ACCOUNT_OFFLINE_PROJECTS]);
  }

  public changeLanguage(language: string): void {
    this._translate.use(language);
    localStorage.setItem('language', language);
  }
}
