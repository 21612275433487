import {FormControl} from '@angular/forms';
import {JsonProperty} from '@dhkatz/json-ts';

export class ApprovalResponse {

  @JsonProperty('type')
  private _type: string;

  @JsonProperty('required')
  private _required: boolean;

  @JsonProperty('content')
  private _content: string;

  @JsonProperty('accept')
  private _accept: string;

  private _field: FormControl;

  public constructor() {
    this._type = undefined;
    this._required = undefined;
    this._content = undefined;
    this._accept = undefined;
    this._field = undefined;
  }

  public get type(): string {
    return this._type;
  }

  public set type(value: string) {
    this._type = value;
  }

  public get required(): boolean {
    return this._required;
  }

  public set required(value: boolean) {
    this._required = value;
  }

  public get content(): string {
    return this._content;
  }

  public set content(value: string) {
    this._content = value;
  }

  public get accept(): string {
    return this._accept;
  }

  public set accept(value: string) {
    this._accept = value;
  }

  public get field(): FormControl {
    return this._field;
  }

  public set field(value: FormControl) {
    this._field = value;
  }
}
