import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ViewerConfig} from '../../../../../../configs/viewer-config';
import {OfflineService} from '../../../../../../offline/services/offline-service/offline.service';
import {PrintService} from '../../../../../../print/communication/print.service';
import {ServiceWorkerService} from '../../../../../../web-workers/service/service-worker.service';
import {DraggableWindow} from '../../../../screens/version/components/interfaces/draggable-window';
import {
  LoadingProgressService
} from '../../../../screens/version/components/loading-progress/service/loading-progress.service';
import {
  AbstractWindowComponent,
  contentAnimations,
  windowAnimations
} from '../../../abstracts/screens/abstract-window.component';
import {
  CustomInteractivesCommunicationService
} from '../../../layer-manager/modules/custom-interactives-manager/communiaction/custom-interactives-communication.service';
import {ViewChange} from '../../../layer/communication/interfaces/view-change';
import {LayersCommunicationService} from '../../../layer/communication/layers-communication.service';
import {CustomTaskResponse} from '../../api/data/custom-task-response';
import {TaskType} from '../../api/data/enums/task-type.enum';
import {TaskResponse} from '../../api/data/task-response';
import {TasksOfflineService} from '../../api/service/tasks-offline.service';
import {TasksService} from '../../api/service/tasks.service';
import {TasksSettingsComponent} from './dialogs/tasks-settings/tasks-settings.component';

@Component({
  selector: 'app-tasks',
  animations: [windowAnimations, contentAnimations],
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent extends AbstractWindowComponent implements OnInit, ViewChange, DraggableWindow {

  @Input()
  public versionId: string;

  @Input()
  public projectId: string;

  @Input()
  public windows: Map<string, DraggableWindow>;

  public isOffline = false;

  public customTasks: CustomTaskResponse[];

  private _settingsEnabled = true;
  private _tasks: TaskResponse[];

  public constructor(private readonly _tasksService: TasksService, private readonly  _tasksOfflineService: TasksOfflineService,
                     private readonly _cdr: ChangeDetectorRef, private readonly _dialog: MatDialog,
                     private readonly _offline: OfflineService, layersCommunication: LayersCommunicationService,
                     loading: LoadingProgressService, print: PrintService, workerService: ServiceWorkerService,
                     customInteractivesCommunicationService: CustomInteractivesCommunicationService) {
    super(layersCommunication, customInteractivesCommunicationService, loading, print, workerService);
    this.showWindow = false;
  }

  public ngOnInit(): void {
    this.windows['TasksWindow'] = this;
    this.afterViewInit();
    this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
      this.isOffline = result;
      const requestMethod = this.isOffline
        ? this._tasksOfflineService.getTasks(this.projectId)
        : this.getOnlineMethods();
      requestMethod.subscribe((response: TaskResponse[]) => {
        this._tasks = response;
        this.joinTasks();
      }, () => {
        this._tasks = [];
        this.joinTasks();
      });
      if (this.projectId === undefined) {
        this.customTasks = [];
        return;
      }
      this.loadCustomTasks();
    });
  }

  private getOnlineMethods(): Observable<TaskResponse[]> {
    return this.projectId === undefined
      ? this._tasksService.getTasksPreview(this.versionId)
      : this._tasksService.getTasks(this.projectId);
  }

  private loadCustomTasksFromOffline(): void {
    this._tasksOfflineService.getCustomTasks(this.projectId).subscribe((response: CustomTaskResponse[]) => {
      this.customTasks = response;
      this.joinTasks();
    }, () => {
      this.customTasks = [];
      this.joinTasks();
    });
  }

  private loadCustomTasks(): void {
    this._tasksService.getCustomTasks(this.projectId).subscribe((response: CustomTaskResponse[]) => {
      if (!response && this.isOffline) {
        this.loadCustomTasksFromOffline();
        return;
      }
      this.customTasks = response;
      this.joinTasks();
    }, () => {
      if (this.isOffline) {
        this.loadCustomTasksFromOffline();
        return;
      }
      this.customTasks = [];
      this.joinTasks();
    });
  }

  public get settingsEnabled(): boolean {
    return !!this.projectId && this._settingsEnabled && self.navigator.onLine && this.isUserOrHigher;
  }

  public get showEmptyInfo(): boolean {
    return !this.customTasks || !this.customTasks.length || !this.customTasks.find((task) => task.enabled);
  }

  private joinTasks(): void {
    if (this._tasks === undefined || this.customTasks === undefined) return;
    this.loading.configLoaded();
    if (this.customTasks.length || !this._tasks.length) return;
    this._settingsEnabled = false;
    this._tasks.forEach((task: TaskResponse) => {
      const customTask = new CustomTaskResponse();
      customTask.enabled = task.enabled;
      customTask.type = TaskType.SYSTEM;
      customTask.title = task.title;
      customTask.description = task.description;
      this.customTasks.push(customTask);
    });
  }

  public afterViewInit(): void {
    this.updateSize();
    this.windowParams.x = this.viewWidth - this.windowParams.width + ViewerConfig.LEFT_BAR_SIZE - 10;
    this.windowParams.y = ViewerConfig.LEFT_BAR_SIZE + 10;
    super.afterViewInit();
    this._cdr.detectChanges();
  }

  public optionsClick(): void {
    this._dialog.open(TasksSettingsComponent, ViewerConfig.getTasksDialogSettings(
      {tasks: this.customTasks, projectId: this.projectId, isOffline: this.isOffline})).afterClosed()
      .subscribe((tasks: CustomTaskResponse[]) => {
        this.customTasks = tasks;
        setTimeout(() => {
          this.updateSize();
          this.correctPosition();
        }, 0);
      });
  }

  public customInteractiveEditModeDisabled(): void {
  }
}
