<div [ngClass]="{'carriage-element': true, 'drag': true, 'gray': !isAvailable}">
  <div class="image drag">
    <app-image-preload class="drag" [normal]="project.image" [retina]="project.retinaImage" [endpoint]="'RESOURCES'"
                       [none]="'empty_proj'"></app-image-preload>
  </div>
  <div class="name drag">
    <span class="drag">
      {{project.title}}
    </span>
  </div>
  <div (click)="setSelected()" [ngClass]="{'arrow_box': true, 'drag': true, 'selected': isThis()}">
  </div>
</div>
