import {JsonProperty} from '@dhkatz/json-ts';
import {VectorLevelResponse} from '../levels/vector-level-response';
import {AbstractSingleLayerResponse} from './abstracts/abstract-single-layer-response';

export class VectorLayerResponse extends AbstractSingleLayerResponse {

  @JsonProperty('pl')
  private _preLoading: boolean;

  @JsonProperty({name: 'd', type: VectorLevelResponse})
  private _details: VectorLevelResponse[];

  public constructor() {
    super();
    this._preLoading = undefined;
    this._details = undefined;
  }

  public get preLoading(): boolean {
    return this._preLoading;
  }

  public get details(): VectorLevelResponse[] {
    return this._details;
  }

  public set details(value: VectorLevelResponse[]) {
    this._details = value;
  }
}
