import {Size} from '../../../../../../globals/api/data/size';
import {BrowserUtil} from '../../../../../../utils/browser-util';
import {AbstractPreRenderData} from '../data/abstracts/abstract-pre-render-data';
import {PreRenderedDrawPool} from '../drawers/interfaces/pre-rendered-draw-pool';
import {PreRenderElement} from '../elements/pre-render-element';
import {LoadingPool} from '../loader/loading-pool';
import {PreRenderPart} from '../parts/pre-render-part';
import {PartsUtil} from '../utils/parts-util';
import {AbstractLevel} from './abstracts/abstract-level';

export class PreRenderLevel extends AbstractLevel<PreRenderPart> implements PreRenderLevel {

  private readonly _loader: LoadingPool;

  public constructor(partsData: Map<string, AbstractPreRenderData[]>, sizes: Size[], nextSizes: Size[], drawPool: PreRenderedDrawPool,
                     offline: boolean) {
    super(sizes, nextSizes);
    this._loader = new LoadingPool(() => drawPool.elementLoadComplete(), 10,
      BrowserUtil.asyncLoad ? 10 : 1);
    this.defaultSize = sizes[0];
    this.setElements(partsData, drawPool, offline);
  }

  private setElements(partsData: Map<string, AbstractPreRenderData[]>, drawPool: PreRenderedDrawPool, offline: boolean): void {
    PartsUtil.setPartsFromMapWithSize(partsData, this.defaultSize, drawPool.partSize,
      (col: number, row: number, data: AbstractPreRenderData[], width: number, height: number) =>
        this.parts.push(new PreRenderPart(new PreRenderElement(col, row, drawPool.partSize, width, height),
          data, drawPool, this, this._loader, offline)));
  }

  public setDisabledLayers(layers: boolean[]): void {
    this.parts.forEach((part: PreRenderPart) => part.setDisabledLayers(layers));
  }

  protected stopLoading(): void {
    this._loader.stopLoading();
    this.parts.forEach((part: PreRenderPart) => part.stopLoading());
  }

  public clear(): void {
    this._loader.clear();
    super.clear();
  }

  public destroy(): void {
    this._loader.destroy();
    super.destroy();
  }
}
