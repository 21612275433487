import {Size} from '../../../../../../../globals/api/data/size';
import {RectSize} from '../../../../../../../globals/elements/rect-size';
import {AbstractPreRenderData} from '../../data/abstracts/abstract-pre-render-data';
import {PreRenderType} from '../../data/enums/pre-render-type.enum';
import {AbstractPreRenderDrawPool} from '../../drawers/interfaces/abstracts/abstract-pre-render-draw-pool';
import {PreRenderDrawPool} from '../../drawers/interfaces/pre-render-draw-pool';
import {PreRenderElement} from '../../elements/pre-render-element';
import {CanDraw} from '../../interfaces/can-draw';
import {LoadingPool} from '../../loader/loading-pool';
import {CachedPreRenderPart} from '../cached-pre-render-part';
import {PreRender} from '../interfaces/pre-render';
import {RasterPreRenderPart} from '../raster-pre-render-part';
import {ScaledRasterPreRenderPart} from '../scaled-raster-pre-render-part';
import {AbstractPart} from './abstract-part';

export abstract class AbstractPreRenderPart<T extends AbstractPreRenderDrawPool> extends AbstractPart<PreRenderElement>
  implements PreRenderDrawPool {

  public ctx: CanvasRenderingContext2D;
  public canvas: HTMLCanvasElement;

  protected parts: PreRender[] = [];
  protected preRenderComplete: boolean;
  protected preRendering: boolean;

  protected constructor(data: PreRenderElement, protected readonly _drawPool: T, canDraw: CanDraw) {
    super(data, _drawPool.canvas, canDraw);
  }

  protected setCanvas(): void {
    this.canvas = this.getCanvas();
    if (!this.canvas) return;
    this.canvas.width = this.data.width;
    this.canvas.height = this.data.height;
    this.ctx = this.canvas.getContext('2d');
    if (!this.ctx) return;
    this.ctx.imageSmoothingEnabled = true;
    this.ctx.imageSmoothingQuality = 'high';
  }

  protected abstract getCanvas(): HTMLCanvasElement;

  protected setParts(preRenderData: AbstractPreRenderData[], loader: LoadingPool, offline: boolean): void {
    if (!preRenderData) return;
    preRenderData.forEach((data: AbstractPreRenderData) => {
      switch (data.type) {
        case PreRenderType.raster:
        case PreRenderType.combined:
          this.parts.push(new RasterPreRenderPart(data as any, this, loader, offline));
          return;
        case PreRenderType.scaledRaster:
        case PreRenderType.scaledCombined:
          this.parts.push(new ScaledRasterPreRenderPart(data as any, this, loader, offline));
          return;
        case PreRenderType.vector:
          this.parts.push(new CachedPreRenderPart(data as any, this, loader, offline));
          return;
      }
    });
  }

  public abstract draw(scaleRect?: RectSize, viewRect?: RectSize): void;

  public stopLoading(): void {
    this.preRendering = false;
    this.parts.forEach((part: PreRender) => part.stopLoading());
  }

  public clear(): void {
    this.preRendering = false;
    this.parts.forEach((part: PreRender) => part.clear());
  }

  public destroy(): void {
    this.parts.forEach((part: PreRender) => part.destroy());
    this.canvas = undefined;
  }

  public setDisabledLayers(layers: boolean[]): void {
    let preRenderComplete = true;
    this.parts.forEach((part: PreRender) => {
      const result = part.setDisabledLayers(layers);
      if (!this.preRenderComplete || result) preRenderComplete = false;
    });
    this.preRenderComplete = preRenderComplete;
    if (this.preRenderComplete) this.clear();
    else {
      this.preRendering = false;
      this.draw();
    }
  }

  public get partSize(): Size {
    return this._drawPool.partSize;
  }

  public get levelSizes(): Size[] {
    return this._drawPool.levelSizes;
  }

  public addToDraw(raster: ImageBitmap | HTMLImageElement | HTMLCanvasElement): void {
    if (!this.canvas) return;
    this.ctx.drawImage(raster, 0, 0, this.canvas.width, this.canvas.height);
  }

  public elementLoadComplete(): void {
    this._drawPool.elementLoadComplete();
  }

  protected preRenderDraw(): void {
    let preRenderComplete = true;
    this.parts.forEach((part: PreRender) => {
      if (!part.draw()) preRenderComplete = false;
    });
    this.preRenderComplete = preRenderComplete;
    if (this.preRenderComplete) this.clear();
    this.poolAddToDraw(true);
  }

  protected abstract poolAddToDraw(clear?: boolean): void;

  public reDraw(): void {
    if (this.preRenderComplete || !this.canvas) return;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.preRenderDraw();
  }
}
