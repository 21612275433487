import {JsonProperty} from '@dhkatz/json-ts';

export class TitleItem {

  @JsonProperty('id')
  private _id: string;

  @JsonProperty('title')
  private _title: string;

  public constructor() {
    this._id = undefined;
    this._title = undefined;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get id(): string {
    return this._id;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get title(): string {
    return this._title;
  }
}
