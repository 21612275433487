import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {LayerResponse} from '../data/layer-response';
import {LayerEndpoints} from '../layer-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class LayerService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public getLayer(projectId: string): Observable<LayerResponse> {
    return this._http.get<JsonObject>(LayerEndpoints.LAYER.replace(LayerEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonObject) => deserializeFix(LayerResponse, response)));
  }

  public getLayerPreview(versionId: string): Observable<LayerResponse> {
    return this._http.get<JsonObject>(LayerEndpoints.LAYER_PREVIEW.replace(LayerEndpoints.VERSION_ID, versionId))
      .pipe(map((response: JsonObject) => deserializeFix(LayerResponse, response)));
  }
}
