import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractIndexPreRenderData} from './abstracts/abstract-index-pre-render-data';
import {PreRenderData} from './interfaces/pre-render-data';

export class RasterPreRenderData extends AbstractIndexPreRenderData implements PreRenderData {

  @JsonProperty('d')
  private _data: string;

  @JsonProperty('p')
  private _preLoading: boolean;

  public constructor() {
    super();
    this._data = undefined;
    this._preLoading = undefined;
  }

  public get data(): string {
    return this.active ? this._data : undefined;
  }

  public set data(value: string) {
    this._data = value;
  }

  public get preLoading(): boolean {
    return this._preLoading;
  }

  public set preLoading(value: boolean) {
    this._preLoading = value;
  }
}
