import {RectSize} from '../../../../../../globals/elements/rect-size';
import {AbstractPreRenderData} from '../data/abstracts/abstract-pre-render-data';
import {PreRenderedDrawPool} from '../drawers/interfaces/pre-rendered-draw-pool';
import {PreRenderElement} from '../elements/pre-render-element';
import {CanDraw} from '../interfaces/can-draw';
import {LoadingPool} from '../loader/loading-pool';
import {AbstractPreRenderPart} from './abstracts/abstract-pre-render-part';

export class PreRenderPart extends AbstractPreRenderPart<PreRenderedDrawPool> {

  private _viewRect: RectSize;
  private _scaleRect: RectSize;

  public constructor(data: PreRenderElement, preRenderData: AbstractPreRenderData[], drawPool: PreRenderedDrawPool, canDraw: CanDraw,
                     loader: LoadingPool, offline: boolean) {
    super(data, drawPool, canDraw);
    this.setCanvas();
    this.setParts(preRenderData, loader, offline);
  }

  protected getCanvas(): HTMLCanvasElement {
    return document.createElement('canvas');
  }

  public draw(scaleRect?: RectSize, viewRect?: RectSize): void {
    if (viewRect) this._viewRect = viewRect;
    if (scaleRect) this._scaleRect = scaleRect;
    if (!this._viewRect || !this._scaleRect || !this.canDraw(this._scaleRect)) return;
    if (!this.preRenderComplete && !this.preRendering) {
      this.preRendering = true;
      this.preRenderDraw();
    }
    this._drawPool.addToDraw(this._viewRect, this.data, this._scaleRect, this.canvas);
  }

  public destroy(): void {
    if (!this.canvas) return;
    this.canvas.remove();
    super.destroy();
  }

  protected poolAddToDraw(clear?: boolean): void {
    if (!this._viewRect || !this._scaleRect || !this.canDraw(this._scaleRect)) return;
    this._drawPool.addToDraw(this._viewRect, this.data, this._scaleRect, this.canvas, clear);
  }
}
