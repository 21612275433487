<div class="window layer-manager" #window [@fadeAnimation]="windowState">
    <app-window-header [headerTitle]="'project.layers.title' | translate" [(close)]="showWindow" [(show)]="showContent"
                       [drag]="this" [settings]="settingsEnabled" (settingsClick)="optionsClick()"></app-window-header>
    <div class="content" [@slideAnimation]="contentState" (@slideAnimation.done)="updateDivParams()">
        <p class="sub-title">{{'project.layers.subtitle' | translate}}:</p>
        <div class="layers">
            <div class="element" [ngClass]="{'disable': isAllDisabled()}"
                 (click)="changeAllLayersVisibility(isAllDisabled())">
                <div class="element-icon">
                    <mat-icon>{{isAllDisabled() ? 'visibility_off' : 'visibility'}}</mat-icon>
                </div>
                <div class="switch-all-title">
                  {{'project.layers.allLayers' | translate}}
                </div>
            </div>
            <ng-container *ngFor="let item of layerManager; let first = first; let i = index">
                <div class="element" *ngIf="item?.layers" [ngClass]="{'disable': !managerLayers[i]}"
                     (click)="changeLayerVisibility(i)">
                  <div class="element-icon">
                    <mat-icon>{{managerLayers[i] ? 'visibility' : 'visibility_off'}}</mat-icon>
                  </div>
                  <div class="title" [innerHTML]="item?.title | html"></div>
                </div>
            </ng-container>
            <div class="element" id="interactives" *ngIf="containsInteractives"
                 [ngClass]="{'disable': isAllDisabledInteractive()}"
                 (click)="changeAllInteractivesVisibility(isAllDisabledInteractive())">
                <div class="element-icon">
                    <mat-icon>{{isAllDisabledInteractive() ? 'visibility_off' : 'visibility'}}</mat-icon>
                </div>
                <div class="switch-all-title">
                  {{'project.layers.allInteractives' | translate}}
                </div>
            </div>
          <ng-container *ngFor="let item of layerManager; let first = first; let i = index">
            <div class="element" *ngIf="item?.interactives" [ngClass]="{'disable': !managerLayers[i]}"
                 (click)="changeLayerVisibility(i)">
              <div class="element-icon">
                <mat-icon>{{managerLayers[i] ? 'visibility' : 'visibility_off'}}</mat-icon>
              </div>
              <div class="title" [innerHTML]="item?.title | html"></div>
            </div>
          </ng-container>
          <div class="element" id="custom-interactives" *ngIf="containsCustomInteractives"
               [ngClass]="{'disable': isAllDisabledCustomInteractive()}"
               (click)="changeAllCustomInteractivesVisibility(isAllDisabledCustomInteractive())">
            <div class="element-icon">
              <mat-icon>{{isAllDisabledCustomInteractive() ? 'visibility_off' : 'visibility'}}</mat-icon>
            </div>
            <div class="switch-all-title">
              {{'project.layers.allCustomInteractives' | translate}}
            </div>
          </div>
          <ng-container *ngFor="let item of layerManager; let first = first; let i = index">
            <div class="element" *ngIf="item?.customInteractive" [ngClass]="{'disable': !managerLayers[i]}"
                 (click)="changeLayerVisibility(i)">
              <div class="element-icon">
                <mat-icon>{{managerLayers[i] ? 'visibility' : 'visibility_off'}}</mat-icon>
              </div>
              <div class="title" [innerHTML]="item?.title | html"></div>
            </div>
          </ng-container>
        </div>
    </div>
</div>
