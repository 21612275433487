// https://github.com/imulus/retinajs/blob/master/src/retina.js
// `Retina.isRetina`
//
// The minimum device pixel ratio threshold is lowered a bit
// to give the most convenient results.
// E.g. retina images are considered being worth loading 
// when device pixel ratio is at least 1.5
// as opposed to strictly requiring pixel density === 2.
// Not sure if there are any devices with pixel ratio === 1.5 though.
//
function has_at_least_pixel_density(n)
{
	// IE >= 11
	// http://caniuse.com/#search=matchmedia
	if (window.matchMedia && window.matchMedia(media_query(n)).matches)
	{
		return true
	}

	return false
}

export function media_query(n)
{
	let threshold
	let threshold_fraction

	if (n <= 1)
	{
		threshold = 1
		threshold_fraction = 1
	}
	else
	{
		threshold = n - 0.5
		threshold_fraction = `${2 * n - 1}/2`
	}

	return `(-webkit-min-device-pixel-ratio: ${threshold}), (min--moz-device-pixel-ratio: ${threshold}), (-o-min-device-pixel-ratio: ${threshold_fraction}), (min-resolution: ${threshold}dppx)`
}

export function device_pixel_ratio()
{
	if (typeof(window) === 'undefined')
	{
		return 1
	}

	// IE >= 11
	// http://caniuse.com/#feat=devicepixelratio
	// https://developer.mozilla.org/ru/docs/Web/API/Window/devicePixelRatio
	if (window.devicePixelRatio)
	{
		return window.devicePixelRatio
	}

	let n = 1

	while (has_at_least_pixel_density(n + 1))
	{
		n++
	}

	return n
}