import {JsonProperty} from '@dhkatz/json-ts';

export class InstitutionsResponse {

  @JsonProperty('id')
  private _id: string;

  @JsonProperty('title')
  private _title: string;

  @JsonProperty('streetName')
  private _streetName: string;

  @JsonProperty('streetNo')
  private _streetNo: string;

  @JsonProperty('apartmentNo')
  private _apartmentNo: string;

  @JsonProperty('postCode')
  private _postCode: string;

  @JsonProperty('city')
  private _city: string;

  @JsonProperty('province')
  private _province: string;

  @JsonProperty('taxId')
  private _taxId: string;

  public constructor() {
    this._id = undefined;
    this._title = undefined;
    this._streetName = undefined;
    this._streetNo = undefined;
    this._apartmentNo = undefined;
    this._postCode = undefined;
    this._city = undefined;
    this._province = undefined;
    this._taxId = undefined;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get streetName(): string {
    return this._streetName;
  }

  public set streetName(value: string) {
    this._streetName = value;
  }

  public get streetNo(): string {
    return this._streetNo;
  }

  public set streetNo(value: string) {
    this._streetNo = value;
  }

  public get apartmentNo(): string {
    return this._apartmentNo;
  }

  public set apartmentNo(value: string) {
    this._apartmentNo = value;
  }

  public get postCode(): string {
    return this._postCode;
  }

  public set postCode(value: string) {
    this._postCode = value;
  }

  public get city(): string {
    return this._city;
  }

  public set city(value: string) {
    this._city = value;
  }

  public get province(): string {
    return this._province;
  }

  public set province(value: string) {
    this._province = value;
  }

  public get taxId(): string {
    return this._taxId;
  }

  public set taxId(value: string) {
    this._taxId = value;
  }
}
