<mat-dialog-content>
  <mat-accordion [(dragulaModel)]='tasks' [dragula]='"tasks-bag"' [ngClass]="{'anim-hide': !init}">
    <mat-expansion-panel class="task" *ngFor="let task of tasks" #matExpansionPanel>
      <mat-expansion-panel-header (click)="expandPanel(matExpansionPanel, $event)" [collapsedHeight]="'auto'"
                                  [expandedHeight]="'auto'">
        <mat-panel-title>
          <mat-checkbox class="unclickable" [checked]="task.enabled"
                        (change)="enableChanged(task, $event)"></mat-checkbox>
          <div [innerHTML]="task.title"></div>
        </mat-panel-title>
        <mat-panel-description *ngIf="projectId && task?.type !== type.SYSTEM">
          <mat-icon class="unclickable" (click)="update.emit(task)">edit</mat-icon>
          <mat-icon class="unclickable" (click)="remove.emit(task)">delete_outline</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="description" [innerHTML]="task.description"></div>
    </mat-expansion-panel>
  </mat-accordion>
  <div *ngIf="!tasks?.length" class="empty-info">
    <p><span>{{'project.tasks.empty' | translate}}</span></p>
    <p [innerHTML]="'project.tasks.emptyAdd' | translate"></p>
  </div>
</mat-dialog-content>
