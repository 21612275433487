import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {AccountService} from '../../../../api/service/account.service';
import {InstitutionsResponse} from '../../../api/data/institutions-response';
import {InstitutionsService} from '../../../api/service/institutions.service';

@Component({
  selector: 'app-institution-assign',
  templateUrl: './institution-assign.component.html',
  styleUrls: ['./institution-assign.component.scss']
})
export class InstitutionAssignComponent implements OnInit {

  @Input()
  public institutions: string[];

  public enableEdit = false;
  public showSpinner = true;
  public institutionsAssigned: InstitutionsResponse[] = [];
  public institutionsAssign: InstitutionsResponse[] = [];
  public institutionsFound: InstitutionsResponse[] = [];
  public institutionField: FormControl = new FormControl();

  public constructor(private readonly _accountService: AccountService, private readonly _service: InstitutionsService,
                     private readonly _snackBar: MatSnackBar, private readonly _translate: TranslateService) {
  }

  public ngOnInit(): void {
    if (this.institutions) this._service.getInstitutions(this.institutions).subscribe((response: InstitutionsResponse[]) => {
      this.institutionsAssigned = response;
      this.cancel();
    }, () => this.showSpinner = false, () => this.showSpinner = false);
    else this.showSpinner = false;
    this.findInstitution('');
  }

  public findInstitution(search: string): void {
    this._service.search(search).subscribe((response: InstitutionsResponse[]) => this.institutionsFound = response);
  }

  public assignToInstitution(institution: InstitutionsResponse): void {
    this.institutionField.setValue('');
    if (this.institutionsAssign.find((assigned: InstitutionsResponse) => assigned.id === institution.id)) return;
    this.institutionsAssign.push(institution);
    setTimeout(() => this.findInstitution(''), 100);
  }

  public removeAssigned(index: number): void {
    this.institutionsAssign.splice(index, 1);
  }

  public submit(): void {
    this.showSpinner = true;
    this._accountService.updateInstitutions(this.institutionsAssign.map((institutions: InstitutionsResponse) => institutions.id))
      .subscribe(() => {
        this.institutionsAssigned = this.institutionsAssign.map((institution: InstitutionsResponse) => institution);
        this.cancel();
        ViewerConfig.openSnackBar(this._snackBar, this._translate.instant('system.toast.savedSuccessfully'));
      }, () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public cancel(): void {
    this.institutionsAssign = this.institutionsAssigned.map((institution: InstitutionsResponse) => institution);
    this.enableEdit = false;
    this.institutionField.setValue('');
  }
}
