import {Injectable, OnDestroy} from '@angular/core';
import {Rwd} from './enums/rwd.enum';
import {RwdChange} from './interfaces/rwd-change';

@Injectable({
  providedIn: 'root'
})
export class RwdService implements OnDestroy {

  private static readonly SCREEN_XXXL = 1800;
  private static readonly SCREEN_XXL = 1500;
  private static readonly SCREEN_XL = 1200;
  private static readonly SCREEN_LG = 992;
  private static readonly SCREEN_MD = 768;
  private static readonly SCREEN_SM = 576;

  private readonly _rwdChangeListeners: RwdChange[] = [];

  private _screen: Rwd;
  private readonly _resizeFunc = () => this.onResize();

  public constructor() {
    window.onresize = this._resizeFunc;
    this.onResize();
  }

  public get screen(): Rwd {
    return this._screen;
  }

  private onResize(): void {
    const screen = this._screen;
    this._screen = this.getScreen();
    if (screen !== this._screen) this.callRwdChange();
  }

  private getScreen(): Rwd {
    const width = document.body.offsetWidth;
    if (width >= RwdService.SCREEN_XXXL) return Rwd.screenXxxl;
    if (width >= RwdService.SCREEN_XXL) return Rwd.screenXxl;
    if (width >= RwdService.SCREEN_XL) return Rwd.screenXl;
    if (width >= RwdService.SCREEN_LG) return Rwd.screenLg;
    if (width >= RwdService.SCREEN_MD) return Rwd.screenMd;
    if (width >= RwdService.SCREEN_SM) return Rwd.screenSm;
    return Rwd.screenXs;
  }

  public addRwdChangeListener(listener: RwdChange, onStart?: boolean): void {
    if (onStart) this._rwdChangeListeners.unshift(listener);
    else this._rwdChangeListeners.push(listener);
  }

  public removeRwdChangeListener(listener: RwdChange): void {
    const index: number = this._rwdChangeListeners.indexOf(listener);
    if (index === -1) return;
    this._rwdChangeListeners.splice(index, 1);
  }

  private callRwdChange(): void {
    if (!this._rwdChangeListeners.length) return;
    this._rwdChangeListeners.forEach((listener: RwdChange) => {
      try {
        listener.onRwdChange(this._screen);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public ngOnDestroy(): void {
    window.removeEventListener('resize', this._resizeFunc);
  }
}
