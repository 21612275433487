import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Role} from '../../../../../authorization/api/data/enums/role.enum';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {ServiceWorkerService} from '../../../../../web-workers/service/service-worker.service';
import {AccountService} from '../../../../api/service/account.service';
import {AbstractAccountPart} from '../abstracts/abstract-account-part';

@Component({
  selector: 'app-role-data',
  templateUrl: './role-data.component.html',
  styleUrls: ['./role-data.component.scss']
})
export class RoleDataComponent extends AbstractAccountPart {

  public readonly roles: Role[] = [Role.USER, Role.TEACHER, Role.INSTITUTION];
  public roleField: FormControl;

  public constructor(service: AccountService, snackBar: MatSnackBar, serviceWorker: ServiceWorkerService,
                     private readonly _translate: TranslateService) {
    super(service, snackBar, serviceWorker);
  }

  protected createForm(): void {
    this.form = new FormGroup({
      role: this.roleField
    });
  }

  protected createFormControls(): void {
    this.roleField = new FormControl(this.role, [Validators.required]);
  }

  public submit(): void {
    if (!this.form.valid) return;
    if (this.roleField.value === this.role) {
      this.cancel();
      return;
    }
    this.showSpinner = true;
    this.service.roleChange(this.roleField.value).subscribe(() => {
      this.cancel();
      this.roleField.setValue(this.role);
      ViewerConfig.openSnackBar(this.snackBar, this._translate.instant('profile.role.successToast'));
    }, () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public cancel(): void {
    this.enableEdit = false;
  }
}
