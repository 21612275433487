import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Subscriber} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {InteractiveResponse} from '../data/interactive-response';
import {InteractiveEndpoints} from '../interactive-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class InteractiveOfflineService {

  public constructor() {
  }

  public getInteractive(projectId: string): Observable<InteractiveResponse> {
    const path = InteractiveEndpoints.INTERACTIVE.replace(InteractiveEndpoints.PROJECT_ID, projectId);
    return new Observable<InteractiveResponse>((subscriber: Subscriber<InteractiveResponse>) =>
      OfflineUtil.loadText(projectId, path, (file: string) =>
        subscriber.next(deserializeFix(InteractiveResponse, JSON.parse(file)))
      ));
  }
}
