import {JsonProperty} from '@dhkatz/json-ts';

export class BibliographyResponse {

  @JsonProperty('t')
  private _title: string;

  @JsonProperty('d')
  private _description: string;

  public constructor() {
    this._title = undefined;
    this._description = undefined;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }
}
