import {FormControl, FormGroup} from '@angular/forms';

export class PasswordUtil {

  public static generatePassword(letters: number, capitalLetters: number, numbers: number, symbols: number, minLength: number,
                                 maxLength: number): string {
    const chars = [
      'ABCDEFGHJKLMNPQRSTUVWXYZ',
      'abcdefghijkmnpqrstuvwxyz',
      '23456789',
      '!@#$%^&*()=+[<>?|}{/'
    ];
    if (maxLength < minLength) maxLength = minLength;
    const require = letters + capitalLetters + numbers + symbols;
    if (minLength < require) minLength = require;
    const random = minLength - require + Math.ceil(Math.random() * (maxLength - minLength));
    let requiredChars = [letters, capitalLetters, numbers, symbols].map((len, i) =>
      Array(len).fill(chars[i]).map((x) => x[Math.floor(Math.random() * x.length)]).join(''))
      .concat().join('').split('').sort(() => 0.5 - Math.random()).join('');
    requiredChars += chars.join('').split('').sort(() => 0.5 - Math.random()).slice(1, random).join('');
    return requiredChars;
  }

  public static mustMatch(controlName: string, matchingControlName: string): any {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) return;
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  public static passwordStrength(letters: number, capitalLetters: number, numbers: number, symbols: number, minLength: number,
                                 errorName: string = 'weakPassword'): any {
    return (field: FormControl) => {
      const error = {};
      error[errorName] = true;
      const password = field.value;

      const hasLetters = new RegExp('(?=(.*[a-z]){' + letters + ',})');
      const hasCapitalLetters = new RegExp('(?=(.*[A-Z]){' + capitalLetters + ',})');
      const hasNumbers = new RegExp('(?=(.*[\\d]){' + numbers + ',})');
      const hasSymbols = new RegExp('(?=(.*[!@#$%^&*()=+[<>?|}{/]){' + symbols + ',})');

      if (password && password.length < minLength) return error;
      if (letters && !hasLetters.test(password)) return error;
      if (capitalLetters && !hasCapitalLetters.test(password)) return error;
      if (numbers && !hasNumbers.test(password)) return error;
      if (symbols && !hasSymbols.test(password)) return error;

      return null;
    };
  }
}
