import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DragService} from '../../../../../../../services/drag-service/drag.service';
import {Drag} from '../../../../../../../services/drag-service/interafces/drag';

@Component({
  selector: 'app-window-header',
  templateUrl: './window-header.component.html',
  styleUrls: ['./window-header.component.scss']
})
export class WindowHeaderComponent implements Drag, OnDestroy, OnInit {

  @Input()
  public headerTitle: string;

  @Input()
  public close: boolean;

  @Output()
  public closeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public show = true;

  @Output()
  public showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public settings = false;

  @Output()
  public settingsClick: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public drag: Drag;

  @ViewChild('header', {static: true})
  public element: ElementRef;

  public constructor(private _dragService: DragService) {
  }

  public ngOnInit(): void {
    if (!this.element) return;
    this._dragService.addDrag(this);
  }

  public changeClose(): void {
    this.close = !this.close;
    this.closeChange.emit(this.close);
  }

  public changeShow(): void {
    this.show = !this.show;
    this.showChange.emit(this.show);
  }

  public onDrag(event: any, x: number, y: number): void {
    if (this.drag) this.drag.onDrag(event, x, y);
  }

  public onStartDrag(event: any): void {
    if (this.drag) this.drag.onStartDrag(event);
  }

  public onStopDrag(event: any): void {
    if (this.drag) this.drag.onStopDrag(event);
  }

  public ngOnDestroy(): void {
    this._dragService.removeDrag(this);
  }
}
