import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {JsonObject} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {FontsEndpoints} from '../../../../version/modules/fonts/api/fonts-endpoints';
import {CopyrightsEndpoints} from '../copyrights-endpoints';
import {CopyrightsResponse} from '../data/copyrights-response';
import {deserializeFix} from '../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class CopyrightsService {

  public constructor(private readonly _http: HttpClient) {
  }

  public getCopyrights(projectId: string): Observable<CopyrightsResponse> {
    return this._http.get<JsonObject>(CopyrightsEndpoints.COPYRIGHTS.replace(FontsEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonObject) => deserializeFix(CopyrightsResponse, response)));
  }

  public getCopyrightsPreview(versionId: string): Observable<CopyrightsResponse> {
    return this._http.get<JsonObject>(CopyrightsEndpoints.COPYRIGHTS_PREVIEW.replace(FontsEndpoints.VERSION_ID, versionId))
      .pipe(map((response: JsonObject) => deserializeFix(CopyrightsResponse, response)));
  }
}
