import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AbstractPointResponse} from '../../../../../../../../interactive/api/data/points/abstracts/abstract-point-response';
import {PointService} from '../../../../../../../../interactive/modules/points/api/services/point.service';
import {CustomInteractivesCommunicationService} from '../../../../../communiaction/custom-interactives-communication.service';

@Component({
  selector: 'app-point-remove',
  templateUrl: './point-remove.component.html',
  styleUrls: ['./point-remove.component.scss']
})
export class PointRemoveComponent {

  @Output() public close = new EventEmitter();

  @Input() public projectId: string;
  @Input() public layerId: string;
  @Input() public point: AbstractPointResponse;

  public constructor(protected readonly service: PointService,
                     protected readonly customInteractiveCommunication: CustomInteractivesCommunicationService,
                     protected readonly dialogRef: MatDialogRef<PointRemoveComponent>) {
  }

  public closeRemoveDialog(): void {
    this.close.emit(false);
  }

  public delete(): void {
    this.service.removePoint(this.projectId, this.layerId, this.point.type, this.point.id).subscribe(() => {
      this.customInteractiveCommunication.callInteractiveCustomLayersChanged();
      this.dialogRef.close();
    });
  }
}
