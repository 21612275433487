import {Endpoints} from '../../globals/api/endpoints';

export class AuthorizationEndpoints extends Endpoints {

  protected static readonly AUTH = Endpoints.VIEWER + '/auth';

  public static readonly LOGIN = AuthorizationEndpoints.AUTH + '/login';
  public static readonly TOKEN_REFRESH = AuthorizationEndpoints.AUTH + '/refresh';
  public static readonly REGISTER = AuthorizationEndpoints.AUTH + '/register';
  public static readonly RESEND_REGISTER_TOKEN = AuthorizationEndpoints.AUTH + '/resend-register';
  public static readonly RESET_PASSWORD = AuthorizationEndpoints.AUTH + '/reset-password';
  public static readonly CHECK_RESET_PASSWORD_TOKEN = AuthorizationEndpoints.AUTH + '/check-reset-password-token';
  public static readonly CHECK_TEACHER_INVITATION_TOKEN = AuthorizationEndpoints.AUTH + '/check-institution-token';
  public static readonly REGISTER_CONFIRM = AuthorizationEndpoints.AUTH + '/register-confirm';
  public static readonly PASSWORD_CONFIRM = AuthorizationEndpoints.AUTH + '/password-confirm';
  public static readonly EMAIL_CONFIRM = AuthorizationEndpoints.AUTH + '/email-confirm';
  public static readonly USER_EXISTS = AuthorizationEndpoints.AUTH + '/user-exists';
  public static readonly COUPON_VALID = AuthorizationEndpoints.AUTH + '/coupon-valid';
  public static readonly REFRESH_CREDENTIALS = AuthorizationEndpoints.AUTH + '/credentials';
}
