import {deserialize} from '@dhkatz/json-ts';
import {Constructor, Json, JsonArray, JsonObject} from '@dhkatz/json-ts/src/util';

export function deserializeFix<T extends object, U extends Json>(type: T | Constructor<T>, json: U): T;
export function deserializeFix<T extends object, U extends JsonArray>(type: T | Constructor<T>, json: U): T[];
export function deserializeFix<T extends object, U extends JsonObject>(type: T | Constructor<T>, json: U): T;
export function deserializeFix<T extends object>(
  type: T | Constructor<T>,
  json: JsonObject | JsonArray
): T | T[] | undefined {
  return fixValues(deserialize(type, json));
}

function fixValues(data: any): any {
  if (isEmpty(data)) return undefined;
  if (isPrimitive(data)) return data;
  if (Array.isArray(data)) return data.map((value: any) => fixValues(value));
  if (isPrimitiveObject(data)) return data.valueOf();
  Object.keys(data).forEach((key: any) => data[key] = fixValues(data[key]));
  return data;
}

function isEmpty(data: any): boolean {
  return data === 'null';
}

function isPrimitiveObject(data: any): boolean {
  return data instanceof String
    || data instanceof Number
    || data instanceof Boolean;
}

function isPrimitive(data: any): boolean {
  return (typeof data).toLowerCase() === 'string' ||
    (typeof data).toLowerCase() === 'number' ||
    (typeof data).toLowerCase() === 'boolean' ||
    (typeof data).toLowerCase() === 'bigint' ||
    (typeof data).toLowerCase() === 'symbol' ||
    (typeof data).toLowerCase() === 'undefined' ||
    (typeof data).toLowerCase() === 'function' ||
    data === null;
}
