import {JsonProperty} from '@dhkatz/json-ts';
import {AnyConverter} from '../../../../../../../globals/api/json/any-converter';
import {ArrayStringConverter} from '../../../../../../../globals/api/json/array-string-converter';
import {AbstractPointResponse} from './abstracts/abstract-point-response';
import {ChartData} from '../../../../../../../charts/interfaces/chart-data';

export class ChartPointResponse extends AbstractPointResponse implements ChartData {

  @JsonProperty({name: 'ch', converter: AnyConverter.converter})
  private _chart: any;

  @JsonProperty({name: 'th', converter: ArrayStringConverter.converter, type: Object})
  private _themes: string[];

  public constructor() {
    super();
    this._chart = undefined;
    this._themes = undefined;
  }

  public get chart(): any {
    return this._chart;
  }

  public get themes(): string[] {
    return this._themes;
  }
}
