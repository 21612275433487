import {Pipe, PipeTransform} from '@angular/core';
import {Endpoints} from '../api/endpoints';

@Pipe({
  name: 'fileUrl'
})
export class FileUrlPipe implements PipeTransform {

  public constructor() {
  }

  public transform(url: string, endpoint?: string): any {
    if (url === undefined) return '';
    return !endpoint || !Endpoints[endpoint] ? url : Endpoints[endpoint] + url;
  }
}
