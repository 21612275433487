<div class="center-box">
  <div class="center-box-container">
    <div id="confirm">
      <ng-container *ngIf="status === confirmStatus.success">
        <mat-icon class="success">done_outline</mat-icon>
        <span [outerHTML]="'registrationConfirmation.info.success' | translate:{email: email}"></span>
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.invalid">
        <mat-icon class="error">error_outline</mat-icon>
        {{'registrationConfirmation.info.invalid' | translate}}
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.expired">
        <mat-icon class="error">error_outline</mat-icon>
        {{'registrationConfirmation.info.expired' | translate}}
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.used">
        <mat-icon class="warn">warning</mat-icon>
        {{'registrationConfirmation.info.used' | translate}}
      </ng-container>
      <ng-container *ngIf="status === confirmStatus.resend">
        <mat-icon class="success">mail_outline</mat-icon>
        <span [outerHTML]="'registrationConfirmation.info.resend' | translate:{email: email}"></span>
      </ng-container>
      <footer>
        <button *ngIf="status !== confirmStatus.expired" class="submit-button" [routerLink]="'/'">
          {{'system.label.goToMain' | translate}}
        </button>
        <button *ngIf="status === confirmStatus.expired" class="submit-button" (click)="resend()">
          {{'registrationConfirmation.label.resend' | translate}}
        </button>
      </footer>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner" class="full"></app-spinner>
