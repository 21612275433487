<div [ngClass]="{'authorization-container': true, 'expand': true}">
    <div class="system-dialog">
        <div [ngClass]="{'loading': showSpinner}">
            <header>
              {{title | translate}}
                <span *ngIf="isDialog" class="close" (click)="closeDialog()"><mat-icon>close</mat-icon></span>
            </header>
            <app-login *ngIf="screen === screensEnum.login && !registrationOnly" [(showSpinner)]="showSpinner"
                       (screen)="changeScreen($event)" (loginSuccess)="loginSuccess()" (error)="error.emit($event)"
                       [email]="email"></app-login>
            <app-registration *ngIf="screen === screensEnum.registration || registrationOnly" [role]="role"
                              [(showSpinner)]="showSpinner" (screen)="changeScreen($event)" (error)="error.emit($event)"
                              [email]="email" [(registrationOnly)]="registrationOnly" [token]="token"
                              (registered)="registered = true"></app-registration>
            <app-reset-password *ngIf="screen === screensEnum.resetPassword && !registrationOnly"
                                [(showSpinner)]="showSpinner" (screen)="changeScreen($event)" (error)="error.emit($event)"
                                [email]="email"></app-reset-password>
            <app-change-password *ngIf="screen === screensEnum.changePassword && !registrationOnly"
                                 [(showSpinner)]="showSpinner" (screen)="changeScreen($event)"
                                 (error)="error.emit($event)"></app-change-password>
        </div>
        <app-spinner *ngIf="showSpinner" class="full primary"></app-spinner>
    </div>
</div>
