import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Subscriber} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {LayerManagerResponse} from '../data/layer-manager-response';
import {LayerManagerEndpoints} from '../layer-manager-endpoints';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class LayerManagerOfflineService {

  public constructor() {
  }

  public getLayerManager(projectId: string): Observable<LayerManagerResponse[]> {
    const path = LayerManagerEndpoints.LAYER_MANAGER.replace(LayerManagerEndpoints.PROJECT_ID, projectId);
    return new Observable<LayerManagerResponse[]>((subscriber: Subscriber<LayerManagerResponse[]>) =>
      OfflineUtil.loadText(projectId, path, (file: string) =>
        subscriber.next(JSON.parse(file).map((legend: any) => deserializeFix(LayerManagerResponse, legend)))
      ));
  }
}
