import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthorizationDialogComponent} from '../../../../authorization/screens/authorization/authorization-dialog/authorization-dialog.component';
import {AuthorizationScreens} from '../../../../authorization/screens/enums/authorization-screens.enum';
import {RoutingConfig} from '../../../../configs/routing-config';
import {ViewerConfig} from '../../../../configs/viewer-config';
import {ServiceWorkerService} from '../../../../web-workers/service/service-worker.service';
import {AbstractRoleComponent} from '../../abstracts/abstract-role.component';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent extends AbstractRoleComponent {

  @Input()
  public disabled: boolean;

  public constructor(serviceWorker: ServiceWorkerService, private readonly _router: Router, private readonly _dialog: MatDialog,
                     private readonly _snackBar: MatSnackBar, private readonly _translate: TranslateService) {
    super(serviceWorker);
  }

  public logout(): void {
    this.workerService.logout();
    localStorage.removeItem('offlineEmail');
    ViewerConfig.openSnackBar(this._snackBar,
      this._translate.instant('authorization.logoutToast'));
  }

  public login(): void {
    this._dialog.open(AuthorizationDialogComponent, ViewerConfig.getAuthDialogOptions());
  }

  public register(): void {
    this._dialog.open(AuthorizationDialogComponent,
      ViewerConfig.getAuthDialogOptions({screen: AuthorizationScreens.registration}));
  }

  public goToAccount(): void {
    this._router.navigate([RoutingConfig.ACCOUNT_PROFILE]);
  }

  public goToAccountManage(): void {
    this._router.navigate([RoutingConfig.ACCOUNT_MANAGE]);
  }

  public goToAccountSubscriptions(): void {
    this._router.navigate([RoutingConfig.ACCOUNT_SUBSCRIPTIONS]);
  }
}
