import {ICustomConverter} from '@dhkatz/json-ts';

export class MapNumberStringConverter implements ICustomConverter<Map<number, string>> {

  public static converter = new MapNumberStringConverter();

  public fromJson(data: object): Map<number, string> {
    if (data === undefined) return undefined;
    const hashMap = new Map<number, string>();
    Object.keys(data).forEach((key) => {
      hashMap.set(+key, data[key]);
    });
    return hashMap;
  }

  public toJson(data: Map<number, string>): object {
    const obj = {};
    data.forEach((value, key) => obj[key] = value);
    return obj;
  }
}
