import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ErrorResponse} from '../../../globals/api/data/error-response';
import {ServiceWorkerService} from '../../../web-workers/service/service-worker.service';
import {JWT} from '../../../web-workers/workers/service-worker/interceptors/security-interceptor/jwt/jwt';
import {AuthorizationService} from '../../api/services/authorization.service';
import {AuthorizationScreens} from '../enums/authorization-screens.enum';
import {ConfirmStatus} from '../enums/confirm-status.enum';

@Component({
  selector: 'app-change-password-screen',
  templateUrl: './change-password-screen.component.html',
  styleUrls: ['./change-password-screen.component.scss']
})
export class ChangePasswordScreenComponent implements OnInit {

  public status: ConfirmStatus;
  public confirmStatus = ConfirmStatus;
  public screensEnum = AuthorizationScreens;
  public showSpinner = true;

  public constructor(private readonly _service: AuthorizationService, private readonly _route: ActivatedRoute,
                     private readonly _serviceWorker: ServiceWorkerService) {
  }

  public ngOnInit(): void {
    this._serviceWorker.logout();
    setTimeout(() => this._route.queryParams.subscribe((params) => {
      const token = params['tok'];
      try {
        if (!JWT.getUnsafePayload(token)) throw new Error('Token error');
      } catch {
        this.status = ConfirmStatus.invalid;
        this.showSpinner = false;
        return;
      }
      this._service.checkResetPasswordToken(token).subscribe(() => this.status = ConfirmStatus.success,
        (error: HttpErrorResponse) => {
          this.showSpinner = false;
          this.handleError(error.error);
        }, () => this.showSpinner = false);
    }), 0);
  }

  public handleError(error: ErrorResponse): void {
    if (error.key === 'reset_password_token_signature') {
      this.status = ConfirmStatus.used;
      return;
    }
    if (error.key === 'reset_password_token_expired') {
      this.status = ConfirmStatus.expired;
      return;
    }
    this.status = ConfirmStatus.invalid;
  }
}
