<div class="search">
  <div class="left-bar bar">
  </div>
  <div class="top-bar bar">
    <div class="row">
      <mat-form-field class="tags-field">
        <input matInput [placeholder]="'project.search.tags' | translate" [formControl]="tagsField"
               [matAutocomplete]="auto" (ngModelChange)="filterTags()">
        <mat-autocomplete class="search" #auto="matAutocomplete" (optionSelected)="addTag($event.option.value)">
          <ng-container *ngFor="let tag of filteredTags">
            <mat-option [value]="tag">
              {{tag.title}}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="search-field">
        <input type="text" [placeholder]="'system.form.placeholder.search' | translate" name="searchField" matInput
               [formControl]="searchField" (ngModelChange)="searchDelay()" #autoFocus="matInput">
        <button mat-button class="clear" *ngIf="searchField.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="searchField.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-icon class="close noselect" (click)="closeDialog()">close</mat-icon>
    </div>
    <div class="tag-container">
      <mat-chip-list>
        <mat-chip class="tag-chip" *ngFor="let tag of selectedTags" [selectable]="selectable" [removable]="removable"
                  (removed)="removeTag(tag)">
          {{tag?.title}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <div class="right-bar bar">
  </div>
  <div class="bottom-bar bar">
    <app-logo></app-logo>
  </div>
  <div class="content-box">
    <div class="content-box-container">
      <div *ngIf="anyProjects()">
        <div *ngFor="let projects of projectsGroups; let index = index" class="content-row">
          <app-carriage [carriageIndex]="index" [projectsList]="projects" [inDialog]="true"
                        (closeDialog)="closeDialog()" [carriageSize]="carriageSize"></app-carriage>
        </div>
      </div>
      <div class="empty" *ngIf="!anyProjects() && !showSpinner">
        <span
          *ngIf="searchField.value || selectedTags.length">{{'project.search.empty' | translate}}
        </span>
        <span
          *ngIf="!searchField.value && !selectedTags.length">{{'project.search.start' | translate}}
        </span>
      </div>
      <div class="empty" *ngIf="showSpinner">
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
</div>
