import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {serialize} from '@dhkatz/json-ts';
import {Observable} from 'rxjs';
import {AuthorizationEndpoints} from '../authorization-endpoints';
import {AuthRequest} from '../data/auth-request';
import {ChangePasswordRequest} from '../data/change-password-request';
import {CouponValidRequest} from '../data/coupon-valid-request';
import {RegisterRequest} from '../data/register-request';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  public constructor(private readonly _http: HttpClient) { //
  }

  public sendAuthRequest(authRequest: AuthRequest): Observable<void> {
    return this._http.post<void>(AuthorizationEndpoints.LOGIN, serialize(authRequest));
  }

  public register(request: RegisterRequest): Observable<boolean> {
    return this._http.post<boolean>(AuthorizationEndpoints.REGISTER, serialize(request));
  }

  public resetPassword(email: string): Observable<void> {
    return this._http.post<void>(AuthorizationEndpoints.RESET_PASSWORD, email);
  }

  public resendRegisterToken(email: string): Observable<void> {
    return this._http.put<void>(AuthorizationEndpoints.RESEND_REGISTER_TOKEN, email);
  }

  public confirmEmail(token: string): Observable<void> {
    return this._http.put<void>(AuthorizationEndpoints.EMAIL_CONFIRM, token);
  }

  public confirmRegister(token: string): Observable<void> {
    return this._http.put<void>(AuthorizationEndpoints.REGISTER_CONFIRM, token);
  }

  public changePassword(request: ChangePasswordRequest): Observable<void> {
    return this._http.put<void>(AuthorizationEndpoints.PASSWORD_CONFIRM, serialize(request));
  }

  public checkResetPasswordToken(token: string): Observable<void> {
    return this._http.post<void>(AuthorizationEndpoints.CHECK_RESET_PASSWORD_TOKEN, token);
  }

  public checkTeacherInvitationToken(token: string): Observable<void> {
    return this._http.post<void>(AuthorizationEndpoints.CHECK_TEACHER_INVITATION_TOKEN, token);
  }

  public userExists(email: string): Promise<string> {
    return this._http.post<string>(AuthorizationEndpoints.USER_EXISTS, email).toPromise();
  }

  public couponValid(request: CouponValidRequest): Promise<string> {
    return this._http.post<string>(AuthorizationEndpoints.COUPON_VALID, serialize(request)).toPromise();
  }
}
