import {Endpoints} from '../../globals/api/endpoints';

export class AccountEndpoints extends Endpoints {

  public static readonly ACCOUNTS = AccountEndpoints.VIEWER + '/account';
  public static readonly ACCOUNT = AccountEndpoints.ACCOUNTS + '/' + AccountEndpoints.ACCOUNT_ID;
  public static readonly ACCOUNTS_MY_EMAIL_CHANGE = AccountEndpoints.ACCOUNTS + '/email';
  public static readonly ACCOUNTS_MY_PASSWORD_CHANGE = AccountEndpoints.ACCOUNTS + '/password';
  public static readonly ACCOUNTS_ADD_TEACHER_TO_INSTITUTION = AccountEndpoints.ACCOUNTS + '/add-teacher';
  public static readonly ACCOUNTS_ADD_STUDENT_TO_INSTITUTION = AccountEndpoints.ACCOUNTS + '/add-student';
  public static readonly ACCOUNTS_SEND_INSTITUTION_INVITATION = AccountEndpoints.ACCOUNTS + '/institution-invite';
  public static readonly ACCOUNTS_GET_INSTITUTIONS_FOR_STUDENT = AccountEndpoints.ACCOUNTS + '/institutions-student';
  public static readonly ACCOUNTS_GET_INSTITUTIONS_FOR_TEACHER = AccountEndpoints.ACCOUNTS + '/institutions-teacher';
  public static readonly ACCOUNTS_INSTITUTION_STUDENTS = AccountEndpoints.ACCOUNTS + '/institution-students';
  public static readonly ACCOUNTS_INSTITUTION_TEACHERS = AccountEndpoints.ACCOUNTS + '/institution-teachers';
  public static readonly ACCOUNTS_INSTITUTION_CODE = AccountEndpoints.ACCOUNTS + '/institution-code';
  public static readonly ACCOUNTS_INSTITUTION_REMOVE_STUDENT_FROM_INSTITUTION = AccountEndpoints.ACCOUNT + '/remove-student';
  public static readonly ACCOUNTS_INSTITUTION_REMOVE_TEACHER_FROM_INSTITUTION = AccountEndpoints.ACCOUNT + '/remove-teacher';
  public static readonly ACCOUNTS_INSTITUTION_REMOVE_INSTITUTION_FROM_STUDENT =
      AccountEndpoints.ACCOUNT + '/remove-institution-from-student';
  public static readonly ACCOUNTS_INSTITUTION_REMOVE_INSTITUTION_FROM_TEACHER =
      AccountEndpoints.ACCOUNT + '/remove-institution-from-teacher';
  public static readonly ACCOUNT_ADDRESS_DATA = AccountEndpoints.ACCOUNTS + '/address-data';
  public static readonly ACCOUNT_ROLE_CHANGE = AccountEndpoints.ACCOUNTS + '/role-change';
  public static readonly ACCOUNT_INSTITUTIONS = AccountEndpoints.ACCOUNTS + '/institutions';
  public static readonly ACCOUNT_APPROVALS = AccountEndpoints.ACCOUNTS + '/approvals';
}
