import {Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ViewerConfig} from '../../configs/viewer-config';
import {ErrorResponse} from '../api/data/error-response';
import {AlertComponent} from './alert/alert.component';
import {ResponseErrorComponent} from './response-error/response-error.component';
import {deserializeFix} from '../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {

  public constructor(private readonly _dialog: MatDialog, private readonly _ngZone: NgZone) {
  }

  public showError(error: any): Observable<any> {
    return this._ngZone.run(() => {
      error = deserializeFix(ErrorResponse, error);
      return this._dialog.open(ResponseErrorComponent, ViewerConfig.getDialogOptions(error)).afterClosed();
    });
  }

  public showAlert(title: string, message: string): Observable<any> {
    return this._dialog.open(AlertComponent, ViewerConfig.getDialogOptions({
      title,
      message
    })).afterClosed();
  }
}
