<div class="container-box">
  <div class="account-part user-management">
    <div class="box-title">
      {{'assignmentManagement.teacher.assignedAsATeacher.title' | translate}}
    </div>
    <hr>
    <div #scrollContent class="account-list">
      <table *ngIf="list.length" mat-table [dataSource]="list"
             [ngClass]="{'mat-elevation-z8': true, 'loading': showSpinner}">
        <ng-container matColumnDef="title">
          <mat-cell *matCellDef="let element">{{element.title}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="code">
          <mat-cell *matCellDef="let element">
            <app-institution-code [institutionId]="element.id"></app-institution-code>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-cell *matCellDef="let element; let i = index;">
            <mat-icon class="trash" (click)="removeFromInstitution(i)">delete</mat-icon>
          </mat-cell>
        </ng-container>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </table>
      <div *ngIf="!showSpinner && !list.length" class="empty-list">
        {{'assignmentManagement.noAssignments' | translate}}
      </div>
      <app-spinner *ngIf="showSpinner" class="full"></app-spinner>
    </div>
  </div>
</div>
