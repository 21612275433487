import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {TitleItem} from '../../../../../globals/api/data/title-item';
import {DialogsService} from '../../../../../globals/dialogs/dialogs.service';
import {OnElementScroll} from '../../../../../globals/interfaces/on-element-scroll';
import {SubscriptionAccessService} from '../../../../../subscriptions/services/subscription-access.service';
import {AccountService} from '../../../../api/service/account.service';
import {AbstractManagementPartComponent} from '../abstracts/abstract-management-part-component';

@Component({
  selector: 'app-user-teacher',
  templateUrl: './user-teacher.component.html',
  styleUrls: ['./user-teacher.component.scss']
})
export class UserTeacherComponent extends AbstractManagementPartComponent<TitleItem> implements OnInit, OnElementScroll {

  public readonly displayedColumns: string[] = ['title', 'code', 'actions'];

  public constructor(service: AccountService, private readonly _dialogs: DialogsService, private readonly _snackBar: MatSnackBar,
                     private readonly _translate: TranslateService, private readonly _accessService: SubscriptionAccessService) {
    super(service);
  }

  public getNextPage(page: number): void {
    this.showSpinner = true;
    this.service.getTeacherInstitutions(page).subscribe((response: TitleItem[]) =>
        this.list = this.listController.getUpdatedList(page, response, this.list, TitleItem),
      () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public removeFromInstitution(index: number): void {
    const institution = this.list[index];
    this._dialogs.showAlert(this._translate.instant('assignmentManagement.teacher.assignedAsATeacher.removeAlert.title'),
      this._translate.instant('assignmentManagement.teacher.assignedAsATeacher.removeAlert.description',
        {name: institution.title}))
      .subscribe((decision: boolean) => {
        if (!decision) return;
        this.showSpinner = true;
        this.service.removeInstitutionFromTeacher(institution.id).subscribe(() => {
          this.list.splice(index, 1);
          this.list = [...this.list];
          this._accessService.clearAndReloadSubscriptionAccess();
          ViewerConfig.openSnackBar(this._snackBar, this._translate
            .instant('assignmentManagement.teacher.assignedAsATeacher.removeToast', {name: institution.title}));
        }, () => this.showSpinner = false, () => this.showSpinner = false);
      });
  }
}
