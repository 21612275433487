import {NgModule} from "@angular/core";
import {DecodeHtmlEntities} from "./decode-html-entities.Pipe";

export class DecodeHtmlEntitiesModule {static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    declarations: [DecodeHtmlEntities],
    exports:[DecodeHtmlEntities]
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}
interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
