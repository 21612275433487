import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractPreRenderData} from './abstract-pre-render-data';

export abstract class AbstractIndexPreRenderData extends AbstractPreRenderData {

  @JsonProperty('i')
  private _index: number;

  @JsonProperty('a')
  protected activePart: boolean;

  protected constructor() {
    super();
    this._index = undefined;
    this.activePart = undefined;
  }

  public get index(): number {
    return this._index;
  }

  public set index(value: number) {
    this._index = value;
  }

  public setDisabledLayers(layers: boolean[]): boolean {
    const prevActive = this.activePart;
    this.activePart = !layers[this._index];
    return prevActive !== this.activePart;
  }

  public get active(): boolean {
    return this.activePart;
  }
}
