import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Observable, Subscriber} from 'rxjs';
import {OfflineUtil} from '../../../../../../utils/offline-util';
import {BibliographyEndpoints} from '../bibliography-endpoints';
import {BibliographyResponse} from '../data/bibliography-response';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class BibliographyOfflineService {

  public constructor() {
  }

  public getBibliography(projectId: string): Observable<BibliographyResponse[]> {
    const path = BibliographyEndpoints.BIBLIOGRAPHY_ENTRY.replace(BibliographyEndpoints.PROJECT_ID, projectId);
    return new Observable<BibliographyResponse[]>((subscriber: Subscriber<BibliographyResponse[]>) =>
      OfflineUtil.loadText(projectId, path, (file: string) =>
        subscriber.next(JSON.parse(file).map((task) => deserializeFix(BibliographyResponse, task)))
      ));
  }
}
