import {Injectable} from '@angular/core';
import {FontResponse} from '../api/data/font-response';
import {FontsInfoLoaded} from './interfaces/fonts-info-loaded';
import {FontsLoaded} from './interfaces/fonts-loaded';

@Injectable({
  providedIn: 'root'
})
export class FontsCommunicationService {

  private _fontsInfoLoadedListeners: FontsInfoLoaded[] = [];
  private _fontsLoadedListeners: FontsLoaded[] = [];

  public constructor() {
  }

  public addFontsInfoLoadedListener(listener: FontsInfoLoaded): void {
    this._fontsInfoLoadedListeners.push(listener);
  }

  public removeFontsInfoLoadedListener(listener: FontsInfoLoaded): void {
    const index: number = this._fontsInfoLoadedListeners.indexOf(listener);
    if (index === -1) return;
    this._fontsInfoLoadedListeners.splice(index, 1);
  }

  public fontsInfoLoaded(fonts: FontResponse[]): void {
    if (this._fontsInfoLoadedListeners.length === 0) return;
    this._fontsInfoLoadedListeners.forEach((listener: FontsInfoLoaded) => {
      try {
        listener.onFontsInfoLoaded(fonts);
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public addFontsLoadedListener(listener: FontsLoaded): void {
    this._fontsLoadedListeners.push(listener);
  }

  public removeFontsLoadedListener(listener: FontsLoaded): void {
    const index: number = this._fontsLoadedListeners.indexOf(listener);
    if (index === -1) return;
    this._fontsLoadedListeners.splice(index, 1);
  }

  public fontsLoaded(): void {
    if (this._fontsLoadedListeners.length === 0) return;
    this._fontsLoadedListeners.forEach((listener: FontsLoaded) => {
      try {
        listener.onFontsLoaded();
      } catch (exception) {
        console.log(exception);
      }
    });
  }
}
