import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Json, JsonArray} from '@dhkatz/json-ts/typings/util';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BibliographyEndpoints} from '../bibliography-endpoints';
import {BibliographyResponse} from '../data/bibliography-response';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class BibliographyService {

  public constructor(private readonly _http: HttpClient) {
  }

  public getBibliography(projectId: string): Observable<BibliographyResponse[]> {
    return this._http.get<JsonArray>(BibliographyEndpoints.BIBLIOGRAPHY_ENTRY
      .replace(BibliographyEndpoints.PROJECT_ID, projectId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(BibliographyResponse, value))));
  }

  public getBibliographyPreview(versionId: string): Observable<BibliographyResponse[]> {
    return this._http.get<JsonArray>(BibliographyEndpoints.BIBLIOGRAPHY_ENTRY_PREVIEW
      .replace(BibliographyEndpoints.VERSION_ID, versionId))
      .pipe(map((response: JsonArray) => response.map((value: Json) => deserializeFix(BibliographyResponse, value))));
  }
}
