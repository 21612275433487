<div class="center-box" *ngIf="showApprovals">
  <div class="center-box-container">
    <div class="system-dialog approvals-container">
      <header>{{'approvals.title.main' | translate}}</header>
      <div class="system-dialog-body">
        <div class="messeges">
          <form>
            <div class="required-approvals">
              <h3 *ngIf="requiredApprovals?.length && optionalApprovals?.length">{{'approvals.title.required' | translate}}</h3>
              <mat-form-field class="mat-field-validation-container" *ngFor="let approval of requiredApprovals">
                <span [outerHTML]="approval?.content"></span>
                <input type="text" matInput [formControl]="approval?.field">
                <mat-checkbox (change)="approval?.field?.setValue($event.checked ? true : undefined)">
                  <span [outerHTML]="approval?.accept"></span>
                </mat-checkbox>
                <mat-error *ngIf="approval?.field?.getError('required')"
                           [innerHTML]="'approvals.error.termRequired' | translate"></mat-error>
              </mat-form-field>
            </div>
            <div class="optional-approvals" *ngIf="optionalApprovals?.length">
              <h3>{{'approvals.title.optional' | translate}}</h3>
              <mat-form-field class="mat-field-validation-container" *ngFor="let approval1 of optionalApprovals">
                <span [outerHTML]="approval1?.content"></span>
                <input type="text" matInput [formControl]="approval1?.field">
                <mat-checkbox (change)="approval1?.field?.setValue($event.checked ? true : undefined)">
                  <span [outerHTML]="approval1?.accept"></span>
                </mat-checkbox>
              </mat-form-field>
            </div>
            <button class="submit-button" (click)="onSubmit()">{{'system.label.send' | translate}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
