import {Injectable} from '@angular/core';
import {Subscriber} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {TitleItem} from '../../../globals/api/data/title-item';
import {OfflineUtil} from '../../../utils/offline-util';
import {CategoryEndpoints} from '../category-endpoints';
import {deserializeFix} from '../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class CategoryOfflineService {

  public constructor() {
  }

  public getCategoryPath(categoryId: string, projectId: string): Observable<TitleItem[]> {
    const path = CategoryEndpoints.CATEGORY_PATH.replace(CategoryEndpoints.CATEGORY_ID, categoryId);
    return new Observable<TitleItem[]>((subscriber: Subscriber<TitleItem[]>) =>
      OfflineUtil.loadText(projectId, path, (file: string) => {
        subscriber.next(JSON.parse(file).map((value: any) => deserializeFix(TitleItem, value)));
      }));
  }
}
