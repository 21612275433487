import {JsonProperty} from '@dhkatz/json-ts';

export class AuthRequest {

  @JsonProperty('email')
  private _email: string;

  @JsonProperty('password')
  private _password: string;

  @JsonProperty('isPlain')
  private _isPlain: boolean;

  @JsonProperty('isAutoLogin')
  private _isAutoLogin: boolean;

  public constructor() {
    this._email = undefined;
    this._password = undefined;
    this._isPlain = undefined;
    this._isAutoLogin = undefined;
  }

  public get email(): string {
    return this._email;
  }

  public get password(): string {
    return this._password;
  }

  public set email(email: string) {
    this._email = email;
  }

  public set password(password: string) {
    this._password = password;
  }

  public get isPlain(): boolean {
    return this._isPlain;
  }

  public set isPlain(isPlain: boolean) {
    this._isPlain = isPlain;
  }

  public get isAutoLogin(): boolean {
    return this._isAutoLogin;
  }

  public set isAutoLogin(isAutoLogin: boolean) {
    this._isAutoLogin = isAutoLogin;
  }
}
