<div class="window minimap noselect" #window [@fadeAnimation]="windowState">
    <app-window-header [headerTitle]="'project.miniMap.title' | translate" [(close)]="showWindow" [(show)]="showContent"
                       [drag]="this"></app-window-header>
    <div [@slideAnimation]="contentState" (@slideAnimation.done)="updateDivParams()" class="content">
        <canvas class="drag" #miniMap></canvas>
        <div class="resize-icon">
            <img class="drag" #resize src="/assets/images/scale.png"
                 srcset="/assets/images/scale.png 1x, /assets/images/scale_2x.png 2x">
        </div>
    </div>
</div>
