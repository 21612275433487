import {Inject, Injectable} from '@angular/core';
import {serialize} from '@dhkatz/json-ts';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';
import {RegisterRequest} from '../../authorization/api/data/register-request';
import {Environment} from '../../runtime-environments/environment.interface';
import {APP_ENV} from '../../variables';
import {RegisterEvent} from './data/register-event';

@Injectable({
  providedIn: 'root'
})
export class TagManagerService {

  private readonly _enabled;

  public constructor(private readonly _angulartics2: Angulartics2GoogleTagManager, @Inject(APP_ENV) env: Environment) {
    this._enabled = env.env === 'prod';
  }

  public init(): void {
    if (this._enabled) this._angulartics2.startTracking();
  }

  public pushEvent(name: string, category: string, value?: any): void {
    if (!this._enabled) return;
    const properties = {};
    properties['category'] = category;
    if (value) properties['value'] = value;
    this._angulartics2.eventTrack(name, properties);
  }

  public pushRegisterEvent(request: RegisterRequest): void {
    if (!this._enabled) return;
    const event = new RegisterEvent(request);
    this.pushEvent('register', 'account-managament', serialize(event));
  }
}
