import {Inject, Injectable} from '@angular/core';
import {Role} from '../../authorization/api/data/enums/role.enum';
import {DialogsService} from '../../globals/dialogs/dialogs.service';
import {Environment} from '../../runtime-environments/environment.interface';
import {APP_ENV} from '../../variables';
import {SecurityWorkerMessage} from '../enums/security-worker-message.enum';
import {ServiceWorkerType} from '../enums/service-worker-type.enum';
import {ServiceWorkerCommunicationService} from './service-worker-communication.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {

  private _reload: boolean;
  private _error: boolean;
  private _login: boolean;
  private _email: string;
  private _role: Role;

  public constructor(private readonly _communication: ServiceWorkerCommunicationService,
                     private readonly _dialogs: DialogsService, @Inject(APP_ENV) private readonly _env: Environment) {
  }

  public get email(): string {
    return this._email;
  }

  public get role(): Role {
    return this._role;
  }

  public register(): void {
    if (!('serviceWorker' in navigator)) {
      console.log('The current browser doesn\'t support service workers.'); // alert że trzeba zainstalować nowszą przeglądarkę
      return;
    }
    navigator.serviceWorker.register(this._env.serviceWorker)
      .catch((errorEvent?: any) => this.onRegisterError(errorEvent));
    navigator.serviceWorker.ready.then((registration: ServiceWorkerRegistration) => {
      this._reload = true;
      registration.onupdatefound = () => setTimeout(() => this.initWorker(), 100);
      if (registration.active.state === 'activating') {
        registration.active.onstatechange = (event: Event) => {
          if ((event.target as ServiceWorker).state === 'activated') this.initWorker();
        };
      } else this.initWorker();
    }).catch((errorEvent?: any) => this.onRegisterError(errorEvent));
  }

  private initWorker(): void {
    navigator.serviceWorker.onmessage = (event: MessageEvent) => this.workerMessage(event);
    this.init();
    setTimeout(() => this._reload ? this._communication.callServiceWorkerUpdated() :
      this._communication.callServiceWorkerActivated(), 0);

  }

  private onRegisterError(errorEvent?: any): void {
    this._communication.callServiceWorkerError(errorEvent);
    console.log(errorEvent);
  }

  private workerMessage(event: MessageEvent): void {
    if (event.source !== navigator.serviceWorker.controller || !event.data) return;
    switch (event.data.type) {
      case ServiceWorkerType.security:
        this.securityWorkerMessage(event.data.message);
        break;
    }
  }

  private securityWorkerMessage(message?: any): void {
    switch (message.type) {
      case SecurityWorkerMessage.login:
        this._login = true;
        this._email = message.data.email;
        this._role = message.data.role;
        this._communication.callSecurityWorkerLogin(this._email, this._role);
        break;
      case SecurityWorkerMessage.logout:
        this._login = false;
        this._email = undefined;
        this._role = undefined;
        this._communication.callSecurityWorkerLogout();
        break;
      case SecurityWorkerMessage.error:
        if (this._error) return;
        this._error = true;
        this._dialogs.showError(message.data).subscribe(() => {
          this._error = false;
        });
        break;
      case SecurityWorkerMessage.subscruption:
        this._communication.callSecurityWorkerSubscription();
        break;
    }
  }

  private init(): void {
    navigator.serviceWorker.controller.postMessage({
      type: ServiceWorkerType.security,
      message: {
        type: SecurityWorkerMessage.init
      }
    });
  }

  public logout(): void {
    navigator.serviceWorker.controller.postMessage({
      type: ServiceWorkerType.security,
      message: {
        type: SecurityWorkerMessage.logout
      }
    });
  }

  public get isLoggedIn(): boolean {
    return this._login;
  }

  public get isLoggedInOnline(): boolean {
    return this._login && navigator.onLine;
  }
}
