import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {device_pixel_ratio} from 'javascript-retina-detect';
import {ViewerConfig} from '../../../../../../../../configs/viewer-config';
import {OfflineService} from '../../../../../../../../offline/services/offline-service/offline.service';
import {DragService} from '../../../../../../../../services/drag-service/drag.service';
import {Drag} from '../../../../../../../../services/drag-service/interafces/drag';
import {EmbedPointDialogComponent} from '../../../../../layer-manager/modules/custom-interactives-manager/screens/dialogs/dialog-point/parts/embed-point-dialog/embed-point-dialog.component';
import {ImagePointDialogComponent} from '../../../../../layer-manager/modules/custom-interactives-manager/screens/dialogs/dialog-point/parts/image-point-dialog/image-point-dialog.component';
import {TextPointDialogComponent} from '../../../../../layer-manager/modules/custom-interactives-manager/screens/dialogs/dialog-point/parts/text-point-dialog/text-point-dialog.component';
import {LayersCommunicationService} from '../../../../../layer/communication/layers-communication.service';
import {IconResponse} from '../../../../api/data/icon-response';
import {AbstractPointResponse} from '../../../../api/data/points/abstracts/abstract-point-response';
import {EmbedPointResponse} from '../../../../api/data/points/embed-point-response';
import {LinkType} from '../../../../api/data/points/enums/link-type.enum';
import {PointType} from '../../../../api/data/points/enums/point-type.enum';
import {PositionRequest} from '../../api/data/position-request';
import {PointService} from '../../api/services/point.service';
import {PointDetailsComponent} from '../point-details/point-details.component';

@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styleUrls: ['./point.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PointComponent implements OnInit, OnChanges, OnDestroy, Drag {

  @Input()
  public point: AbstractPointResponse;

  @Input()
  public icon: IconResponse;

  @Input()
  public x: number;

  @Input()
  public y: number;

  @Input()
  public scale: number;

  @Input()
  public isDrag: boolean;

  public isOffline: boolean;

  @Input()
  public projectId: string;

  @Input()
  public layerId: string;

  @Input()
  public editMode: boolean;

  @Output()
  public isCustomDragChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('pointElement', {static: true})
  public element: ElementRef;

  public isMoved: boolean;
  public title: string;
  public iconUrl: string;

  public startDrag: string;

  private _startX: number;
  private _startY: number;
  private _dragX: number;
  private _dragY: number;

  public constructor(private readonly _sanitizer: DomSanitizer, private readonly _dialog: MatDialog,
                     private readonly pointService: PointService, private readonly _offline: OfflineService,
                     private readonly _dragService: DragService, private readonly layersCommmunication: LayersCommunicationService) {
  }

  public ngOnInit(): void {
    if (!this.element) return;
    this._dragService.addDrag(this);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.icon) return;
    setTimeout(() => {
      this._offline.hasOfflineAccess(this.projectId).then((result: boolean) => {
        this.isOffline = result;
      });
    }, 0);
    if (changes.x || changes.y) this.isMoved = true;
    this.iconUrl = device_pixel_ratio() > 1 && this.icon.retinaIcon ? this.icon.retinaIcon : this.icon.icon;
  }

  public ngOnDestroy(): void {
    this._dragService.removeDrag(this);
  }

  public getStyle(): any {
    const x = this.x + (this.point.x + 20) * this.scale - 20;
    const y = this.y + (this.point.y + 20) * this.scale - 20;
    return this._sanitizer.bypassSecurityTrustStyle(`top: ${y}px; left: ${x}px`);
  }

  public onClick(): void {
    if (this.editMode) {
      if (this._startX !== this.point.x || this._startY !== this.point.y) return;
      this.editPoint();
      return;
    }
    if (!this.isMoved) this._dialog.open(PointDetailsComponent, ViewerConfig.getInteractiveDialogOptions({
      point: this.point,
      offline: this.isOffline,
      project: this.projectId
    }));
    this.isMoved = false;
  }

  private editPoint(): void {
    this.isCustomDragChange.emit(false);
    const params = {
      layerId: this.layerId,
      projectId: this.projectId,
      point: this.point,
      iconId: this.icon.id
    };
    let dialog;
    if (this.point.type === PointType.text) dialog = TextPointDialogComponent;
    else if (this.point.type === PointType.embed) {
      const embedPoint = this.point as EmbedPointResponse;
      params['type'] = embedPoint.linkType === LinkType.soundcloud ? [LinkType.soundcloud] : [LinkType.youtube, LinkType.vimeo];
      dialog = EmbedPointDialogComponent;
    } else dialog = ImagePointDialogComponent;
    this._dialog.open(dialog, ViewerConfig.getPointDialogSettings(params));
  }

  public onDrag(event: any, x?: number, y?: number, startInfo?: any): void {
    if (!this.editMode) return;
    this._dragX += this.layersCommmunication.getScaledDrag(x);
    this._dragY += this.layersCommmunication.getScaledDrag(y);
    this.layersCommmunication.setScaledDimension(this.point, this._dragX, this._dragY, this.element.nativeElement);
  }

  public onStartDrag(event: any): void {
    this._startX = this.point.x;
    this._startY = this.point.y;
    if (!this.editMode) return;
    this.startDrag = ' start-drag';
    this._dragX = this.point.x;
    this._dragY = this.point.y;
    this.isCustomDragChange.emit(true);
  }

  public onStopDrag(event: any): void {
    this.startDrag = '';
    if (this._startX === this.point.x && this._startY === this.point.y) return;
    const positionRequest = new PositionRequest();
    positionRequest.x = this.point.x;
    positionRequest.y = this.point.y;
    this.pointService.updatePointPosition(this.projectId, this.layerId, this.point.type, this.point.id, positionRequest).subscribe();
    this.isCustomDragChange.emit(false);
  }

}
