import {JsonProperty} from '@dhkatz/json-ts';
import {Size} from '../../../../../../globals/api/data/size';
import {LayersListConverter} from '../json/layers-list-converter';
import {AbstractLayerResponse} from './layer-response/layers/abstracts/abstract-layer-response';

export class LayerResponse {

  @JsonProperty({name: 'p', type: Size})
  private _part: Size;

  @JsonProperty({name: 's', type: Size})
  private _sizes: Size[];

  @JsonProperty({name: 'l', converter: LayersListConverter.converter, type: Object})
  private _layers: AbstractLayerResponse[];

  public constructor() {
    this._part = undefined;
    this._sizes = undefined;
    this._layers = undefined;
  }

  public get part(): Size {
    return this._part;
  }

  public get sizes(): Size[] {
    return this._sizes;
  }

  public get layers(): AbstractLayerResponse[] {
    return this._layers;
  }
}
