import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {PasswordUtil} from '../../../../../utils/password-util';
import {ChangePasswordRequest} from '../../../../api/data/change-password-request';
import {AuthorizationService} from '../../../../api/services/authorization.service';
import {AbstractAuthorizationComponent} from '../abstracts/abstract-authorization.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends AbstractAuthorizationComponent implements OnInit {

  public passwordField: FormControl;
  public confirmPasswordField: FormControl;
  public hide = true;
  public token: string;
  public showConfirm: boolean;

  private _form: FormGroup;

  public constructor(private readonly _service: AuthorizationService, private readonly _route: ActivatedRoute) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => this._route.queryParams.subscribe((params) => this.token = params['tok']), 0);
  }

  protected createFormControls(): void {
    this.passwordField = new FormControl('', [Validators.required,
      PasswordUtil.passwordStrength(1, 1, 1, 0, 6)]);
    this.confirmPasswordField = new FormControl();
  }

  protected createForm(): void {
    this._form = new FormGroup({
      password: this.passwordField,
      confirmPassword: this.confirmPasswordField
    }, {
      validators: PasswordUtil.mustMatch('password', 'confirmPassword')
    });
  }

  public onSubmit(): void {
    if (!this._form.valid) return;
    this.changeShowSpinner(true);
    const request = new ChangePasswordRequest();
    request.password = this.passwordField.value;
    request.token = this.token;
    this._service.changePassword(request).subscribe(() => {
      this.changeShowSpinner(false);
      this.showConfirm = true;
    }, (error: HttpErrorResponse) => {
      this.showSpinner = false;
      switch (error.error.key) {
        case 'weak_password':
          this.passwordField.setErrors({weakPassword: true});
          break;
        default:
          this.error.emit(error.error);
          break;
      }
    }, () => this.changeShowSpinner(false));
  }

  public generatePassword(): void {
    const password = PasswordUtil.generatePassword(1, 1, 1, 1, 6, 15);
    this.passwordField.setValue(password);
    this.confirmPasswordField.setValue(password);
  }
}
