import {Injectable} from '@angular/core';
import {deserialize} from '@dhkatz/json-ts';
import {Subscriber} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {OfflineUtil} from '../../../../utils/offline-util';
import {VersionResponse} from '../data/version-response';
import {VersionEndpoints} from '../version-endpoints';
import {deserializeFix} from '../../../../utils/deserialize-util';

@Injectable({
  providedIn: 'root'
})
export class VersionOfflineService {

  public constructor() {
  }

  public getVersion(projectId: string): Observable<VersionResponse> {
    const path = VersionEndpoints.VERSION.replace(VersionEndpoints.PROJECT_ID, projectId);
    return new Observable<VersionResponse>((subscriber: Subscriber<VersionResponse>) =>
      OfflineUtil.loadText(projectId, path, (file: string) => {
        subscriber.next(deserializeFix(VersionResponse, JSON.parse(file)));
      }));
  }
}
