import {JsonProperty} from '@dhkatz/json-ts';
import {RectSize} from '../../../../../../globals/elements/rect-size';

export class ScaledRasterElement extends RectSize {

  @JsonProperty('d')
  private _data: string;

  public constructor() {
    super();
    this._data = undefined;
  }

  public get data(): string {
    return this._data;
  }

  public set data(value: string) {
    this._data = value;
  }

  public clone(): ScaledRasterElement {
    const clone = new ScaledRasterElement();
    clone.setRectSize(this.x, this.y, this.width, this.height);
    clone.data = this._data;
    return clone;
  }
}
