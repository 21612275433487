import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {TitleItem} from '../../../../../globals/api/data/title-item';
import {OnElementScroll} from '../../../../../globals/interfaces/on-element-scroll';
import {ListController} from '../../../../../utils/list-controller';
import {AccountService} from '../../../../api/service/account.service';

@Component({template: ''})
export abstract class AbstractManagementPartComponent<T extends TitleItem> implements OnInit, OnElementScroll {

  @ViewChild('scrollContent', {static: true})
  public activeScrollable: ElementRef;

  public list: T[];
  public showSpinner = true;

  public searchField = new FormControl('');

  protected listController: ListController;

  protected constructor(protected readonly service: AccountService) {
  }

  public ngOnInit(): void {
    this.list = [];
    this.listController = new ListController(this, ViewerConfig.LIST_SIZE, this.activeScrollable.nativeElement);
  }

  public abstract getNextPage(page: number): void;

  public search(): void {
    this.listController.resetPageCounter();
    this.list = [];
    this.getNextPage(1);
  }

  public clearSearch(): void {
    this.searchField.setValue('');
    this.search();
  }
}
