import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ViewerConfig} from '../../../../../configs/viewer-config';
import {TitleItem} from '../../../../../globals/api/data/title-item';
import {DialogsService} from '../../../../../globals/dialogs/dialogs.service';
import {OnElementScroll} from '../../../../../globals/interfaces/on-element-scroll';
import {SubscriptionAccessService} from '../../../../../subscriptions/services/subscription-access.service';
import {AccountService} from '../../../../api/service/account.service';
import {AbstractManagementPartComponent} from '../abstracts/abstract-management-part-component';

@Component({
  selector: 'app-user-student',
  templateUrl: './user-student.component.html',
  styleUrls: ['./user-student.component.scss']
})
export class UserStudentComponent extends AbstractManagementPartComponent<TitleItem> implements OnInit, OnElementScroll {

  public readonly displayedColumns: string[] = ['title', 'actions'];
  public codeField = new FormControl('');

  public constructor(service: AccountService, private readonly _dialogs: DialogsService, private readonly _snackBar: MatSnackBar,
                     private readonly _translate: TranslateService, private readonly _accessService: SubscriptionAccessService) {
    super(service);
  }

  public getNextPage(page: number): void {
    this.showSpinner = true;
    this.service.getStudentInstitutions(page).subscribe((response: TitleItem[]) =>
        this.list = this.listController.getUpdatedList(page, response, this.list, TitleItem),
      () => this.showSpinner = false, () => this.showSpinner = false);
  }

  public addToInstitution(): void {
    if (!this.codeField.value || this.codeField.invalid) return;
    this.showSpinner = true;
    this.service.addStudentToInstitution(this.codeField.value).subscribe((response: TitleItem) => {
      const institution = response;
      if (!institution) return;
      this.list.push(institution);
      this.codeField.reset();
      this._accessService.clearAndReloadSubscriptionAccess();
      ViewerConfig.openSnackBar(this._snackBar, this._translate
        .instant('assignmentManagement.student.assignedAsAStudent.assignToast', {name: institution.title}));
    }, (error: HttpErrorResponse) => {
      switch (error.error.key) {
        case 'institution_not_found':
          this.codeField.setErrors({code: true});
          break;
        case 'user_already_in_institution':
          this.codeField.setErrors({institution: true});
          break;
      }
      this.showSpinner = false;
    }, () => this.showSpinner = false);
  }

  public removeFromInstitution(index: number): void {
    const institution = this.list[index];
    this._dialogs.showAlert(this._translate.instant('assignmentManagement.student.assignedAsAStudent.removeAlert.title'),
      this._translate.instant('assignmentManagement.student.assignedAsAStudent.removeAlert.description',
        {name: institution.title}))
      .subscribe((decision: boolean) => {
        if (!decision) return;
        this.showSpinner = true;
        this.service.removeInstitutionFromStudent(institution.id).subscribe(() => {
          this.list.splice(index, 1);
          this.list = [...this.list];
          this._accessService.clearAndReloadSubscriptionAccess();
          ViewerConfig.openSnackBar(this._snackBar, this._translate
            .instant('assignmentManagement.student.assignedAsAStudent.removeToast', {name: institution.title}));
        }, () => this.showSpinner = false, () => this.showSpinner = false);
      });
  }
}
