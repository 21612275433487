import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {CacheService} from '../../cache/api/services/cache.service';
import {AbstractRoleComponent} from '../../globals/screens/abstracts/abstract-role.component';
import {SecurityWorkerLogin} from '../../web-workers/service/interfaces/security-worker-login';
import {SecurityWorkerLogout} from '../../web-workers/service/interfaces/security-worker-logout';
import {ServiceWorkerCommunicationService} from '../../web-workers/service/service-worker-communication.service';
import {ServiceWorkerService} from '../../web-workers/service/service-worker.service';
import {SubscriptionProjectResponse} from '../api/data/subscription-project-response';
import {SubscriptionProjectsResponse} from '../api/data/subscription-projects-response';
import {SubscriptionService} from '../api/service/subscription.service';
import {SubscriptionLoaded} from './interfaces/subscription-loaded';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionAccessService extends AbstractRoleComponent implements OnDestroy, SecurityWorkerLogin, SecurityWorkerLogout {

  private readonly _subscriptionLoadedListeners: SubscriptionLoaded[] = [];
  private _login: boolean;
  private _projects: SubscriptionProjectsResponse;

  public constructor(private readonly _workerCommunication: ServiceWorkerCommunicationService, private readonly _ngZone: NgZone,
                     private readonly _service: SubscriptionService, private readonly _cacheService: CacheService,
                     serviceWorker: ServiceWorkerService) {
    super(serviceWorker);
    this._workerCommunication.addSecurityWorkerLoginListener(this);
    this._workerCommunication.addSecurityWorkerLogoutListener(this);
    if (serviceWorker.isLoggedIn) this.onSecurityWorkerLogin();
  }

  public addSubscriptionLoadedListener(listener: SubscriptionLoaded): void {
    this._subscriptionLoadedListeners.push(listener);
  }

  public removeSubscriptionLoadedListener(listener: SubscriptionLoaded): void {
    const index: number = this._subscriptionLoadedListeners.indexOf(listener);
    if (index === -1) return;
    this._subscriptionLoadedListeners.splice(index, 1);
  }

  private callSubscriptionLoaded(): void {
    if (this._subscriptionLoadedListeners.length === 0) return;
    this._ngZone.run(() => {
      this._subscriptionLoadedListeners.filter((listener: SubscriptionLoaded) => !!listener)
        .forEach((listener: SubscriptionLoaded) => listener.onSubscriptionLoaded());
    });
  }

  public ngOnDestroy(): void {
    this._workerCommunication.removeSecurityWorkerLoginListener(this);
    this._workerCommunication.removeSecurityWorkerLogoutListener(this);
  }

  public onSecurityWorkerLogin(): void {
    this._login = true;
    if (this.isCreatorOrHigher) {
      this.callSubscriptionLoaded();
      return;
    }
    this.reloadSubscriptionAccess();
  }

  public clearAndReloadSubscriptionAccess(): void {
    this._cacheService.getCacheDates().subscribe(() => setTimeout(() => this.reloadSubscriptionAccess(), 500));
  }

  private reloadSubscriptionAccess(): void {
    if (!this._login) return;
    this._service.getSubscriptionProjects()
      .subscribe((response: SubscriptionProjectsResponse) => {
        this._projects = response;
        this.callSubscriptionLoaded();
      }, () => {
      });
  }

  public onSecurityWorkerLogout(): void {
    this._login = false;
    this._projects = undefined;
  }

  public haveAccess(projectId: string): boolean {
    return this.isCreatorOrHigher || !!this.getUser(projectId).length || !!this.getStudent(projectId).length ||
      !!this.getTeacher(projectId).length;
  }

  public haveStudentAccess(projectId: string): boolean {
    return this.isCreatorOrHigher || !!this.getStudent(projectId).length;
  }

  public haveTeacherAccess(projectId: string): boolean {
    return this.isCreatorOrHigher || !!this.getTeacher(projectId).length;
  }

  private getUser(projectId: string): SubscriptionProjectResponse[] {
    if (!this._login || !this._projects || !this._projects.user) return [];
    return this._projects.user.filter((value: SubscriptionProjectResponse) => this.haveProject(value, projectId));
  }

  private getStudent(projectId: string): SubscriptionProjectResponse[] {
    if (!this._login || !this._projects || !this._projects.student) return [];
    return this._projects.student.filter((value: SubscriptionProjectResponse) => this.haveProject(value, projectId));
  }

  private getTeacher(projectId: string): SubscriptionProjectResponse[] {
    if (!this._login || !this._projects || !this._projects.teacher) return [];
    return this._projects.teacher.filter((value: SubscriptionProjectResponse) => this.haveProject(value, projectId));
  }

  private haveProject(subscription: SubscriptionProjectResponse, projectId: string): boolean {
    const now = new Date();
    return (subscription.projectId === projectId || subscription.projectId === 'FULL_ACCESS') &&
      ((subscription.normal && subscription.normal > now) || (subscription.demo && subscription.demo > now));
  }

  public isDemo(projectId: string): boolean {
    if (this.isCreatorOrHigher) return false;
    const now = new Date();
    const subscriptions = this.getUser(projectId);
    subscriptions.push(...this.getStudent(projectId));
    subscriptions.push(...this.getTeacher(projectId));
    return !subscriptions.some((value: SubscriptionProjectResponse) => value.normal && value.normal > now);
  }

  public getProjectValidityDate(projectId: string): Date {
    if (this.isCreatorOrHigher) return undefined;
    const subscriptions = this.getUser(projectId);
    subscriptions.push(...this.getStudent(projectId));
    subscriptions.push(...this.getTeacher(projectId));
    const validityDate = subscriptions.map((subscription) => subscription.normal)
      .sort((date1, date2) => (date1.getTime() - date2.getTime()))[0];
    if (validityDate) return validityDate;
    return subscriptions.map((subscription) => subscription.demo)
      .sort((date1, date2) => (date1.getTime() - date2.getTime()))[0];
  }
}
