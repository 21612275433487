import {Size} from '../../../../../globals/api/data/size';
import {IdbVectorCacheUtil} from '../../../../../utils/idb-cache-util';
import {CacheRequest} from '../../../../../utils/models/cache-request';
import {VectorLayerResponse} from '../api/data/layer-response/layers/vector-layer-response';
import {AbstractCacheConstructor} from './abstracts/abstract-cache-constructor';
import {VectorDrawer} from './drawers/vector-drawer';
import {PrepareComplete} from './interfaces/prepare-complete';

export class CacheConstructor extends AbstractCacheConstructor {

  private _drawer: VectorDrawer;

  public constructor(cacheRequest: CacheRequest, data: VectorLayerResponse, levelSizes: Size[], partSize: Size,
                     private readonly _complete: PrepareComplete, offline: boolean) {
    super(cacheRequest, data, levelSizes, partSize, offline);
    this.startCache(data);
  }

  public asyncClearCanvas(): void {
    if (!this.pool.drawing) this.syncClearCanvas();
  }

  public syncClearCanvas(): void {
    if (this.prepareCacheData || !this.canvas) return;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.pool.clearCanvas = false;
  }

  public render(element: any): void {
    if (!this.prepareCacheData) setTimeout(() => {
      if (!this.canvas) return;
      this._drawer.draw(element.data, element.scaleRect);
      this._complete.drawComplete();
      this.pool.drawComplete();
    }, 0);
  }

  protected setCanvas(data: VectorLayerResponse): void {
    this.canvas = document.createElement('canvas');
    super.setCanvas();
    this._drawer = new VectorDrawer(this.ctx);
    this.setLevels(data);
  }

  public destroy(): void {
    if (!this.canvas) return;
    this.canvas.remove();
    this.canvas = undefined;
    this.ctx = undefined;
  }

  protected saveCache(cacheRequest: CacheRequest): void {
    if (!this.canvas) return;
    if (HTMLCanvasElement.prototype.toBlob) this.canvas.toBlob((blob: Blob) => IdbVectorCacheUtil.cache(cacheRequest).set(blob).then(() => {
      this.cacheComplete();
      this.cacheSaveComplete();
    }), 'image/png');
    else setTimeout(() => {
      const binStr = atob(this.canvas.toDataURL('image/png').split(',')[1]);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      const blob = new Blob([arr], {type: 'image/png'});
      IdbVectorCacheUtil.cache(cacheRequest).set(blob).then(() => {
        this.cacheComplete();
        this.cacheSaveComplete();
      });
    }, 0);
  }

  protected resize(width: number, height: number): void {
    if (!this.canvas) return;
    this.canvas.width = width;
    this.canvas.height = height;
  }

  protected prepareCacheComplete(): void {
    this.drawCache();
  }

  protected fullCacheComplete(): void {
    this._complete.cacheSaveComplete(this);
  }

  protected elementCacheComplete(): void {
    this._complete.cacheComplete();
  }

  protected cacheComplete(): void {
    this._complete.cacheComplete();
    super.cacheComplete();
  }

  public elementLoadComplete(): void {
    super.elementLoadComplete();
    this._complete.loadedComplete();
  }

  protected cacheCalcComplete(elements: number): void {
    this._complete.cacheCalcComplete(elements);
  }
}
