<div class="window bibliography" #window [@fadeAnimation]="windowState">
  <app-window-header [headerTitle]="'project.bibliography.title' | translate" [(close)]="showWindow"
                     [(show)]="showContent" [drag]="this"></app-window-header>
  <div class="content" [@slideAnimation]="contentState" (@slideAnimation.done)="updateDivParams()">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let entry of bibliography" (afterCollapse)="updateDivParams()"
                           (afterExpand)="updateDivParams()">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <mat-panel-title [innerHTML]="entry.title | target"></mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHTML]="entry.description | target"></div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
