import {RectSize} from '../../../../../../globals/elements/rect-size';

export class RasterDrawer {

  public constructor(private readonly _ctx: CanvasRenderingContext2D) {
  }

  public draw(data: RectSize, raster: ImageBitmap | HTMLImageElement | HTMLCanvasElement, scaleRect: RectSize, clear?: boolean): void {
    const x = data.x * scaleRect.width + scaleRect.x;
    const y = data.y * scaleRect.height + scaleRect.y;
    const width = data.width * scaleRect.width;
    const height = data.height * scaleRect.height;
    try {
      if (clear) this._ctx.clearRect(x, y, width, height);
      this._ctx.drawImage(raster, x, y, width, height);
    } catch (e) {
      console.log(e);
    }
  }
}
