import {JsonProperty} from '@dhkatz/json-ts';
import {LayerManagerResponse} from '../../../layer-manager/api/data/layer-manager-response';

export class LegendResponse extends LayerManagerResponse {

  @JsonProperty('ic')
  private _icon: string;

  @JsonProperty('r')
  private _retinaIcon: string;

  public constructor() {
    super();
    this._icon = undefined;
    this._retinaIcon = undefined;
  }

  public get icon(): string {
    return this._icon;
  }

  public get retinaIcon(): string {
    return this._retinaIcon;
  }
}
