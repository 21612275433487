import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ServiceWorkerService} from '../../../web-workers/service/service-worker.service';
import {JWT} from '../../../web-workers/workers/service-worker/interceptors/security-interceptor/jwt/jwt';
import {AuthorizationService} from '../../api/services/authorization.service';
import {Role} from '../../api/data/enums/role.enum';

@Component({
  selector: 'app-authorization-screen',
  templateUrl: './authorization-screen.component.html',
  styleUrls: ['./authorization-screen.component.scss']
})
export class AuthorizationScreenComponent implements OnInit {

  private static readonly KEY = 'DB%e3clGi16^B862J8wBsYIB2syngI&&Gvvgp81qi!oNlWYb6hu*gOF13qT6GrwDF%EG5G2cIFeW8liINvm@pkDaQ9epluwSMBel';

  public email = '';
  public role: Role;
  public ready: boolean;
  public changeUrl: boolean;

  public constructor(private readonly _service: AuthorizationService, private readonly _route: ActivatedRoute,
                     private readonly _serviceWorker: ServiceWorkerService) {
  }

  public ngOnInit(): void {
    this._serviceWorker.logout();
    setTimeout(() => this._route.queryParams.subscribe((params) => {
      const token = params['tok'];
      let payload;
      try {
        payload = JWT.getPayload(token, AuthorizationScreenComponent.KEY);
        if (!payload) throw new Error('Token error');
      } catch {
        this.changeUrl = true;
        this.ready = true;
        return;
      }
      this.email = payload.subject;
      if (payload.id) this.role = Role[payload.id as keyof typeof Role];
      this.ready = true;
    }), 0);
  }
}
