import {JsonProperty} from '@dhkatz/json-ts';
import {ArrayNumberConverter} from '../../../../../../../../../globals/api/json/array-number-converter';

export abstract class AbstractLevelResponse {

  @JsonProperty({name: 'l', converter: ArrayNumberConverter.converter, type: Object})
  private _levels: number[];

  protected constructor() {
    this._levels = undefined;
  }

  public get levels(): number[] {
    return this._levels;
  }
}
