<div class="system-dialog">
  <header>{{data?.title}}</header>
  <div class="system-dialog-body">
    <p>{{data?.message}}</p>
    <div class="buttons">
      <button class="cancel-button" mat-dialog-close>{{'system.label.no' | translate}}</button>
      <button class="submit-button" (click)="accept()">{{'system.label.yes' | translate}}</button>
    </div>
  </div>
</div>
