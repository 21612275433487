import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CategoryResponse} from '../../../category/api/data/category-response';
import {CategoryService} from '../../../category/api/services/category.service';
import {RoutingConfig} from '../../../configs/routing-config';
import {Rwd} from '../../../globals/services/enums/rwd.enum';
import {RwdService} from '../../../globals/services/rwd.service';
import {ProjectResponse} from '../../api/data/project-response';
import {ProjectService} from '../../api/services/project.service';
import {AbstractProjectsComponent} from './abstracts/abstract-projects.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent extends AbstractProjectsComponent implements OnInit {

  public categoryId: string;
  public categories: CategoryResponse[];
  public projectsGroups: ProjectResponse[][] = [];
  public showSpinner = true;

  private _projects: ProjectResponse[];

  public constructor(private readonly _categoryService: CategoryService, private readonly _projectService: ProjectService,
                     private readonly _route: ActivatedRoute, rwd: RwdService) {
    super(rwd);
  }

  public ngOnInit(): void {
    this._route.paramMap.subscribe((value: any) => this.init(value.params));
  }

  private init(params: any): void {
    this.categoryId = undefined;
    this.categories = undefined;
    this.projectsGroups = [];
    this._projects = undefined;
    setTimeout(() => {
      this.categoryId = params[RoutingConfig.CATEGORY_ID];
      this._categoryService.getCategories(this.categoryId).subscribe((response: CategoryResponse[]) => {
        if (!response || !response.length) return;
        if (response.length > 1 || response[0].id !== this.categoryId) this.categories = response;
        else this.getProjects();
      }, () => this.showSpinner = false, () => this.showSpinner = false);
    }, 0);
  }

  private getProjects(): void {
    this._projectService.getDirectCategoryProjects(this.categoryId).subscribe((response: ProjectResponse[]) => {
      this._projects = response;
      this.defineProjectsGroups();
    });
  }

  private defineProjectsGroups(startIndex?: number): void {
    if (!startIndex) startIndex = 0;
    let endIndex = startIndex + this.carriageSize;
    if (endIndex > this._projects.length) endIndex = this._projects.length;

    const projects: ProjectResponse[] = [];
    for (let i = startIndex; i < endIndex; i++) {
      projects.push(this._projects[i]);
    }
    this.projectsGroups.push(projects);

    if (endIndex < this._projects.length) this.defineProjectsGroups(endIndex);
  }

  public onRwdChange(screen: Rwd): void {
    super.onRwdChange(screen);
    if (!this._projects || this.categories) return;
    this.projectsGroups = [];
    this.defineProjectsGroups();
  }
}
