import {JsonProperty} from '@dhkatz/json-ts';
import {AbstractCustomPointRequest} from './abstracts/abstract-custom-point-request';

export class ImagePointRequest extends AbstractCustomPointRequest {

  @JsonProperty('file')
  private _file: string;

  public constructor() {
    super();
    this._file = undefined;
  }

  public get file(): string {
    return this._file;
  }

  public set file(value: string) {
    this._file = value;
  }
}
