import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CustomTaskResponse} from '../../../../api/data/custom-task-response';
import {TasksSettingsScreen} from './enums/tasks-settings-screen.enum';

@Component({
  selector: 'app-tasks-settings',
  templateUrl: './tasks-settings.component.html',
  styleUrls: ['./tasks-settings.component.scss']
})
export class TasksSettingsComponent {

  public tasks: CustomTaskResponse[];
  public projectId: string;
  public init: boolean;
  public isOffline: boolean;
  public screen = TasksSettingsScreen.List;
  public screens = TasksSettingsScreen;
  public selected: CustomTaskResponse;

  public constructor(private readonly _dialogRef: MatDialogRef<TasksSettingsComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this._dialogRef.afterOpened().subscribe(() => this.init = true);
    this._dialogRef.backdropClick().subscribe(() => {
      if (this.init) this._dialogRef.close(this.tasks);
    });
    if (!data) return;
    this.tasks = [];
    if (data.tasks) data.tasks.forEach((task: CustomTaskResponse) => this.tasks.push(Object.create(task)));
    this.projectId = data.projectId;
    this.isOffline = data.isOffline;
  }

  @HostListener('window:keydown.esc')
  public onEscKeyDown(): void {
    if (this.init) this._dialogRef.close(this.tasks);
  }

  public add(): void {
    this.selected = undefined;
    this.screen = TasksSettingsScreen.Edit;
  }

  public update(task: CustomTaskResponse): void {
    this.selected = task;
    this.screen = TasksSettingsScreen.Edit;
  }

  public remove(task: CustomTaskResponse): void {
    this.selected = task;
    this.screen = TasksSettingsScreen.Remove;
  }
}
