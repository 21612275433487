import {JsonProperty} from '@dhkatz/json-ts';
import {AddressData} from './address-data';

export class AccountResponse {

  @JsonProperty('email')
  private _email: string;

  @JsonProperty('institutions')
  private _institutions: string;

  @JsonProperty({name: 'addressData', type: AddressData})
  private _addressData: AddressData;

  public constructor() {
    this._email = undefined;
    this._addressData = undefined;
    this._institutions = undefined;
  }

  public get email(): string {
    return this._email;
  }

  public get addressData(): AddressData {
    return this._addressData;
  }

  public get institutions(): string {
    return this._institutions;
  }
}
