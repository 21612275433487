import {Size} from '../../../../../../globals/api/data/size';
import {PartResponse} from '../../api/data/layer-response/parts/part-response';

export class PartsUtil {

  public static readonly FIX = 0.5;

  public static setParts(levelSize: Size, partSize: Size, iterator: any): void {
    const cols = Math.ceil(levelSize.width / partSize.width);
    const rows = Math.ceil(levelSize.height / partSize.height);
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        iterator(col, row);
      }
    }
  }

  public static setPartsWithSize(levelSize: Size, partSize: Size, iterator: any): void {
    const cols = Math.ceil(levelSize.width / partSize.width);
    const rows = Math.ceil(levelSize.height / partSize.height);
    for (let row = 0; row < rows; row++) {
      const height = PartsUtil.getPartSize(row, rows, partSize.height, levelSize.height);
      for (let col = 0; col < cols; col++) {
        const width = PartsUtil.getPartSize(col, cols, partSize.width, levelSize.width);
        iterator(col, row, width, height);
      }
    }
  }

  public static setPartsFromMap<T>(partsData: Map<string, T>, levelSize: Size, partSize: Size, iterator: any): void {
    for (const entry of Array.from(partsData.entries())) {
      const key = entry[0].split('_');
      const col = +key[0];
      const row = +key[1];
      iterator(col, row, entry[1]);
    }
  }

  public static setPartsFromMapWithSize<T>(partsData: Map<string, T>, levelSize: Size, partSize: Size, iterator: any): void {
    const cols = Math.ceil(levelSize.width / partSize.width);
    const rows = Math.ceil(levelSize.height / partSize.height);
    for (const entry of Array.from(partsData.entries())) {
      const key = entry[0].split('_');
      const col = +key[0];
      const row = +key[1];
      const width = PartsUtil.getPartSize(col, cols, partSize.width, levelSize.width);
      const height = PartsUtil.getPartSize(row, rows, partSize.height, levelSize.height);
      iterator(col, row, entry[1], width, height);
    }
  }

  public static setPartsFromData(partsData: PartResponse[], levelSize: Size, partSize: Size, iterator: any): void {
    const cols = Math.ceil(levelSize.width / partSize.width);
    const rows = Math.ceil(levelSize.height / partSize.height);
    partsData.forEach((part: PartResponse) => {
      const width = PartsUtil.getPartSize(part.col, cols, partSize.width, levelSize.width);
      const height = PartsUtil.getPartSize(part.row, rows, partSize.height, levelSize.height);
      iterator(part.col, part.row, part.data, width, height);
    });
  }

  public static getPartSize(no: number, count: number, partSize: number, levelSize: number): number {
    return no < count - 1 ? partSize : levelSize - no * partSize;
  }
}
