import {deserialize, ICustomConverter} from '@dhkatz/json-ts';
import {AbstractPointResponse} from '../data/points/abstracts/abstract-point-response';
import {EmbedPointResponse} from '../data/points/embed-point-response';
import {PointType} from '../data/points/enums/point-type.enum';
import {ImagePointResponse} from '../data/points/image-point-response';
import {MoviePointResponse} from '../data/points/movie-point-response';
import {SoundPointResponse} from '../data/points/sound-point-response';
import {TextPointResponse} from '../data/points/text-point-response';
import {ChartPointResponse} from '../data/points/chart-point-response';
import {deserializeFix} from '../../../../../../utils/deserialize-util';

export class PointsListConverter implements ICustomConverter<AbstractPointResponse[]> {

  public static converter = new PointsListConverter();

  public fromJson(data: any[]): AbstractPointResponse[] {
    if (data === undefined || data.length === 0) return undefined;
    const points: AbstractPointResponse[] = [];
    for (const point of data) {
      const type: PointType = (<any>PointType)[point['ty']];
      switch (type) {
        case PointType.embed:
          points.push(deserializeFix(EmbedPointResponse, point));
          break;
        case PointType.image:
          points.push(deserializeFix(ImagePointResponse, point));
          break;
        case PointType.movie:
          points.push(deserializeFix(MoviePointResponse, point));
          break;
        case PointType.sound:
          points.push(deserializeFix(SoundPointResponse, point));
          break;
        case PointType.text:
          points.push(deserializeFix(TextPointResponse, point));
          break;
        case PointType.chart:
          points.push(deserializeFix(ChartPointResponse, point));
          break;
      }
    }
    return points;
  }

  public toJson(data: AbstractPointResponse[]): AbstractPointResponse[] {
    return data;
  }
}
