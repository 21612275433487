import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {deserialize} from '@dhkatz/json-ts';
import {ViewerConfig} from '../../../../../../../../../configs/viewer-config';
import {InteractiveLayerResponse} from '../../../../../../interactive/api/data/interactive-layer-response';
import {CustomInteractiveRequest} from '../../../api/data/custom-interactive-request';
import {CustomInteractiveService} from '../../../api/services/custom-interactive-service';
import {CustomInteractivesCommunicationService} from '../../../communiaction/custom-interactives-communication.service';
import {InteractiveSettingsDialogComponent} from '../interactive-settings-dialog.component';
import {deserializeFix} from '../../../../../../../../../utils/deserialize-util';

@Component({
  selector: 'app-interactive-edit',
  templateUrl: './interactive-edit.component.html',
  styleUrls: ['./interactive-edit.component.scss']
})
export class InteractiveEditComponent implements OnChanges {
  @Input()
  public cInteractiveLayer: InteractiveLayerResponse;

  @Input()
  public cInteractiveLayers: InteractiveLayerResponse[];

  @Output()
  public InteractiveLayerChange = new EventEmitter<InteractiveLayerResponse[]>();

  @Input()
  public screen: InteractiveSettingsDialogComponent;

  @Input()
  public projectId: string;

  @Output()
  public close = new EventEmitter<void>();

  public titleConfig: any;
  public titleField: FormControl;
  public enabledField: FormControl;

  private _form: FormGroup;

  public constructor(private readonly _service: CustomInteractiveService,
                     private readonly _communication: CustomInteractivesCommunicationService) {
    this.titleConfig = ViewerConfig.wysiwygBasicConfig;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.titleField = new FormControl(this.cInteractiveLayer ? this.cInteractiveLayer.title : '', Validators.required);
    this.enabledField = new FormControl(this.cInteractiveLayer ? this.cInteractiveLayer.enabled : true);

    this._form = new FormGroup({
      title: this.titleField,
      enabled: this.enabledField
    });
  }

  public onSubmit(): void {
    if (!this._form.valid) return;
    const request = deserializeFix(CustomInteractiveRequest, this._form.value);
    const requestMethod = this.cInteractiveLayer
      ? this._service.updateLayer(this.projectId, this.cInteractiveLayer.id, request)
      : this._service.addLayer(this.projectId, request);
    requestMethod.subscribe((response: string) => {
      if (!response) return;
      this._communication.callInteractiveCustomLayersChanged();
      this.close.emit();
    });
  }
}
