import {LayerType} from '../../../../../../globals/api/data/enums/layer-type.enum';
import {Size} from '../../../../../../globals/api/data/size';
import {RectSize} from '../../../../../../globals/elements/rect-size';
import {CacheRequest} from '../../../../../../utils/models/cache-request';
import {LayerResponse} from '../../api/data/layer-response';
import {AbstractLayerResponse} from '../../api/data/layer-response/layers/abstracts/abstract-layer-response';
import {CombinedLayerResponse} from '../../api/data/layer-response/layers/combined-layer-response';
import {RasterLayerResponse} from '../../api/data/layer-response/layers/raster-layer-response';
import {VectorLayerResponse} from '../../api/data/layer-response/layers/vector-layer-response';
import {AbstractLevelResponse} from '../../api/data/layer-response/levels/abstracts/abstract-level-response';
import {PartResponse} from '../../api/data/layer-response/parts/part-response';
import {AbstractPreRenderData} from '../data/abstracts/abstract-pre-render-data';
import {CombinedPreRenderData} from '../data/combined-pre-render-data';
import {PreRenderType} from '../data/enums/pre-render-type.enum';
import {CombinedPreRenderDataPart} from '../data/parts/combined-pre-render-data-part';
import {ScaledCombinedPreRenderDataPart} from '../data/parts/scaled-combined-pre-render-data-part';
import {RasterPreRenderData} from '../data/raster-pre-render-data';
import {ScaledCombinedPreRenderData} from '../data/scaled-combined-pre-render-data';
import {ScaledRasterPreRenderData} from '../data/scaled-raster-pre-render-data';
import {VectorPreRenderData} from '../data/vector-pre-render-data';
import {PoolDraw} from '../drawers/interfaces/pool-draw';
import {PreRenderedDrawPool} from '../drawers/interfaces/pre-rendered-draw-pool';
import {PoolDrawer} from '../drawers/pool-drawer';
import {RasterDrawer} from '../drawers/raster-drawer';
import {ScaledRasterElement} from '../elements/scaled-raster-element';
import {PreRenderLevel} from '../levels/interfaces/pre-render-level';
import {PartsUtil} from '../utils/parts-util';

export abstract class AbstractPreRenderConstructor<PreRender extends PreRenderLevel> implements PreRenderedDrawPool, PoolDraw {

  public readonly levelSizes: Size[];
  public readonly partSize: Size;
  public readonly ctx: CanvasRenderingContext2D;
  public ready: boolean;

  protected levels: PreRender[] = [];

  private readonly _drawer: RasterDrawer;
  private readonly _pool: PoolDrawer;
  private _viewRect: RectSize;

  protected constructor(data: LayerResponse, cacheRequest: CacheRequest, public readonly canvas: HTMLCanvasElement) {
    this.levelSizes = data.sizes;
    this.partSize = data.part;
    if (!this.canvas) return;
    this.ctx = this.canvas.getContext('2d');
    if (!this.ctx) return;
    this.ctx.imageSmoothingEnabled = true;
    this.ctx.imageSmoothingQuality = 'high';
    this._drawer = new RasterDrawer(this.ctx);
    this._pool = new PoolDrawer(this);
  }

// DATA PREPARE <-------------------------------------------------------------------------------------------------------------> DATA PREPARE

  protected abstract setPreRenderLevels(layers: AbstractLayerResponse[], cacheRequest: CacheRequest, offline: boolean): void;

  protected getNextLevelSizes(index: number): Size[] {
    return index < this.levelSizes.length - 1 ? [this.levelSizes[index + 1]] : [];
  }

  protected getPreRenderLevelData(index: number, cacheRequest: CacheRequest,
                                  layers: AbstractLayerResponse[]): Map<string, AbstractPreRenderData[]> {
    cacheRequest = cacheRequest.clone().setLevel(index);
    const levelParts: Map<string, AbstractPreRenderData[]> = new Map();
    layers.forEach((layer: AbstractLayerResponse) => {
      switch (layer.type) {
        case LayerType.raster:
          this.getRasterPreRenderLevelData(layer as RasterLayerResponse, index, levelParts);
          break;
        case LayerType.combined:
          this.getCombinedPreRenderLevelData(layer as CombinedLayerResponse, index, levelParts);
          break;
        case LayerType.vector:
          this.getVectorPreRenderLevelData(layer as VectorLayerResponse, index, levelParts, cacheRequest);
          break;
      }
    });

    this.setPartsActive(levelParts);
    return levelParts;
  }

  private getRasterPreRenderLevelData(rasterLayer: RasterLayerResponse, index: number,
                                      levelParts: Map<string, AbstractPreRenderData[]>): void {
    const rasterLevel = this.getLevel(rasterLayer.details, index);
    if (!rasterLevel) return;
    if (rasterLevel.size === index) this.setRasterParts(rasterLevel.data, rasterLayer.index, rasterLayer.preLoading, levelParts);
    else this.setScaledRasterParts(rasterLevel.data, rasterLayer.index, rasterLayer.preLoading, this.levelSizes[rasterLevel.size],
      this.levelSizes[index], levelParts);
  }

  private getCombinedPreRenderLevelData(combinedLayer: CombinedLayerResponse, index: number,
                                        levelParts: Map<string, AbstractPreRenderData[]>): void {
    const combinedLevel = this.getLevel(combinedLayer.details, index);
    if (!combinedLevel) return;
    const layerIndexes = Array.from(combinedLayer.index.keys());
    if (combinedLevel.size === index) this.setCombinedParts(combinedLevel.data, layerIndexes, combinedLayer.index, levelParts);
    else this.setScaledCombinedParts(combinedLevel.data, layerIndexes, combinedLayer.index, this.levelSizes[combinedLevel.size],
      this.levelSizes[index], levelParts);
  }

  private getVectorPreRenderLevelData(vectorLayer: VectorLayerResponse, index: number,
                                      levelParts: Map<string, AbstractPreRenderData[]>, cacheRequest: CacheRequest): void {
    const vectorLevel = this.getLevel(vectorLayer.details, index);
    if (!vectorLevel) return;
    this.setVectorParts(cacheRequest, vectorLayer.index, this.levelSizes[index], levelParts);
  }

  private setPartsActive(levelParts: Map<string, AbstractPreRenderData[]>): void {
    Array.from(levelParts.values()).forEach(((parts: AbstractPreRenderData[]) =>
      parts.forEach((part: AbstractPreRenderData) => part.setDisabledLayers([]))));
  }

  private getLevel<T extends AbstractLevelResponse>(levels: T[], index: number): T {
    return levels.find((level: T) => level.levels.includes(index));
  }

  private setRasterParts(parts: PartResponse[], index: number, preLoading: boolean,
                         levelParts: Map<string, AbstractPreRenderData[]>): void {
    let count = 0;
    parts.forEach((part: PartResponse) => {
      const partsData = this.getPreRenderData(part.col, part.row, levelParts);
      const rasterData = new RasterPreRenderData();
      rasterData.type = PreRenderType.raster;
      rasterData.index = index;
      rasterData.preLoading = preLoading;
      rasterData.data = part.data;
      partsData.push(rasterData);
      count++;
    });
    if (preLoading) this.loadCalcComplete(count);
  }

  private setScaledRasterParts(parts: PartResponse[], index: number, preLoading: boolean, size: Size, levelSize: Size,
                               levelParts: Map<string, AbstractPreRenderData[]>): void {
    const scaledParts = this.getScaledParts(parts, size, levelSize);
    if (!Array.from(scaledParts.keys()).length) return;
    let count = 0;
    PartsUtil.setPartsFromMap(scaledParts, levelSize, this.partSize, (col: number, row: number, data: ScaledRasterElement[]) => {
      const partsData = this.getPreRenderData(col, row, levelParts);
      const rasterData = new ScaledRasterPreRenderData();
      rasterData.type = PreRenderType.scaledRaster;
      rasterData.index = index;
      rasterData.preLoading = preLoading;
      rasterData.data = data;
      partsData.push(rasterData);
      count += data.length;
    });
    if (preLoading) this.loadCalcComplete(count);
  }

  private getScaledParts(parts: PartResponse[], size: Size, levelSize: Size): Map<string, ScaledRasterElement[]> {
    const scaledRasterParts = this.getScaledRasterParts(parts, size, levelSize);
    const scaledParts: Map<string, ScaledRasterElement[]> = new Map();
    PartsUtil.setPartsWithSize(levelSize, this.partSize, (col: number, row: number, width: number, height: number) => {
      const partsIntPart = this.getScaledPartsInPart(scaledRasterParts, col, row, width, height);
      if (!partsIntPart.length) return;
      scaledParts.set(col + '_' + row, partsIntPart);
    });
    return scaledParts;
  }

  private getScaledPartsInPart(parts: ScaledRasterElement[], col: number, row: number, width: number,
                               height: number): ScaledRasterElement[] {
    const scaledParts: ScaledRasterElement[] = [];
    parts.forEach((part: ScaledRasterElement) => {
      if (!this.isInPart(part, col, row, width, height)) return;
      const scaledPart = part.clone();
      scaledPart.x -= col * this.partSize.width;
      if (scaledPart.x > 0) scaledPart.x -= PartsUtil.FIX;
      scaledPart.y -= row * this.partSize.height;
      if (scaledPart.y > 0) scaledPart.y -= PartsUtil.FIX;
      scaledParts.push(scaledPart);
    });
    return scaledParts;
  }

  public isInPart(part: ScaledRasterElement, col: number, row: number, width: number, height: number): boolean {
    const minX = part.x - col * this.partSize.width;
    const minY = part.y - row * this.partSize.height;
    const maxX = part.width + minX;
    const maxY = part.height + minY;
    return maxX > 0 && minX < width && maxY > 0 && minY < height;
  }

  private getScaledRasterParts(parts: PartResponse[], size: Size, levelSize: Size): ScaledRasterElement[] {
    const scaleX = levelSize.width / size.width;
    const scaleY = levelSize.height / size.height;
    const scaledParts: ScaledRasterElement[] = [];
    PartsUtil.setPartsFromData(parts, size, this.partSize,
      (col: number, row: number, data: string, width: number, height: number) => {
        const scaledPart = new ScaledRasterElement();
        scaledPart.data = data;
        scaledPart.x = col * this.partSize.width * scaleX;
        scaledPart.y = row * this.partSize.height * scaleY;
        scaledPart.width = width * scaleX;
        scaledPart.height = height * scaleY;
        scaledParts.push(scaledPart);
      });
    return scaledParts;
  }

  private setCombinedParts(parts: PartResponse[], layerIndexes: number[], index: Map<number, boolean>,
                           levelParts: Map<string, AbstractPreRenderData[]>): void {
    parts.forEach((part: PartResponse) => {
      const partsData = this.getPreRenderData(part.col, part.row, levelParts);
      const combinedData = this.getCombinedDataPart(layerIndexes, partsData);
      const combinedDataPart = new CombinedPreRenderDataPart();
      combinedDataPart.index = index;
      combinedDataPart.data = part.data;
      combinedData.push(combinedDataPart);
    });
  }

  private setScaledCombinedParts(parts: PartResponse[], layerIndexes: number[], index: Map<number, boolean>, size: Size, levelSize: Size,
                                 levelParts: Map<string, AbstractPreRenderData[]>): void {
    const scaledParts = this.getScaledParts(parts, size, levelSize);
    if (!Array.from(scaledParts.keys()).length) return;
    PartsUtil.setPartsFromMap(scaledParts, levelSize, this.partSize, (col: number, row: number, data: ScaledRasterElement[]) => {
      const partsData = this.getPreRenderData(col, row, levelParts);
      const combinedData = this.getScaledCombinedDataPart(layerIndexes, partsData);
      const combinedDataPart = new ScaledCombinedPreRenderDataPart();
      combinedDataPart.index = index;
      combinedDataPart.data = data;
      combinedData.push(combinedDataPart);
    });
  }

  private setVectorParts(cacheRequest: CacheRequest, index: number, levelSize: Size,
                         levelParts: Map<string, AbstractPreRenderData[]>): void {
    cacheRequest = cacheRequest.clone().setLayer(index);
    PartsUtil.setParts(levelSize, this.partSize, (col: number, row: number) => {
      const partsData = this.getPreRenderData(col, row, levelParts);
      const vectorData = new VectorPreRenderData();
      vectorData.type = PreRenderType.vector;
      vectorData.index = index;
      vectorData.data = cacheRequest.clone().setRow(row).setCol(col);
      partsData.push(vectorData);
    });
  }

  private getPreRenderData(col: number, row: number, levelParts: Map<string, AbstractPreRenderData[]>): AbstractPreRenderData[] {
    const key = col + '_' + row;
    let data: AbstractPreRenderData[] = levelParts.get(key);
    if (!data) {
      data = [];
      levelParts.set(key, data);
    }
    return data;
  }

  private getCombinedDataPart(index: number[], partsData: AbstractPreRenderData[]): CombinedPreRenderDataPart[] {
    const layerIndexes = JSON.stringify(index);
    let combinedDataPart = partsData.find((partData: AbstractPreRenderData) => partData.type === PreRenderType.combined &&
      JSON.stringify((partData as CombinedPreRenderData).index) === layerIndexes) as CombinedPreRenderData;
    if (combinedDataPart) return combinedDataPart.getData();
    combinedDataPart = new CombinedPreRenderData();
    combinedDataPart.index = index;
    combinedDataPart.type = PreRenderType.combined;
    combinedDataPart.setData([]);
    partsData.push(combinedDataPart);
    return combinedDataPart.getData();
  }

  private getScaledCombinedDataPart(index: number[], partsData: AbstractPreRenderData[]): ScaledCombinedPreRenderDataPart[] {
    const layerIndexes = JSON.stringify(index);
    let combinedDataPart = partsData.find((partData: AbstractPreRenderData) => partData.type === PreRenderType.scaledCombined &&
      JSON.stringify((partData as ScaledCombinedPreRenderData).index) === layerIndexes) as ScaledCombinedPreRenderData;
    if (combinedDataPart) return combinedDataPart.getData();
    combinedDataPart = new ScaledCombinedPreRenderData();
    combinedDataPart.index = index;
    combinedDataPart.type = PreRenderType.scaledCombined;
    combinedDataPart.setData([]);
    partsData.push(combinedDataPart);
    return combinedDataPart.getData();
  }

// DRAW <-----------------------------------------------------------------------------------------------------------------------------> DRAW

  public draw(viewRect?: RectSize): void {
    if (!viewRect && !this._viewRect) return;
    if (viewRect) this._viewRect = viewRect;
    if (!this.ready || !this.levels.length) return;
    this._pool.clearPool();
    this.levels.forEach((level: PreRender) => level.draw(this._viewRect));
    this._pool.drawPool();
  }

  public setDisabledLayers(layers: boolean[]): void {
    if (!this.levels.length) return;
    this.levels.forEach((level: PreRender) => level.setDisabledLayers(layers));
  }

  public destroy(): void {
    if (!this.levels.length) return;
    this.levels.forEach((level: PreRender) => level.destroy());
    this.levels = [];
  }

  public resize(width: number, height: number): void {
    if (!this.canvas) return;
    this.canvas.width = width;
    this.canvas.height = height;
  }

  public abstract elementLoadComplete(): void;

  public abstract loadCalcComplete(elements: number): void;

  public addToDraw(viewRect: RectSize, data: RectSize, scaleRect: RectSize,
                   raster: ImageBitmap | HTMLImageElement | HTMLCanvasElement, clear?: boolean): void {
    if (!this._viewRect.equal(viewRect)) return;
    this._pool.addToPool({data, raster, scaleRect, clear});
  }

  public asyncClearCanvas(): void {
    if (!this._pool.drawing) this.syncClearCanvas();
  }

  public render(element: any): void {
    this._drawer.draw(element.data, element.raster, element.scaleRect, element.clear);
    this._pool.drawComplete();
  }

  public syncClearCanvas(): void {
    if (!this.canvas) return;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this._pool.clearCanvas = false;
  }

  public reDraw(): void {
  }

  public poolDrawComplete(): void {
  }
}
