import {JsonProperty} from '@dhkatz/json-ts';
import {LayerType} from '../../../../../../../../../globals/api/data/enums/layer-type.enum';
import {EnumConverter} from '../../../../../../../../../globals/api/json/enum-converter';

export abstract class AbstractLayerResponse {

  @JsonProperty({name: 't', converter: EnumConverter.converter(LayerType)})
  private _type: LayerType;

  protected constructor() {
    this._type = undefined;
  }

  public get type(): LayerType {
    return this._type;
  }
}
