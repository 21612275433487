import {Size} from '../../../../../../globals/api/data/size';
import {RectSize} from '../../../../../../globals/elements/rect-size';
import {CacheStatus} from '../../../../../../utils/enums/cache-status.enum';
import {IdbVectorCacheUtil} from '../../../../../../utils/idb-cache-util';
import {CacheRequest} from '../../../../../../utils/models/cache-request';
import {VectorLayerResponse} from '../../api/data/layer-response/layers/vector-layer-response';
import {VectorLevelResponse} from '../../api/data/layer-response/levels/vector-level-response';
import {DrawElement} from '../../api/data/vector-file-response/abstracts/draw-element';
import {PoolDraw} from '../drawers/interfaces/pool-draw';
import {VectorDrawPool} from '../drawers/interfaces/vector-draw-pool';
import {PoolDrawer} from '../drawers/pool-drawer';
import {AbstractLevel} from '../levels/abstracts/abstract-level';
import {CacheLevel} from '../levels/cache-level';
import {PartsUtil} from '../utils/parts-util';

export abstract class AbstractCacheConstructor implements VectorDrawPool, PoolDraw {

  public ctx: CanvasRenderingContext2D;
  public canvas: HTMLCanvasElement;

  protected readonly pool: PoolDrawer = new PoolDrawer(this);
  protected readonly levelsRange: number[] = [];
  protected prepareCacheData = true;

  private readonly _cacheRequest: CacheRequest;
  private _cacheData: any[] = [];
  private _loaded = 0;

  protected levels: CacheLevel[] = [];
  protected viewRect: RectSize;

  protected constructor(cacheRequest: CacheRequest, data: VectorLayerResponse, public readonly levelSizes: Size[],
                        public readonly partSize: Size, private readonly _offline) {
    this._cacheRequest = cacheRequest.clone().setLayer(data.index);
    data.details.forEach((level: VectorLevelResponse) => this.levelsRange.push(...level.levels));

  }

  protected startCache(data: VectorLayerResponse): void {
    IdbVectorCacheUtil.setStatus(this._cacheRequest, CacheStatus.preparing).then(() => this.setCanvas(data));
  }

  protected setLevels(data: VectorLayerResponse): void {
    if (data === undefined || data.details === undefined || data.details.length === 0) return;
    data.details.forEach((detail: VectorLevelResponse) => {
      const sizes = [];
      const nextSizes = [];
      detail.levels.forEach((level, index) => {
        sizes[index] = this.levelSizes[level];
        if (level < this.levelSizes.length - 1) nextSizes[index] = this.levelSizes[level + 1];
      });
      this.levels.push(new CacheLevel(sizes, nextSizes, detail.data, this, this._offline));
    });
  }

  protected prepareCache(): void {
    this.levelsRange.forEach((level: number) => PartsUtil.setPartsWithSize(this.levelSizes[level], this.partSize,
      (col: number, row: number, width: number, height: number) =>
        this.setCachePart(level, col, row, new Size().setSize(width, height))));
    this.setCachePartData();
  }

  private setCachePart(level: number, col: number, row: number, size: Size): void {
    const viewRect = new RectSize();
    viewRect.width = this.levelSizes[level].width;
    viewRect.height = this.levelSizes[level].height;
    viewRect.x = -col * this.partSize.width;
    viewRect.y = -row * this.partSize.height;
    const request = this._cacheRequest.clone().setRow(row).setCol(col).setLevel(level);
    this._cacheData.push({request, size, viewRect});
  }

  private setCachePartData(index: number = 0): void {
    if (index >= this._cacheData.length) {
      if (!this._cacheData.length) {
        this.cacheSaveComplete();
        return;
      }
      this.pool.clear();
      this.prepareCacheData = false;
      this.cacheCalcComplete(this._cacheData.length +
        this._cacheData.map((part: any) => part.pool.length).reduce((a: number, b: number) => a + b));
      this.prepareCacheComplete();
      return;
    }
    const element = this._cacheData[index];
    IdbVectorCacheUtil.cache(element.request).get().then((blob: Blob) => {
      if (blob) this.removeCachePart(index);
      else this.checkCachePartData(element, index);
    }).catch(() => this.checkCachePartData(element, index));
  }

  private checkCachePartData(element: any, index: number): void {
    if (!this.canvas) return;
    this.canvas.width = element.size.width;
    this.canvas.height = element.size.height;
    this.levels.forEach((levelElement: AbstractLevel<any>) => levelElement.draw(element.viewRect));
    if (!this.pool.pool.length) {
      this.removeCachePart(index);
      return;
    }
    element['pool'] = this.pool.pool;
    this.pool.clearPool();
    this.setCachePartData(++index);
  }

  private removeCachePart(index: number): void {
    this._cacheData.splice(index, 1);
    this.setCachePartData(index);
  }

  protected drawCache(): void {
    if (!this._cacheData.length) return;
    const element = this._cacheData[0];
    this.resize(element.size.width, element.size.height);
    this.pool.addToPool(...this._cacheData[0].pool);
  }

  public poolDrawComplete(): void {
    if (!this._cacheData.length) return;
    const element = this._cacheData[0];
    this.saveCache(element.request);
  }

  protected cacheComplete(): void {
    this._cacheData.splice(0, 1);
    this.pool.clearPool();
    this.drawCache();
  }

  protected cacheSaveComplete(): void {
    if (this._cacheData.length) return;
    this.elementCacheComplete();
    IdbVectorCacheUtil.setStatus(this._cacheRequest, CacheStatus.complete)
      .then(() => {
        this.destroy();
        this.fullCacheComplete();
      });
  }

  protected setCanvas(data?: VectorLayerResponse): void {
    if (!this.canvas) return;
    this.canvas.width = this.partSize.width;
    this.canvas.height = this.partSize.height;
    this.ctx = this.canvas.getContext('2d');
    if (!this.ctx) return;
    this.ctx.imageSmoothingEnabled = true;
    this.ctx.imageSmoothingQuality = 'high';
  }

  public elementLoadComplete(): void {
    if (this.levels.length > ++this._loaded) return;
    this.prepareCache();
  }

  public addToDraw(data: DrawElement, scaleRect: RectSize): void {
    this.pool.addToPool({data, scaleRect});
  }

  protected abstract saveCache(cacheRequest: CacheRequest): void;

  public abstract render(element: any): void;

  public abstract syncClearCanvas(): void;

  public abstract asyncClearCanvas(): void;

  protected abstract resize(width: number, height: number): void;

  public abstract destroy(): void;

  protected abstract fullCacheComplete(): void;

  protected abstract elementCacheComplete(): void;

  protected abstract prepareCacheComplete(): void;

  protected abstract cacheCalcComplete(element: number): void;
}
