import {JsonProperty} from '@dhkatz/json-ts';
import {Size} from '../api/data/size';

export class RectSize extends Size {

  @JsonProperty('x')
  private _x: number;

  @JsonProperty('y')
  private _y: number;

  public constructor(size?: Size | RectSize) {
    super();
    if (size === undefined) {
      this._x = undefined;
      this._y = undefined;
      return;
    }
    this.width = size.width;
    this.height = size.height;
    if (!(size instanceof RectSize)) return;
    const rectSize = <RectSize>size;
    this._x = rectSize.x;
    this._y = rectSize.y;
  }

  public get x(): number {
    return this._x;
  }

  public set x(value: number) {
    this._x = value;
  }

  public get y(): number {
    return this._y;
  }

  public set y(value: number) {
    this._y = value;
  }

  public setRectSize(x: number, y: number, width: number, height: number): RectSize {
    super.setSize(width, height);
    this._x = x;
    this._y = y;
    return this;
  }

  public equal(size: Size | RectSize): boolean {
    const equal = size.width.toPrecision(1) === this.width.toPrecision(1) &&
      size.height.toPrecision(1) === this.height.toPrecision(1);
    if (!(size instanceof RectSize)) return equal;
    const rectSize = size as RectSize;
    return equal && rectSize.x.toPrecision(1) === this.x.toPrecision(1) &&
      rectSize.y.toPrecision(1) === this.y.toPrecision(1);
  }
}
