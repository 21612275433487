<div class="container-box">
  <div class="account-part user-management">
    <div class="box-title">
      {{'assignmentManagement.student.assignedAsAStudent.title' | translate}}
      <div class="add-code">
        <mat-form-field>
          <input type="text" placeholder="{{'assignmentManagement.student.assignedAsAStudent.schoolCode' | translate}}"
                 name="codeField" matInput [formControl]="codeField">
          <button title="{{'assignmentManagement.student.assignedAsAStudent.addToSchool' | translate}}"
                  class="submit-button" matSuffix (click)="addToInstitution()" [disabled]="showSpinner">
            <mat-icon>add</mat-icon>
          </button>
          <mat-error *ngIf="codeField.hasError('code')">
            {{'assignmentManagement.student.assignedAsAStudent.error.invalidSchoolCode' | translate}}
          </mat-error>
          <mat-error *ngIf="codeField.hasError('institution')">
            {{'assignmentManagement.student.assignedAsAStudent.error.alreadyAssigned' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <hr>
    <div #scrollContent class="account-list">
      <table *ngIf="list.length" mat-table [dataSource]="list"
             [ngClass]="{'mat-elevation-z8': true, 'loading': showSpinner}">
        <ng-container matColumnDef="title">
          <mat-cell *matCellDef="let element">{{element.title}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-cell *matCellDef="let element; let i = index;">
            <mat-icon class="trash" (click)="removeFromInstitution(i)">delete</mat-icon>
          </mat-cell>
        </ng-container>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </table>
      <div *ngIf="!showSpinner && !list.length" class="empty-list">
        {{'assignmentManagement.noAssignments' | translate}}
      </div>
      <app-spinner *ngIf="showSpinner" class="full"></app-spinner>
    </div>
  </div>
</div>
