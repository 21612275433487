import {JsonProperty} from '@dhkatz/json-ts';

export class FontFileResponse {

  @JsonProperty('t')
  private _type: string;

  @JsonProperty('f')
  private _file: string;

  public constructor() {
    this._type = undefined;
    this._file = undefined;
  }

  public get type(): string {
    return this._type;
  }

  public get file(): string {
    return this._file;
  }
}
