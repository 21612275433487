import {Injectable} from '@angular/core';
import {CustomInteractivesEditMode} from './interface/custom-interactives-edit-mode';
import {InteractiveCustomLayersChanged} from './interface/interactive-custom-layers-changed';

@Injectable({
  providedIn: 'root'
})
export class CustomInteractivesCommunicationService {

  private _InteractiveCustomLayersChangedListeners: InteractiveCustomLayersChanged[] = [];
  private _customInteractiveEditModeListeners: CustomInteractivesEditMode[] = [];

  public constructor() {
  }

  public addInteractiveCustomLayersChangedListener(listener: InteractiveCustomLayersChanged): void {
    this._InteractiveCustomLayersChangedListeners.push(listener);
  }

  public removeOnInteractiveLayersChangedListener(listener: InteractiveCustomLayersChanged): void {
    const index: number = this._InteractiveCustomLayersChangedListeners.indexOf(listener);
    if (index === -1) return;
    this._InteractiveCustomLayersChangedListeners.splice(index, 1);
  }

  public callInteractiveCustomLayersChanged(): void {
    if (this._InteractiveCustomLayersChangedListeners.length === 0) return;
    for (const listener of this._InteractiveCustomLayersChangedListeners) {
      try {
        listener.interactiveCustomLayersChanged();
      } catch (exception) {
        console.log(exception);
      }
    }
  }

  public addCustomInteractiveEditModeListener(listener: CustomInteractivesEditMode): void {
    this._customInteractiveEditModeListeners.push(listener);
  }

  public removeCustomInteractiveEditModeListener(listener: CustomInteractivesEditMode): void {
    const index: number = this._customInteractiveEditModeListeners.indexOf(listener);
    if (index === -1) return;
    this._customInteractiveEditModeListeners.splice(index, 1);
  }

  public callCustomInteractiveEditModeEnabled(layerId: string, projectId: string): void {
    if (this._customInteractiveEditModeListeners.length === 0) return;
    for (const listener of this._customInteractiveEditModeListeners) {
      try {
        listener.customInteractiveEditModeEnabled(layerId, projectId);
      } catch (exception) {
        console.log(exception);
      }
    }
  }

  public callCustomInteractiveEditModeDisabled(): void {
    if (this._customInteractiveEditModeListeners.length === 0) return;
    for (const listener of this._customInteractiveEditModeListeners) {
      try {
        listener.customInteractiveEditModeDisabled();
      } catch (exception) {
        console.log(exception);
      }
    }
  }
}
