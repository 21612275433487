import {JsonProperty} from '@dhkatz/json-ts';
import {MapNumberBooleanConverter} from '../../../json/map-number-boolean-converter';
import {RasterLevelResponse} from '../levels/raster-level-response';
import {AbstractLayerResponse} from './abstracts/abstract-layer-response';

export class CombinedLayerResponse extends AbstractLayerResponse {

  @JsonProperty({name: 'l', converter: MapNumberBooleanConverter.converter})
  private _index: Map<number, boolean>;

  @JsonProperty({name: 'd', type: RasterLevelResponse})
  private _details: RasterLevelResponse[];

  public constructor() {
    super();
    this._index = undefined;
    this._details = undefined;
  }

  public get index(): Map<number, boolean> {
    return this._index;
  }

  public get details(): RasterLevelResponse[] {
    return this._details;
  }

  public set details(value: RasterLevelResponse[]) {
    this._details = value;
  }
}
