import {JsonProperty} from '@dhkatz/json-ts';

export class AddressData {

  @JsonProperty('firstName')
  private _firstName: string;

  @JsonProperty('lastName')
  private _lastName: string;

  @JsonProperty('institutionName')
  private _institutionName: string;

  @JsonProperty('streetName')
  private _streetName: string;

  @JsonProperty('streetNumber')
  private _streetNumber: string;

  @JsonProperty('apartmentNumber')
  private _apartmentNumber: string;

  @JsonProperty('postCode')
  private _postCode: string;

  @JsonProperty('city')
  private _city: string;

  @JsonProperty('state')
  private _state: string;

  @JsonProperty('country')
  private _country: string;

  @JsonProperty('taxId')
  private _taxId: string;

  @JsonProperty('phoneNo')
  private _phoneNo: string;

  public constructor() {
    this._firstName = undefined;
    this._lastName = undefined;
    this._institutionName = undefined;
    this._streetName = undefined;
    this._streetNumber = undefined;
    this._apartmentNumber = undefined;
    this._postCode = undefined;
    this._city = undefined;
    this._state = undefined;
    this._country = undefined;
    this._taxId = undefined;
    this._phoneNo = undefined;
  }

  public get firstName(): string {
    return this._firstName;
  }

  public set firstName(value: string) {
    this._firstName = value;
  }

  public get lastName(): string {
    return this._lastName;
  }

  public set lastName(value: string) {
    this._lastName = value;
  }

  public get institutionName(): string {
    return this._institutionName;
  }

  public set institutionName(value: string) {
    this._institutionName = value;
  }

  public get streetName(): string {
    return this._streetName;
  }

  public set streetName(value: string) {
    this._streetName = value;
  }

  public get streetNumber(): string {
    return this._streetNumber;
  }

  public set streetNumber(value: string) {
    this._streetNumber = value;
  }

  public get apartmentNumber(): string {
    return this._apartmentNumber;
  }

  public set apartmentNumber(value: string) {
    this._apartmentNumber = value;
  }

  public get postCode(): string {
    return this._postCode;
  }

  public set postCode(value: string) {
    this._postCode = value;
  }

  public get city(): string {
    return this._city;
  }

  public set city(value: string) {
    this._city = value;
  }

  public get state(): string {
    return this._state;
  }

  public set state(value: string) {
    this._state = value;
  }

  public get country(): string {
    return this._country;
  }

  public set country(value: string) {
    this._country = value;
  }

  public get taxId(): string {
    return this._taxId;
  }

  public set taxId(value: string) {
    this._taxId = value;
  }

  public get phoneNo(): string {
    return this._phoneNo;
  }

  public set phoneNo(value: string) {
    this._phoneNo = value;
  }
}
