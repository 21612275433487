import {Size} from '../../../../../../../globals/api/data/size';
import {RectSize} from '../../../../../../../globals/elements/rect-size';
import {PartsUtil} from '../../utils/parts-util';

export abstract class AbstractImageElement extends RectSize {

  protected constructor(col: number, row: number, partSize: Size) {
    super();
    this.x = col * partSize.width - col * PartsUtil.FIX;
    this.y = row * partSize.height - row * PartsUtil.FIX;
    this.width = partSize.width;
    this.height = partSize.height;
  }
}
