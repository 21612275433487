<mat-dialog-content>
  <div class="container" [ngClass]="{'loading': showSpinner}">
    <p translate [translateParams]="{title: task?.title | plain}">project.tasks.removeInfo</p>
    <footer class="buttons">
      <button mat-button class="cancel-button" (click)="close.emit()">{{'system.label.cancel' | translate}}</button>
      <button mat-button class="submit-button" (click)="onSubmit()">{{'system.label.remove' | translate}}</button>
    </footer>
  </div>
</mat-dialog-content>
<app-spinner *ngIf="showSpinner" class="full primary"></app-spinner>
