<header mat-dialog-title class="add-point-header">
  <ng-container *ngIf="!point">
    {{'project.interactive.points.title.add' | translate}}
  </ng-container>
  <ng-container *ngIf="point">
    {{'project.interactive.points.title.edit' | translate}}
  </ng-container>
  {{'project.interactive.points.title.embedPoint' | translate}}
  <span class="close" (click)="closeDialog()"><mat-icon>close</mat-icon></span>
</header>
<mat-dialog-content class="add-point-container" *ngIf="!removePoint">
  <form>
    <app-icon [(icon)]="iconId" [error]="iconError"></app-icon>

    <div class="embed-media">
      <div *ngIf="linkTypes?.length > 1" class="embed-select interactive-point-select">
        <mat-form-field class="mat-select-value">
          <mat-select placeholder="{{'project.interactive.points.labels.linkType' | translate}}"
                      [formControl]="embedTypeField">
            <mat-option *ngFor="let type of linkTypes;" [value]="type"
                        class="interactive-point-option embed-select-option">
              {{type}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="embedTypeField.hasError('required')" class="select-error">
            {{'project.interactive.points.error.linkTypeIsRequired' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field *ngIf="linkTypes?.length > 1" class="embedVideoLink">
        <input placeholder="{{'project.interactive.points.labels.videoLink' | translate}}" matInput
               [formControl]="embedLinkField">
        <mat-error *ngIf="embedLinkField.hasError('required')">
          {{'project.interactive.points.error.linkIsRequired' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="linkTypes?.length === 1" class="embedAudioLink">
        <input placeholder="{{'project.interactive.points.labels.videoLink' | translate}}" matInput
               [formControl]="embedLinkField">
        <mat-error *ngIf="embedLinkField.hasError('required')">
          {{'project.interactive.points.error.linkIsRequired' | translate}}
        </mat-error>
      </mat-form-field>
    </div>
    <label class="field-label">{{'project.interactive.points.labels.title' | translate}}</label>
    <ck-editor [config]="basicWysiwygConfig" [formControl]="titleField" skin="moono-lisa"
               [language]="'system.langCode' | translate" [fullPage]="false"></ck-editor>
    <mat-error *ngIf="titleField.hasError('required')">
      {{'project.interactive.points.error.titleIsRequired' | translate}}
    </mat-error>
    <label class="field-label">{{'project.interactive.points.labels.description' | translate}}</label>
    <ck-editor skin="moono-lisa" [config]="wysiwygConfig" [formControl]="descriptionField"
               [language]="'system.langCode' | translate" [fullPage]="false"></ck-editor>
    <mat-error *ngIf="descriptionField.hasError('required')">
      {{'project.interactive.points.error.descriptionIsRequired' | translate}}
    </mat-error>
    <mat-accordion class="add-point-text-more">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'project.interactive.points.info.moreFields' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field>
          <input placeholder="{{'project.interactive.points.labels.source' | translate}}" matInput
                 [formControl]="sourceField">
        </mat-form-field>
        <mat-form-field>
          <input placeholder="{{'project.interactive.points.labels.sourceLink' | translate}}" matInput [formControl]="sourceUrlField">
        </mat-form-field>
        <mat-form-field>
          <input placeholder="{{'project.interactive.points.labels.copyrights' | translate}}" matInput [formControl]="copyrightsField">
        </mat-form-field>
        <mat-form-field>
          <input placeholder="{{'project.interactive.points.labels.copyrightsLink' | translate}}" matInput [formControl]="copyrightsUrlField">
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>

    <footer class="buttons interactive-points-footer">
      <button *ngIf="point" mat-button class="cancel-button" (click)="removePoint = true">
        {{'project.interactive.points.button.delete' | translate}}
      </button>
      <button mat-button class="submit-button" (click)="onSubmit()">
        <ng-container *ngIf="!point">
          {{'project.interactive.points.button.add' | translate}}
        </ng-container>
        <ng-container *ngIf="point">
          {{'project.interactive.points.button.save' | translate}}
        </ng-container>
      </button>
    </footer>
  </form>
</mat-dialog-content>
<app-point-remove *ngIf="removePoint" (close)="removePoint = false" [projectId]="projectId" [layerId]="layerId"
                  [point]="point"></app-point-remove>
