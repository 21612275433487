import {Component, OnInit} from '@angular/core';
import {AbstractRoleComponent} from '../../../globals/screens/abstracts/abstract-role.component';
import {ServiceWorkerService} from '../../../web-workers/service/service-worker.service';
import {AccountResponse} from '../../api/data/account-response';
import {AccountService} from '../../api/service/account.service';

@Component({
  selector: 'app-account',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends AbstractRoleComponent implements OnInit {

  public accountResponse: AccountResponse;
  public showSpinner = true;

  public constructor(private readonly _service: AccountService, workerService: ServiceWorkerService) {
    super(workerService);
  }

  public ngOnInit(): void {
    this._service.getAccountDetails().subscribe((details: AccountResponse) => {
      this.accountResponse = details;
    }, () => this.showSpinner = false, () => this.showSpinner = false);
  }
}
